import React from "react";
import SRList from "../../component/List";

export default function ListAllSchool2(props) {
  const { moreSearch } = props;

  const title = "Báo cáo kiểm tra vở ghi chép toàn trường";
  const columns = [
    { name: "id", label: "ID", options: { filter: true, sort: false } },
    { name: "title", label: "Lớp", options: { filter: true, sort: false } },
    { name: "total_1", label: "Số lượt kiểm tra", options: {  filter: true, sort: true,
        customBodyRender: (value, tableMeta) => {
          const total = tableMeta.rowData[3]+tableMeta.rowData[4]+tableMeta.rowData[5]+tableMeta.rowData[6];
          return total
        } } },
    {
      name: "total_1",
      label: "Đầy đủ, sạch sẽ",
      options: { filter: true, sort: true },
    },
    {
      name: "total_2",
      label: "Đủ, cầu thả",
      options: { filter: true, sort: true },
    },
    {
      name: "total_3",
      label: "Ghi chép thiếu",
      options: { filter: false, sort: true },
    },
    {
      name: "total_4",
      label: "Không ghi chép",
      options: { filter: false, sort: true },
    },
  ];

  return (
    <>
      <SRList
        name="report_attends_class"
        moresearch={moreSearch}
        params={
          moreSearch
            ? "begin=" +
              moreSearch.begin +
              "&end=" +
              moreSearch.end +
              "&type=" +
              moreSearch.type
            : ""
        }
        title={title}
        defaultLimit={200}
        serverSide={false}
        columns={columns}
        noAction={true}
      ></SRList>
    </>
  );
}

