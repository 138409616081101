import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { URL_LIST_ALL } from "../../configs/Api";
import Spinner from "../../component/Spinner";

export default function ActionSend(props) {
  const { timeOut, listQuestion, detail, onSuccess, test } = props;
  const { user } = useSelector((state) => state.auth);
  const { Answers } = useSelector(state => state.save);
  const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
  const localclass_id = user && user.data && user.data.class && user.data.class.class_id > 0 ? user.data.class.class_id : 0;
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    //if(timeOut===true)saveParts()
  }, [timeOut]);// eslint-disable-line

  const calculated = (_listAnswers) => {

    let data = { number: 0, point: 0, total: listQuestion.length }
    if (_listAnswers) {
      let per = 10 / data.total;
      Object.values(_listAnswers).map((item) => {
        if (item.percent === 100) data.number++
        data.point += Math.round(per * item.percent) / 100
        return item;
      })
    }
    return data;
  }

  const saveParts = (status) => {
    setLoading(status)
    const result = calculated(Answers);
    let count_answer = 0
    try {
      count_answer = Object.keys(Answers).length
    } catch (error) {

    }
    const datahis = {
      test_id: detail.id,
      school_id: school_id,
      grade_id: detail.grade_id,
      class_id: localclass_id,
      subject_id: detail.subject_id,
      type_id: detail.type_id,
      time: detail.id,
      title: detail.title,
      content: JSON.stringify(Answers),
      correct_number: result.number,
      point: result.point,
      total_number: listQuestion.length,
      remain: 0,
      status: status,
      istest: test ? 1 : 0,
      total_answer: count_answer
    }
    // console.log(Answers);
    Axios.post(URL_LIST_ALL + "exam_history", datahis, user.config).then(async (res) => {
      setLoading(null)
      if (res.data.status === "success") {
        onSuccess(status);
      } else console.log(res.data.message);
    }).catch((err) => { console.log(err); setLoading(null) });

  }
  return (
    <>
      <button type="submit" className="btn btn-success btn-block cursor-pointer" onClick={() => saveParts(1)}>
        {loading === 1 ? <Spinner /> : <>Hoàn thành</>}
      </button>
      <button type="submit" className="btn btn-warning btn-block cursor-pointer" onClick={() => saveParts(0)}>
        {loading === 0 ? <Spinner /> : <>Lưu nháp</>}
      </button>
    </>
  );
}
