import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import ViewStep from "../examination/tests/ViewStep";
import Spinner from "../component/Spinner";
import { CONFIG_STEP_TEST_1 } from "../configs";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/Api";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import QuestionByTree from "../examination/tests/QuestionByTree";
import GenderCode from "../component/GenderCode";

const ShowTre = ({ list, questions, onCheck }) => {
  const [show, setShow] = useState(null)
  return <>
    {list.map((item, index) => <div key={index} className="pl-3">
      {item.children && item.children.length > 0 && <span className="fas fa-plus-circle text-success mr-2" onClick={() => setShow(item.id)}></span>}
      <input type="checkbox" value={item.id} onClick={(e) => onCheck(e, item)} defaultChecked={(questions && questions[item.id]) ? true : false} />
      &nbsp; {item.title} {show === item.id && item.children && item.children.length > 0 && <div className="pl-3"><ShowTre list={item.children} onCheck={onCheck} /></div>}
    </div>)}
  </>
}

export default function RacingStep2(props) {
  const { user } = useSelector((state) => state.auth);
  const { detail, nextStep, previewStep } = props;
  const [tree, setTree] = useState(null);
  const [mytree, setMyTree] = useState(null);

  useEffect(() => {
    if (detail) {
      let mounted = true;
      Axios.get(URL_LIST_ALL + "at_exam_questions?limit=100000&grade_id=" + detail.grade_id + '&category_id=' + detail.category_id, user.config)
        .then(async (res) => {
          if (res.data.status === "success") {
            let data_ques = res.data.data
            let newQues = []
            data_ques.map( (item,i) => {
              if(!handleCheckItem(item.id)){
                newQues.push(item)
              }
            })
            setTree(newQues)
          } else console.log(res.data.message);
        })
        .catch((err) => console.log(err));
      return () => (mounted = false);
    }
  }, [detail]);// eslint-disable-line

  const formik = useFormik({
    initialValues: detail,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false)
      nextStep(values)
    },
  });

  const selectQuestion = (qID) => {
    var listQuestion = formik.values.questions
    if (listQuestion && Array.isArray(listQuestion)) {
      if (handleCheckItem(qID.id)) {
      } else {
        listQuestion.push(qID)
        handleRemoveItem(qID.id)
      }
    } else {
      listQuestion = [qID]
      handleRemoveItem(qID.id)
    }
    formik.setFieldValue('questions',listQuestion)
  }

  const handleCheckItem = (id) => {
    try{
      if (formik.values && formik.values.questions) {
        const itemExists = formik.values.questions.some(item => item.id === id);
        if (itemExists) {
          return true
        } else {
          return false
        }
      }
    }catch(e){

    }
    
  };

  const handleRemoveItem = (id) => {
    const updatedTree = tree.filter(item => item.id !== id);
    setTree(updatedTree);
  };
  const deleteQuestion = (id) => {
    const updatedTree = detail.questions.filter(item => item.id !== id);
    detail.questions = updatedTree;
    formik.values.questions = updatedTree
  };

  const actionFinished = (_tree, _question) => {
    setMyTree(null);
    let questions = { ...formik.values.questions };
    questions[_tree.id] = _tree;
    if (_question.length > 0) questions[_tree.id].list = _question;
    else delete questions[_tree.id];
    formik.setFieldValue('questions', questions);
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-md-6 offset-md-3"><ViewStep step={1} arrStep={CONFIG_STEP_TEST_1} /></div>
      <div className="col-sm-12">
        {(tree && formik) && <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-md-4">
              <h4>Danh sách câu hỏi</h4>
              <div className="w-100">
                {tree && tree.map((item, index) =>
                  <div className="rounded p-2 mb-2 border item-tree" key={index}>
                    <div className="title">
                      <GenderCode text_string={item.title} />
                    </div>
                    <div className="w-100 d-flex justify-content-between align-items-center border-top pt-2">
                      <span>ID: {item.id}</span>
                      <button type="button" className="btn btn-sm btn-info" onClick={() => selectQuestion(item)}>
                        Chọn câu hỏi
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-8">
              <h4>Các câu hỏi đã chọn - <span>Số lượng: {formik.values.questions ? formik.values.questions.length : 0} câu</span></h4>
              {formik.values.questions && Array.isArray(formik.values.questions) && <div>
                {formik.values.questions.map((item, index) => {
                  return (
                    <div className="rounded p-2 mb-2 border item-tree" key={index}>
                      <div className="title">
                        <GenderCode text_string={item.title} />
                      </div>
                      <div className="w-100 d-flex justify-content-between align-items-center border-top pt-2">
                        <span>ID: {item.id}</span>
                        <button type="button" className="btn btn-sm btn-danger" onClick={() => deleteQuestion(item.id)}>
                          Xóa
                        </button>
                      </div>
                    </div>
                  )
                })}
              </div>}
            </div>
            <div className="col-12 form-group mg-t-8 text-center">
              <button type="button" onClick={previewStep} className="btn-fill-lg bg-blue-dark btn-hover-yellow" >Quay lại</button>
              <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" > {formik.isSubmitting ? <Spinner /> : <>Tiếp tục</>}</button>
            </div>
          </div>
        
        </form>}
      </div>
    </div>
  );
}
