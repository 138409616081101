import Axios from "axios";
import React, {  useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { URL_LIST_ALL } from "../configs/Api";
import AlertErrorForm from "./AlertErrorForm";
import { useFormik } from "formik";
import * as Yup from "yup";
import UserDropDown from "./UserDropDown";
import { checkRole } from "../model";


export default function TeacherOfSubject(props) {
    const { id,row, onRefresh } = props;
    const { user } = useSelector((state) => state.auth);
    const { year_id } = useSelector((state) => state.save);
    const school_id = user && user.data && user.data.school_id>0?user.data.school_id:0;
    const [refresh, setRefresh] = useState(false);
    const [show, setShow] = useState(false);
    const [members, setMembers] = useState([]);
    const allowReset = checkRole(user,'list-users','reset');

    const formik = useFormik({
        initialValues: {user_id:'',class_id:'',school_id, subject_id:id , year_id },
        enableReinitialize:true,
        validationSchema: Yup.object().shape({
            user_id: Yup.string().required('Chọn tài khoản'),
            class_id: Yup.string().required('Chọn lớp'),
        }),
        onSubmit: (values, { setSubmitting }) => {
            Axios.post(URL_LIST_ALL+'users_class',values, user.config).then((res)=>{
                setSubmitting(false)
                if(res.data.status==='success'){
                    setRefresh(!refresh)
                }else{
                    alert(res.data.message); 
                }
              }).catch((err)=>{
                alert(err); 
              })
        }
    });

    useEffect(() => {
        if(show===true)
        {
            setMembers([])
            Axios.get(URL_LIST_ALL+'users_class?limit=100&subject_id='+id+ "&year_id=" + year_id, user.config).then((res)=>{
                if(res.data.status==='success'){
                    setMembers(res.data.data)
                }else{
                    alert(res.data.message); 
                }
            })
        }
    }, [show,id,user,refresh,year_id]);


    const deleteMember = (member) => {
        const stt = window.confirm('Bạn có chắc muốn xoá giáo viên khỏi bộ phận?')
        if(stt)
        Axios.delete(URL_LIST_ALL+'users_class/'+member.idpri, user.config).then((res)=>{
            if(res.data.status==='success'){
                setRefresh(!refresh)
                
            }else{
                alert(res.data.message); 
            }
        })
    }

    const ClosePopup= () => {
        if(onRefresh)onRefresh()
        setShow(false)
    }

    return (
        <>
        {allowReset?<>
        <button className="btn btn-info btn-sm" onClick={()=>setShow(true)}><i className="fas fa-user"></i> Giáo viên</button>
        </>:<>
        <button className="btn btn-secondary btn-sm" disabled ><i className="fas fa-user"></i> Giáo viên</button>
        </>}
        <Modal show={show} onHide={() => setShow(false)} animation={true} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Danh sách giáo viên</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                    <div className="d-flex">
                        <span className="mr-2 mt-1">Thêm giáo viên: </span>
                        <div><UserDropDown apiname={"users?page=1&limit=500&typeaccount=1&school_id=" + school_id} placeholder="Chọn giáo viên" colvalue={'users.id'} colname={'users.fullname'}  formik={formik} name='user_id'  onChange={async(e)=>{   await formik.setFieldValue('user_id',e)   }}></UserDropDown>
                        </div>
                        <div className="ml-2"><UserDropDown apiname={"class?page=1&limit=500&grade_id=" + (row?row.grade_id:'')+ "&year_id=" + year_id} placeholder="Chọn lớp" colvalue={'class.id'} colname={'class.title'}  formik={formik} name='class_id'  onChange={async(e)=>{   await formik.setFieldValue('class_id',e)   }}></UserDropDown>
                        </div>
                        <button type="submit" className="btn btn-success btn-sm mx-2" >Thêm</button>
                        <AlertErrorForm formik={formik} name="user_id"></AlertErrorForm>
                        <AlertErrorForm formik={formik} name="class_id"></AlertErrorForm>
                    </div>
                </form>

                {members.length>0 && <table className="table table-sm table-hover table-striped mt-2">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Lớp</th>
                        <th>Họ và tên</th>
                        <th>Số điện thoại</th>
                        <th>Xoá</th>
                    </tr>
                    </thead>
                    <tbody>
                        {members.map((item,i)=><tr key={i}>
                            <td>{i+1}</td>
                            <td>{item.classtitle}</td>
                            <td>{item.fullname}</td>
                            <td>{item.mobile}</td>
                            <td><button type="button" className="btn btn-danger btn-sm" onClick={()=>deleteMember(item)} >Xoá</button></td>
                        </tr>)}
                    </tbody>
                </table>}
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary btn-sm" onClick={ClosePopup} >Đóng</button>
            </Modal.Footer>
        </Modal>
        </>
    );
}
