import React, { useEffect, useState } from "react";
import ButtonCheck from "../../component/ButtonCheck";
import Violation from "../../component/Violation";
import {Cell} from 'react-sticky-table';

export default function ItemExtrac(props) {
  const { i, data, choiceItem, isaction } = props;
    const [attends, setAttends] = useState(1);
    const [currentData, setCurrentData] = useState(data);
    const [note2, setNote2] = useState(data.notes2);

    const updateOnDay = (stt) => {
        setAttends(stt);
        choiceItem({...currentData,status: stt});
    };

    useEffect(() => {
        setAttends(parseInt(currentData.status))
    }, [currentData]);

    const setChangeData=(e) => {
        let newdata = {...e,status: attends}
        choiceItem(newdata);
        setCurrentData(newdata);
    }
    const onChangeNote = (e) => {
        setNote2(e.target.value)
    }
    const onBlurNote = () => {
        choiceItem({...currentData,notes2:note2});
    } 

  
  return (
    <>
      <Cell >
        <div className="text-center w-100">{i + 1}</div>
      </Cell>
      <Cell >
        {data.fullname}
      </Cell>
      <Cell >
        <div className="text-center w-100">
          <i  className={ data.gender === 1 ? "fas fa-male text-success" : "fas fa-female text-danger"}></i>
        </div>
      </Cell>
      <Cell >
        <div className="text-center w-100">
          <span onClick={() => isaction?updateOnDay(1):null}><ButtonCheck checked={attends===1} color='#21c83d' ></ButtonCheck></span>
        </div>
      </Cell>
      <Cell >
        <div className="text-center w-100">
          <span onClick={() => isaction?updateOnDay(2):null}><ButtonCheck checked={attends===2} color='#efd732'></ButtonCheck></span>
        </div>
      </Cell>
      <Cell >
        <div className="text-center w-100">
          <span onClick={() => isaction?updateOnDay(3):null}><ButtonCheck checked={attends===3} color='#fd7a23'></ButtonCheck></span>
        </div>
      </Cell>
      <Cell >
        <div className="text-center w-100">
          <span onClick={() => isaction?updateOnDay(4):null}><ButtonCheck checked={attends===4} color='#db0d18'></ButtonCheck></span>
        </div>
      </Cell>
      <Violation data={data} updateData={setChangeData}  isaction={isaction}></Violation>
      <Cell >
          <div className="text-center w-100">
          <input className="form-control form-control-sm" style={{minWidth:250}} placeholder="Ghi chú" value={note2} onChange={onChangeNote} onBlur={onBlurNote} ></input>
          </div>
      </Cell>
    </>
  );
}
