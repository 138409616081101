import React from "react";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import { COLORS_CHECK } from "../../configs";

export default function ReportItemAttends(props) {
  const { i, student, listOnday,lunch, tietHoc } = props;
  const titles = [
    "Chưa điểm danh",
    "Có mặt",
    "Đến muộn",
    "Vắng có phép",
    "Vắng không phép",
  ];
  const violation = "#900";


  return (
    <>
      <td>
        <div className="text-center w-100">{i + 1}</div>
      </td>
      <td>{student.fullname}</td>
      <td>{student.code}</td>
      <td>{lunch?<>{lunch.status2===1?<span style={{color:'#21c83d'}}>Có mặt</span>:<span  style={{color:'#db0d18'}}>Vắng mặt</span>}</>:<span  style={{color:'#999'}}>Chưa điểm danh</span>}</td>
      <td>{lunch?<>{lunch.status===1?<span style={{color:'#21c83d'}}>Có </span>:<span  style={{color:'#db0d18'}}>Không </span>}</>:<span  style={{color:'#999'}}>Chưa điểm danh</span>}</td>
      {tietHoc.map((item, i) => {
        let onday = null;
        listOnday.map((day) => {
          if (day.orderonday === item.orderonday) {
            onday = day;
            return true;
          }
          return day;
        });

        return (
          <td key={i}>
            <div className="text-center w-100 d-flex justify-content-center">
              {onday === null ? (
                <i style={{ color: COLORS_CHECK[0] }}>{titles[0]}</i>
              ) : (
                <div>
                  <OverlayTrigger placement="top" overlay={<Tooltip>{onday.notes2}</Tooltip>}>
                  <b style={{ color: COLORS_CHECK[onday.status], fontSize: 14 }}>{titles[onday.status]}</b>
                  </OverlayTrigger>
                  {onday.violation ? (
                    <p style={{ color: violation, fontSize: 14 }}>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>{onday.notes}</Tooltip>}
                      >
                        <b>Vi phạm</b>
                      </OverlayTrigger>
                    </p>
                  ) : null}
                </div>
              )}
            </div>
          </td>
        );
      })}
    </>
  );
}
