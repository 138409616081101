import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../login.scss";
// import Axios from "axios";
import { URL_LIST_ALL } from "../configs/Api";
import { actions } from "../redux/authRedux";
import Axios from "axios";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import bg_login from "../assets/img/bg-login.svg"
import logo from "../assets/img/logo.png"
const { REACT_APP_RELEASE } = process.env;

const release = REACT_APP_RELEASE;
export default function Login() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({ isAuthorized: auth.user != null }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const ValidSchema = Yup.object().shape({
    username: Yup.string().trim().nullable().required("Vui lòng nhập tên đăng nhập"),
    password: Yup.string().trim().nullable().min(6, "Mật khẩu có độ dài tối thiểu 6 ký tự").max(150, "Mật khẩu có độ dài tối đa 150 ký tự").required("Vui lòng nhập mật khẩu"),
  });
  //936931979 
  //983191138
  //adminngoisao - 123456
  const createConfig = (_token) => {
    return {
      headers: {
        'Authorization': 'Bearer ' + _token,
        'Content-Type': 'application/json; charset=UTF-8',
        'search': ''
      }
    }
  }

  const successGoogle = (response) => {
    let urlParams = new URLSearchParams(window.location.search);
    let redirect_url = urlParams.get('redirect_url');
    Axios.post(URL_LIST_ALL + "login-social", response).then((res) => {
      if (res.data.status === "success") {
        let user = res.data;
        user.config = createConfig(res.data.access_token);
        dispatch(actions.login(user));
        if(redirect_url) {
          window.location.href = redirect_url
        }
      } else alert(res.data.message)
    }).catch((err) => console.log(err));
  }


  const formik = useFormik({
    initialValues: { username: release === "false" ? "adminxuandinh" : '', password: release === "false" ? "123456" : '' },
    validationSchema: ValidSchema,
    onSubmit: (values, { setSubmitting }) => {
      Axios.post(URL_LIST_ALL + "login", values)
        .then((res) => {
          setSubmitting(false);
          if (res.data.status === "success") {
            let user = res.data;
            user.config = createConfig(res.data.access_token);
            dispatch(actions.login(user));
          } else alert(res.data.message)
        })
        .catch((err) => console.log(err));
    },
  });

  return (
    <div>
      {isAuthorized ? (
        <Redirect to="/" />
      ) : (
        <>
          <div className="limiter" >
            <div className="container-login100" style={{backgroundImage: "url(" + bg_login + ")"}}>
              <div className="wrap-login100 p-t-90 p-b-30 shadow">
                <form
                  className="login100-form validate-form"
                  method="post"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="logo-group mb-4">
                    <img src={logo} alt=""/>
                  </div>
                  <p className="w-100 text-center">Vui lòng đăng ký hoặc đăng nhập để tiếp tục sử dụng tài nguyên trên website Tomotech</p>
                  <div className="wrap-input100 validate-input mt-3">
                    <input
                      className="input100 shadow"
                      type="text"
                      name="username"
                      placeholder="Tên đăng nhập"
                      {...formik.getFieldProps("username")}
                    />
                    <span className="focus-input100"></span>
                  </div>
                  {formik.touched.username && formik.errors.username ? (
                    <div className="text-danger mt-1 text-center">{formik.errors.username}</div>
                  ) : null}

                  <div className="wrap-input100 validate-input mt-3">
                    <span className="btn-show-pass">
                      <i className="fa fa fa-eye"></i>
                    </span>
                    <input className="input100 shadow" type="password" name="password" placeholder="Mật khẩu" {...formik.getFieldProps("password")} />
                    <span className="focus-input100"></span>

                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-danger mt-1 text-center">{formik.errors.password}</div>
                  ) : null}
                  <div className="container-login100-form-btn mt-3">
                    <button
                      type="submit"
                      className="login100-form-btn"
                      disabled={formik.isSubmitting}
                    >
                      Đăng nhập
                    </button>
                    <div className="pt-3">
                      <GoogleOAuthProvider clientId="285885337697-22aa6seq9vsieovg6ptfq87pobmlkv1f.apps.googleusercontent.com">
                        <GoogleLogin
                          clientId="285885337697-22aa6seq9vsieovg6ptfq87pobmlkv1f.apps.googleusercontent.com"
                          onSuccess={credentialResponse => {
                            let token = credentialResponse.credential
                            successGoogle(jwtDecode(token))
                            console.log(jwtDecode(token));
                          }}
                          onError={() => {
                            console.log('Login Failed');
                          }}
                        />
                      </GoogleOAuthProvider>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
