import { useFormik } from "formik";
import * as Yup from "yup";
import React, {  useState } from "react";
import AkkhorLayout from "../layout/Akkhor";
import {  useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/Api";
import { confirmAlert } from "react-confirm-alert";

export default function FormPasswordPage() {
    const { user } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        initialValues: {oldpassword:'',password:'',repassword:''},
        enableReinitialize:true,
        validationSchema: Yup.object().shape({
            oldpassword: Yup.string().trim().nullable().required('Nhập mật khẩu cũ').max(150, "Độ dài không quá 150 ký tự"),
            password: Yup.string().trim().nullable().required('Nhập mật khẩu mới').min(6, "Độ dài tối thiểu 6 ký tự").max(150, "Độ dài không quá 150 ký tự"),
            repassword: Yup.string().trim().nullable().required('Nhập xác nhận mật khẩu').oneOf([Yup.ref('password'), null], 'Xác nhận không chính xác'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            Axios.post(URL_LIST_ALL+'change', values, user.config).then(res => {
                
                resetForm();
                setLoading(false);
                setSubmitting(false);
                if(res.data.status==='success'){
                    confirmAlert({ title: 'Thành công',message: 'Đổi mật khẩu thành công',buttons: [{ label: 'OK' }]}); 
                  }else{
                      confirmAlert({ title: 'Lỗi',message: res.data.message,buttons: [{ label: 'OK' }]}); 
                  }
            }).catch(err => console.log(err));
        },
    });


  return (
    <AkkhorLayout idrole="change-password">
      <div className="card height-auto mt-5">
        <div className="card-body">
            <div className="heading-layout1">
                <div className="item-title">
                    <h3>Thay đổi mật khẩu</h3>
                </div>
            </div>
            <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-12 form-group">
                        <label>Mật khẩu cũ <span className="text-danger">*</span></label>
                        <input type="password" placeholder="" className="form-control height32" {...formik.getFieldProps("oldpassword")} autoComplete="off"/>
                        <AlertErrorForm formik={formik} name="oldpassword"></AlertErrorForm>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-xl-4 col-lg-6 col-12 form-group">
                        <label>Mật khẩu mới  <span className="text-danger">*</span></label>
                        <input type="password" placeholder="" className="form-control height32"  {...formik.getFieldProps("password")} autoComplete="off"/>
                        <AlertErrorForm formik={formik} name="password"></AlertErrorForm>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-xl-4 col-lg-6 col-12 form-group">
                        <label>Xác nhận mật khẩu</label>
                        <input type="password" placeholder="" className="form-control height32" {...formik.getFieldProps("repassword")} autoComplete="off"/>
                        <AlertErrorForm formik={formik} name="repassword"></AlertErrorForm>
                    </div>
                   
                    <div className="col-12 form-group mg-t-8">
                    <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner />:<>Lưu mật khẩu</>}</button>
                        <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                    </div>
                </div>
            </form>
        </div>
      </div>
    </AkkhorLayout>
  );
}
