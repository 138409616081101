import React from "react";

export default function ViewStep(props) {
  const { step,arrStep } = props;
  
  return (
    <div className="liststep">
      {arrStep.map((title,index)=><div className={"itemstep "+(index<=step&&'active')} key={index}>
        <div className="number">{index+1}</div>
        <div className="title">{title}</div>
      </div>)}
    </div>
  );
}
