import React from "react";
import QTrueFlase from "./QTrueFlase";
import QChooseMulti from "./QChooseMulti";
import QChooseOne from "./QChooseOne";
import QDropDrag from "./QDropDrag";
import QEssay from "./QEssay";
import QFillTo from "./QFillTo";
import QMatching from "./QMatching";
import FormQuestion from "../FormQuestion/index";
import { useSelector } from "react-redux";

export default function ChoiceQuestionGeneral(props) {
  const {
    item,
    index,
    onRefresh,
    action,
    parentItem,
  } = props;
  const { Answers } = useSelector(state => state.save);

  const objQues = {
    QTrueFlase: (props) => <QTrueFlase {...props} />,
    QChooseMulti: (props) => <QChooseMulti {...props} />,
    QChooseOne: (props) => <QChooseOne {...props} parentItem={parentItem} questionIdx={index} />,
    QDropDrag: (props) => <QDropDrag {...props} />,
    QEssay: (props) => <QEssay {...props} />,
    QFillTo: (props) => <QFillTo {...props} />,
    QMatching: (props) => <QMatching {...props} />
  };
  const Tag = objQues[item.code];

  let currentAnswer = null
  if (parentItem === undefined)
    currentAnswer = item && item.id && Answers && Answers[item.id]
      ? Answers[item.id].answers
      : null
  else
    currentAnswer = Answers && Answers[parentItem.id] && Answers[parentItem.id].children[index]
      ? Answers[parentItem.id].children[index].answers
      : null

  return (
    <>
      <Tag
        item={item}
        currentAnswer={currentAnswer}
      />
      {action && (
        <FormQuestion
          index={index}
          item={item}
          edit={true}
          onRefresh={onRefresh}
        />
      )}
    </>
  );
}
