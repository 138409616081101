import Axios from "axios";
import React, { useEffect, useState } from "react";
import AkkhorLayout from '../../layout/Akkhor';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { URL_LIST_ALL } from "../../configs/Api";
import ChoiceQuestion from "../questions/ChoiceQuestion";
import { Link } from 'react-router-dom';
import Moment from "react-moment";
import moment from "moment";
import TimeOut from "./TimeOut";
import ActionSend from "./ActionSend";
import { confirmAlert } from "react-confirm-alert";
import { saveAnswers } from "../../redux/Actions";
import GenderCode from "../../component/GenderCode";
import wavFile from "../../assets/alam.wav"
import SuggestQuestion from "../../component/SuggestQuestion";
import Spinner from "../../component/Spinner";

const _ = require('lodash');

export default function DetailExam(props) {
  const { id } = useParams();
  let history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const { Answers } = useSelector(state => state.save);
  const [soundPlay, setSoundPlay] = useState(false);
  const [detail, setDetail] = useState(null);
  const [listQuestion, setListQuestion] = useState([]);
  const [indexQuestion, setIndexQuestion] = useState(0);
  const [prevQuesID, setPrevQuesID] = useState(0);
  const [now] = useState(moment(new Date()));
  const [formatMask, setFormatMask] = useState('HH:mm:ss');
  const [timeOut, setTimeOut] = useState(false);
  const [considers, setConsiders] = useState(null);
  const [sendSuccess, setSendSuccess] = useState(false);// eslint-disable-line
  const dispatch = useDispatch();
  const [studenOnleave, setStudenOnleave] = useState(false);

  const [checking, setChecking] = useState(false);   // Phản hồi từ API

  useEffect(() => {
    if (id > 0) {
      let mounted = true;
      Axios.get(URL_LIST_ALL + "at_exam_tests/" + id, user.config).then(async (res) => {
        if (res.data.status === "success" && mounted) {
          const _detail = res.data.data;
          const localTime = _detail.time;
          if (localTime >= 60) setFormatMask('HH:mm:ss');
          else setFormatMask('mm:ss');
          now.add(localTime, 'minutes')//minutes
          setDetail(_detail);
          let _listquestion = [];
          const questions = _detail.questions
          Object.values(questions).map(ques => {
            ques.list.map((q) => {
              if (q.code === 'QMatching') {
                q.corrects = _.shuffle(q.corrects);
              }
              q.answers = { list: q.answers, correct: q.corrects }
              _listquestion.push(q);
              return q;
            })
            return (ques)
          })
          if (_detail.random_question === 1) {
            _listquestion = _listquestion.sort(() => Math.random() - 0.5)
          }
          if (_listquestion && _listquestion.length > 0) setListQuestion(_listquestion);
          if (_detail.currentAnswer) {
            dispatch(saveAnswers(_detail.currentAnswer))
          }
        }
        else console.log(res.data.message);
      }).catch((err) => console.log(err));

      return () => (mounted = false);
    }
  }, [user, id, now]);// eslint-disable-line

  useEffect(() => {
    if (detail && detail.exam_student && detail.exam_student.status === 0) {
      Axios.put(URL_LIST_ALL + "at_exam_students/" + detail.exam_student.id, { status: 2 }, user.config).then(async (res) => { console.log(123); })
      return false
    }
  }, [detail])// eslint-disable-line

  const checkActiveQues = (index, indexcurrent) => {
    const _ques = listQuestion[index];
    if (index == indexcurrent) return 'bg-primary text-light';
    else if (considers && considers.hasOwnProperty(_ques.id)) return 'bg-warning text-drak';
    else if (Answers && Answers.hasOwnProperty(_ques.id) && Answers[_ques.id] && (Answers[_ques.id].answers || Answers[_ques.id].answers == 0)) {
      return 'bg-success text-light';
    }
    else return 'bg-light text-drak';
  }

  const onChangeConsider = (e) => {
    const stt = e.target.checked;
    const idques = e.target.value;
    let _localconsider = { ...considers };
    if (stt) {
      _localconsider[idques] = true;
    } else {
      delete _localconsider[idques]
    }
    setConsiders(_localconsider)
  }

  const onSuccess = (status) => {
    // Xử lý nộp bài, nộp nháp
    setSendSuccess(true)
    const mess = status === 1 ? "nộp bài" : "lưu nháp"
    confirmAlert({
      title: "Thành công", message: "Bạn đã thực hiện " + mess + " thành công", buttons: [{
        label: "OK", onClick: () => {
          window.location.href = '/examination-view/' + id
        }
      }],
    });
  }

  useEffect(() => {
    if (studenOnleave && !sendSuccess && URL_LIST_ALL !== 'http://localhost:8000/api/' && window.location.hostname !== 'localhost' && (detail && detail.type != 3)) {
      setTimeOut(Axios.get(URL_LIST_ALL + "notifi-students?status=1&test_id=" + id, user.config).then(async (res) => {
        setSoundPlay(true)
        confirmAlert({
          title: "Cảnh báo", message: "Vui lòng không rời khỏi màn hình bài thi", buttons: [{
            label: "OK", onClick: () => {
              setStudenOnleave(false)
              setSoundPlay(false)
              Axios.get(URL_LIST_ALL + "notifi-students?status=0&test_id=" + id, user.config).then(async (res) => { })
            }
          }],
        });
      }), 3000)
    }
  }, [studenOnleave])

  useEffect(() => {
    if (soundPlay) {
      const audio = new Audio(wavFile);
      audio.play()
      setSoundPlay(false)
    }
  }, [soundPlay]);

  useEffect(() => {
    if (Answers && listQuestion && listQuestion[indexQuestion] && listQuestion[indexQuestion].id) {
      let id_ques = listQuestion[indexQuestion].id
      if (Answers && Answers[id_ques]) {
        saveStudentAnswer(id_ques)
      }
    }
  }, [Answers, indexQuestion ? indexQuestion : null])

  const checkAnswer = async (question, answer) => {
    const apikey = 'sk-proj-ylhclsRfpkBiUIV2HjDeT3BlbkFJCVQ3O30fmvCoGDwpmzN8';
    try {
      setChecking(true)
      const response = await Axios.post('https://api.openai.com/v1/chat/completions', {
        model: "gpt-4o-mini",
        messages: [
          { role: "system", content: "You are a Python code grader. You will receive a question and a student's answer. You should return whether the answer is correct or incorrect in JSON format and in Vietnamese. Explain in detail if the student's answer is not correct." },
          { role: "user", content: `Question: ${question}\n\nStudent's Answer: ${answer}\n\nPlease check the answer and return the result in JSON format as follows: { "correct": true/false, "explanation": "Giải thích cho câu trả lời." }` }
        ],
        max_tokens: 500
      }, {
        headers: {
          'Authorization': `Bearer ${apikey}`,
          'Content-Type': 'application/json'
        }
      });

      let result = response.data.choices[0].message.content;
      if (result.includes('```json\n{')) {
        result = result.replace('```json\n{', '{')
      }
      if (result.includes('}\n```')) {
        result = result.replace('}\n```', '}')
      }
      try {
        setChecking(false)
        result = JSON.parse(result)
      } catch (parseError) {
        setChecking(false)
        console.error("Error parsing JSON:", parseError);
      }
      return result;
    } catch (error) {
      console.error("Error checking answer:", error);
      return false;
    }

  };
  const saveStudentAnswer = async (qid) => {
    let student_answers = Answers && Answers[qid] && (Answers[qid].answers || Answers[qid].answers == 0) ? Answers[qid].answers : null
    let point = 0
    if (detail && detail.currentAnswer && detail.currentAnswer[qid]) {
      if (student_answers || student_answers == 0) {

        let ans_true = true
        let question_answers = Answers[qid].question.corrects
        if (!Array.isArray(question_answers)) {
          question_answers = JSON.parse(question_answers)
        }
        let snschoice = []
        if (Answers[qid].question.code === "QFillTo") {
          question_answers.forEach((item, i) => {
            if (typeof item == 'string' && item.includes("||")) {
              const arr = item.split('||').map(string => string.toString().trim())
              if (student_answers && student_answers[i] && arr.includes(student_answers[i].trim())) {
                ans_true = true
                snschoice.push(1)
              } else {
                ans_true = false
                snschoice.push(0)
              }
            } else {
              if (item == student_answers[i]) {/* eslint eqeqeq: 0 */
                ans_true = true
                snschoice.push(1)
              } else if (item == student_answers[i] || (typeof student_answers[i] == 'string' && item == student_answers[i].trim())) {/* eslint eqeqeq: 0 */
                ans_true = true
                snschoice.push(1)
              } else if (typeof item == 'string' && typeof student_answers[i] == 'string' && item.trim() == student_answers[i].trim()) {/* eslint eqeqeq: 0 */
                ans_true = true
                snschoice.push(1)
              } else {
                ans_true = false
                snschoice.push(0)
              }
            }
          });
        }
        if (Answers[qid].question.code === "QChooseOne") {
          if (student_answers == question_answers) {
            ans_true = true
            snschoice.push(1)
          } else {
            ans_true = false
            snschoice.push(0)
          }
        }
        if (Answers[qid].question.code === "QChooseMulti") {
          ans_true = true
          Object.keys(student_answers).forEach((item, i) => {
            if (question_answers[i] != item) { /* eslint eqeqeq: 0 */
              ans_true = false
            }
          });
          snschoice.push(ans_true ? 1 : 0)
        }

        if (Answers[qid].question.code === "QDropDrag") {
          ans_true = true
          student_answers.forEach((itA, idx) => {
            if (itA !== question_answers[idx]) {
              ans_true = false
            }
          });
          snschoice.push(ans_true ? 1 : 0)
        }
        // if(ans_true){
        //   point = detail.currentAnswer[qid].question.point
        // }else{
        //   point = 0
        // }
        let datasave = {
          student_id: user.data.id,
          test_id: id,
          question_id: qid,
          corrects: JSON.stringify(student_answers),
          status: 2,
          // point: point,
          comments: null
        }
        // console.log(datasave);
        if (Answers[qid].question.code === "QCode") {
          ans_true = true
          const response = await checkAnswer(Answers[qid].question.title, student_answers);  // Gọi API để kiểm tra câu trả lời và chờ kết quả
          if (response) {
            try {
              let decode = response
              if (decode) {
                if (decode.correct == true) {
                  snschoice.push(1)
                  ans_true = true
                  point = listQuestion[indexQuestion].point
                  datasave.comments = decode.explanation
                } else {
                  snschoice.push(0)
                  ans_true = false
                  datasave.comments = decode.explanation
                }
              }
            } catch (parseError) {
              console.error("Error parsing JSON:", parseError);
            }

            Axios.get(URL_LIST_ALL + "at_exam_students_answers?student_id=" + user.data.id + '&question_id=' + qid + '&test_id=' + id, user.config).then(async (res) => {
              if (res && res.data && res.data.data) {
                let data_answer = res.data.data[0]
                if (data_answer) {
                  Axios.put(URL_LIST_ALL + "at_exam_students_answers/" + data_answer.id_student_answer, datasave, user.config).then(async (res) => {
                  }).catch((err) => { console.log(err); });
                } else {
                  Axios.post(URL_LIST_ALL + "at_exam_students_answers", datasave, user.config).then(async (res) => {
                  }).catch((err) => { console.log(err); });
                }
              }
            }).catch((err) => { console.log(err); });
          }
        } else {
          Axios.get(URL_LIST_ALL + "at_exam_students_answers?student_id=" + user.data.id + '&question_id=' + qid + '&test_id=' + id, user.config).then(async (res) => {
            if (res && res.data && res.data.data) {
              let data_answer = res.data.data[0]
              if (data_answer) {
                Axios.put(URL_LIST_ALL + "at_exam_students_answers/" + data_answer.id_student_answer, datasave, user.config).then(async (res) => {
                }).catch((err) => { console.log(err); });
              } else {
                Axios.post(URL_LIST_ALL + "at_exam_students_answers", datasave, user.config).then(async (res) => {
                }).catch((err) => { console.log(err); });
              }
            }
          }).catch((err) => { console.log(err); });
        }
      }
    }else{
      console.log(12233);
      
    }
  }

  return (
    <AkkhorLayout idrole="examination" roleignone={user.data.typeaccount === 2} isonleave={true} onLeave={() => { if (user && user.data.typeaccount === 2) { setStudenOnleave(true) } }}>
      {detail && listQuestion && (
        <div className="container-fluid p-0">
          <div className="breadcrumbs-area py-3">
            <ul>
              <li>
                <Link to="/">Trang chủ</Link>
              </li>
              <li>{detail.type === 3 ? "Phiếu bài tập" : "Bài kiểm tra"}</li>
            </ul>
          </div>
          <div className="card height-auto card-question">
            <div className="card-body">
              <div>
                <div className="row">
                  <div className="col-sm-9">
                    {listQuestion.length > 0 && listQuestion[indexQuestion] && (
                      <div className="mb-2 border h-100 rounded position-relative overflow-hidden " id={"nuques-" + indexQuestion}>
                        <h2 className="race-title text-center my-2">
                          <span>{detail.title}</span>
                          <SuggestQuestion question_id={listQuestion[indexQuestion].id} />
                        </h2>
                        <div>
                          <div className="font-weight-bold p-2 bg-info text-light d-flex justify-content-between">
                            <div>Câu hỏi {indexQuestion + 1}</div>
                            <div className="text-warning"><input type="checkbox" checked={(considers && considers[listQuestion[indexQuestion].id]) ? true : false} onChange={onChangeConsider} value={listQuestion[indexQuestion].id} /> Đang cân nhắc</div>
                          </div>
                          {listQuestion[indexQuestion].parent_title && <div className="p-2 border-bottom">
                            <GenderCode text_string={listQuestion[indexQuestion].parent_title} />
                          </div>}
                          <div className="p-3 title-question">
                            <GenderCode text_string={listQuestion[indexQuestion].title} />
                          </div>
                          <div className="w-100 pb-5">
                            <ChoiceQuestion
                              key={indexQuestion}
                              index={indexQuestion}
                              item={listQuestion[indexQuestion]}
                              test={detail}
                              action={false}
                            ></ChoiceQuestion>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between position-bottom p-3">
                          <div>{indexQuestion > 0 && <button className="btn btn-info" onClick={() => {
                            setTimeOut(() => {
                              setPrevQuesID(listQuestion[indexQuestion].id);
                              setIndexQuestion(indexQuestion - 1);
                            }, 100)
                          }} >Câu trước</button>}</div>
                          <div>{indexQuestion < listQuestion.length - 1 &&
                            <button className="btn btn-info d-flex align-items-center" onClick={() => {
                              setTimeOut(() => {
                                setPrevQuesID(listQuestion[indexQuestion].id);
                                setIndexQuestion(indexQuestion + 1);
                              }, 100)
                            }} disabled={checking}> {checking ? <><span>Đang lưu câu trả lời</span> <Spinner /></> : 'Câu tiếp'}</button>}</div>
                        </div>
                      </div>
                    )}

                  </div>
                  <div className="col-sm-3">
                    {!timeOut && <TimeOut className="p-3" now={now} formatMask={formatMask} onTimeOut={setTimeOut}></TimeOut>}
                    <div className="d-sm-none w-100 mt-3 " ></div>
                    <h5 className="mb-3 text-warning">Hiện tại: <Moment interval={1000} format="DD/MM/YYYY HH:mm:ss"></Moment></h5>
                    <ActionSend timeOut={timeOut} listQuestion={listQuestion} detail={detail} test="1" onSuccess={(val) => { onSuccess(val) }} />
                    <div className="border rounded p-2 mt-3">
                      <div className="text-center pb-3"><b>Danh sách câu hỏi</b></div>
                      <div className="container-fluid">
                        <div className="row">
                          {listQuestion.map((item, index) => (<div key={index} className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-3" style={{ padding: 3, fontSize: 14 }}>
                            <div onClick={() => {
                              setTimeOut(() => {
                                setPrevQuesID(listQuestion[indexQuestion].id);
                                setIndexQuestion(index);
                              }, 100)
                            }} className={"border rounded text-center font-weight-bold cursor-pointer " + checkActiveQues(index, indexQuestion)}>{index + 1}</div>
                          </div>))}
                        </div>
                      </div>
                    </div>
                    <div className="border rounded p-2 mt-3">
                      <div className="text-center pb-4"><b>Chú thích</b></div>
                      <div className="container-fluid p-0">
                        <ul>
                          <li className="d-flex mb-3" style={{ fontSize: 14 }}><div className="border rounded p-2 mr-2 px-3 bg-light"></div> Câu hỏi chưa trả lời</li>
                          <li className="d-flex mb-3" style={{ fontSize: 14 }}><div className="border rounded p-2 mr-2 px-3 bg-primary"></div> Câu hỏi hiện tại</li>
                          <li className="d-flex mb-3" style={{ fontSize: 14 }}><div className="border rounded p-2 mr-2 px-3 bg-warning"></div> Câu hỏi đang cân nhắc</li>
                          <li className="d-flex mb-3" style={{ fontSize: 14 }}><div className="border rounded p-2 mr-2 px-3 bg-success"></div> Câu hỏi đã trả lời</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </AkkhorLayout>
  );
}
