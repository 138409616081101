import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { LIST_ALPHA } from "../../../configs";
import { saveAnswers } from "../../../redux/Actions";
import parse from 'html-react-parser'

export default function QChooseOne(props) {
    const { item, currentAnswer,checkQuestions,objAnswers } = props; // LNT
    const { Answers } = useSelector(state => state.save);
    const [point, setPoint]= useState(0)
    const [answersST, setAnswersST]= useState(null)
    const [answersCorrects, setAnswerscCorrects]= useState(null)
    const dispatch = useDispatch();
    const answers = item.answers
    
    useEffect(()=>{
        console.log(currentAnswer, "SDSLD");
        if(objAnswers && objAnswers[item.id]){
                setPoint(objAnswers[item.id].point)
                setAnswersST(objAnswers[item.id].answers)
                setAnswerscCorrects(objAnswers[item.id].question.corrects)
        }
    },[checkQuestions])
    const chooseItem = (index) => {
        let newListAnswers = { ...Answers }
        newListAnswers[item.id] = { question: item, answers: index, percent: (index + '') === (answers.correct + '') ? 100 : 0 , point: index ==item.corrects ? 1:0 };
        console.log(newListAnswers[item.id]);
        dispatch(saveAnswers(newListAnswers))
    }
    
    return (<div className="col-12 form-group">
        <ul>
        {answers && <>
            
                {answers.list ?
                    <> 
                    {answers.list.map((item, i) =>
                        <li key={i} className="d-flex mb-2 align-items-center item-choice-one " >
                            {LIST_ALPHA[i]}. <div className={"w-100 py-1 px-2 cursor-pointer rounded border " + (currentAnswer === i ? ' border-info bg-info text-white' : ' border-grey')} onClick={() => chooseItem(i)}>
                                {item.includes("<class") && item.includes(">") ? item : parse(item)}
                            </div>
                        </li>
                      
                        )}
                    </>
                    :
                    <>
                 
                    {objAnswers[item.id] ?
                    
                    answers.map((item, i) =>
                        <li key={i} className="d-flex mb-2 align-items-center item-choice-one " >
                           {point ==1?
                           
                           <>
                               
                                {LIST_ALPHA[i]}. <div className={"w-100 py-1 px-2 cursor-pointer rounded border " + (answersST === i ? ' border-success bg-success text-white' : ' border-grey')} >
                                    {item.includes("<class") && item.includes(">") ? item : parse(item)}
                                </div>
                           </>
                           :
                           
                            <>
                                {LIST_ALPHA[i]}. <div className={"w-100 py-1 px-2 cursor-pointer rounded border " + (answersST === i ? ' border-danger bg-danger text-white' : ' border-grey')+(answersCorrects === i ? ' border-success bg-success text-white' : ' border-grey')} >
                                    {item.includes("<class") && item.includes(">") ? item : parse(item)}
                                </div>
                            </> 
                           }
                            

                        </li>)
                    :answers.map((item, i) =>
                        <li key={i} className="d-flex mb-2 align-items-center item-choice-one " >
                            {LIST_ALPHA[i]}. <div className={"w-100 py-1 px-2 cursor-pointer rounded border " + (currentAnswer === i ? ' border-info bg-info text-white' : ' border-grey')} onClick={() => chooseItem(i)}>
                                {item.includes("<class") && item.includes(">") ? item : parse(item)}
                            </div>
                        </li>)}
                        </>
                }
            </>}
        </ul>
    </div>);
}
