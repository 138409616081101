import Axios from "axios";
import AkkhorLayout from '../../layout/Akkhor';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { URL_LIST_ALL } from "../../configs/Api";
import { Link } from 'react-router-dom';
import moment from 'moment'

export default function DetailExamViewAzota(props) {
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const [detail, setDetail] = useState(null);
  const [studentAnswer, setstudentAnswer] = useState(null);
  const [comments, setComments] = useState([])

  useEffect(() => {
    if (id > 0) {
      let mounted = true;
      Axios.get(URL_LIST_ALL + "at_exam_students_azota/" + id, user.config).then(async (res) => {
        if (res.data.status === "success" && mounted) {
          const _detail = res.data.data;
          setComments(_detail.comments)
          setDetail(_detail)
          setstudentAnswer(_detail.info.content ? JSON.parse(_detail.info.content) : null)
        }
        else console.log(res.data.message);
      }).catch((err) => console.log(err));

      return () => (mounted = false);
    }
  }, [user, id]);

  return (
    <AkkhorLayout idrole="examination" roleignone={user.data.typeaccount === 2}>
      {(detail) ? (<div className="container-fluid">
        <div className="breadcrumbs-area py-3">
          <ul>
            <li>
              <Link to="/">Trang chủ</Link>
            </li>
            <li>{detail.type === 1 ? "Phiếu bài tập" : "Bài kiểm tra"}</li>
          </ul>
        </div>
        <div className="card height-auto">
          <div className="card-body">
            <div className="w-100">
              {detail && detail.student_info &&
                <>
                  <div className="w-100"><strong>Họ tên: </strong> {detail.student_info.fullname}</div>
                  <div className="w-100"><strong>Mã số: </strong> {detail.student_info.code}</div>
                  <div className="w-100"><strong>Lớp: </strong> {detail.student_info.class}</div>
                </>
              }

            </div>

            <div className="row">
              <div className="col-sm-4 d-flex">
                <div className="text-primary" style={{ fontSize: 50 }}><i className="fas fa-medal"></i></div>
                <div className="py-1 px-2">
                  <div style={{ fontSize: 25, fontWeight: 'bold' }}>{detail.author_mark ? "Đã chấm" : "Chưa chấm"}</div>
                  <div>Trạng thái chấm bài</div>
                </div>
              </div>
              <div className="col-sm-4 d-flex">
                <div className="text-success" style={{ fontSize: 50 }}><i className="fas fa-clipboard-list"></i></div>
                <div className="py-1 px-2">
                  <div style={{ fontSize: 25, fontWeight: 'bold' }}>{detail.point > 10 ? 10 : Math.round(detail.point * 10) / 10}</div>
                  <div>Điểm đạt được</div>
                </div>
              </div>
              <div className="col-sm-4 d-flex">
                <div className="text-info" style={{ fontSize: 50 }}><i className="far fa-clock"></i></div>
                <div className="py-1 px-2">
                  <div style={{ fontSize: 25, fontWeight: 'bold' }}>{moment.unix(detail.time).utc().format(detail.time > 3600 ? 'HH:mm:ss' : 'm:ss')}</div>
                  <div>Thời gian làm bài</div></div>
              </div>
            </div>


            <div>
              <h2 className="text-center mb-3">{detail.title}</h2>
            </div>

            <div className="row">
              <div className="col-12 mb-3">
                <h4 className="mb-2">Đề bài</h4>
                <div className="row">
                  {detail.files && detail.files.map(function (item, i) {
                    return (<div className="col-auto" key={i} style={{ maxWidth: 200 }}>
                      {item.type === "image" ?
                        <img src={item.url} style={{ width: "100%", height: 200, objectFit: "cover" }} alt="" />
                        :
                        <img src="https://tomoclass2021.s3.ap-southeast-1.amazonaws.com/images/2021/11/08/rules_of_fight_club.png" style={{ width: "100%", height: 200, objectFit: "cover" }} alt="" />
                      }
                      <button className="btn btn-sm btn-info w-100" style={{ borderRadius: 0 }} onClick={() => window.open(item.url, "_blank")}>Tải về</button>
                    </div>)
                  })}
                </div>
              </div>
              {studentAnswer &&
                <div className="col-12 mt-3">
                  <h4 className="mb-2">Bài làm</h4>
                  <div className="row">
                    {studentAnswer.map(function (item, i) {
                      return (<div className="col-auto" key={i} style={{ maxWidth: 200 }}>
                        {item.type === "image" ?
                          <img src={item.url} style={{ width: "100%", height: 200, objectFit: "cover" }} alt="" />
                          :
                          <img src="https://tomoclass2021.s3.ap-southeast-1.amazonaws.com/images/2021/11/08/rules_of_fight_club.png" style={{ width: "100%", height: 200, objectFit: "cover" }} alt="" />
                        }
                        <button className="btn btn-sm btn-info w-100" style={{ borderRadius: 0 }} onClick={() => window.open(item.url, "_blank")}>Tải về</button>
                      </div>)
                    })}
                  </div>
                </div>
              }
              {comments && comments.length > 0 &&
                <>
                  <div className="col-12 mt-3">
                    <h4 className="mb-2">Giáo viên chấm bài</h4>
                    {detail && detail.mark_name && <div className="w-100">Tên giáo viên: {detail.mark_name}</div>}
                    <div className="row">
                      {comments.map(function (item, i) {
                        return (<div className="col-auto" key={i} style={{ maxWidth: 200 }}>
                          {item.type === "image" ?
                            <img src={item.url} style={{ width: "100%", height: 200, objectFit: "cover" }} alt="" />
                            :
                            <img src="https://tomoclass2021.s3.ap-southeast-1.amazonaws.com/images/2021/11/08/rules_of_fight_club.png" style={{ width: "100%", height: 200, objectFit: "cover" }} alt="" />
                          }
                          <button className="btn btn-sm btn-info w-100" style={{ borderRadius: 0 }} onClick={() => window.open(item.url, "_blank")}>Tải về</button>
                        </div>)
                      })}
                    </div>
                  </div>
                </>
              }

            </div>
          </div>
        </div>
      </div>
      ) : (<>
        <div className="breadcrumbs-area py-3">
          <ul>
            <li>
              <Link to="/">Trang chủ</Link>
            </li>
          </ul>
        </div>
      </>)}
    </AkkhorLayout>
  );
}
