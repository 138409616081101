import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CodeMirror from '@uiw/react-codemirror';
import { langs } from '@uiw/codemirror-extensions-langs';
import { saveAnswers } from "../../../redux/Actions";
import { githubLightInit } from "@uiw/codemirror-theme-github";

export default function QCode(props) {
    const { item, currentAnswer } = props;
    const { Answers } = useSelector(state => state.save);
    const dispatch = useDispatch();
    const [localAnswer, setLocalAnswer] = useState(currentAnswer || "");
    const [codeValue, setCodeValue] = useState('');

    // Hàm để lưu câu trả lời của học sinh vào Redux khi có sự thay đổi
    const handleChange = (value) => {
        setCodeValue(value);
        // console.log(value);
    };

    // Hàm để lưu câu trả lời vào Redux khi rời khỏi CodeMirror
    const handleBlur = () => {
        if (codeValue) {
            let newListAnswers = { ...Answers };
            newListAnswers[item.id] = { question: item, answers: codeValue, percent: 0 };
            dispatch(saveAnswers(newListAnswers));
        }

    };
    const codeMirrorRef = useRef(null);
    return (
        <div className="col-12 mt-3">
            <div className="w-100 border mb-5">
                <CodeMirror
                    height="400px"
                    theme={githubLightInit({
                        settings: {
                            caret: '#c6c6c6',
                            fontFamily: 'monospace',
                        }
                    })}
                    ref={codeMirrorRef}
                    extensions={[langs.python()]}
                    value={localAnswer}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </div>
        </div>
    );
}
