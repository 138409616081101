import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/Akkhor";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { URL_LIST_ALL } from "../configs/Api";
import { useLocation, useParams } from "react-router-dom";
import MyEditor2 from "../component/MyEditor2";
import CodeMirror from '@uiw/react-codemirror';
import { langs } from '@uiw/codemirror-extensions-langs';
import parse from 'html-react-parser';
import { saveListTabs } from "../redux/Actions";
import { Modal } from "react-bootstrap";
import QuestionByTree from "../examination/tests/QuestionByTree";
import QTrueFlase from "../examination/questions/ViewQuestion/QTrueFlase";
import QChooseMulti from "../examination/questions/ViewQuestion/QChooseMulti";
import QChooseOne from "../examination/questions/ViewQuestion/QChooseOne";
import QDropDrag from "../examination/questions/ViewQuestion/QDropDrag";
import QEssay from "../examination/questions/ViewQuestion/QEssay";
import QFillTo from "../examination/questions/ViewQuestion/QFillTo";
import QMatching from "../examination/questions/ViewQuestion/QMatching";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import { confirmAlert } from "react-confirm-alert";
import TabContent from "../component/TabContent";
import ShowCodePython from "../component/ShowCodePython";
import ShowTab2 from "../component/ShowTab2";
import ChoiceQuestion from "../examination/questions/ChoiceQuestion";
import { githubLightInit } from '@uiw/codemirror-theme-github';
import Pagination from "react-js-pagination";
import UserDropDown from "../component/UserDropDown";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ShowTre = ({ list, questions, onCheck }) => {
    const [show, setShow] = useState(null)

    return <>
        {list.map((item, index) => <div key={index} className="pl-3">
            {item.children && item.children.length > 0 && <span className="fas fa-plus-circle text-success mr-2" onClick={() => setShow(item.id)}></span>}
            <input type="checkbox" value={item.id} onClick={(e) => onCheck(e, item)} defaultChecked={(questions && questions[item.id]) ? true : false} />
            &nbsp; {item.title} {show === item.id && item.children && item.children.length > 0 && <div className="pl-3"><ShowTre list={item.children} onCheck={onCheck} /></div>}
        </div>)}
    </>
}

export default function FormManHinh() {
    let query = useQuery();
    const { user } = useSelector((state) => state.auth);
    const { listTabs } = useSelector((state) => state.save);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [baiHocInfo, setBaiHocInfo] = useState(null);
    const [khoaHocInfo, setKhoaHocInfo] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [reloadChild, setReloadChild] = useState(false);
    const dispatch = useDispatch();
    const [listChild, setListChild] = useState();
    const [bhID, setBhID] = useState(parseInt(query.get("bh_id")))
    const [khID, setKhID] = useState()

    const [tree, setTree] = useState(null);
    const [reloadTree, setReloadTree] = useState(false);
    const [mytree, setMyTree] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [page, setPage] = useState(1);
    const limit = 7 ? 7 : 10;
    const [totalRecord, setTotalRecord] = useState(7 ? 7 : 10);
    const defaultdetail = {
        author_id: user.data.id, title: '', status: 1, baihoc_id: bhID, khoahoc_id: khID
    }
    const [copyKhId,setCopyKhID] = useState(null);
    const [copyBhId,setCopyBhID] = useState(null);
    const [copyMhId,setCopyMhID] = useState(null);
    const [modalMhCopy, setModalMhCopy] = useState(false);

    useEffect(() => {
        if (detail) {
            Axios.get(URL_LIST_ALL + "param_trees/1?grade_id=" + detail.khoahoc_id + '&subject_id=' + detail.subject_id, user.config).then(async (res) => {
                if (res.data.status === "success") {

                    setTree(res.data.data);
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
        }
    }, [detail, reloadTree]);// eslint-disable-line

    const onCheck = (e, _item) => {
        if (e.target.checked) {
            setMyTree(_item)
        } else {
            let questions = { ...formadd_item.values.kh_value };
            delete questions[_item.id];
            formadd_item.setFieldValue('kh_value', questions)
        }
    }

    const actionFinished = (_tree, _question) => {
        setMyTree(null);
        let questions = { ...formadd_item.values.kh_value };
        questions[_tree.id] = _tree;
        if (_question.length > 0) questions[_tree.id].list = _question;
        else delete questions[_tree.id];
        if (questions) {
            var newList = []
            if (formadd_item.values.kh_value && Array.isArray(formadd_item.values.kh_value)) {
                newList = formadd_item.values.kh_value.slice()
            }
            Object.values(questions).forEach(it => {
                if (it.list) {
                    it.list.forEach(its => {
                        newList.push(its)
                    });
                }
            });
            formadd_item.setFieldValue('kh_value', newList)
        }
    }

    const onUploadImage = (file) => {
        let data = new FormData();
        data.append("name", "file")
        data.append("file", file)
        data.append("filetype", file.type)
        Axios.post(URL_LIST_ALL + "fileupload", data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: user.config.headers.Authorization,
            }
        }).then(async (res) => {
            if (res.data.status === "success") {
                const url = res.data.message
                if (url) {
                    formadd_item.setFieldValue('kh_value', url)
                    
                    console.log(res.data);
                }
            } else {
                return;
            }
        }).catch((err) => { console.log(err); })
    }
    const selectLang = (type) => {
        if (type == 'javascript') return langs.jsx()
        if (type == 'html') return langs.html()
        if (type == 'python') return langs.python()
        if (type == 'php') return langs.php()
        if (type == 'css') return langs.css()
        if (type == 'java') return langs.java()
        if (type == 'sql') return langs.sql()
        if (type == 'kotlin') return langs.kotlin()
        if (type == 'c') return langs.c()
        return langs.html()
    }

    const removeItemQues = (i) => {
        if (Array.isArray(formadd_item.values.kh_value)) {
            let newList = formadd_item.values.kh_value.slice()
            if (i > -1) {
                newList.splice(i, 1)
                formadd_item.setFieldValue('kh_value', newList)
            }
        }
    }

    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            title: Yup.string().trim().nullable().required('Nhập tên màn hình khóa học').max(150, "Độ dài không quá 150 ký tự")
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'khoahoc_manhinh/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    dispatch(saveListTabs([]))
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật màn hình thành công',
                            buttons: [{
                                label: 'OK', onClick: () => {
                                    window.location.href = "/edit-manhinh/" + id
                                }
                            }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {

                values.khoahoc_id = khID
                Axios.post(URL_LIST_ALL + 'khoahoc_manhinh', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        dispatch(saveListTabs([]))
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới màn hình thành công',
                            buttons: [{
                                label: 'OK', onClick: () => {
                                    window.location.href = "/edit-manhinh/" + res.data.id
                                }
                            }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    const formadd_item = useFormik({
        initialValues: { kh_value: '', kh_type: 'text', manhinh_id: detail ? detail.manhinh_id : 0 },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({

        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if (values.id && values.id > 0) {
                values.manhinh_id = detail.id
                if (values.kh_type == "tab") {
                    values.kh_value = JSON.stringify(listTabs)
                } else {
                    values.kh_value = Array.isArray(values.kh_value) ? JSON.stringify(values.kh_value) : values.kh_value
                }

                Axios.put(URL_LIST_ALL + 'khoahoc_param/' + values.id, values, user.config).then(res => {
                    setLoading(false);
                    if (res.data.status === "success") {

                        confirmAlert({
                            title: 'Thành công',
                            message: 'Thành công',
                            buttons: [{
                                label: 'OK', onClick: () => {
                                    setReloadChild(!reloadChild)
                                    setShowModal(false)
                                    dispatch(saveListTabs([]))
                                    resetForm()
                                }
                            }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {

                values.manhinh_id = detail.id
                if (values.kh_type == "tab") {
                    values.kh_value = JSON.stringify(listTabs)
                }

                values.kh_value = Array.isArray(values.kh_value) ? JSON.stringify(values.kh_value) : values.kh_value
                Axios.post(URL_LIST_ALL + 'khoahoc_param', values, user.config).then(res => {
                    setLoading(false);
                    if (res.data.status === "success") {

                        confirmAlert({
                            title: 'Thành công',
                            message: 'Thành công',
                            buttons: [{
                                label: 'OK', onClick: () => {
                                    setReloadChild(!reloadChild)
                                    setShowModal(false)
                                    dispatch(saveListTabs([]))
                                    resetForm()
                                }
                            }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }

        },
    });

    useEffect(
        function () {
            if (id > 0) {
                Axios.get(URL_LIST_ALL + "khoahoc_manhinh/" + id, user.config).then(async (res) => {
                    if (res.data.status === "success") {
                        setDetail(res.data.data);
                        setBhID(res.data.data.baihoc_id)
                        setKhID(res.data.data.khoahoc_id);
                    } else console.log(res.data.message);
                }).catch((err) => console.log(err));
            }
            if (bhID > 0) {
                Axios.get(URL_LIST_ALL + "khoahoc_baihoc/" + bhID, user.config).then(async (res) => {
                    if (res.data.status === "success") {
                        setBaiHocInfo(res.data.data)
                        setKhID(res.data.data.khoahoc_id);
                        formik.setFieldValue('baihoc_id', res.data.data.id)
                    } else console.log(res.data.message);
                }).catch((err) => console.log(err));
            }
        }, [user, id, bhID]
    );

    useEffect(() => {
        if (id > 0) {
            Axios.get(URL_LIST_ALL + "khoahoc_param?manhinh_id=" + id + "&orderby=khoahoc_lk.position-asc&limit=" + limit + "&page=" + page, user.config).then(async (res) => {
                if (res.data.status === "success") {
                    setListChild(res.data.data);
                    setTotalRecord(res.data.total)
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
        }
    }, [id, user, reloadChild, page, limit])
   
    useEffect(() => {
        if (khID > 0) {
            Axios.get(URL_LIST_ALL + "khoahoc/" + khID, user.config).then(async (res) => {
                if (res.data.status === "success") {
                    setKhoaHocInfo(res.data.data);
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
        }
    }, [id, user, khID])

    const deleteParam = (idparam) => {
        Axios.post(URL_LIST_ALL + "delete_khoahoc_param", { id_param: idparam, id_manhinh: id }, user.config).then(async (res) => {
            if (res.data.status === "success") {
                setReloadChild(!reloadChild)
                alert("Xoá thành công")
            } else console.log(res.data.message);
        }).catch((err) => console.log(err));
    }
    const editParam = (idparam) => {
        formadd_item.setFieldValue('kh_type', "")
        formadd_item.setFieldValue('kh_value', "")
        formadd_item.setFieldValue('id', "")
        setShowModal(true)
        Axios.get(URL_LIST_ALL + "khoahoc_param/" + idparam, user.config).then(async (res) => {
            if (res.data.status === "success") {
                let info = res.data.data

                formadd_item.setFieldValue('kh_type', info.kh_type)
                formadd_item.setFieldValue('kh_value', info.kh_value)
                formadd_item.setFieldValue('id', info.id)

            } else console.log(res.data.message);
        }).catch((err) => console.log(err));

        // formadd_item.setFieldValue('kh_type')
    }
    const changePosition = (idparam, type, manhinh_id) => {
        var data = { name: 'khoahoc_param', type: type, id_param: idparam, manhinh_id: manhinh_id }
        Axios.put(URL_LIST_ALL + 'change_position/' + idparam, data, user.config).then(res => {
            if (res.data.status === "success") {
                setReloadChild(!reloadChild)
            } else alert(res.data.message);
        }).catch(err => console.log(err));
    }

    const choiceScreen = () => {
        if(copyMhId){
            let data = {from_id:copyMhId,to_id:id}
            Axios.post(URL_LIST_ALL + 'copy_manhinh', data, user.config).then(res => {
                if (res.data.status === "success") {
                   alert("Liên kết thành công")
                   setModalMhCopy(false)
                   setCopyKhID(null)
                   setCopyBhID(null)
                   setCopyMhID(null)
                   setReloadChild(!reloadChild)
                } else alert(res.data.message);
            }).catch(err => console.log(err));
        }else{
            alert('Vui lòng chọn màn hình cần liên kết')
        }
    }
    return (
        <AkkhorLayout idrole="khoa-hoc">
            <div className="breakcrumb">
                <ul>
                    <li><a href={"/khoa-hoc/"}>Khóa học</a></li>
                    <li>/</li>
                    <li><a href={"/danh-sach-bai-hoc/" + (khoaHocInfo && khoaHocInfo.id)}>{khoaHocInfo && khoaHocInfo.title}</a></li>
                    <li>/</li>
                    <li><a href={"/man-hinh?baihocid=" + (baiHocInfo && baiHocInfo.id)}>{baiHocInfo && baiHocInfo.title}</a></li>
                    <li>/</li>
                    <li>{detail && detail.title}</li>
                </ul>
            </div>
            <div className="card height-auto mt-5">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Màn hình khóa học</h3>
                        </div>
                    </div>
                    <div className="row">
                        <form className="col-12 new-added-form" method="post" onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-12 col-sm-6 form-group">
                                    <label>Tiêu đề <span className="text-danger">*</span></label>
                                    <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("title")} autoComplete="off" />
                                    <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                                </div>

                                <div className="col-12 col-sm-6 form-group">
                                    <label>Trạng thái</label>
                                    <select className="form-control height32" {...formik.getFieldProps("status")}>
                                        <option value="0">Khoá</option>
                                        <option value="1">Hoạt động</option>
                                    </select>
                                </div>

                                <div className="col-12 text-center form-group mg-t-8">
                                    <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu lại</>}</button>
                                    {id > 0 &&
                                        <>
                                            <button className="btn-fill-lg btn-info" type="button" onClick={() => { setShowModal(true); formadd_item.resetForm() }}>Thêm dữ liệu màn hình</button>
                                            <button className="btn-fill-lg btn-primary" type="button" onClick={() => { setModalMhCopy(true) }}>Liên kết màn hình khác</button>
                                            <a href={'/add-manhinh?bh_id=' + bhID} className="btn-fill-lg btn-success">Thêm màn hình</a>
                                        </>
                                    }
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={() => { setShowModal(false); dispatch(saveListTabs([])) }} animation={true} size="xl" dialogClassName="modal-90w">
                {id > 0 &&
                    <div className="card height-auto mt-5">
                        <div className="card-body">
                            <div className="heading-layout1">
                                <div className="item-title">
                                    <h3 className="w-100 text-center">Nội dung cho màn hình {formadd_item.values.kh_type}</h3>
                                </div>
                            </div>
                            <div className="row">
                                <form className="col-12 new-added-form" method="post" onSubmit={formadd_item.handleSubmit}>
                                    <div className="col-lg-6 col-12 flex-fill form-group">
                                        <label className="form-label">Phân loại nội dung</label>
                                        <select className="form-control form-control-sm" onChange={(e) => { formadd_item.setFieldValue('kh_type', e.target.value); formadd_item.setFieldValue('kh_value', '') }} value={formadd_item.values.kh_type}>
                                            <option value="text">Văn bản</option>
                                            <option value="image">Hình ảnh</option>
                                            <option value="tab">Tab</option>
                                            <option value="code">Code</option>
                                            <option value="quest">Câu hỏi, yêu cầu</option>
                                            <option value="note">Ghi chú</option>
                                            <option value="importhtml">Upload file HTML</option>
                                        </select>
                                    </div>
                                    {formadd_item.values.kh_type === 'text' &&
                                        <div className="col-lg-12 col-12 form-group" >
                                            <label className="form-label"> Nội dung</label>
                                            <MyEditor2 height={550} key={Math.random()} initialValue={formadd_item.values.kh_value ? formadd_item.values.kh_value : ""}
                                                onValue={(value) => value && formadd_item.setFieldValue("kh_value", value)}
                                            />
                                        </div>
                                    }
                                    {formadd_item.values.kh_type === 'image' &&
                                        <div className="col-lg-12 col-12 form-group" >
                                            <label className="form-label">Chọn ảnh</label>
                                            <div className="w-100">
                                                <input type="file" accept="image/*" onChange={(e) => onUploadImage(e.target.files[0])}></input>
                                                {formadd_item.values.kh_value && <img src={formadd_item.values.kh_value} className="img" width={300} />}
                                            </div>
                                        </div>
                                    }

                                    {formadd_item.values.kh_type === 'importhtml' &&
                                        <div className="col-lg-12 col-12 form-group" >
                                            <label className="form-label">Chọn file</label>
                                            <div className="w-100">
                                            <label>Nhập chiều cao <span className="text-danger">*</span></label>
                                            <input type="text" placeholder="" className="form-control height32 mb-4 col-5" onChange={(e) => formadd_item.setFieldValue('khmeta', e.target.value)} value={formadd_item.values.khmeta} autoComplete="off" />
                                           
                                                <input type="file" accept="*" onChange={(e) => onUploadImage(e.target.files[0])}></input>
                                                {formadd_item.values.kh_value && <>
                                                    <div className="view-show-html">
                                                        <iframe style={{width:"100%", height:"auto", }} title="iframe" src={formadd_item.values.kh_value+"&showsize=true"}></iframe>
                                                    </div>
                                                </>}
                                            </div>
                                        </div>
                                    }
                                    {formadd_item.values.kh_type === 'tab' && 

                                        <div className="col-lg-12 col-12 " >

                                            <TabContent
                                                arrTabValue={formadd_item.values.kh_value}
                                                onValue={(value) => value && formadd_item.setFieldValue("kh_value", value)} />
                                        </div>
                                    }
                                    {formadd_item.values.kh_type === 'code' &&
                                        <>
                                            <div className="col-lg-6 col-12 flex-fill form-group">
                                                <label className="form-label">Chọn ngôn ngữ</label>
                                                <select className="form-control form-control-sm" onChange={(e) => formadd_item.setFieldValue('khmeta', e.target.value)} value={formadd_item.values.khmeta}>
                                                    <option value="html">Html</option>
                                                    <option value="css">Css</option>
                                                    <option value="javascript">Javascript</option>
                                                    <option value="php">Php</option>
                                                    <option value="python">Python</option>
                                                    <option value="java">Java</option>
                                                    <option value="c">C</option>
                                                    <option value="kotlin">Kotlin</option>
                                                </select>
                                            </div>
                                            <div className="w-100">
                                                {formadd_item.values.khmeta &&
                                                    <CodeMirror
                                                        height="500px"
                                                        theme={githubLightInit({
                                                            settings: {
                                                                caret: '#c6c6c6',
                                                                fontFamily: 'monospace',
                                                            }
                                                        })}
                                                        extensions={[selectLang(formadd_item.values.khmeta)]}
                                                        value={formadd_item.values.kh_value}
                                                        onChange={(value, viewUpdate) => {
                                                            formadd_item.setFieldValue("kh_value", value)
                                                        }}
                                                    />
                                                }
                                            </div>
                                        </>
                                    }
                                    {formadd_item.values.kh_type === 'quest' &&
                                        <div className="col-sm-12">
                                            {(tree) && <div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <h4>Cây kiến thức</h4>
                                                        <div className="border rounded position-relative p-2">
                                                            <button className="button-reload" type="button" title="Refresh" onClick={() => setReloadTree(!reloadTree)}><i className="fas fa-history"></i></button>
                                                            <ShowTre list={tree} questions={formadd_item.values.kh_value} onCheck={onCheck} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="w-100 d-flex justify-content-end">
                                                            <a href="/add-at_exam_questions" className="btn btn-info btn-sm" target="_blank">Thêm câu hỏi</a>
                                                        </div>
                                                        {formadd_item.values.kh_value && Array.isArray(formadd_item.values.kh_value) && formadd_item.values.kh_value.map((ans, index) => {

                                                            return (<div key={index} className="mb-2 border rounded overflow-hidden " id={"nuques-" + index}>
                                                                <div>
                                                                    <div className={"font-weight-bold p-2 bg-info d-flex justify-content-between"}>
                                                                        <div>Câu hỏi {index + 1}</div>
                                                                        <div className="">
                                                                            <button type="button" className="btn btn-sm btn-danger" onClick={() => { removeItemQues(index) }}><i className="fas fa-trash"></i></button>
                                                                        </div>
                                                                    </div>
                                                                    {ans.parent_title && <div className="p-2 border-bottom">{parse(ans.parent_title)}</div>}
                                                                    <div className="p-3 border-bottom mb-3">{parse(ans.title)}</div>
                                                                    <div>
                                                                        {ans.code === 'QTrueFlase' && <QTrueFlase currentAnswer={ans.corrects} />}
                                                                        {ans.code === 'QChooseMulti' && <QChooseMulti item={ans} currentAnswer={ans.corrects} />}
                                                                        {ans.code === 'QChooseOne' && <QChooseOne item={ans} currentAnswer={ans.corrects} />}
                                                                        {ans.code === 'QDropDrag' && <QDropDrag item={ans} currentAnswer={ans.corrects} />}
                                                                        {ans.code === 'QEssay' && <QEssay item={ans} currentAnswer={ans.corrects} />}
                                                                        {ans.code === 'QFillTo' && <QFillTo item={ans} currentAnswer={ans.corrects} />}
                                                                        {ans.code === 'QMatching' && <QMatching item={ans} currentAnswer={ans.corrects} />}
                                                                    </div>
                                                                    {ans.comments &&
                                                                        <div className="comment border-top p-2 mt-3">
                                                                            <span>Nhận xét</span>
                                                                            <div className="w-100 mt-2 d-flex">
                                                                                <strong>{ans.teacher_comment}</strong>: {parse(ans.comments + '')}
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                </div>
                                                            </div>)
                                                        })}
                                                    </div>
                                                </div>
                                                <Modal show={mytree !== null} onHide={() => setMyTree(null)} animation={true} size="xl" dialogClassName="modal-90w">
                                                    {mytree != null && <QuestionByTree tree={mytree} finish={actionFinished} />}
                                                </Modal>
                                            </div>}
                                        </div>
                                    }
                                    <div className="w-100 text-right mt-4">
                                        <button type="submit" className="btn btn-sm btn-success">{formadd_item.values && formadd_item.values.id ? "Cập nhật màn hình" : "Thêm vào màn hình"}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                }
            </Modal>
            <Modal show={modalMhCopy} onHide={() => setModalMhCopy(false)} animation={true} size="xl" dialogClassName="modal-90w">
                <Modal.Header closeButton>
                    <Modal.Title>Liên kết dữ liệu từ màn hình khác</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 col-sm-4 form-group">
                            <label>Chọn khóa học</label>
                            <UserDropDown apiname={"khoahoc?orderby=title-ASC"} placeholder="Chọn khóa học" colvalue={'khoahoc.id'} colname={'khoahoc.title'} name='grade_id'
                                onChange={async (e) => {setCopyKhID(e);setCopyBhID(null);setCopyMhID(null)}}></UserDropDown>
                        </div>
                        <div className="col-12 col-sm-4 form-group">
                            <label>Chọn chương học/Bài học</label>
                            <UserDropDown apiname={"khoahoc_baihoc?orderby=title-ASC&khoahoc_id="+(copyKhId ? copyKhId : 0)} placeholder="Chọn chương học/Bài học" colvalue={'khoahoc_baihoc.id'} colname={'khoahoc_baihoc.title'} name='grade_id'
                                onChange={async (e) => {setCopyBhID(e);setCopyMhID(null)}}></UserDropDown>
                        </div>
                        <div className="col-12 col-sm-4 form-group">
                            <label>Chọn màn hình</label>
                            <UserDropDown apiname={"khoahoc_manhinh?orderby=title-ASC&baihoc_id="+(copyBhId ? copyBhId : 0)} placeholder="Chọn chương học/Bài học" colvalue={'khoahoc_manhinh.id'} colname={'khoahoc_manhinh.title'} name='grade_id'
                                onChange={async (e) => {setCopyMhID(e)}}></UserDropDown>
                        </div>
                        <div className="w-100 p-3 rounded border">
                            {copyMhId ? <iframe width="100%" height={500} src={"/bai-hoc-chi-tiet/iframe?khoahoc_id=" + copyKhId + "&id_mh="+copyMhId}></iframe> :''}
                        </div>
                        <div className="w-100 d-flex justify-content-end mt-3">
                            {copyMhId ? <button type="button" className="btn btn-sm btn-success" onClick={()=>choiceScreen()}>Chọn màn hình</button> : ''}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {listChild && <>
                <div className="card height-auto mt-5">
                    <div className="card-body">
                        <div className="heading-layout1 mb-0">
                            <div className="item-title">
                                <h3>Nội dung hiển thị</h3>
                            </div>
                        </div>
                    </div>
                </div>
                {listChild.map((item, i) => {
                    var kh_value = item.kh_type == 'quest' ? JSON.parse(item.kh_value) : item.kh_value
                    return (
                        <div className="card height-auto mt-5" key={item.id}>
                            <div className="card-body p-0">
                                <div className="w-100 card-top border-bottom d-flex justify-content-end">
                                    <button type="button" className="btn border-left rounded-0 btn-sm" title="Di chuyển lên" onClick={() => changePosition(item.id, 'up', id)}><i className="fas fa-caret-up"></i></button>
                                    <button type="button" className="btn border-left rounded-0 btn-sm" title="Di chuyển xuống" onClick={() => changePosition(item.id, 'down', id)}><i className="fas fa-caret-down"></i></button>
                                    <button type="button" className="btn btn-sm btn-warning rounded-0" title="Sửa" onClick={() => editParam(item.id)}><i className="fas fa-pencil-alt"></i></button>
                                    <button type="button" className="btn btn-sm btn-danger rounded-0" title="Xóa" onClick={() => deleteParam(item.id)}><i className="fas fa-trash-alt"></i></button>
                                </div>

                                <div className="w-100 p-3 item-mh">
                                    {item.kh_type == 'quest' && /* eslint eqeqeq: 0 */
                                        kh_value.map((ans, index) => {
                                            return <div key={index} className="mb-2 border rounded overflow-hidden " id={"nuques-" + index}>
                                                <div>
                                                    <div className={"font-weight-bold p-2 bg-info d-flex justify-content-between"}>
                                                        <div>Câu hỏi {index + 1}</div>
                                                    </div>
                                                    {ans.parent_title && <div className="p-2 border-bottom">{parse(ans.parent_title)}</div>}
                                                    <div className="p-3 border-bottom mb-3">{parse(ans.title)}</div>
                                                    <div>
                                                        <ChoiceQuestion
                                                            key={index}
                                                            index={index}
                                                            item={ans}
                                                            test={detail}
                                                            action={false}
                                                        ></ChoiceQuestion>
                                                    </div>
                                                    {ans.comments &&
                                                        <div className="comment border-top p-2 mt-3">
                                                            <span>Nhận xét</span>
                                                            <div className="w-100 mt-2 d-flex">
                                                                <strong>{ans.teacher_comment}</strong>: {parse(ans.comments + '')}
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        })
                                    }

                                    <div className="custom-list-content"> {item.kh_type === 'text' && parse(kh_value)}</div>
                                    {item.kh_type == 'code' && /* eslint eqeqeq: 0 */
                                        <CodeMirror
                                            height="auto"
                                            theme={githubLightInit({
                                                settings: {
                                                    caret: '#c6c6c6',
                                                    fontFamily: 'monospace',
                                                }
                                            })}
                                            readOnly={true}
                                            extensions={[selectLang(item.khmeta)]}
                                            value={item.kh_value}
                                            onChange={(value, viewUpdate) => {
                                                // setValueMH(value)
                                            }}
                                        />
                                    }
                                </div>
                                {item.kh_type == 'tab' && parse(kh_value) &&
                                    <div className="col-12">
                                        {JSON.parse(item.kh_value)[0].type == "python" ?
                                            <>

                                                <ShowCodePython arrTab={JSON.parse(item.kh_value)} />
                                            </>
                                            :
                                            <ShowTab2
                                                id_param={item.id}
                                                arrTab={JSON.parse(item.kh_value)}
                                            />
                                        }
                                    </div>
                                }
                                {item.kh_type == 'importhtml' &&
                                    <div className="col-12">
                                        <div className="view-show-html2">
                                            <iframe style={{width:"100%", height:"90vh", }} title="iframe" src={item.kh_value}></iframe>
                                        </div>
                                    </div>
                                        
                                }
                            </div>
                        </div>
                    )
                })}


            </>
            }
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px" }}>

                <Pagination
                    activePage={page}
                    itemsCountPerPage={limit}
                    totalItemsCount={totalRecord}
                    pageRangeDisplayed={5}
                    itemClass="page-item"
                    linkClass="page-link"
                    prevPageText="<"
                    nextPageText=">"
                    onChange={setPage}
                />
            </div>
        </AkkhorLayout>
    );
}
