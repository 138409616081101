import React, { useState } from "react";

export default function QFillTo(props) {
    const { onAnswers, answers, maxNumber, setMaxNumber } = props;
    const [currentAnswer,setCurrentAnswer] = useState(answers && answers.correct ? answers.correct : []);//

    const getList = () => {
        let list = [];
        for (let i = 1; i <= maxNumber; i++) {
            list.push(i)
        }
        return list
    }

    const onChange = (e, i) => {
        if(e){
            let newList = [...currentAnswer]
            newList[i] = e.target.value
            setCurrentAnswer(newList)
        }
    }

    const onBlur = () => {
        onAnswers({ ...answers, correct: currentAnswer })
    }

    return (<div className="col-12 form-group">
        <button type="button" className="btn btn-sm btn-info mb-3" onClick={() => setMaxNumber(maxNumber + 1)}>Thêm câu trả lời</button>
        <label className="text-danger">Note *: Nhập các đáp án đúng cho 1 vị trí cách nhau bởi dấu chấm || (ví dụ: 22||hai hai||hai mươi hai)</label>
        {getList().map((item, i) => <div key={i} className="mb-2 ">

            <label>Câu trả lời đúng cho vị trí {i + 1} </label>
            <div className="d-flex align-items-center">
                <div className="w-100">
                    <input type="text" className="form-control" value={currentAnswer && currentAnswer[i] ? currentAnswer[i] : ''} onChange={(e) => onChange(e, i)} onBlur={onBlur} />
                </div>
            </div>
        </div>)}
    </div>);
}
