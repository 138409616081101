import React from "react";
import { LIST_ALPHA } from "../../../configs";
import parse from 'html-react-parser'

export default function QChooseOne(props) {
    const { item, currentAnswer } = props;
    const answers = item.answers

    return (<div className="col-12 form-group question-choisce ">
        <ul>
            {answers && <>
                {answers.list ?
                    <>
                        {answers.list.map((item, i) => <li key={i} className="d-flex mb-2 align-items-center item-choice-one " >
                            {LIST_ALPHA[i]}. <div className={"w-100 py-1 px-2 cursor-pointer rounded " + (parseInt(currentAnswer) === i ? ' border-info bg-info text-white' : ' border-grey')} >
                                {item.includes("<class") && item.includes(">") ? item : parse(item)}
                            </div>
                        </li>)}
                    </>
                    :
                    <>
                        {answers.map((item, i) => <li key={i} className="d-flex mb-2 align-items-center item-choice-one " >
                            {LIST_ALPHA[i]}. <div className={"w-100 py-1 px-2 cursor-pointer rounded " + (parseInt(currentAnswer) === i ? ' border-info bg-info text-white' : ' border-grey')} >
                                {item.includes("<class") && item.includes(">") ? item : parse(item)}
                            </div>
                        </li>)}
                    </>
                }
            </>}
        </ul>
    </div>);
}
