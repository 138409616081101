import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/Akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import UserDropDown from "../component/UserDropDown";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/Api";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { Modal, Button, Accordion } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormAddStudenBus from "../form/FormAddStudenBus";


export default function FormBusClass() {
    const { user } = useSelector((state) => state.auth);
    const { year_id } = useSelector((state) => state.save);
    const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [show, setShow] = useState(false);
    const [tripInfo, setTripInfo] = useState(null);

    const [nameTrip, setNameTrip] = useState()
    const [posTrip, setPosTrip] = useState()
    const [latitude, setLatitude] = useState()
    const [longitude, setLongitude] = useState()
    const [listBusTrip, setListBusTrip] = useState([])

    const defaultdetail = { school_id, year_id, author_id: user.data.id, grade_id: '', title: '', status: 1, type: 1, teacher_id: null, teacher_id2: null }

    useEffect(function () {
        if (id > 0) {
            let mounted = true;
            Axios.get(URL_LIST_ALL + "bus_class/" + id, user.config)
                .then(async (res) => {
                    if (res.data.status === "success") {
                        if (mounted) {
                            setDetail(res.data.data);
                            loadTripBus()
                        }
                    } else console.log(res.data.message);
                })
                .catch((err) => console.log(err));
            return () => (mounted = false);
        }
    }, [user, id, refresh]);

    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            title: Yup.string().trim().nullable().required('Nhập tên lớp').max(150, "Độ dài không quá 150 ký tự"),
            teacher_id: Yup.string().trim().nullable().required('Chọn giáo viên chủ nhiệm'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'bus_class/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập thông tin xe bus thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                Axios.post(URL_LIST_ALL + 'bus_class', values, user.config).then(res => {
                    setRefresh(!refresh)
                    setLoading(false);
                    setSubmitting(false);
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới thông tin xe bus thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    const loadTripBus = () => {
        Axios.get(URL_LIST_ALL + "bus_trip?orderby=position-asc&bus_class_id=" + id, user.config).then((res) => {
            if (res.data.status === "success") {
                setListBusTrip(res.data.data);
            } else console.log(res.data.message);
        }).catch((err) => { });
    }

    const addBusTrip = () => {
        if (nameTrip && posTrip) {
            let data = { bus_class_id: id, title: nameTrip, position: posTrip, latitude: latitude, longitude: longitude }
            Axios.post(URL_LIST_ALL + 'bus_trip', data, user.config).then(res => {
                setLoading(false);
                if (res.data.status === "success") {
                    confirmAlert({
                        title: 'Thành công',
                        message: 'Bạn thêm mới hành trình xe bus thành công',
                        buttons: [{ label: 'OK' }]
                    });
                    loadTripBus()
                } else {
                    confirmAlert({
                        title: 'Lỗi',
                        message: res.data.message,
                        buttons: [{ label: 'OK' }]
                    });
                }
                setNameTrip(null)
                setPosTrip(null)
                setLatitude(null)
                setLongitude(null)
            }).catch(err => console.log(err));
        }
    }

    const formEdit = useFormik({
        initialValues: tripInfo && { title: tripInfo.title, position: tripInfo.position, latitude: tripInfo.latitude, longitude: tripInfo.longitude },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            title: Yup.string().trim().nullable().required('Nhập tên lớp').max(150, "Độ dài không quá 150 ký tự"),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            Axios.put(URL_LIST_ALL + 'bus_trip/' + tripInfo.id, values, user.config).then(res => {
                setLoading(false);
                if (res.data.status === "success") {
                    confirmAlert({
                        title: 'Thành công',
                        message: 'Cập nhật hành trình xe bus thành công',
                        buttons: [{ label: 'OK' }]
                    });
                    loadTripBus()
                } else {
                    confirmAlert({
                        title: 'Lỗi',
                        message: res.data.message,
                        buttons: [{ label: 'OK' }]
                    });
                }
                setSubmitting(false);
                setTripInfo(null)
                setShow(false)
            }).catch(err => console.log(err));
        },
    });

    const deleteStudentBus = (id) => {
        confirmAlert({
            title: "Thông báo",
            message: "Bạn có chắc muốn xoá ?",
            buttons: [
                {
                    label: "Có",
                    onClick: () => {
                        Axios.delete(URL_LIST_ALL + 'bus_trip_student/' + id, user.config).then(res => {
                            if (res.data.status === "success") {
                                setRefresh(!refresh)
                            } else alert(res.data.message);
                        }).catch(err => console.log(err));
                    },
                },
                { label: "Không" },
            ],
        });
    }
    return (
        <AkkhorLayout idrole="list-class">
            <div className="card height-auto mt-5">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Quản lý xe bus</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Tên xe <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("title")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Giáo viên phụ trách  <span className="text-danger">*</span></label>
                                <UserDropDown apiname={"users?typeaccount=1&orderby=fullname-ASC&limit=500&school_id=" + school_id} placeholder="Chọn giáo viên" colvalue={'users.id'} colname={'users.fullname'} formik={formik} name='teacher_id'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('teacher_id', e)
                                    }}></UserDropDown>
                                <AlertErrorForm formik={formik} name="teacher_id"></AlertErrorForm>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Giáo viên phụ</label>
                                <UserDropDown apiname={"users?typeaccount=1&orderby=fullname-ASC&limit=500&school_id=" + school_id} placeholder="Chọn giáo viên" colvalue={'users.id'} colname={'users.fullname'} formik={formik} name='teacher_id2'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('teacher_id2', e)
                                    }}></UserDropDown>
                                <AlertErrorForm formik={formik} name="teacher_id2"></AlertErrorForm>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Trạng thái</label>
                                <select className="form-control height32" {...formik.getFieldProps("status")}>
                                    <option value="0">Khoá</option>
                                    <option value="1">Hoạt động</option>
                                </select>
                            </div>
                            {id > 0 &&
                                <div className="col-12 mt-2">
                                    <h5>Các điểm dừng xe</h5>
                                    <div className="row mt-3">
                                        <div className="col-12 col-sm-3 form-group">
                                            <label>Tên điểm dừng xe</label>
                                            <input type="text" placeholder="VD: Tố Hữu - Thanh Xuân" className="form-control height32" autoComplete="off" onChange={(e) => setNameTrip(e.target.value)} />
                                        </div>
                                        <div className="col-12 col-sm-2 form-group">
                                            <label>Thứ tự hiển thị</label>
                                            <input type="number" placeholder="VD: 1" className="form-control height32" autoComplete="off" onChange={(e) => setPosTrip(e.target.value)} />
                                        </div>
                                        <div className="col-12 col-sm-2 form-group">
                                            <label>Latitude</label>
                                            <input type="text" placeholder="" className="form-control height32" autoComplete="off" onChange={(e) => setLatitude(e.target.value)} />
                                        </div>
                                        <div className="col-12 col-sm-2 form-group">
                                            <label>Longitude</label>
                                            <input type="text" placeholder="" className="form-control height32" autoComplete="off" onChange={(e) => setLongitude(e.target.value)} />
                                        </div>
                                        <div className="col-12 col-sm-2 form-group">
                                            <label>#</label>
                                            <button type="button" className="btn btn-sm btn-info" onClick={addBusTrip}>Thêm</button>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="col-12 mt-3">
                                <Accordion className="w-100 mt-3">
                                    <div className="row">
                                        {listBusTrip && listBusTrip.map((item, index) => {
                                            return (
                                                <div className="col-12 col-sm-4" key={index}>
                                                    <Accordion.Item eventKey={index} key={index} className="card mt-3">
                                                        <Accordion.Header as="div" bsPrefix="accordion-header card-header">
                                                            <div className="row d-flex justify-content-between">
                                                                <div className="col-10">
                                                                    <p>{item.title}</p>
                                                                </div>
                                                                <div className="pr-3">
                                                                    <span className="fas fa-angle-down"></span>
                                                                </div>
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body bsPrefix="list-studen-card accordion-body card-body">
                                                            <div className="w-100 d-flex justify-content-between">
                                                                <h4>Danh sách học sinh</h4>
                                                                <div>
                                                                    <button type="button" className="btn btn-sm btn-warning" onClick={() => { setShow(true); setTripInfo(item) }}>Sửa điểm dừng xe</button>
                                                                </div>
                                                            </div>
                                                            <div className="w-100 mt-2">
                                                                <FormAddStudenBus tripid={item.id} bus_id={id} setRefresh={(e) => { setRefresh(!refresh) }} />
                                                                <div className="list-student mt-3">
                                                                    {detail && detail.list_trip && detail.list_trip[item.id] &&
                                                                        detail.list_trip[item.id].map(function (it, ix) {
                                                                            return (<div className="w-100 item-student d-flex align-items-center justify-content-between" key={ix}>
                                                                                <span>
                                                                                    {it.fullname} {it.class && "- Lớp " + it.class}
                                                                                </span>
                                                                                <button className="btn btn-sm btn-danger" type="button" onClick={() => { deleteStudentBus(it.id) }}>Xóa</button>
                                                                            </div>)
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </Accordion>
                            </div>

                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu lớp</>}</button>
                                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                            </div>
                        </div>
                    </form>
                    {id > 0 &&
                        <Modal show={show} onHide={() => setShow(false)} animation={true} size="lg">
                            <Modal.Header closeButton>
                                <Modal.Title>Sửa hành trình bus</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form className="new-added-form" method="post" onSubmit={formEdit.handleSubmit}>
                                    <div className="row">
                                        <div className="col-12 form-group">
                                            <label>Tên hành trình <span className="text-danger">*</span></label>
                                            <input type="text" placeholder="" className="form-control height32" {...formEdit.getFieldProps("title")} autoComplete="off" />
                                        </div>
                                        <div className="col-12 form-group">
                                            <label>Vị trí<span className="text-danger">*</span></label>
                                            <input type="text" placeholder="" className="form-control height32" {...formEdit.getFieldProps("position")} autoComplete="off" />
                                        </div>
                                        <div className="col-12 form-group">
                                            <label>Latitude<span className="text-danger">*</span></label>
                                            <input type="text" placeholder="" className="form-control height32" {...formEdit.getFieldProps("latitude")} autoComplete="off" />
                                        </div>
                                        <div className="col-12 form-group">
                                            <label>Longitude<span className="text-danger">*</span></label>
                                            <input type="text" placeholder="" className="form-control height32" {...formEdit.getFieldProps("longitude")} autoComplete="off" />
                                        </div>
                                        <div className="col-12 form-group">
                                            <button type="submit" disabled={formEdit.isSubmitting} className="btn btn-sm btn-success">{loading ? <Spinner /> : <>Lưu lại</>}</button>
                                        </div>

                                    </div>
                                </form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShow(false)}>Đóng</Button>
                            </Modal.Footer>
                        </Modal>
                    }
                </div>
            </div>
        </AkkhorLayout>
    );
}
