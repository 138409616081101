import React, { useEffect, useState } from 'react';
import AkkhorLayout from '../../layout/Akkhor';
import { useSelector } from "react-redux";
import { Link, useParams } from 'react-router-dom';
import Axios from 'axios';
import { URL_LIST_ALL } from '../../configs/Api';
import SRList from "../../component/List";
import ShowQuestion from "../questions/ShowQuestion";
import parse from 'react-html-parser';

export default function ReportExamQuestionsView() {
    const { idtest,id } = useParams();
    const { user } = useSelector((state) => state.auth);
    const [detail, setDetail] = useState([]);
    const [answers, setAnswers] = useState([]);

    useEffect(() => {
        let mounted = true;

        Axios.get( URL_LIST_ALL + "at_exam_tests_question/" + idtest + '?question_id='+id, user.config).then(async (res) => {
            if (res.data.status === "success" && mounted) {
                const _detail = res.data.data;
                let corrects = _detail.decode.corrects
                setAnswers({list:_detail.decode.answers,correct:corrects})
                setDetail(_detail);
            }
            else console.log(res.data.message);
        }).catch((err) => console.log(err));

        return () => (mounted = false);
    }, [user, id,idtest]);

    let columns = [
        { name: "id", label: "ID", options: { filter: false, sort: true } },
        { name: "fullname", label: "Họ và tên", options: { filter: true, sort: true } },
        { name: "corrects", label: "Câu trả lời của học sinh",  
            options: {
                filter: false, sort: false, customBodyRender: (value, tableMeta) => {
                    let _items = detail
                    const index = tableMeta.rowIndex
                    const itemData = tableMeta.tableData[index];
                    if(itemData.code ==="QMatching"){
                        let _corrects = value
                        let _x =[];
                        _corrects.map((item) => {
                            _x[item.right] = item.label;
                            return item;
                        })
                        _items.corrects = _x;
                    }
                    return <div >
                        {_items && <ShowQuestion type="answer" item={_items} answers={{correct:value}} />}
                    </div>;
                }
            }, 
        },
        { name: "percent", label: "Đánh giá", options: { filter: false, sort: false , customBodyRender: (value, tableMeta) => { return value === 100 ? <span className="btn btn-sm bg-success text-light">Đúng</span> : <span className="btn btn-sm bg-danger text-light">Sai</span>}} },
    ];
  
    //lastname-ASC_fullname-ASC
    return (
        <AkkhorLayout idrole="examination-report" roleignone={user.data.typeaccount === 2}>
            
            {detail && <>
                <div className="breadcrumbs-area py-2">
                    <ul>
                        <li>
                            <Link to="/">Trang chủ</Link>
                        </li> 
                       
                        <li>
                            <Link to="/examination-report">Thống kê</Link>
                        </li>
                        <li>
                            <Link to={"/examination-report-question/"+idtest}>{detail.test_title}</Link>
                        </li>
                        <li>Chi tiết theo câu hỏi</li>
                    </ul>
                </div>
                {user && <div className="card height-auto">
                    <div className="border rounded overflow-hidden">
                        <div className="p-3">{detail.title && parse(detail.title)}</div>
                        <div>
                            <ShowQuestion type="question" item={detail} answers={answers} />
                        </div>
                    </div>
                    
                    <SRList name={'report_exam_student_answer'} idrole={"list-tests"} linkaction={'tests'} params={"question_id="+id+'&test_id='+idtest+'&status=1'} title={""} defaultLimit={100} serverSide={true} noAction={true} onlyList={true} columns={columns} />
                        
                </div>}
            </>}
        </AkkhorLayout>
    );
}
