import React, { useEffect, useState } from 'react';
import AkkhorLayout from '../layout/Akkhor';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { URL_LIST_ALL } from '../configs/Api';
import anhnencode from "../assets/img/anhnencode-thumb.jpg"

export default function ListKhoaHocRc() {
    const { user } = useSelector((state) => state.auth);
    const [list, setList] = useState([]);
    useEffect(() => {
        if (user) {
            let mounted = true;
            let url = URL_LIST_ALL + "my_khoahoc"
            Axios.get(url, user.config).then(async (res) => {
                if (res.data.status === "success" && mounted) {
                    setList(res.data.data);
                }
                else console.log(res.data.message);
            }).catch((err) => console.log(err));

            return () => (mounted = false);
        }
    }, [user]);

    return (
        <AkkhorLayout idrole="examination" roleignone={user.data.typeaccount === 2}>
            <div className="breadcrumbs-area py-sm-2">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>Đường đua Tomo <i className='fas fa-flag-checkered'></i></li>
                </ul>
            </div>
            {user && <div className="card height-auto">
                <div className="card-body list-khoahoc-racing">
                    {list.length > 0 ? <>
                        <div className="row">
                            {list.map((item, i) =>
                                <div className="col-12 col-sm-6 col-md-3 item-racing" key={i}>
                                    <a href={'/tomo-racings/' + item.id} className="wrapper border rounded shadow mb-3 overflow-hidden">
                                        <div className='thumbnail'>
                                            <img src={item.thumbnail ? item.thumbnail : anhnencode} alt=""/>
                                        </div>
                                        <div className='title'><h4>{item.title}</h4></div>
                                    </a>
                                </div>
                            )}
                        </div>
                    </>
                        :
                        <h5>Đường đua chưa bắt đầu</h5>
                    }
                </div>
            </div>}
        </AkkhorLayout>
    );
}
