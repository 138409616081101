
import React from "react";
import { useSelector } from "react-redux";
import SRList from "../component/List";
import Moment from "react-moment";
import AkkhorLayout from "../layout/Akkhor";

export default function ListLopHoc() {
  let params = '';
  const { user } = useSelector((state) => state.auth);
 
  const columns = [
    { name: "title", label: "Tên lớp học", options: { filter: true, sort: true } },
    { name: "khoahoc_name", label: "Tên khóa học", options: { filter: true, sort: true } },
    {
      name: "datejoin", label: "Ngày tham gia",
      options: {
        filter: false, sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const time = new Date(value * 1000);
          return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
        },
      }
    },
    {
        name: "alias", label: "Thao tác",
        options: {
          filter: false, sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            
            return <div className="w-100"><a className="btn btn-sm btn-success" href={"/bai-hoc-chi-tiet/"+value}>Vào học</a></div>;
          },
        }
      },
  ];


  return (
    <AkkhorLayout idrole="khoa-hoc" roleignone={true}>
      <SRList name="myclass" idrole="khoa-hoc" params={params} title="Khóa học" defaultLimit={50} serverSide={true} columns={columns}></SRList>
    </AkkhorLayout>
  );
}
