import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AkkhorLayout from "../layout/Akkhor";
import CustomList from "../component/CustomList";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import FormBaiHoc from "./FormBaiHoc";
import { URL_LIST_ALL } from "../configs/Api";
import { Modal } from "react-bootstrap";
export default function ListBaiHoc() {
  const { user } = useSelector((state) => state.auth);
  const { id } = useParams();

  const [params, setParam] = useState("&khoahoc_id=" + id);
  const [idBH, setIdBH] = useState(0);
  const [loadBaiHoc, setLoadBaiHoc] = useState(false);
  const [arrChuong, setArrChuong] = useState([]);
  const [idChuong, setIdChuong] = useState(0);
  const [khInfo, setKhInfo] = useState();
  const [orderBy, setOrderBy] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const columns = [
    { name: "id", label: "ID" },
    { name: "title", label: "Tên bài học" },
    { name: "parent", label: "Thuộc chương" },
    { name: "datecreate", label: "Ngày tạo" },
    {
      name: "id", label: "Thao tác",
      options: {
        filter: false, sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {

          return <>

            <button className="btn btn-sm btn-warning m-1" onClick={() => { setIdBH(value); setShowModal(true) }} data-toggle="tooltip" data-placement="bottom" title="sửa"><i className="fas fa-pencil-alt"></i></button>
            <button className="btn btn-sm btn-danger m-1" onClick={() => actionDelete(value)} data-toggle="tooltip" data-placement="bottom" title="Xóa"><i className="fas fa-trash-alt"></i></button>
            <div className="form-pos">
              <button className="btn btn-sm btn-outline-info" type="button" title="Di chuyển lên" onClick={() => changePosition(value, 'up')}><span className="fas fa-angle-up"></span></button>
              <button className="btn btn-sm btn-outline-info" type="button" title="Di chuyển xuống" onClick={() => changePosition(value, 'down')}><span className="fas fa-angle-down"></span></button>
            </div>

          </>;
        },
      }
    },
  ];
  useEffect(
    function () {

      setOrderBy("ASC")
      if (id > 0) {

        let mounted = true;
        Axios.get(URL_LIST_ALL + "khoahoc/" + id, user.config)
          .then(async (res) => {
            if (res.data.status === "success") {
              if (mounted) {
                setKhInfo(res.data.data);
              }
            } else console.log(res.data.message);
          })
          .catch((err) => console.log(err));

        return () => (mounted = false);
      }
    }, [user, id]
  );
  useEffect(() => {

    Axios.get(URL_LIST_ALL + "khoahoc_baihoc?parent_id=0&orderby=position-ASC&khoahoc_id=" + id, user.config)
      .then(async (res) => {
        if (res.data.status === "success") {

          setArrChuong(res.data.data)


        } else console.log(res.data.message);
      })
      .catch((err) => console.log(err));
  }, [user, id, refresh]);
  const changePosition = (id, type) => {
    var data = { name: "khoahoc_baihoc", type: type }
    Axios.put(URL_LIST_ALL + 'change_position/' + id, data, user.config).then(res => {
      if (res.data.status === "success") {
        setRefresh(!refresh)
      } else alert(res.data.message);
    }).catch(err => console.log(err));
  }
  const loadData = () => {

    Axios.get(URL_LIST_ALL + "khoahoc/" + id, user.config)
      .then(async (res) => {
        if (res.data.status === "success") {

          setKhInfo(res.data.data);

        } else console.log(res.data.message);
      })
      .catch((err) => console.log(err));

  }


  const getIdBaiHoc = (id) => {
    setIdBH(id)
    setShowModal(true)
  }
  const selectChuong = (idChuong) => {
    setIdChuong(idChuong)
    if (idChuong == "all") {
      setParam("&khoahoc_id=" + id + "&orderby=position-" + orderBy)
    } else if (idChuong == "0") {
      setParam("&parent_id=0&orderby=position-" + orderBy)
    } else {
      setParam("&parent_id=" + idChuong + "&orderby=position-" + orderBy)
    }

  }
  const selecOrderBy = (value) => {

    setOrderBy(value)
    setParam("&parent_id=" + idChuong + "&orderby=position-" + value)
  }
  const closeModal = () => {
    setShowModal(false)
    setRefresh(!refresh)
  }
  const actionDelete = (id) => {

    confirmAlert({
      title: "Thông báo",
      message: "Bạn có chắc muốn xoá dữ liệu",
      buttons: [
        {
          label: "Có",
          onClick: () => {
            Axios.delete(URL_LIST_ALL + "khoahoc_baihoc" + '/' + id, user.config).then(res => {

              if (res.data.status === "success") {
                setRefresh(!refresh)
              } else alert(res.data.message);
            }).catch(err => console.log(err));
          },
        },
        { label: "Không" },
      ],
    });
  }

  return (
    <AkkhorLayout idrole="khoa-hoc">
      <h4 className="w-100 mt-5 mb-2">Khóa học: {khInfo && khInfo.title}</h4>

      <Modal show={showModal} onHide={() => { setShowModal(false); setIdBH(0) }} animation={true} size="xl" dialogClassName="modal-50w">
        <div className="p-6" style={{ padding: "20px" }}>
          <FormBaiHoc khID={id} id={idBH} loadData={loadData} closeModal={closeModal} />
        </div>
      </Modal>
      <form >

        <div className="col-12 form-group" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div className="col-4 col-sm-4" >
            <label>Sắp xếp</label>
            <select className="form-control height32" onChange={(e) => selecOrderBy(e.target.value)} value={orderBy}>
              <option value="ASC">Từ thấp lên cao</option>
              <option value="DESC">Từ cao xuống thấp</option>
            </select>
          </div>
          <div className="col-4 col-sm-4" >
            <label>Chọn chương</label>
            <select className="form-control height32" onChange={(e) => selectChuong(e.target.value)} value={idChuong}>
              <option value="all">Tất cả</option>
              <option value="0">Các chương</option>
              {arrChuong && arrChuong.length > 0 && arrChuong.map((item, index) => {
                return (
                  <option value={item.id} key={index}>{item.title}</option>
                )
              })}
            </select>
          </div>
          <div className="col-4 col-sm-4 d-flex justify-content-end mb-3"><button type="button" onClick={() => setShowModal(true)} className="btn btn-success m-2"
            style={{ fontSize: 13, fontWeight: '600' }}>
            <i className="fas fa-plus-"></i> Thêm mới</button></div>
        </div>
      </form>
      <CustomList name="khoahoc_baihoc" noAction={true} refresh={refresh} linkactionadd={"khoahoc_baihoc?kh_id=" + id} idrole="khoa-hoc" params={params} title="Bài học" defaultLimit={50} serverSide={true} columns={columns}></CustomList>
    </AkkhorLayout>
  );
}
