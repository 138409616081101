import { initialState, slice } from "./Slice";

const { actions } = slice;

export const saveAnswers = (Answers) => dispatch =>
{
    dispatch(actions.choice({Answers}))
}
export const saveListTabs = (listTabs) => dispatch =>
{
    dispatch(actions.choice({listTabs}))
}
export const saveCodeValue = (codevalue) => dispatch =>
{
    dispatch(actions.choice({codevalue}))
}
export const saveChangeTimeTable = (TimeTableSave) => dispatch =>
{
    dispatch(actions.choice({TimeTableSave}))
}
export const setResetPost = (resetpost) => dispatch =>
{
    dispatch(actions.choice({resetpost}))
}
export const setResetComments = (resetcomments) => dispatch =>
{
    dispatch(actions.choice({resetcomments}))
}

export const saveUser = (user) => dispatch =>
{
    dispatch(actions.choice({user}))
}
export const saveRefreshSocket = (datasocket) => dispatch =>
{
    dispatch(actions.choice({datasocket}))
}
export const saveFollowTest = (followtest) => dispatch =>
{
    dispatch(actions.choice({followtest}))
}

export const saveYear = (year_id) => dispatch =>
{
    dispatch(actions.choice({year_id}))
}

export const saveListYear = (listyear) => dispatch =>
{
    dispatch(actions.choice({listyear}))
}

export const saveRedux = (state) => dispatch =>
{
    
    dispatch(actions.choice(state))
}

export const resetRedux = () => dispatch =>
{
    
    dispatch(actions.choice(initialState))
}