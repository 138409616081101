import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import AkkhorLayout from '../../layout/Akkhor';
import { useSelector } from "react-redux";
import { useParams,useHistory } from "react-router-dom";
import { URL_LIST_ALL } from "../../configs/Api";
import { Link } from 'react-router-dom';
import Moment from "react-moment";
import moment from "moment";
import TimeOut from "./TimeOut";
import { confirmAlert } from "react-confirm-alert";
import Spinner from "../../component/Spinner";


export default function DetailExamAzota(props) {
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const [detail, setDetail] = useState(null);
  const [now] = useState(moment(new Date()));
  const [formatMask, setFormatMask] = useState('HH:mm:ss');
  const [timeOut, setTimeOut] = useState(false);
  const [loading, setLoading] = useState(null);
  const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
  const localclass_id = user && user.data && user.data.class && user.data.class.class_id > 0 ? user.data.class.class_id : 0;
  const [fileUpload, setFileUpload] = useState([]);
  const inputRef = useRef(null);
  let history = useHistory();



  useEffect(() => {
    if (id > 0) {
      let mounted = true;
      Axios.get(URL_LIST_ALL + "at_exam_tests/" + id, user.config).then(async (res) => {
        if (res.data.status === "success" && mounted) {
          const _detail = res.data.data;
          const localTime = _detail.time;
          if (localTime >= 60) setFormatMask('HH:mm:ss');
          else setFormatMask('mm:ss');
          now.add(localTime, 'minutes')//minutes
          
          setDetail(_detail);

        }
        else console.log(res.data.message);
      }).catch((err) => console.log(err));

      return () => (mounted = false);
    }
  }, [user, id, now]);// eslint-disable-line

  const Nopbai = () => {
    setLoading(true)

    const datahis = {
      test_id: detail.id,
      school_id: school_id,
      grade_id: detail.grade_id,
      class_id: localclass_id,
      subject_id: detail.subject_id,
      type_id: detail.type_id,
      time: detail.id,
      title: detail.title,
      content: JSON.stringify(fileUpload),
      correct_number: 0,
      point: 0,
      total_number: fileUpload.length,
      remain: 0,
      status: 1,
      total_answer: fileUpload.length,
      istest:1
    }
    Axios.post(URL_LIST_ALL + "exam_history", datahis, user.config).then(async (res) => {
      setLoading(null)
      if (res.data.status === "success") {
        confirmAlert({
          title: 'Thành công',
          message: "Nộp bài thành công",
          buttons: [{ label: "OK" ,onClick: () => {
            history.goBack()
          }}]
        })
      } else console.log(res.data.message);
    }).catch((err) => { console.log(err); setLoading(null) });
  }

  const uploadFile = async (event) => {

    const file = event.target.files[0]
    if (file) {
      let data = new FormData()
      data.append("type", "s3")
      data.append("name", "file")
      data.append("file", file)
      data.append("filetype", file.type)
      let file_size = file.size;
      if (file_size > 3145728) {
        confirmAlert({
          title: 'Lỗi',
          message: "File không được lớn hơn 3 Mb",
          buttons: [{ label: 'OK' }]
        })
        return
      } else {
        Axios.post(URL_LIST_ALL + "upload", data, user.config).then(async (res) => {
          if (res.data.status === "success") {

            let newList = fileUpload.slice();
            newList.push({ url: res.data.url, type: res.data.type })
            setFileUpload(newList)

          } else {
            return;
          }
        }).catch((err) => { console.log(err) })
      }

    }
  }

  const removeFile = async (url) => {
    let newList = []
    fileUpload.map(function (item) {
      if (item.url !== url) {
        newList.push(item)
      }
      return item
    })
    if (newList) {
      setFileUpload(newList)
    }
  }

  return (
    <AkkhorLayout idrole="examination" roleignone={user.data.typeaccount === 2}>
      {detail && (<div className="container-fluid">
        <div className="breadcrumbs-area py-3">
          <ul>
            <li>
              <Link to="/">Trang chủ</Link>
            </li>
            <li>Phiếu bài tập azota</li>
          </ul>
        </div>
        <div className="card height-auto">
          <div className="card-body">
            <div>
              <div className="row">
                <div className="col-sm-9">
                  <h2 className="text-center mb-3">{detail.title}</h2>

                  <div className="row mb-3">
                    <h4 className="text-left mb-3 col-12">Đề bài</h4>
                    {detail.files && detail.files.map(function (item, i) {
                      return (<div className="col-auto" key={i} style={{ maxWidth: 200 }}>
                        {item.type === "image" ?
                          <img src={item.url} style={{ width: "100%", height: 200, objectFit: "cover" }} alt="" />
                          :
                          <img src="https://tomoclass2021.s3.ap-southeast-1.amazonaws.com/images/2021/11/08/rules_of_fight_club.png" style={{ width: "100%", height: 200, objectFit: "cover" }} alt="" />
                        }
                        <button className="btn btn-sm btn-info w-100" style={{ borderRadius: 0 }} onClick={() => window.open(item.url, "_blank")}>Tải về</button>
                      </div>)
                    })}
                  </div>
                  <div className="row">
                    <h4 className="text-left mb-3 col-12">Làm bài</h4>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-12 form-group">
                         
                          <div className="row list-file">
                            {fileUpload && fileUpload.length > 0 && fileUpload.map(function (item, i) {
                              return (
                                <div className="item-upload" key={i} style={{ width: 130, overflow: "hidden", padding: 15, marginBottom: 15 }}>
                                  {item.type === "image" ? <img src={item.url} style={{ width: 100, height: 150, objectFit: "cover" }} alt="" />
                                    :
                                    <img src="https://tomoclass2021.s3.ap-southeast-1.amazonaws.com/images/2021/11/08/rules_of_fight_club.png" style={{ width: 100, height: 150, objectFit: "cover" }} alt="" />
                                  }
                                  <button className="btn btn-sm btn-danger text-center w-100" onClick={() => { removeFile(item.url) }}>Xóa</button>
                                </div>
                              )
                            })}
                          </div>
                          <div className="form-upload">
                            <input type="file" id="file" ref={inputRef} style={{ display: "none" }} accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={uploadFile} />
                            <div className="d-flex justify-content-center align-item-center" style={{ border: "1px dashed", padding: 20, borderRadius: 5, cursor: "pointer" }} onClick={() => { inputRef.current.click() }}>
                              <span>Tải lên bài làm</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  {!timeOut && <TimeOut className="p-3" now={now} formatMask={formatMask} onTimeOut={setTimeOut}></TimeOut>}
                  <h5 className="mb-3 text-warning">Hiện tại: <Moment interval={1000} format="DD/MM/YYYY HH:mm:ss"></Moment></h5>
                  <button type="button" className="btn btn-success btn-lg btn-block py-4 cursor-pointer" style={{ minWidth: 200, maxWidth: '100%' }} onClick={()=>Nopbai()}>
                    {loading ? <Spinner /> : <>Nộp bài</>}
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
    </AkkhorLayout>
  );
}
