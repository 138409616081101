
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useHistory } from "react-router-dom";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/Api";
import HeaderBar from "../component/HeaderBar";
import ViewScreenKh from "../component/ViewScreenKh";
import { Modal } from "react-bootstrap";
import { githubLightInit } from '@uiw/codemirror-theme-github';
import CodeMirror from '@uiw/react-codemirror';
import { langs } from '@uiw/codemirror-extensions-langs';
import { saveCodeValue } from "../redux/Actions";
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
export default function ListBaiHocCuaToi() {

    const { user } = useSelector((state) => state.auth);
    const { codevalue } = useSelector((state) => state.save);
    const { alias } = useParams();
    let query = useQuery();
    const dispatch = useDispatch()
    const [infoClass, setInfoClass] = useState({});
    const [maxPosition, setMaxPosition] = useState(0);
    const [clickTabSibar, setClickTabSibar] = useState(false);
    const [listBaiHoc, setListBaiHoc] = useState([]);
    const [currentScreen, setCurrentScreen] = useState(null)
    const [currentScreen2, setCurrentScreen2] = useState(null)
    const [currentScreen1, setCurrentScreen1] = useState(null)
    const [onRefresh, setOnRefresh] = useState(false)
    const [loading, setLoading] = useState(false);
    const [arrListManHinh, setArrListManHinh] = useState([]);
    const [arrListManHinhNext, setArrListManHinhNext] = useState([]);
    const [arrListManHinhPrev, setArrListManHinhPrev] = useState([]);
    const [modalIde, setModalIde] = useState(false);
    const [langType, setLangType] = useState('html')
    const [codeResult, setCodeResult] = useState(null)
    const khoahoc_id = parseInt(query.get("khoahoc_id"));

    let history = useHistory();
    const handleClick = () => {
        history.push('./' + alias);
    }
    useEffect(
        function () {
            if (user) {
                setCurrentScreen(null)
                setLoading(true)
                Axios.get(URL_LIST_ALL + "baihoc?" + (alias == "iframe" ? "khoahoc_id=" + khoahoc_id : "alias=" + alias), user.config).then(async (res) => {
                    if (res.data.status === "success") {
                        if (res.data.data.kh_info) {
                            setLangType(res.data.data.kh_info.language_code)
                        }
                        setInfoClass(res.data.data.class_info)
                        setListBaiHoc(res.data.data.data_list)
                        setCurrentScreen(res.data.data.current_screen)
                        setMaxPosition(res.data.data.maxPosition)
                        let currentScreenTest = res.data.data.current_screen
                        loadListManHinh(res.data.data.current_screen)
                        if (currentScreenTest.position > 1) {
                            currentScreenTest1(currentScreenTest)
                        }
                        currentScreenTest2(currentScreenTest)

                    }
                    setLoading(false)
                }).catch((err) => {
                    setLoading(false)
                    console.log(err);
                });
            }
        }, [user, alias, onRefresh]
    );

    const selectLang = (type) => {
        if (type == 'javascript') return langs.jsx()
        if (type == 'html') return langs.html()
        if (type == 'python') return langs.python()
        if (type == 'php') return langs.php()
        if (type == 'css') return langs.css()
        if (type == 'java') return langs.java()
        if (type == 'sql') return langs.sql()
        if (type == 'kotlin') return langs.kotlin()
        if (type == 'c') return langs.c()
        return langs.html()
    }
    const loadData = () => {
        Axios.get(URL_LIST_ALL + "baihoc?alias=" + alias, user.config).then(async (res) => {
            if (res.data.status === "success") {
                setInfoClass(res.data.data.class_info)
                setListBaiHoc(res.data.data.data_list)
                setCurrentScreen(res.data.data.current_screen)
                setMaxPosition(res.data.data.maxPosition)
            }
        }).catch((err) => {
            setLoading(false)
            console.log(err);
        });
    }

    const action_gender_code = () => {
        if (codevalue) {
            setCodeResult(null)
            Axios.post("https://course-api.tomocode.net/api/" + "code_generade", { content: codevalue, code_type: langType }, user.config).then(async (res) => {
                if (res.data.status === "success") {
                    setCodeResult(res.data.data_rs)
                }
            }).catch((err) => {
                setLoading(false)
                console.log(err);
            });
        }
    }

    const ItemBaiHoc = (list, ischild = false) => {
        if (list && Array.isArray(list)) {
            return (<>
                {list.map((item, i) => {
                    return (
                        <div className="ld-lesson-items" key={i}>
                            {currentScreen && (item.CheckSeen == 1 || item.checkSeenParent == 1 || currentScreen.baihoc_id === item.id) ?
                                <div className={"ld-lesson-item-preview p-3 d-flex " + (currentScreen && (currentScreen.baihoc_id === item.id || currentScreen.id === item.id) ? "active" : '')}>
                                    <i className={"mt-1 mr-1 " + (currentScreen && (item.CheckSeen == 1 || item.checkSeenParent == 1 || currentScreen.baihoc_id === item.id || currentScreen.id === item.id) ? "fas fa-check-circle" : 'far fa-circle')}></i> <span>{item.title} </span>
                                </div>
                                :
                                <div className={"ld-lesson-item-preview p-3 d-flex "} style={{ color: "#ccc", cursor: "no-drop" }}>
                                    <i className={"mt-1 mr-1  far fa-circle"}></i> <span>{item.title}</span>
                                </div>
                            }
                            {item.child && item.child.length > 0 && <div className="ld-table-list ld-topic-list">{ItemBaiHoc(item.child)}</div>}
                            {item.list_screen && ItemScreen(item.list_screen)}
                        </div>
                    )
                })}
            </>)
        } else {
            return
        }
    }
    const currentScreenTest2 = async (currentScreen) => {
        await Axios.get(URL_LIST_ALL + "khoahoc_manhinh?khoahoc_id=" + currentScreen.khoahoc_id + "&position=" + (currentScreen.position + 1) + "&seen=1", user.config).then(async (res) => {
            if (res.data.status === "success") {
                setCurrentScreen2(res.data.data[0])
                loadListManHinhNext(res.data.data[0])
            }
        }).catch((err) => {
            setLoading(false)
            console.log(err);
        });
    }
    const currentScreenTest1 = async (currentScreen) => {
        await Axios.get(URL_LIST_ALL + "khoahoc_manhinh?khoahoc_id=" + currentScreen.khoahoc_id + "&position=" + (currentScreen.position - 1) + "&seen=1", user.config).then(async (res) => {
            if (res.data.status === "success") {
                setCurrentScreen1(res.data.data[0])
                loadListManHinhPrev(res.data.data[0])
            }
        }).catch((err) => {
            setLoading(false)
            console.log(err);
        });
    }
    const ItemScreen = (list) => {
        return (list.map((item, i) => {
            return (<div className="list-screen" key={i}>
                {currentScreen && (currentScreen.id === item.id || item.seen == 1) ?
                    <div className={"ld-table-list-item p-2 cursor-poiter " + (currentScreen && currentScreen.id === item.id ? "active bg-success text-white" : "")} onClick={() => loadCurrentScreen(item.id)}>
                        {(currentScreen && currentScreen.id === item.id ? <i className="fas fa-arrow-circle-right"></i> : "-")} {item.title} 
                    </div>
                    :
                    <div className={"ld-table-list-item p-2 "} style={{ cursor: "no-drop", color: "#ccc" }} >
                        {(currentScreen && currentScreen.id === item.id ? <i className="fas fa-arrow-circle-right"></i> : "-")} {item.title}  
                    </div>
                }
            </div>)
        }))
    }


    const loadCurrentScreen = async (screenID) => {
        handleClick()
        setArrListManHinh([])
        setLoading(true)
        await Axios.get(URL_LIST_ALL + "khoahoc_manhinh/" + screenID + "?seen=1", user.config).then(async (res) => {
            if (res.data.status === "success") {
                setCurrentScreen(res.data.data.current_screen)
                currentScreenTest2(res.data.data.current_screen)
                currentScreenTest1(res.data.data.current_screen)
            }
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            console.log(err);
        });

    }
    const onNextScreen = async () => {
        handleClick()
        setLoading(true)
        currentScreenTest2(currentScreen2)
        currentScreenTest1(currentScreen2)
        setArrListManHinhPrev(arrListManHinh)
        setArrListManHinh(arrListManHinhNext)

        await Axios.post(URL_LIST_ALL + "next_screen", { khoahoc_id: currentScreen.khoahoc_id, student_id: user.data.id, manhinh_id: currentScreen.id, status: 1 }, user.config).then(async (res) => {
            if (res.data.status === "success") {
                setCurrentScreen(res.data.data)
            }

        }).catch((err) => {
            setLoading(false)
            console.log(err);
        });

        loadData()
        setLoading(false)

    }

    const onPrevScreen = async () => {
        handleClick()
        setLoading(true)
        currentScreenTest2(currentScreen1)
        currentScreenTest1(currentScreen1)
        setArrListManHinhNext(arrListManHinh)
        setArrListManHinh(arrListManHinhPrev)
        await Axios.post(URL_LIST_ALL + "prev_screen", { khoahoc_id: currentScreen.khoahoc_id, student_id: user.data.id, manhinh_id: currentScreen.id, status: 1 }, user.config).then(async (res) => {
            if (res.data.status === "success") {
                setCurrentScreen(res.data.data)
            }
        }).catch((err) => {
            setLoading(false)
            console.log(err);
        });

        loadData()
        setLoading(false)

    }
    const loadListManHinh = async (currentScreen) => {
        if (user && currentScreen) {
            await Axios.get(URL_LIST_ALL + "khoahoc_param?manhinh_id=" + currentScreen.id + "&orderby=khoahoc_lk.position-asc&limit=1000", user.config).then(async (res) => {
                if (res.data.status === "success") {
                    setArrListManHinh(res.data.data)
                }
            }).catch((err) => console.log(err));
        }
    }
    const loadListManHinhNext = async (currentScreen) => {
        if (user && currentScreen) {
            await Axios.get(URL_LIST_ALL + "khoahoc_param?manhinh_id=" + currentScreen.id + "&orderby=khoahoc_lk.position-asc&limit=1000", user.config).then(async (res) => {
                if (res.data.status === "success") {
                    setArrListManHinhNext(res.data.data)
                }
            }).catch((err) => console.log(err));
        }
    }
    const loadListManHinhPrev = async (currentScreen) => {
        if (user && currentScreen) {
            await Axios.get(URL_LIST_ALL + "khoahoc_param?manhinh_id=" + currentScreen.id + "&orderby=khoahoc_lk.position-asc&limit=1000", user.config).then(async (res) => {
                if (res.data.status === "success") {
                    setArrListManHinhPrev(res.data.data)

                }
            }).catch((err) => console.log(err));
        }
    }
    return (alias !== 'iframe' ?
        <>
            <HeaderBar />
            <div className="learndash-wrapper">
                <div className="ld-focus ld-focus-initial-transition ld-focus-position-default">
                    <div className="ld-focus-sidebar" style={clickTabSibar ? { transform: "translateX(-310px)" } : { transform: "translateX(0px)" }}>
                        <div className="ld-course-navigation-heading">
                            <span className="ld-focus-sidebar-trigger" onClick={() => { setClickTabSibar(!clickTabSibar); }}>
                                <span className="ld-icon ld-icon-arrow-left">{clickTabSibar ? <i className="fas fa-angle-right"></i> : <i className="fas fa-angle-left"></i>}</span>
                            </span>
                            <h3>
                                <a href="https://tomocode.net/courses/huong-dan-html/" id="ld-focus-mode-course-heading" previewlistener="true">
                                    <span className="ld-icon ld-icon-content"><i className="fas fa-book"></i></span>
                                    {infoClass ? infoClass.khoahoc_title : ""}
                                </a>
                            </h3>
                        </div>
                        <div className="ld-focus-sidebar-wrapper">
                            <div className="ld-course-navigation">
                                <div className="ld-course-navigation-list">
                                    <div className="ld-lesson-navigation">
                                        {listBaiHoc && ItemBaiHoc(listBaiHoc)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="ld-focus-main" style={clickTabSibar ? { width: "100%", marginLeft: "40px" } : { width: "calc(100% -350px)", marginLeft: "350px" }}>
                        {loading ?
                            <div className="outer-loader">
                                <div className="loader"></div>
                            </div> :
                            currentScreen && <ViewScreenKh maxPosition={maxPosition} arrListManHinh={arrListManHinh} arrListManHinhNext={arrListManHinhNext} currentScreen={currentScreen} onNextScreen={() => onNextScreen()} onPrevScreen={() => onPrevScreen()} />
                        }
                    </div>

                </div>
            </div >
            {/* <div className="group-ide">
                <button type="button" className="btn btn-sm btn-info" onClick={() => setModalIde(true)}><i className="	fas fa-code"></i></button>
                <Modal show={modalIde} onHide={() => { setModalIde(false); }} animation={true} size="xl" dialogClassName="modal-90w">
                    <button className="btn btn-sm btn-modal-close" title="Close" onClick={() => setModalIde(false)}><i className="far fa-times-circle"></i></button>
                    <div className="card height-auto">
                        <div className="card-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12 col-sm-6 ide-left">
                                        <div className="w-100 d-flex justify-content-end extention-bar mb-1">
                                            <button className="btn btn-sm btn-default border mr-1 shadow-sm" type="button" title="Clear" onClick={() => { dispatch(saveCodeValue('')); setCodeResult(null) }}><i className="fas fa-file-code"></i></button>
                                            <button className="btn btn-sm btn-success shadow-sm" type="button" title="Running" onClick={() => action_gender_code()}><i className="fas fa-play"></i></button>
                                        </div>
                                        <CodeMirror
                                            height="auto"
                                            minHeight="500px"
                                            className="shadow-sm"
                                            theme={githubLightInit({
                                                settings: {
                                                    caret: '#c6c6c6',
                                                    fontFamily: 'monospace',
                                                }
                                            })}
                                            extensions={[selectLang(langType)]}
                                            value={codevalue}
                                            onChange={(value, viewUpdate) => {
                                                dispatch(saveCodeValue(value))
                                            }}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6 ide-result">
                                        <div className="container-fluid wrapper shadow-sm">
                                            {codeResult ?
                                                <iframe title="iframe" src={'https://course-api.tomocode.net/view_code_result?alias=' + codeResult}></iframe>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div> */}
        </>
        :
        currentScreen &&
        <div className="wrapper-content">
            {loading ?
                <div className="outer-loader">
                    <div className="loader"></div>
                </div> :
                currentScreen && <ViewScreenKh maxPosition={maxPosition} arrListManHinh={arrListManHinh} hideNextButton={true} arrListManHinhNext={arrListManHinhNext} currentScreen={currentScreen} onNextScreen={() => onNextScreen()} onPrevScreen={() => onPrevScreen() } />
            }
        </div>
    )
}
