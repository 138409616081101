import { useFormik } from "formik";
import * as Yup from "yup";
import React from "react";
import ViewStep from "../examination/tests/ViewStep";
import { CONFIG_STEP_TEST_1, LIST_ALPHA } from "../configs";
import AlertErrorForm from "../component/AlertErrorForm";
import UserDropDownClass from "../component/UserDropDownClass";
import Spinner from "../component/Spinner";
import { Accordion } from "react-bootstrap";
import StudentByClass from "../examination/tests/StudentByClass";
import GenderCode from "../component/GenderCode";

export default function RacingStep3(props) {
  const { detail, nextStep, previewStep } = props;

  const formik = useFormik({
    initialValues: detail,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
    }),
    onSubmit: (values, { setSubmitting }) => {
      console.log(values.questions);
      setSubmitting(true)
      if (values.listclass && values.listclass.length > 0) {
        values.number = values.listclass.length
        values.listclass.map((item, index) => {
          if (values.hasOwnProperty('class_' + item.value)) {
            values.listclass[index] = values['class_' + item.value]
          }
          return item
        })
      }else{
        values.listclass = null
      }
      setSubmitting(false)
      nextStep(values)
    },
  });

  const showAnwser = (item) => {
    let qanswer = item.answers
    let correct = item.corrects
    if (!Array.isArray(qanswer)) {
      qanswer = JSON.parse(qanswer)
    }
    if (item.type_id == 7) {
      return (<div>
        <ul>
          {qanswer && qanswer.map((item, i) => <li key={i} className="d-flex mb-2 align-items-center item-choice-one " >
            {LIST_ALPHA[i]}. <div className={"w-100 py-1 px-2 cursor-pointer rounded " + (parseInt(correct) == i ? ' border-info bg-info text-white' : ' border-grey')} >
              <GenderCode text_string={item} />
            </div>
          </li>)
          }
        </ul>
      </div>)
    }
    if (item.type_id == 8) {
      if (Array.isArray(correct) == false) {
        correct = JSON.parse(correct)
      }
      return (<div>
        <ul>
          {qanswer && qanswer.map((item, i) => <li key={i} className="d-flex mb-2 align-items-center item-choice-one " >
            {LIST_ALPHA[i]}. <div className={"w-100 py-1 px-2 cursor-pointer rounded " + (correct[i] ? ' border-info bg-info text-white' : ' border-grey')} >
              <GenderCode text_string={item} />
            </div>
          </li>)
          }
        </ul>
      </div>)
    }
    if (item.type_id == 9) {
      correct = JSON.parse(correct)
      return (<div>
        <ul>
          {correct && correct.map((item, i) =>
            <li key={i} className="d-flex mb-2 align-items-center item-choice-one " >
              <input className="form-control height-32" type="text" value={item} readOnly />
            </li>)
          }
        </ul>
      </div>)
    }
  }

  const deleteQuestion = (id) => {
    const updatedTree = formik.values.questions.filter(item => item.id !== id);
    detail.questions = updatedTree;
    formik.values.questions = updatedTree
  };

  return (
    <div className="row">
      <div className="col-sm-12 col-md-6 offset-md-3">
        <ViewStep step={2} arrStep={CONFIG_STEP_TEST_1} />

        <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-12 form-group">
              <div className="w-100 mt-3 mb-3">
                <div className="mt-2 mb-3">
                  Tổng số câu hỏi: {formik.values.questions ? formik.values.questions.length : 0}
                </div>
              </div>

              {formik.values.questions && Array.isArray(formik.values.questions) && <div>
                {formik.values.questions.map((item, index) => {
                  return (
                    <div className="rounded p-2 mb-2 border item-tree" key={index}>
                      <div className="border rounded p-2 title mb-2">
                        <label><strong>Câu hỏi:</strong> </label>
                        <GenderCode text_string={item.title} />
                      </div>
                      <div className="answer border rounded p-2 mb-2">
                        <label><strong>Câu trả lời</strong></label>
                        {showAnwser(item)}
                      </div>
                      <div className="w-100 d-flex align-items-center border-top pt-2 mb-2">
                        <label className="mb-0 mr-2">Điểm</label>
                        <input type="text" defaultValue={item.point ? item.point : 1} name="point" className="form-control form-control-sm" placeholder="Nhập điểm" onChange={(e) => { formik.values.questions[index].point = e.target.value }} />
                      </div>
                      <div className="w-100 d-flex justify-content-between align-items-center border-top pt-2">
                        <span>ID: {item.id}</span>
                        <button type="button" className="btn btn-sm btn-danger" onClick={() => deleteQuestion(item.id)}>
                          Xóa
                        </button>
                      </div>
                    </div>
                  )
                })}
              </div>}
              <label>Chọn lớp <span className="text-danger">*</span> </label>
              <UserDropDownClass apiname={"class?key=&orderby=title-ASC&page=1&limit=500&grade_id=" + (formik.values.grade_id)} placeholder="Tất cả lớp" detail={detail} colvalue={'class.id'} formik={formik} colname={'class.title'} name='listclass'
                onChange={async (e) => { await formik.setFieldValue('listclass', e); }}
              ></UserDropDownClass>
              <AlertErrorForm formik={formik} name="listclass"></AlertErrorForm>
            </div>
            <div className="col-12 form-group">
              <Accordion defaultActiveKey={0}>
                {formik.values.listclass && formik.values.listclass.map((item, index) => {
                  return <Accordion.Item eventKey={index} className="card mt-3" key={index}>
                    <Accordion.Header as="div" bsPrefix="accordion-header card-header" onClick={() => { }}>
                      <div className="row d-flex justify-content-between">
                        <div className="col-10">
                          Lớp {item.label}
                        </div>
                        <div className="pr-3">
                          <span className="fas fa-angle-down"></span>
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body bsPrefix="list-studen-card accordion-body card-body">
                      <StudentByClass myclass={item} onUpdate={(e) => {
                        formik.setFieldValue('class_' + e.value, e);
                      }} />
                    </Accordion.Body>
                  </Accordion.Item>
                })}
              </Accordion>
            </div>

            <div className="col-12 form-group mg-t-8 text-center">
              <button type="button" onClick={previewStep} className="btn-fill-lg bg-blue-dark btn-hover-yellow" >Quay lại</button>
              <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" > {formik.isSubmitting ? <Spinner /> : <>Kết thúc</>}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
