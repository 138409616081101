import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Axios from "axios";
import Spinner from "../component/Spinner";
import { confirmAlert } from "react-confirm-alert";
import { URL_LIST_ALL } from "../configs/Api";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";

export default function ItemFormChild(props) {
    const { parent_id } = props
    const { user } = useSelector((state) => state.auth);
    const [detail, setDetail] = useState(null);
    const [autoRefresh, setAutoRefresh] = useState(false);
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {title:'',parent_id:parent_id},
        enableReinitialize: true,
        validationSchema: Yup.object().shape({ title: Yup.string().trim().nullable().required('Nhập tiêu đề').max(150, "Độ dài không quá 150 ký tự"), }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            Axios.post(URL_LIST_ALL + 'books_tree', values, user.config).then(res => {
                setLoading(false);
                setSubmitting(false);
                if (res.data.status === "success") {
                    resetForm();
                    confirmAlert({
                        title: 'Thành công',
                        message: 'Bạn thêm mới nhánh thành công',
                        buttons: [{ label: 'OK' }]
                    });
                } else {
                    confirmAlert({
                        title: 'Lỗi',
                        message: res.data.message,
                        buttons: [{ label: 'OK' }]
                    });
                }
            }).catch(err => console.log(err));
        },
    });

    return (
        <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
            <div className="row">
                <div className="col-sm-6 col-12 form-group">
                    <label>Tiêu đề <span className="text-danger">*</span></label>
                    <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("title")} autoComplete="off" />
                    <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                </div>
                <div className="col-12  d-flex justify-content-start">
                    <button type="submit" disabled={formik.isSubmitting} className="btn btn-sm btn-success">{loading ? <Spinner /> : <>Lưu lại</>}</button>
                </div>

            </div>
        </form>
    )
}