import React, { useState } from "react";
import { useSelector } from "react-redux";
import AkkhorLayout from "../layout/Akkhor";
import Axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import Spinner from "../component/Spinner";
import UserDropDown from "../component/UserDropDown";
import AlertErrorForm from "../component/AlertErrorForm";
import { URL_LIST_ALL } from "../configs/Api";
import { confirmAlert } from "react-confirm-alert";

export default function UploadQuestion(props) {
  const { user } = useSelector((state) => state.auth);
  const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
  const [selectedFile, setSelectedFile] = useState(null);

  const formik = useFormik({
    initialValues: { grade_id: '', subject_id: '' },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      grade_id: Yup.string().trim().nullable().required('Vui lòng chọn khóa học')
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      let formData = new FormData();
      formData.append('file', selectedFile, selectedFile.name)
      formData.append('type', 'excel_v2');
      formData.append('name', 'question');
      formData.append('test', 1);
      for (const key in values) {
        formData.append(key, values[key]);
      }
      Axios.post(URL_LIST_ALL + 'upload', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: user.config.headers.Authorization,
        }
      }).then(async (res) => {

        setSubmitting(false);
        if (res.data.status === "success") {
          resetForm();
          confirmAlert({
            title: 'Thành công',
            message: 'Bạn đã tải lên ngân hàng câu hỏi thành công',
            buttons: [{ label: 'OK' }]
          });
        } else {
          confirmAlert({
            title: 'Lỗi',
            message: res.data.message,
            buttons: [{ label: 'OK' }]
          });
        }
      }).catch((err) => console.log(err));
    },
  });
  const setSubject = (value) => {
    Axios.get(URL_LIST_ALL + "subjects?grade_id=" + value, user.config)
      .then(async (res) => {
        if (res.data.status === "success") {
          let data_subject = res.data.data
          if (data_subject) {
            formik.setFieldValue("subject_id", data_subject[0].id)
          }
        } else console.log(res.data.message);
      }).catch((err) => { console.log(err) });
  }
  return (
    <AkkhorLayout idrole="exam_question" >
      <div className="card height-auto mt-5">
        <div className="card-body">
          <div className="heading-layout1">
            <div className="item-title">
              <h3>Upload câu hỏi</h3>
            </div>
          </div>
          <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-12 mg-t-8">
                <div className="alert alert-primary" role="alert">
                  Bước 1: Chọn khóa học để thêm câu hỏi
                </div>
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-12 form-group">
                    <label>Khóa học <span className="text-danger">*</span></label>
                    <UserDropDown apiname={"khoahoc?orderby=title-ASC&school_id=" + school_id} placeholder="Chọn khóa học" colvalue={"khoahoc.id"} colname={"khoahoc.title"} formik={formik} name="grade_id"
                      onChange={async (e) => { await formik.setFieldValue("grade_id", e); setSubject(e) }}
                    ></UserDropDown>
                    <AlertErrorForm formik={formik} name="grade_id"></AlertErrorForm>
                  </div>
                  <input type="hidden" placeholder="" className="form-control height32" {...formik.getFieldProps("subject_id")} autoComplete="off" />
                </div>
                <div className="alert alert-primary mt-3" role="alert">
                  Bước 2: Tải file mẫu về
                </div>
                <div className="my-3">
                  <a href="/excels/Mau-import-cau-hoi.xlsx" className="btn btn-success" target="_blank" rel="noopener noreferrer"><i className="fas fa-cloud-download-alt"></i> Tải về tại đây</a>
                </div>
                <div className="alert alert-primary" role="alert">
                  Bước 3: Chỉnh sửa nội dung file excel
                </div>
                <div className="my-3">
                  <ol>
                    <li>Số lượng câu hỏi không quá 100 câu</li>
                    <li>Với dạng câu hỏi điền vào chỗ trống: các đáp án cần cách nhau bởi dấu ^^</li>
                  </ol>
                </div>
                <div className="alert alert-primary" role="alert">
                  Bước 4: Chọn file upload
                </div>
                <div className="my-3">
                  <input type="file" onChange={(e) => setSelectedFile(e.target.files[0])}></input>
                </div>
                <div className="alert alert-primary" role="alert">
                  Bước 5: Nhấn nút "Upload"
                  <div className="mt-3">
                    <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark mr-2">{formik.isSubmitting ? <Spinner /> : <>Upload</>}</button>
                    <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                  </div>
                </div>
              </div>

            </div>
          </form>
        </div>
      </div>
    </AkkhorLayout>
  );
}
