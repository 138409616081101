import React, { useEffect, useState } from 'react';
import AkkhorLayout from '../layout/Akkhor';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { URL_LIST_ALL } from '../configs/Api';
import { useParams } from "react-router-dom";
import CategoryTree from './CategoryTree';

export default function ListRacing() {
    const { user } = useSelector((state) => state.auth);
    const { id } = useParams();
    const [list, setList] = useState([]);
    const [totalPoint, setTotalPoint] = useState(0)
    const [khoahoc, setKhoahoc] = useState(null)

    useEffect(() => {
        if (user && id) {
            let mounted = true;
            let url = URL_LIST_ALL + "get_danhmuc/" + id
            Axios.get(url, user.config).then(async (res) => {
                if (res.data.status === "success" && mounted) {
                    setList(res.data.data);
                }
                else console.log(res.data.message);
            }).catch((err) => console.log(err));
            return () => (mounted = false);
        }
    }, [user]);

    useEffect(() => {
        if (id > 0 && user) {
            Axios.get(URL_LIST_ALL + 'get_point_exam?grade_id=' + id, user.config).then((res) => {
                setTotalPoint(res.data.data.point)
                setKhoahoc(res.data.data.khoahoc)
            })
        }
    }, [id])

    return (
        <AkkhorLayout idrole="examination" roleignone={user.data.typeaccount === 2}>
            <div className="breadcrumbs-area py-sm-2">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>Đường đua Tomo <i className='fas fa-flag-checkered'></i></li>
                </ul>
            </div>
            {user && <div className="card height-auto">
                <div className="card-body">
                    <div className='racing-info mb-3'>
                        <h3>Khóa học: {khoahoc ? khoahoc.title : ''}</h3>
                        <div className='totalpoint d-flex align-items-center position-relative py-2 mt-2'>
                            <p className='mr-3'>Tổng điểm đạt được:</p>
                            <div className="total-point">
                                <div className="wrapper">
                                    <span>{totalPoint} <br />điểm</span>
                                </div>
                            </div>
                        </div>
                        <div className='caydanhmuc'>
                            {list ? <CategoryTree data={list} /> : ''}
                        </div>
                    </div>

                </div>
            </div>}
        </AkkhorLayout>
    );
}
