import Axios from "axios";
import React, { useState } from "react";
import { URL_LIST_ALL } from "../../configs/Api";
import AkkhorLayout from "../../layout/Akkhor";
import "moment/locale/vi";
import { useSelector } from "react-redux";
import Loading from "../../component/Loading";
import { confirmAlert } from "react-confirm-alert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import * as XLSX from 'xlsx';
export default function ImportData() {
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [datebegin, setdateBegin] = useState(new Date());

  const uploadDataApi = (data, apiname) => {
    if (apiname === 'students_excel') {
      let formdata = new FormData()
      formdata.append("file", data)
      setLoading(true)
      Axios.post(URL_LIST_ALL + apiname, formdata, user.config)
        .then((res) => {
          setLoading(false)
          if (res.data.status === "success") {
            confirmAlert({
              title: 'Thành công',
              message: 'Bạn đã cập nhật dữ liệu thành công',
              buttons: [{ label: 'OK' }]
            });
          } else console.log(res.data.message);
        })
        .catch((err) => { setLoading(false); console.log(err) });
    } else {
      if (Array.isArray(data) && data.length > 1) {
        let newdata = []
        let head = data[0];
        data.map((item, index) => {
          if (index > 0) {
            if (Array.isArray(item) && item.length > 0) {
              let newitem = {}
              head.map((key, i) => {
                newitem[key] = item[i] ? item[i] : null;
                return key
              });
              newdata.push(newitem)
            }
          }
          return item;
        })

        setLoading(true)

        Axios.post(URL_LIST_ALL + apiname, newdata, user.config)
          .then((res) => {
            setLoading(false)
            if (res.data.status === "success") {
              confirmAlert({
                title: 'Thành công',
                message: 'Bạn đã cập nhật dữ liệu thành công',
                buttons: [{ label: 'OK' }]
              });
            } else console.log(res.data.message);
          })
          .catch((err) => { setLoading(false); console.log(err) });
      }
    }
  };

  const listImport = [
    { title: 'Nhập thông tin giáo viên', api: 'teachers_excel', temp: 'Giao-Vien.xls' },
    { title: 'Nhập thông tin lớp học', api: 'class_excel', temp: 'Lop-Hoc.xls' },
    { title: 'Nhập thông tin học sinh', api: 'students_excel', temp: 'Hoc-Sinh.xls' },
    { title: 'Nhập giáo viên chủ nhiệm', api: 'teachermain_excel', temp: 'Giao-Vien-Chu-Nhiem.xls' },
    { title: 'Nhập thông tin thời khoá biểu', datebegin: true, api: 'timetable_excel', temp: 'Thoi-Khoa-Bieu-Lop-9.xls' },
    { title: 'Nhập thông tin lớp ngoại khoá: quản lý lớp ngoại khoá, trông trưa', api: 'extrac_excel', temp: 'Lop-Ngoai-Khoa.xls' },
    { title: 'Nhập danh sách học sinh vào các lớp ngoại khoá', api: 'students_extrac_excel', temp: 'Hoc-Sinh-Ngoai-Khoa.xls' }
  ];

  const handleUpload = (e, apiname) => {
    e.preventDefault();
    if (apiname === "students_excel") {
      var files = e.target.files;
      uploadDataApi(files[0], apiname);
    } else {
      var files = e.target.files, f = files[0];
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        let readedData = XLSX.read(data, { type: 'binary' });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
        uploadDataApi(dataParse, apiname);
      };
      reader.readAsBinaryString(f)
    }
  }


  return (
    <AkkhorLayout idrole="setting.import-data">
      <div className="card height-auto mt-5">
        <div className="card-body">
          <div className="heading-layout1">
            <div className="item-title">
              <h3>Nhâp dữ liệu</h3>
            </div>
          </div>
          {loading && <Loading color="secondary" disabledbutton={1} />}
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Tiêu đề chức năng</th>
                <th>Dữ liệu</th>
                <th>Upload file excel</th>
                <th>Tải mẫu file</th>
              </tr>
            </thead>
            <tbody>
              {listImport.map((item, index) => <tr key={index}>
                <td>{index + 1}</td>
                <td valign="center">
                  <label>{item.title}</label>
                </td>
                <td valign="center">
                  {item.datebegin && <><label>Ngày áp dụng</label> <DatePicker className="form-control form-control-sm" selected={datebegin} onChange={setdateBegin} /></>}
                </td>
                <td>
                  <input type="file" onChange={(e) => handleUpload(e, item.api)}></input></td>

                <td>
                  <a href={'/excels/' + item.temp} target="_blank" rel="noopener noreferrer" download>Tải mẫu về</a>
                </td>
              </tr>)}
            </tbody>
          </table>
        </div>
      </div>
    </AkkhorLayout>
  );
}
