import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  TimeTableSave: null,
  user: null,
  Answers: {},
  year_id: null,
  listyear: null,
  listTabs: [],
  codevalue: '',
  datasocket: {},
  followtest: {},
  resetpost: {},
  resetcomments: {},
};
export const slice = createSlice({
  name: "save",
  initialState: initialState,
  reducers: {
    choice: (state, action) => {
      if (action.payload.hasOwnProperty("TimeTableSave"))
        state.TimeTableSave = action.payload.TimeTableSave;
      if (action.payload.hasOwnProperty("user"))
        state.user = action.payload.user;
      if (action.payload.hasOwnProperty("Answers"))
        state.Answers = action.payload.Answers;
      if (action.payload.hasOwnProperty("datasocket"))
        state.datasocket = action.payload.datasocket;
      if (action.payload.hasOwnProperty("followtest"))
        state.followtest = action.payload.followtest;
      if (action.payload.hasOwnProperty("year_id"))
        state.year_id = action.payload.year_id;
      if (action.payload.hasOwnProperty("listyear"))
        state.listyear = action.payload.listyear; 
      if (action.payload.hasOwnProperty("listTabs"))
        state.listTabs = action.payload.listTabs;
      if (action.payload.hasOwnProperty("codevalue"))
        state.codevalue = action.payload.codevalue;
      if (action.payload.hasOwnProperty("resetpost"))
        state.resetpost = action.payload.resetpost;
      if (action.payload.hasOwnProperty("resetcomments"))
        state.resetcomments = action.payload.resetcomments;
    },
  },
});
