import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import AkkhorLayout from "../layout/Akkhor";
import { useDispatch, useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/Api";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import { actions } from "../redux/authRedux";
import { confirmAlert } from "react-confirm-alert";
import avatar_student from "../assets/img/avata_student.png"
import men_teacher from "../assets/img/men-teacher.png"
import girl_teacher from "../assets/img/girl-teacher.png"

export default function FormProfilePage() {
    const { user } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const [dateSelect, setDateSelect] = useState({
        birthday: null
    });
    const [detail, setDetail] = useState(null);
    const [loadingImage, setLoadingImage] = useState(false)
    const dispatch = useDispatch();
    const inputRef = useRef(null);

    useEffect(
        function () {
            let mounted = true;
            Axios.get(URL_LIST_ALL + "users/" + user.data.id, user.config)
                .then(async (res) => {
                    if (res.data.status === "success") {
                        
                        if (mounted) {
                            setDetail(res.data.data);
                            setDateSelect({ birthday: res.data.data.birthday })
                        }
                    } else console.log(res.data.message);
                })
                .catch((err) => console.log(err));
            return () => (mounted = false);

        }, [user]
    );
    const formik = useFormik({
        initialValues: detail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            fullname: Yup.string().trim().nullable().required('Nhập họ và tên').max(150, "Độ dài không quá 150 ký tự"),
            lastname: Yup.string().trim().nullable().required('Nhập tên').max(50, "Độ dài không quá 50 ký tự"),
            birthday: Yup.string().trim().nullable().required('Chọn ngày sinh'),
            mobile: Yup.string().trim().nullable().required('Nhập số điện thoại'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            Axios.put(URL_LIST_ALL + 'users/' + detail.id, values, user.config).then(res => {
                let newUser = Object.assign({}, user);
                newUser.data = values
                dispatch(actions.login(newUser));
                resetForm();
                setLoading(false);
                setSubmitting(false);
                confirmAlert({
                    title: 'Thành công',
                    message: 'Bạn cập nhật thông tin thành công',
                    buttons: [{ label: 'OK' }]
                });
            }).catch(err => console.log(err));
        },
    });
    const handleDate = (name, value) => {
        formik.setFieldValue(name, moment(value).format('YYYY-MM-DD'))
        formik.setFieldTouched(name)
        let newDate = Object.assign({}, dateSelect)
        newDate[name] = value
        setDateSelect(newDate)
    };

    const uploadImage = (event) => {
        const file = event.target.files[0]
        if (file) {
            let data = new FormData()
            data.append("type", "s3")
            data.append("name", "file")
            data.append("file", file)
            data.append("filetype", file.type)
            let file_size = file.size;
            if (file_size > 3145728) {
                confirmAlert({
                    title: 'Lỗi',
                    message: "File không được lớn hơn 3 Mb",
                    buttons: [{ label: 'OK' }]
                })
                return
            } else {
                setLoadingImage(true)
                Axios.post(URL_LIST_ALL + "fileupload", data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: user.config.headers.Authorization,
                    }
                }).then(async (res) => {
                    if (res.data.status === "success") {
                        const url = res.data.message
                        if (url) {
                            formik.setFieldValue('avatar', url)
                            setLoadingImage(false)
                        }
                    } else {
                        setLoadingImage(false)
                        return;
                    }
                }).catch((err) => { console.log(err); setLoadingImage(false) })
            }
        }
    }

    return (
        <AkkhorLayout idrole="update-profile">
            <div className="card height-auto mt-5">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Sửa hồ sơ</h3>
                        </div>
                    </div>
                    <form className="new-added-form form-info" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Ảnh đại diện <span className="text-danger">*</span></label>
                                <div className="text-left">
                                    <button type="button" className="btn p-0 btn-avatar" onClick={() => inputRef.current.click()}>
                                        {loadingImage ? "Đang tải..." : <>
                                            {formik.values && formik.values.avatar ? <img src={formik.values.avatar} alt="" className="avatar" />
                                                :
                                                <img src={user.data.typeaccout == 1 ? (user.data.gender == 1 ? men_teacher : girl_teacher) : avatar_student} alt="" className="avatar" />
                                            }
                                        </>}
                                        <span className="fas fa-upload upload-icon"></span>
                                    </button>
                                    <input type="file" id="file" ref={inputRef} style={{ display: "none" }} accept="image/*" onChange={uploadImage} />
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Họ và tên <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("fullname")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="fullname"></AlertErrorForm>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Tên  <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32"  {...formik.getFieldProps("lastname")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="lastname"></AlertErrorForm>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Ngày sinh <span className="text-danger">*</span></label>
                                <DateTimePicker onChange={(value) => { handleDate('birthday', value) }} value={dateSelect.birthday ? new Date(dateSelect.birthday) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                                <AlertErrorForm formik={formik} name="birthday"></AlertErrorForm>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Điện thoại <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("mobile")} />
                                <AlertErrorForm formik={formik} name="mobile"></AlertErrorForm>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Email</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("email")} />
                            </div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Địa chỉ</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("address")} />
                            </div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Giới tính</label>
                                <select className="form-control height32" {...formik.getFieldProps("gender")}>
                                    <option value="0">Nữ</option>
                                    <option value="1">Nam</option>
                                </select>
                            </div>
                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu hồ sơ</>}</button>
                                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
