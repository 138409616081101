import React from "react";

export default function QFillTo(props) {
    const { item } = props;
    const corrects = item.corrects
    return (<div className="container-fluid question-choisce">
        <div className="row mt-3 mb-3">
            {corrects && <>
                {Array.isArray(corrects) && corrects.map((item, i) =>
                    <div className={"col-12 item-fill mt-1 " + (corrects.length > 1 ? "col-sm-6" : "")} key={i}>
                        <span className="text-number">{i + 1}</span> <textarea style={{ minHeight: 100 }} className={"form-control w-100 form-control-sm border"} value={item} readOnly></textarea>
                    </div>
                )}
                {typeof corrects === 'object' &&
                    Object.keys(corrects).map((key, index) => {
                        return <div className={"col-12 item-fill mt-1 " + (Object.keys(corrects).length > 1 && "col-sm-6")} key={index}>
                            <span className="text-number">{index + 1}</span> <textarea style={{ minHeight: 100 }} className={"form-control col-12 form-control-sm border"} value={corrects[key]} readOnly></textarea>
                        </div>
                    })
                }
            </>}
        </div>
    </div>);
}