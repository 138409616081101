
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";
import AkkhorLayout from "../layout/Akkhor";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/Api";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import Loading from "../component/Loading";
import Spinner from "../component/Spinner";

export default function BusCheckin() {
    const { user } = useSelector((state) => state.auth);
    const { year_id } = useSelector((state) => state.save);
    // const [params, setParams] = useState('')
    const [roleBus, setRolebus] = useState(user && user.data.role_id !== 2 ? false : true)
    const [listBus, setListBus] = useState([])
    const [busID, setBusID] = useState()
    const [listTrip, setListTrip] = useState()
    const [busInfo, setBusInfo] = useState()
    const [busOnday, setBusOnday] = useState()
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [checkListUp, setCheckListUp] = useState({})
    const [checkListDown, setCheckListDown] = useState({})
    const [watting, setWatting] = useState(false)



    useEffect(() => {
        if (year_id) {
            let rolecheck = roleBus
            Axios.get(URL_LIST_ALL + "bus_class?year_id=" + year_id + (user.data.role_id !== 2 ? "&teacher_id=" + user.data.id : ''), user.config).then(async (res) => {
                if (res.data.status === "success") {
                    let listData = res.data.data
                    if (!roleBus) {
                        listData.forEach(it => {
                            if (it.teacher_id === user.data.id || it.teacher_id2 === user.data.id) {
                                rolecheck = true
                            }
                        });
                    }
                    if (rolecheck) {
                        setRolebus(rolecheck)
                        setListBus(res.data.data);
                    }
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
        }

    }, [year_id])// eslint-disable-line 

    useEffect(() => {
        let datenow = moment().format('YYYY-MM-DD');
        if (busID) {
            Axios.get(URL_LIST_ALL + "bus_class/" + busID, user.config)
                .then(async (res) => {
                    if (res.data.status === "success") {
                        setBusInfo(res.data.data);
                    } else console.log(res.data.message);
                })
                .catch((err) => console.log(err));
            Axios.get(URL_LIST_ALL + "bus_trip?bus_class_id=" + busID, user.config)
                .then(async (res) => {
                    if (res.data.status === "success") {
                        setListTrip(res.data.data);
                    } else console.log(res.data.message);
                })
                .catch((err) => console.log(err));
            Axios.get(URL_LIST_ALL + "bus_onday?bus_id=" + busID + '&datecheck=' + datenow, user.config)
                .then(async (res) => {
                    if (res.data.status === "success") {
                        setBusOnday(res.data.data);
                    } else console.log(res.data.message);
                })
                .catch((err) => console.log(err));
        }
    }, [busID, refresh])// eslint-disable-line 

    const checkTrip = (student_id, trip_id, type = 'up') => {
        let check = false
        if (busOnday) {
            busOnday.forEach(item => {
                if (item.student_id === student_id && item[type] === 1 && trip_id === item.trip_id) {
                    check = item.id
                }
            });
        }
        return check
    }

    const checkUp = (value, student_id, trip_id) => {
        let newList = { ...checkListUp }
        if (value) {
            if (newList[trip_id]) {
                if (!newList[trip_id].includes(student_id)) {
                    newList[trip_id].push(student_id)
                }
            } else {
                newList[trip_id] = [student_id]
            }
        } else {
            if (newList[trip_id] !== undefined) {
                if (newList[trip_id].includes(student_id)) {
                    newList[trip_id].splice(newList[trip_id].indexOf(student_id), 1)
                }
            }
        }
        setWatting(true)
        setCheckListUp(newList)
    }
    const checkDown = (value, student_id, trip_id) => {
        let newList = { ...checkListDown }
        if (value) {
            if (newList[trip_id]) {
                if (!newList[trip_id].includes(student_id)) {
                    newList[trip_id].push(student_id)
                }
            } else {
                newList[trip_id] = [student_id]
            }
        } else {
            if (newList[trip_id] !== undefined) {
                if (newList[trip_id].includes(student_id)) {
                    newList[trip_id].splice(newList[trip_id].indexOf(student_id), 1)
                }
            }
        }
        setWatting(true)
        setCheckListDown(newList)
    }
    useEffect(() => {
        if (watting) {
            setTimeout(() => {
                addStudenToBusOnday()
            }, 1000);
        }
    }, [watting])

    const addStudenToBusOnday = () => {
        if (checkListUp || checkListDown) {
            let data = { list_bus_up: checkListUp,list_bus_down:checkListDown, bus_id: busID }
            setLoading(true);
            Axios.post(URL_LIST_ALL + 'student_to_busonday', data, user.config).then(res => {
                setLoading(false);
                if (res.data.status === "success") {
                    confirmAlert({
                        title: 'Thành công',
                        message: 'Điểm danh thành công',
                        buttons: [{ label: 'OK' }]
                    });
                    setRefresh(!refresh)
                    setWatting(false)
                } else {
                    confirmAlert({
                        title: 'Lỗi',
                        message: res.data.message,
                        buttons: [{ label: 'OK' }]
                    });
                    setWatting(false)
                }
            }).catch(err => { console.log(err); setLoading(false); });
        }
    }

    const unCheckOnday = (id,type) => {

        confirmAlert({
            title: 'Thông báo',
            message: 'Bạn có muốn đánh dấu không điểm danh ?',
            buttons: [
                {
                    label: 'Có',
                    onClick: () => {
                        setLoading(true);
                        let data = type === 'up' ? { up: 0 } : { down:0 }
                        
                        Axios.put(URL_LIST_ALL + 'bus_onday/' + id, data, user.config).then(res => {
                            setLoading(false);
                            if (res.data.status === "success") {
                                confirmAlert({
                                    title: 'Thành công',
                                    message: 'Điểm danh thành công',
                                    buttons: [{ label: 'OK' }]
                                });
                                setRefresh(!refresh)
                            } else {
                                confirmAlert({
                                    title: 'Lỗi',
                                    message: res.data.message,
                                    buttons: [{ label: 'OK' }]
                                });
                            }
                        }).catch(err => { console.log(err); setLoading(false); });
                    }
                },
                {
                    label: 'Không'

                }
            ]
        });


    }
    return (
        <AkkhorLayout idrole="bus">
            {loading && (<Loading />)}
            <h3 className="w-100 mt-3">Điểm danh xe bus</h3>
            {roleBus ? <>
                <div className="container-fluid card">
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 col-12 form-group mt-2">
                            <label>Chọn xe bus</label>
                            <select className="form-control height32" onChange={(e) => { setBusID(e.target.value) }}>
                                <option value="">Chọn xe bus</option>
                                {listBus && listBus.map(function (item, i) {
                                    return (
                                        <option value={item.id} key={i}>{item.title}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="container-fluid card  mt-3">
                    <h4 className="text-center mt-3">Các điểm đón</h4>
                    <Accordion className="w-100 mb-3">
                        <div className="row justify-content-center">
                            {listTrip && listTrip.map((item, index) => {
                                return (
                                    <div className="col-12 col-sm-4" key={index}>
                                        <Accordion.Item eventKey={index} className="card mt-3">
                                            <Accordion.Header as="div" bsPrefix="accordion-header card-header" onClick={() => { }}>
                                                <div className="row d-flex justify-content-between">
                                                    <div className="col-10">
                                                        <p>{item.title}</p>
                                                    </div>
                                                    <div className="pr-3">
                                                        <span className="fas fa-angle-down"></span>
                                                    </div>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body bsPrefix="list-studen-card accordion-body card-body">
                                                <div className="w-100 d-flex justify-content-between">
                                                    <h4>Danh sách học sinh</h4>
                                                    <div>
                                                        {watting && <Spinner />}
                                                    </div>
                                                </div>
                                                <div className="w-100 mt-2">
                                                    <div className="list-student mt-3">
                                                        <div className="row">
                                                            <div className="col-6 p-1 border-bottom border-top border-right">
                                                                Họ tên
                                                            </div>
                                                            <div className="col-2 p-1 text-center border-bottom border-top border-right">
                                                                Lớp
                                                            </div>
                                                            <div className="col-2 p-1 text-center border-bottom border-top border-right">
                                                                Lên xe
                                                            </div>
                                                            <div className="col-2 p-1 text-center border-bottom border-top">
                                                                Xuống xe
                                                            </div>
                                                        </div>
                                                        {busInfo && busInfo.list_trip && busInfo.list_trip[item.id] &&
                                                            <>
                                                                {busInfo.list_trip[item.id].map(function (it, ix) {
                                                                    return (<div className="row" key={ix}>
                                                                        <div className="col-6 p-1 border-bottom border-right">
                                                                            {it.fullname}
                                                                        </div>
                                                                        <div className="col-2 p-1 d-flex justify-content-center align-items-center border-bottom border-right">{it.class && it.class}</div>
                                                                        <div className="col-2 p-1 d-flex justify-content-center border-bottom border-right">
                                                                            {checkTrip(it.student_id, item.id, 'up') ?
                                                                                <button type="button" onClick={() => unCheckOnday( checkTrip(it.student_id, item.id, 'up'), 'up')}><span className="fas fa-check-circle text-success"></span></button>
                                                                                :
                                                                                <input type="checkbox" className="checkbox" value={it.student_id} checked={checkListUp && checkListUp[item.id] && checkListUp[item.id].includes(it.student_id) ? true : false} onChange={(e) => { checkUp(e.target.checked, it.student_id, item.id) }} />
                                                                            }
                                                                        </div>
                                                                        <div className="col-2 d-flex p-1 justify-content-center border-bottom">
                                                                            {checkTrip(it.student_id, item.id, 'down') ?
                                                                                <button type="button" onClick={() => unCheckOnday( checkTrip(it.student_id, item.id, 'down'), 'down')}><span className="fas fa-check-circle text-success"></span></button>
                                                                                :
                                                                                <input type="checkbox" className="checkbox" value={it.student_id} checked={checkListDown && checkListDown[item.id] && checkListDown[item.id].includes(it.student_id) ? true : false} onChange={(e) => { checkDown(e.target.checked, it.student_id, item.id) }} />
                                                                            }
                                                                        </div>
                                                                    </div>)
                                                                })}
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                    </div>
                                )
                            })}

                        </div>
                    </Accordion>
                </div>
            </> : <>
                <div className="container-fluid card p-3 mt-3">
                    <h4>Hiện tại bạn chưa quản lý Xe bus nào</h4>
                </div>
            </>}
        </AkkhorLayout>
    );
}
