import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/Akkhor";
import { useSelector } from "react-redux";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/Api";
import { useLocation } from "react-router-dom";
import CustomList from "../component/CustomList";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function ListManHinh() {
    let query = useQuery();

    const { user } = useSelector((state) => state.auth);
    const bhID = parseInt(query.get("baihocid"));
    const [bhInfo, setBhInfo] = useState();
    const [khoaHocInfo, setKhoaHocInfo] = useState(null);

    const columns = [
        { name: "id", label: "ID" },
        { name: "title", label: "Tên màn hình" },
        { name: "datecreate", label: "Ngày tạo" }
    ];
    useEffect(() => {
        Axios.get(URL_LIST_ALL + "khoahoc_baihoc/" + bhID, user.config).then(async (res) => {
            if (res.data.status === "success") {
                setBhInfo(res.data.data);
            } else console.log(res.data.message);
        }).catch((err) => console.log(err));
    }, [user]);
    useEffect(() => {
        if (bhInfo) {
            Axios.get(URL_LIST_ALL + "khoahoc/" + bhInfo.khoahoc_id, user.config).then(async (res) => {
                if (res.data.status === "success") {
                    setKhoaHocInfo(res.data.data);
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
        }

    }, [user, bhInfo]);
    return (
        <AkkhorLayout idrole="khoa-hoc">
            <div className="breakcrumb">
                <ul>
                    <li><a href={"/khoa-hoc/"}>Khóa học</a></li>
                    <li>/</li>
                    <li><a href={"/danh-sach-bai-hoc/" + (khoaHocInfo && khoaHocInfo.id)}>{khoaHocInfo && khoaHocInfo.title}</a></li>
                    <li>/</li>
                    <li>{bhInfo && bhInfo.title}</li>
                </ul>
            </div>
            <h4 className="w-100 mt-5 mb-2">Danh sách màn hình: {bhInfo && bhInfo.title}</h4>
            <div className="card height-auto mt-5">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h4>Màn hình khóa học</h4>
                        </div>
                    </div>
                    <CustomList name="khoahoc_manhinh" linkaction="manhinh" changePos={true} linkactionadd={"manhinh?bh_id=" + bhID} idrole="khoa-hoc" params={'baihoc_id=' + bhID + '&orderby=position-asc'} title="Màn hình" defaultLimit={50} serverSide={true} columns={columns}></CustomList>
                </div>
            </div>
        </AkkhorLayout>
    );
}
