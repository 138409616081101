import React from 'react'

export default function DataDate(props) {
    const { dates} = props;
    const days = []
    for(var i=1; i<=dates; i++) days.push(i)
    const tds = days.map((item, index) => <td key={index}className="justify-content-center align-items-center bg-secondary text-white " >
            <div className="text-center">{item}</div>
            </td>)
    return (
        <>
        {tds}
        <td className="justify-content-center align-items-center bg-secondary text-white ">Nghỉ học</td>
        </>
    )
}
