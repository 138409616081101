import React, { useState,useEffect } from "react";

import ChoiceQuestionGeneral from "./ChoiceQuestionGeneral";

export default function QGeneral(props) {
    const {item} = props;
    const [listQuestion, setListQuestion] = useState([]);
    
    useEffect(() => {
        const children = JSON.parse(item.children)
        let ar_child = [];
        children.forEach(child => {
            const obchild = JSON.parse(child);
            const answers = JSON.parse(obchild.answers)
            obchild.answers = answers
            switch(obchild.type_id) {
                case 6:
                    obchild.code = 'QTrueFlase'
                    break;
                case 7:
                    obchild.code = 'QChooseOne'
                    break; 
                case 8:
                    obchild.code = 'QChooseMulti'
                    break;
                case 9:
                    obchild.code = 'QFillTo'
                    break;
                case 10:
                    obchild.code = 'QMatching'
                    break;
                case 11:
                    obchild.code = 'QDropDrag'
                    break;
                default:
                    obchild.code = 'QEssay'
              }
            ar_child.push(obchild)
        });
        setTimeout(setListQuestion(ar_child),200);
    }, [item]);
    return (
        <div className="col-12">
            <div className="row question-genaral">
                {listQuestion.length > 0 && 
                listQuestion.map((items,i) => (
                    <div className="col-12 col-sm-6 question-item" key={i}>
                        <div className="question-title">{i+1}. {items.title}</div>
                        <ChoiceQuestionGeneral
                            key={i}
                            index={i}
                            item={items}
                            test={item}
                            action={false}
                            parentItem={item}
                        ></ChoiceQuestionGeneral>
                    </div>
                ))}
            </div>
        </div>);
}
