import React from "react";
import { useSelector } from "react-redux";
import SRList from "../component/List";
import moment from "moment";
import Moment from "react-moment";
import AkkhorLayout from "../layout/Akkhor";

export default function ListTeachersManager() {
  let params = '';
  const { user } = useSelector((state) => state.auth);
  params += user && user.data && user.data.school_id > 0 ? 'school_id=' + user.data.school_id : 0;


  const columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "fullname", label: "Họ và tên giáo viên", options: { filter: true, sort: true, filterType: 'textField' } },

    { name: "gradetitle", label: "Khối", width: 150 },
    { name: "subjecttitle", label: "Môn học", width: 250 },
    {
      name: "datecreate", label: "Ngày đăng",
      options: {
        filter: false, sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const time = new Date(value * 1000);
          return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
        },
      }
    }
  ];
  const columnsExport = {
    'id': true, 'username': true, 'firstname': true, 'lastname': true, 'address': true, 'mobile': true, 'email': true, 'nation': true, info: (e) => e ? JSON.parse(e) : null,
    gender: (e) => e === 1 ? 'Nam' : (e === 0 ? 'Nữ' : ''),
    birthday: (e) => {
      if (e) return moment(new Date(e)).format('DD/MM/YYYY')
      return '';
    }
  }

  return (
    <AkkhorLayout idrole="teachers-manager">
      <SRList
        name="teachers_manager"
        idrole="teachers-manager"
        params={params}
        title="Giáo viên tổ trưởng"
        defaultLimit={50}
        serverSide={true}
        columns={columns}
        columnsExport={columnsExport} >
      </SRList>
    </AkkhorLayout>)
}
