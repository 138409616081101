import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from 'react-router-dom';
import { resetRedux } from "../redux/Actions";
import { actions } from "../redux/authRedux";
import logo from "../assets/img/logo.png";
const _ = require('lodash');
//904832009
export default function SiderBar(props) {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    const MyRole = JSON.parse(user && user.data.roles ? user.data.roles : '{}')
    const location = useLocation();
    let localsegment = location.pathname;

    let listMenus = [
        { title: 'Bảng điều khiển', icon: 'flaticon-dashboard', link: '/dashboard' },
        {
            title: 'Lịch học', icon: 'flaticon-calendar', link: '', active: ['/timetable', '/teaching'].includes(localsegment),
            children: [
                { title: 'Thời khoá biểu', icon: 'fas fa-angle-right', link: '/timetable' },
                { title: 'Lịch báo giảng', icon: 'fas fa-angle-right', link: '/teaching' },
            ]
        },
        {
            title: 'Chuyên cần', icon: 'flaticon-checklist', link: '', active: ['/lunch', '/attends', '/sleep', '/special', '/bus', '/extrac', '/examofnotebook', '/examofexercise'].includes(localsegment),
            children: [
                { title: 'Điểm danh tiết học', icon: 'fas fa-angle-right', link: '/attends' },
            ]
        },
        { title: 'Học sinh', icon: 'flaticon-classmates', link: '/list-students' },
        {
            title: 'Câu hỏi, bài thi', icon: 'fas fa-flag-checkered', link: '', active: ['/examination-report','list-danhmuc', '/list-exercise','/list-tomo-racing', '/examination', '/exam_question', '/list-tests','/list-tags', '/marktests', '/list-param', '/tree', '/add-tests','/add-tests?type=1','/upload-question','/list-books-exercise'].includes(localsegment),
            children: [
                { title: 'Các thông số', icon: 'fas fa-angle-right', link: '/list-param' },
                { title: 'Cây kiến thức', icon: 'fas fa-angle-right', link: '/tree' },
                { title: 'Quản lý thẻ', icon: 'fas fa-angle-right', link: '/list-tags' },
                { title: 'Ngân hàng câu hỏi', icon: 'fas fa-angle-right', link: '/exam_question' },
          
                { title: 'Bài kiểm tra', icon: 'fas fa-angle-right', link: '/list-tests' },
                { title: 'Phiếu bài tập', icon: 'fas fa-angle-right', link: '/list-exercise' },
                { title: 'Bài tập cho sách', icon: 'fas fa-angle-right', link: '/list-books-exercise' },
                { title: 'Thống kê', icon: 'fas fa-angle-right', link: '/examination-report' },
                { title: 'Chấm bài kiểm tra', icon: 'fas fa-angle-right', link: '/marktests' },
                { title: 'Bài thi Tomo Racing', icon: 'fas fa-angle-right', link: '/list-tomo-racing' },
                { title: 'Danh mục đường đua', icon: 'fas fa-angle-right', link: '/list-danhmuc' },
            ]
        },
        {
            title: 'Khóa học', icon: 'fas fa-archive', link: '', active: ['/khoa-hoc', ].includes(localsegment),
            children: [
                { title: 'Khóa học', icon: 'fas fa-angle-right', link: '/khoa-hoc' },
            ]
        },
      
        {
            title: 'Lớp học', icon: 'flaticon-maths-class-materials-cross-of-a-pencil-and-a-ruler', link: '', active: ['/list-group-extrac','/list-class', '/list-extrac'].includes(localsegment),
            children: [
                { title: 'Lớp học', icon: 'fas fa-angle-right', link: '/list-class' },
            ]
        },

        {
            title: 'Danh sách khác', icon: 'flaticon-books', link: '', active: ['/list-subjects','/bus_manager',  '/list-years', '/list-grades'].includes(localsegment),
            children: [
                { title: 'Môn học', icon: 'flaticon-open-book', link: '/list-subjects' },
            ]
        },
        { title: 'Tạo QRCODE', icon: 'fas fa-qrcode', link: '/tao-qrcode' },
        {
            title: 'Nhân sự', icon: 'flaticon-user', link: '', active: ['/list-users', '/list-roles', '/list-parts','/teachers-manager'].includes(localsegment), children: [
                { title: 'Tài khoản', icon: 'fas fa-angle-right', link: '/list-users' },
                { title: 'Quyền', icon: 'fas fa-angle-right', link: '/list-roles' }
            ]
        },
        {
            id: 'internal', title: 'Nội bộ', icon: 'flaticon-earth-globe', link: '', active: ['/internal', '/registry-lunch', '/history-login', '/history-action', '/history-action-user'].includes(localsegment), children: [
                { title: 'Lịch sử đăng nhập', icon: 'fas fa-angle-right', link: '/history-login' },
                { title: 'Lịch sử thao tác', icon: 'fas fa-angle-right', link: '/history-action' },
                { title: 'Lịch sử thao tác của mình', icon: 'fas fa-angle-right', link: '/history-action-user' },
            ]
        },

        {
            id: 'setting', title: 'Cài đặt', icon: 'flaticon-settings', link: '', active: ['/import-data', '/create-timetable', '/general-setting',].includes(localsegment), children: [
                { title: 'Cài đặt chung', icon: 'fas fa-angle-right', link: '/general-setting' },
                { title: 'Nhập dữ liệu', icon: 'fas fa-angle-right', link: '/import-data' },
                { title: 'Tạo thời khoá biểu', icon: 'fas fa-angle-right', link: '/create-timetable' },

            ]
        },
    ];
    if (user.data.typeaccount === 2) {
        listMenus = [
            { title: 'Bảng điều khiển', icon: 'flaticon-dashboard', link: '/dashboard', role: true },
            // { title: 'Thời khoá biểu', icon: 'flaticon-calendar', link: '/timetable', role: true },
            // { title: 'Khóa học của tôi', icon: 'fas fa-book-reader', link: '/khoa-hoc-cua-toi', role: true },
            { title: 'Bài tập/ Bài kiểm tra', icon: 'fas fa-book', link: '/examination', role: true },
            { title: 'Đường đua Tomo', icon: 'fas fa-flag-checkered', link: '/tomo-racing', role: true },
        ];
    }
    if (user.data.typeaccount === 4) {
        listMenus = [
            { title: 'Bảng điều khiển', icon: 'flaticon-dashboard', link: '/dashboard', role: true },
            { title: 'Bài tập', icon: 'fas fa-book', link: '/books-exercise', role: true },
        ];
    }


    const checkRole = (idparent, menu) => {
        let isrole = false;
        let id = menu.id;
        if (!id) id = _.trimStart(menu.link, ' /');
        if (id) {
            if (idparent && MyRole.hasOwnProperty(idparent) && MyRole[idparent].children.hasOwnProperty(id) && MyRole[idparent].children[id].checked === true) isrole = true
            else if (MyRole.hasOwnProperty(id) && MyRole[id].checked === true) isrole = true
        } else {
            if (menu.hasOwnProperty('children') && menu.children && menu.children.length > 0) {
                menu.children.map((sub) => {
                    let idsub = _.trimStart(sub.link, ' /');
                    if (idparent && MyRole.hasOwnProperty(idparent) && MyRole[idparent].children.hasOwnProperty(idsub) && MyRole[idparent].children[idsub].checked === true) isrole = true
                    else if (idsub && MyRole.hasOwnProperty(idsub) && MyRole[idsub].checked === true) isrole = true
                    return sub;
                })
            }
        }
        // if(menu.link === '/marktests') console.log(MyRole);
        return isrole
    }


    const subMenu = (idparent, list, active) => {
        return <>
            {list && list.length > 0 ? <ul className={`nav sub-group-menu ${active ? 'sub-group-active' : 'none'}`}>
                {list.map((item, i) => {
                    if (checkRole(idparent, item)) {
                        return <li className="nav-item" key={i}>
                            <Link to={item.link} className={`nav-link ${item.link === localsegment ? 'menu-active' : ''}`}>
                                <i className="fas fa-angle-right"></i>
                                {item.title}
                            </Link>
                        </li>
                    } else {
                        return null
                    }
                })}
            </ul> : null}
        </>
    }
    const dologout = () => {
        confirmAlert({
            title: "Đăng xuất",
            message: "Bạn có chắc muốn đăng xuất",
            closeOnEscape: true,
            closeOnClickOutside: true,
            buttons: [
                {
                    label: "Đăng xuất",
                    onClick: () => {
                        dispatch(resetRedux());
                        dispatch(actions.login(null));
                    },
                },
                { label: "Không" },
            ],
        });
    };
    return (
        <>
            {/* Sidebar Area Start Here */}
            <div className="sidebar-main sidebar-menu-one sidebar-expand-md sidebar-color">
                <div className="mobile-sidebar-header d-md-none">
                    <div className="header-logo">
                        <Link to="/">
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>
                </div>
                <div className="sidebar-menu-content">
                <ul className="nav nav-sidebar-menu sidebar-toggle-view">
                        {listMenus.map((item, i) => {
                            if (checkRole(null, item) || item.role) {
                                let title = item.title
                                
                                title = <span>{title}</span>
                                
                                let menuactive = false
                                if (item.children) {
                                    item.children.map((itemMN, i) => {
                                        if (itemMN.link === localsegment) menuactive = true
                                    })
                                }
                                return <li key={i} className={`nav-item ${item.children ? 'sidebar-nav-item' : ''} ` + (menuactive ? 'active' : '')}>
                                    {!item.children ?
                                        <Link to={item.link} className={`nav-link ${item.link === localsegment ? 'menu-active' : ''}`}>
                                            <i className={item.icon}></i>{title}</Link>
                                        :
                                        <div to={item.link} className={`nav-link ${item.link === localsegment || menuactive ? 'menu-active' : ''}`}><i
                                            className={item.icon}></i>{title}</div>}
                                    {subMenu(item.id, item.children, menuactive)}
                                </li>
                            } else return null
                        })}
                        <li className="nav-item">
                            <a href="#logout" onClick={dologout} className="nav-link">
                                <i className="flaticon-turn-off"></i><span>Đăng xuất</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            {/* Sidebar Area End Here */}
        </>
    );
}
