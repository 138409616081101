import React from "react";

export default function QTrueFlase(props) {
    const {onAnswers,answers} = props;
    
    const onChange = (e) => {
        onAnswers({ correct:e.target.value==='true'?true:false})
    }

    return (<div className="col-12 form-group">
    <label>Chọn đáp án đúng</label>
    <input type="radio" name="correct" checked={answers.correct===false} value="false" onChange={onChange}/> Sai
    <input type="radio" name="correct" checked={answers.correct===true} value="true" onChange={onChange} className="ml-2" /> Đúng
</div>);
}
