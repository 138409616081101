import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MyEditor2 from "../component/MyEditor2";
import { langs } from '@uiw/codemirror-extensions-langs';
import { URL_LIST_ALL } from "../configs/Api";
import ShowTab from "./ShowTab";
import ShowCodePython from "./ShowCodePython";
import CodeMirror from '@uiw/react-codemirror';
import { githubLightInit } from '@uiw/codemirror-theme-github';
import { saveListTabs } from "../redux/Actions";


export default function TabContent(props) {
  const { onValue, arrTabValue } = props
  const dispatch = useDispatch();
  const { listTabs } = useSelector((state) => state.save);
  const [showTab, setShowTab] = useState(false);
  const [showButtonAdd, setShowButtonAdd] = useState(true);
  const [items, setItems] = useState([])
  const [type, setType] = useState("text");
  const [statusTab, setStatusTab] = useState("");


  const [lang, setLang] = useState("html")
  useEffect(() => {

    if (arrTabValue !== "" && JSON.parse(arrTabValue).length > 0) {

      const newArr = JSON.parse(arrTabValue)
      setStatusTab(newArr[0].status)
      dispatch(saveListTabs(newArr))
    }

  }, [arrTabValue])
  const showInputHandler = () => {
    // Thêm một đối tượng mới vào mảng items khi click vào nút "Thêm mới"
    if (type == "code") {

      dispatch(saveListTabs([...listTabs, { id: Date.now(), text: '', type: "code", lang: lang, content: "", status: "custom" }]))

    } else if(type == "mahtml"){
      dispatch(saveListTabs([...listTabs, { id: Date.now(), text: '', type: "mahtml", lang: "html", content: "", status: "custom" }]))
    }else {
      dispatch(saveListTabs([...listTabs, { id: Date.now(), text: '', type: "text", lang: "text", content: "", status: "custom" }]))
    }
     

  };
  const removeItemHandler = (id) => {
    // Xóa đối tượng có id tương ứng khỏi mảng itemss
    const updatedItems = listTabs.filter((item) => item.id !== id);
    dispatch(saveListTabs(updatedItems));

  };
  const luuItem = () => {
    setShowTab(!showTab)
  }
  const handleChangeType = (value) => {
    setType(value)
    setShowButtonAdd(true)
    console.log(value);
  }
  const handleChangeStatus = (value) => {
    setStatusTab(value)
    const id_item = Date.now()
    if (value == "html/css") {
      let arrHTMLCSS = [
        { id: Date.now(), text: 'HTML', type: "code", lang: "html", content: "", status: value },
        { id: Date.now() + 1, text: 'CSS', type: "code", lang: "css", content: "", status: value },
        { id: Date.now() + 2, text: 'Kết quả', type: "text", lang: "text", content: "", status: value }

      ]
      setShowButtonAdd(false)

      dispatch(saveListTabs(arrHTMLCSS))
    } else if (value == "python") {
      dispatch(saveListTabs([{ id: Date.now(), text: 'Python', type: "python", code: "", result: "", status: value }]))
      setShowButtonAdd(false)
    } else if (value == "custom") {
      dispatch(saveListTabs([]))
      setShowButtonAdd(true)
    } else {
      dispatch(saveListTabs([]))
    }

  }

  const selectLang = (type) => {
    if (type == 'javascript') return langs.jsx()
    if (type == 'html') return langs.html()
    if (type == 'python') return langs.python()
    if (type == 'php') return langs.php()
    if (type == 'css') return langs.css()
    if (type == 'java') return langs.java()
    if (type == 'sql') return langs.sql()
    if (type == 'kotlin') return langs.kotlin()
    if (type == 'c') return langs.c()
    return langs.html()
  }

  return (
    <div>
      {listTabs.length > 0 &&
        <button type="button" className="btn btn-warning" onClick={() => luuItem()}>Hiển thị tab</button>
      }

      {showTab == true ?

        <div className="tabs">
          {listTabs.length > 0 && listTabs[0].type == "python" ?
            <ShowCodePython arrTab={listTabs} />
            : <ShowTab arrTab={listTabs} selectLang={selectLang} />}

        </div>
        :
        <>
          <div className="w-100 d-flex mt-1 justify-content-between" >
            <div className="col-lg-4 col-12 flex-fill form-group p-0 ">
              <label className="form-label">Chọn nội dung</label>
              <select className="form-control form-control-sm" onChange={(e) => handleChangeStatus(e.target.value)} value={statusTab}>
                <option value="0">Chọn tab</option>
                <option value="html/css">Html/Css</option>
                <option value="python">Python</option>
                <option value="custom">Custom</option>
              </select>
            </div>
            {(statusTab == "custom") &&
              <div className="col-lg-4 col-12 flex-fill form-group ">
                <label className="form-label">Chọn nội dung</label>
                <select className="form-control form-control-sm" onChange={(e) => handleChangeType(e.target.value)} value={type}>
                  <option value="content">Văn Bản</option>
                  <option value="code">Code</option>
                  <option value="mahtml">Mã Code HTML</option>
                </select>
              </div>
            }
            {
              type && type == "code" &&
              <div className="col-lg-4 col-12 flex-fill form-group " onChange={(e) => { setLang(e.target.value) }}>
                <label className="form-label">Chọn ngôn ngữ</label>
                <select className="form-control form-control-sm">
                  <option value="html">Html</option>
                  <option value="css">Css</option>
                  <option value="javascript">Javascript</option>
                  <option value="php">Php</option>
                  <option value="python">Python</option>
                  <option value="java">Java</option>
                  <option value="c">C</option>
                  <option value="kotlin">Kotlin</option>
                </select>
              </div>
            }

            <div className="col-lg-4 col-12  flex-fill d-flex justify-content-end form-group align-items-center">
              <label className="form-label" style={{ color: "#fff" }}>.</label>
              {showButtonAdd &&
                <button type="button" className="btn btn-primary" onClick={showInputHandler}>Thêm mới</button>
              }

            </div>

          </div>


          {listTabs.length > 0 && listTabs.map((item) => (
            <div key={item.id} className="col-12" style={{ marginBottom: "20px", border: "1px solid #ccc", padding: "15px", borderRadius: "10px" }}>

              <div className="col-lg-12 col-12 form-group" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                {item.status == "custom" ? <input
                  type="text"
                  className="form-control height32 col-md-3"

                  placeholder="Nhập tiêu đề tab"
                  id={`newItem-${item.id}`}
                  value={item.text}
                  onChange={(e) => {
                    const updatedItems = listTabs.map((i) =>
                      i.id === item.id ? { ...i, text: e.target.value } : i
                    );

                    dispatch(saveListTabs(updatedItems));

                  }}

                /> :
                  <input
                    type="text"
                    className="form-control height32 col-md-3"
                    disabled
                    placeholder="Nhập tiêu đề tab"
                    id={`newItem-${item.id}`}
                    value={item.text}
                    onChange={(e) => {
                      const updatedItems = listTabs.map((i) =>
                        i.id === item.id ? { ...i, text: e.target.value } : i
                      );
                      dispatch(saveListTabs(updatedItems));

                    }}

                  />

                }
                {item.status != "python" ? <button type="button" className="btn btn-danger" onClick={() => removeItemHandler(item.id)}>Xóa</button> : null}

              </div>

              {item.type === "text" &&
                <div className="col-lg-12 col-12 form-group" >

                  <MyEditor2 height={500} key={Math.random()} initialValue={item.content ? item.content : ""}
                    onValue={(value) => {
                      const updatedItems = listTabs.map((i) =>
                        i.id === item.id ? { ...i, content: value } : i
                      );
                      dispatch(saveListTabs(updatedItems));

                    }}
                  />
                </div>
              }
              {item.type == "code" &&
                <div className="col-lg-12 col-12 form-group" >

                  <CodeMirror
                    height="500px"
                    theme={githubLightInit({
                      settings: {
                        caret: '#c6c6c6',
                        fontFamily: 'monospace',
                      }
                    })}

                    extensions={[selectLang(item.lang)]}
                    value={item.content}
                    onChange={(value, viewUpdate) => {

                      const updatedItems = listTabs.map((i) =>
                        i.id === item.id ? { ...i, content: value } : i
                      );
                      dispatch(saveListTabs(updatedItems));

                    }}
                  />
                </div>

              }
              {item.type == "mahtml" &&
                <div className="col-lg-12 col-12 form-group" >

                  <CodeMirror
                    height="500px"
                    theme={githubLightInit({
                      settings: {
                        caret: '#c6c6c6',
                        fontFamily: 'monospace',
                      }
                    })}

                    extensions={[langs.html()]}
                    value={item.content}
                    onChange={(value, viewUpdate) => {

                      const updatedItems = listTabs.map((i) =>
                        i.id === item.id ? { ...i, content: value } : i
                      );
                      dispatch(saveListTabs(updatedItems));

                    }}
                  />
                </div>

              }
              {item.type == "python" &&
                <>
                  <div className="col-lg-12 col-12 form-group" style={{ border: "1px solid #ccc", borderRadius: "15px", padding: "15px" }}>
                    <label>Code Python</label>
                    <CodeMirror
                      height="500px"
                      theme={githubLightInit({
                        settings: {
                          caret: '#c6c6c6',
                          fontFamily: 'monospace',
                        }
                      })}

                      extensions={[langs.python()]}
                      value={item.code}
                      onChange={(value, viewUpdate) => {

                        const updatedItems = listTabs.map((i) =>
                          i.id === item.id ? { ...i, code: value } : i
                        );
                        dispatch(saveListTabs(updatedItems));

                      }}
                    />
                  </div>
                  <div className="col-lg-12 col-12 form-group" style={{ border: "1px solid #ccc", borderRadius: "15px", padding: "15px" }}>

                    <label>Kết quả trả về</label>
                    <CodeMirror
                      height="500px"
                      theme={githubLightInit({
                        settings: {
                          caret: '#c6c6c6',
                          fontFamily: 'monospace',
                        }
                      })}

                      extensions={[langs.python()]}
                      value={item.result}
                      onChange={(value, viewUpdate) => {

                        const updatedItems = listTabs.map((i) =>
                          i.id === item.id ? { ...i, result: value } : i
                        );
                        dispatch(saveListTabs(updatedItems));

                      }}
                    />
                  </div>
                </>
              }
            </div>
          ))}
        </>
      }

    </div>
  );
}