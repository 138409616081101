import React, { useState } from "react";
import LineTo from 'react-lineto';
import parse from 'html-react-parser'

const style = {
    delay: true,
    borderColor: '#6c757d',
    borderStyle: 'solid',
    borderWidth: 3,
};

export default function QMatching(props) {
    const { type, item, currentAnswer } = props;
    const answers = item.decode.answers
    const [choices] = useState(currentAnswer || [])
    const correctAnswer = () => {
        let newData = [];
        const correct = currentAnswer.correct
        currentAnswer.list.map((item, i) => {
            newData.push({ left: i, label: correct[i], right: correct.indexOf(correct[i]) })
            return item
        })
        return newData
    }
    return (<div className="col-12 form-group mt-3">
        {type === 'question' &&
            <div className="d-flex">

                {answers && <div>
                    {currentAnswer.list.map((itAs, i) => <div key={i} className="mb-2 ">
                        <button className={"btn btn-sm  " + item.id + "-lineleft" + (i) + " btn-secondary"} >{parse(itAs + '')}</button>
                    </div>)}
                </div>}

                {answers && <div className="ml-5">
                    {currentAnswer.correct.map((itAs, i) => <div key={i} className="mb-2 ">
                        <button className={"btn btn-sm btn-secondary " + item.id + "-lineright" + (i)} >{parse(itAs + '')}</button>
                    </div>)}
                </div>}

                {correctAnswer().map((itAs, i) => {
                    return (<LineTo key={i} from={item.id + "-lineleft" + itAs.left} to={item.id + "-lineright" + itAs.right} fromAnchor="right" toAnchor="left"  {...style} />)
                })}
            </div>
        }
        {type === 'answer' &&
            <div className="d-flex">
                {answers && <div className="pr-3">
                    {answers.map((itAs, i) => <div key={i} className="mb-2 ">
                        <button className={"btn btn-sm  " + item.id + "-stulineleft" + (i) + " btn-secondary"} >{parse(itAs + '')}</button>
                    </div>)}
                </div>}

                {answers && <div className="ml-5">
                   
                    {item.corrects.map((itAs, i) => <div key={i} className="mb-2 ">
                        <button className={"btn btn-sm btn-secondary " + item.id + "-stulineright" + (i)} >{parse(itAs + '')}</button>
                    </div>)}
                </div>}
                {choices.correct.map((itAs, i) => <LineTo key={i} from={item.id + "-stulineleft" + itAs.left} to={item.id + "-stulineright" + itAs.right} fromAnchor="right" toAnchor="left"  {...style} />)}
            </div>
        }
    </div>);
}
