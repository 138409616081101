import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AlertErrorForm from "../../component/AlertErrorForm";
import Spinner from "../../component/Spinner";
import DateRangePicker from "react-bootstrap-daterangepicker";
import ViewStep from "./ViewStep";
import { CONFIG_STEP_TEST_2 } from "../../configs";
import SelectOption from "../../component/SelectOption";
import SelectBoxMultiple from "../../component/SelectBoxMultiple";
import moment from "moment";
import { URL_LIST_ALL } from "../../configs/Api";
import Axios from "axios";
import UserDropDown from "../../component/UserDropDown";

export default function BaiKiemTraStep1(props) {
  const { user } = useSelector((state) => state.auth);
  const { nextStep, detail } = props
  const [dateBegin, setDateBegin] = useState(undefined);
  const [dateEnd, setDateEnd] = useState(undefined);

  const defaultdetail = { type: 2,author_id: user.data.id, title: '',random_question: 0, status: 1, number: 0, total_point: 10, grade_id: '', subject_id: '', listclass: '', questions: {}, time: '', datebegin: '', dateend: '', dateview: '' }

  const formik = useFormik({
    initialValues: detail ? detail : defaultdetail,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      grade_id: Yup.string().trim().nullable().required('Vui lòng chọn khóa học'),
      type_id: Yup.string().trim().nullable().required('Vui lòng chọn loại'),
      title: Yup.string().trim().nullable().required('Nhập tên bài khảo thí').max(150, "Độ dài không quá 150 ký tự"),
      time: Yup.string().trim().nullable().required('Nhập thời gian làm bài'),
      listclass: Yup.array().nullable().required('Nhập số lượng lớp'),
      datebegin: Yup.string().trim().nullable().required('Nhập thời gian mở'),
      dateend: Yup.string().trim().nullable().required('Nhập thời gian đóng')
    }),

    onSubmit: (values, { setSubmitting, resetForm }) => {
      // console.log(values);
      setSubmitting(false)
      nextStep(values)
    },
  });
  useEffect(() => {
    if (detail && detail.datebegin && detail.dateend) {
      setDateBegin(moment(detail.datebegin))
      setDateEnd(moment(detail.dateend))
    }
  }, [detail])

  const handleCallback = async (start, end, label) => {
    setDateBegin(start)
    setDateEnd(end)
    await formik.setFieldValue('datebegin', Math.floor(start / 1000.0))
    await formik.setFieldValue('dateend', Math.floor(end / 1000.0))
  }

  const setSubject = (value) => {
    Axios.get(URL_LIST_ALL + "subjects?grade_id=" + value, user.config)
      .then(async (res) => {
        if (res.data.status === "success") {
          let data_subject = res.data.data
          if (data_subject) {
            formik.setFieldValue("subject_id", data_subject[0].id)
          }
        } else console.log(res.data.message);
      }).catch((err) => { console.log(err) });
  }

  return (

    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
      <div className="col-sm-12 col-md-6 offset-md-3"><ViewStep step={0} arrStep={CONFIG_STEP_TEST_2} /></div>
      <div className="row">
        <div className="col-xl-3 col-lg-3 col-12 form-group">
          <label>Tên bài kiểm tra <span className="text-danger">*</span></label>
          <input type="text" className="form-control h-31" placeholder="Nhập tên bài kiểm tra" name="title"
            {...formik.getFieldProps("title")}
          ></input>
          <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
        </div>
        <div className="col-xl-3 col-lg-4 col-sm-6 col-12 form-group">
          <label>Chọn khóa học <span className="text-danger">*</span></label>
          <UserDropDown apiname={"khoahoc?orderby=title-ASC"} placeholder="Chọn khóa học" colvalue={"khoahoc.id"} colname={"khoahoc.title"} formik={formik} name="grade_id"
            onChange={async (e) => { await formik.setFieldValue("grade_id", e); setSubject(e) }}
          ></UserDropDown>
          <AlertErrorForm formik={formik} name="grade_id"></AlertErrorForm>
        </div>
        <input type="hidden" placeholder="" className="form-control height32" {...formik.getFieldProps("subject_id")} autoComplete="off" />
        <div className="col-xl-3 col-lg-3 col-12 form-group">
          <label>Lớp <span className="text-danger">*</span> </label>
          <SelectBoxMultiple apiname={"class?key=&orderby=title-ASC&page=1&limit=500&grade_id=" + (formik.values.grade_id)} placeholder="Tất cả lớp" detail={detail} colvalue={'class.id'} formik={formik} colname={'class.title'} name='listclass'
            onChange={async (e) => { await formik.setFieldValue('listclass', e); }}
          ></SelectBoxMultiple>
          <AlertErrorForm formik={formik} name="listclass"></AlertErrorForm>
        </div>
        <div className="col-lg-3 col-12 form-group">
          <label>Thời gian làm bài (phút)<span className="text-danger">*</span></label>
          <input type="number" className="form-control h-31" placeholder="Nhập thời gian làm bài" name="time" {...formik.getFieldProps("time")} autoComplete="off"
          ></input>
          <AlertErrorForm formik={formik} name="time"></AlertErrorForm>
        </div>

        <div className="col-lg-9 col-12 form-group">
          <label> Thời gian mở <span className="text-danger">*</span></label>

          <DateRangePicker initialSettings={{ startDate: dateBegin, endDate: dateEnd, minDate: new Date(), timePicker: true }}
            onCallback={handleCallback}
          >
            <div className='d-flex align-items-center'>
              <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Từ ngày</span>
              <input
                className="form-control h-31 form-control-sm"
                placeholder='Từ ngày'
                readOnly={true}
                onChange={(e) => setDateBegin(e)}
                value={dateBegin ? dateBegin.format('YYYY-MM-DD H:m') : ""}
              />
              <span className='fas fa-arrow-right mx-4' />
              <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Đến ngày</span>
              <input
                className="form-control h-31 form-control-sm"
                placeholder='Đến ngày'
                readOnly={true}
                onChange={(e) => setDateEnd(e)}
                value={dateEnd ? dateEnd.format('YYYY-MM-DD H:m') : ""}
              />
            </div>
          </DateRangePicker>
          <AlertErrorForm formik={formik} name="dateBegin"></AlertErrorForm>
          <AlertErrorForm formik={formik} name="dateEnd"></AlertErrorForm>
        </div>

        <div className="col-lg-3 col-12 form-group">
          <label>Loại <span className="text-danger">*</span></label>
          <SelectOption apiname={"exam_param?page=1&orderby=title-ASC&limit=500&idtype=1"} placeholder="Chọn loại" colvalue={'exam_param.id'} colname={'exam_param.title'} formik={formik} name='type_id' onChange={async (e) => { await formik.setFieldValue('type_id', e) }}></SelectOption>
          <AlertErrorForm formik={formik} name="type_id"></AlertErrorForm>
        </div>
        <div className="col-lg-3 col-12 form-group">
              <label>Có trộn câu hỏi hay không </label>
              <select className="form-control height32" {...formik.getFieldProps("random_question")}>
                <option value="1" >Có</option>
                <option value="0" >Không</option>
              </select>
            </div>
        <div className="col-12 form-group mg-t-8 text-center">
          <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" > {formik.isSubmitting ? <Spinner /> : <>Tiếp tục</>}</button>
        </div>
      </div>
    </form>
  );
}
