import React, { useState } from "react";
import Loading from "../component/Loading";
export default function DashboardStudentBook() {
    const [loading] = useState(false);

    return (<>
        {loading ? (<Loading />) : (
            <><div className="breadcrumbs-area">
                <h3>Chào mừng bạn đến với Tomo Code</h3>
            </div>
                <div className="row">
                    <div className="col-12">
                        <div className="dashboard-summery-one ">
                            <div className="row">
                                <div className="col-12 col-sm-4 ">
                                    <a className="text-center card" href="/books-exercise">
                                        <div className="text-center card-icon">
                                            <span className="fas fa-book"></span>
                                        </div>
                                        <div className="card-body">
                                            Bài tập
                                        </div>
                                    </a>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )}
    </>)
}