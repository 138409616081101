import React from "react";

export default function QTrueFlase(props) {
    const {type,answers} = props;
   
    return (
        <div className="col-12 form-group mt-3">
            {type === "question" ?
                <>
                    <button className={"btn btn-sm "+(answers.correct===false?' btn-info':' btn-secondary')}  > Sai </button>
                    <button className={"btn btn-sm ml-3 "+(answers.correct===true?' btn-info':' btn-secondary')} > Đúng </button>
                </>
                :
                <button className={"btn btn-sm ml-3 btn-secondary"}> {answers.correct===true ? 'Đúng' : 'Sai'} </button>
            }
        </div>
    )
}
