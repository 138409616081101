import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import AlertErrorForm from "../../../component/AlertErrorForm";
import Spinner from "../../../component/Spinner";
import QTrueFlase from "./QTrueFlase";
import QChooseMulti from "./QChooseMulti";
import QChooseOne from "./QChooseOne";
import QDropDrag from "./QDropDrag";
import QEssay from "./QEssay";
import QFillTo from "./QFillTo";
import QMatching from "./QMatching";
import Axios from "axios";
import { URL_LIST_ALL } from "../../../configs/Api";
import { useFormik } from "formik";
import * as Yup from "yup";
import UserDropDown from "../../../component/UserDropDown";

export default function FormQuestion(props) {
  const { test, item, index, id, edit, onRefresh } = props;
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState(null);
  const [show, setShow] = useState(false);
  const [answers, setAnswers] = useState({});

  const objQues = {
    QTrueFlase: (props) => (
      <QTrueFlase
        {...props}
        item={item}
        onAnswers={setAnswers}
        answers={answers}
      />
    ),
    QChooseMulti: (props) => (
      <QChooseMulti
        {...props}
        item={item}
        onAnswers={setAnswers}
        answers={answers}
      />
    ),
    QChooseOne: (props) => (
      <QChooseOne
        {...props}
        item={item}
        onAnswers={setAnswers}
        answers={answers}
      />
    ),
    QDropDrag: (props) => (
      <QDropDrag
        {...props}
        item={item}
        onAnswers={setAnswers}
        answers={answers}
      />
    ),
    QEssay: (props) => (
      <QEssay {...props} item={item} onAnswers={setAnswers} answers={answers} />
    ),
    QFillTo: (props) => (
      <QFillTo
        {...props}
        item={item}
        onAnswers={setAnswers}
        answers={answers}
      />
    ),
    QMatching: (props) => (
      <QMatching
        {...props}
        item={item}
        onAnswers={setAnswers}
        answers={answers}
      />
    ),
  };
  const Tag = objQues[item.code];

  useEffect(() => {
    let mounted = true;
    if (id)
      Axios.get(URL_LIST_ALL + "exam_question/" + id, user.config)
        .then(async (res) => {
          if (res.data.status === "success") {
            if (mounted) {
              setDetail(res.data.data);
              setAnswers(JSON.parse(res.data.data.answers));
            }
          } else console.log(res.data.message);
        })
        .catch((err) => console.log(err));
    return () => (mounted = false);
  }, [user, id]);

  const formik = useFormik({
    initialValues: detail
      ? detail
      : {
          test_id: test.id,
          type_id: item.id,
          author_id: user.data.id,
          level_id: "",
          aware_id: "",
          scope_id: "",
          title: "",
          answers: {},
          status: 1,
        },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      level_id: Yup.string().trim().nullable().required("Chọn mức độ  nhận biết "),
      aware_id: Yup.string()
        .trim()
        .nullable()
        .required("Chọn mức độ nhận biết"),
      scope_id: Yup.string().trim().nullable().required("Chọn phạm vi sử dụng"),
      title: Yup.string().trim().nullable().required("Nhập nội dung câu hỏi"),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoading(true);
      values.answers = JSON.stringify(answers);
      if (id > 0) {
        Axios.put(URL_LIST_ALL + "at_exam_question/" + id, values, user.config)
          .then((res) => {
            setShow(false);
            setLoading(false);
            setSubmitting(false);
            if (res.data.status === "success") {
              if (onRefresh) onRefresh();
              resetForm();
              confirmAlert({
                title: "Thành công",
                message: "Bạn cập nhật câu hỏi thành công",
                buttons: [{ label: "OK" }],
              });
            } else {
              confirmAlert({
                title: "Lỗi",
                message: res.data.message,
                buttons: [{ label: "OK" }],
              });
            }
          })
          .catch((err) => console.log(err));
      } else {
        Axios.post(URL_LIST_ALL + "at_exam_question", values, user.config)
          .then((res) => {
            setShow(false);
            setLoading(false);
            setSubmitting(false);
            if (res.data.status === "success") {
              if (onRefresh) onRefresh();
              resetForm();
              confirmAlert({
                title: "Thành công",
                message: "Bạn thêm mới câu hỏi thành công",
                buttons: [{ label: "OK" }],
              });
            } else {
              confirmAlert({
                title: "Lỗi",
                message: res.data.message,
                buttons: [{ label: "OK" }],
              });
            }
          })
          .catch((err) => console.log(err));
      }
    },
  });

  return (
    <>
      {edit === true ? (
        <button
          key={index}
          type="button"
          className="btn btn-warning btn-sm mr-2 mb-2"
          onClick={() => setShow(true)}
        >
          Sửa câu hỏi
        </button>
      ) : (
        <button
          key={index}
          type="button"
          className="btn btn-primary btn-sm mr-2 mb-2"
          onClick={() => setShow(true)}
        >
          {index + 1}: Thêm câu hỏi {item.title}
        </button>
      )}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={true}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {edit === true ? "Sửa" : "Thêm"} câu hỏi "{item && item.title}"
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className="new-added-form"
            method="post"
            onSubmit={formik.handleSubmit}
          >
            <div className="row">
              <div className="col-lg-6 col-12 form-group">
                <label>
                  Mức độ khó <span className="text-danger">*</span>
                </label>
                <UserDropDown
                  apiname={"exam_param?page=1&limit=500&idtype=3"}
                  placeholder="Chọn mức độ"
                  colvalue={"exam_param.id"}
                  colname={"exam_param.title"}
                  formik={formik}
                  name="level_id"
                  onChange={async (e) => {
                    await formik.setFieldValue("level_id", e);
                  }}
                ></UserDropDown>
                <AlertErrorForm
                  formik={formik}
                  name="level_id"
                ></AlertErrorForm>
              </div>
              <div className="col-lg-6 col-12 form-group">
                <label>
                  Mức độ nhận biết <span className="text-danger">*</span>
                </label>
                <UserDropDown
                  apiname={"exam_param?page=1&limit=500&idtype=4"}
                  placeholder="Chọn mức nhận biết"
                  colvalue={"exam_param.id"}
                  colname={"exam_param.title"}
                  formik={formik}
                  name="aware_id"
                  onChange={async (e) => {
                    await formik.setFieldValue("aware_id", e);
                  }}
                ></UserDropDown>
                <AlertErrorForm
                  formik={formik}
                  name="aware_id"
                ></AlertErrorForm>
              </div>
              <div className="col-lg-6 col-12 form-group">
                <label>
                  Phạm vi sử dụng <span className="text-danger">*</span>
                </label>
                <UserDropDown
                  apiname={"exam_param?page=1&limit=500&idtype=5"}
                  placeholder="Chọn phạm vi"
                  colvalue={"exam_param.id"}
                  colname={"exam_param.title"}
                  formik={formik}
                  name="scope_id"
                  onChange={async (e) => {
                    await formik.setFieldValue("scope_id", e);
                  }}
                ></UserDropDown>
                <AlertErrorForm
                  formik={formik}
                  name="scope_id"
                ></AlertErrorForm>
              </div>
              <div className="col-lg-6 col-12 form-group">
                <label>Trạng thái</label>
                <select
                  className="form-control height32"
                  {...formik.getFieldProps("status")}
                >
                  <option value="0">Khoá</option>
                  <option value="1">Hoạt động</option>
                </select>
              </div>
              <div className="col-12 form-group">
                <label>
                  Nội dung câu hỏi <span className="text-danger">*</span>
                </label>
                <textarea
                  placeholder=""
                  className="form-control"
                  rows="4"
                  {...formik.getFieldProps("title")}
                  autoComplete="off"
                ></textarea>
                <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
              </div>
              <Tag />
              <div className="col-12 form-group mg-t-8">
                <button
                  type="submit"
                  disabled={formik.isSubmitting}
                  className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                >
                  {loading ? <Spinner /> : <>Lưu câu hỏi</>}
                </button>
                <button
                  type="reset"
                  onClick={formik.resetForm}
                  className="btn-fill-lg bg-blue-dark btn-hover-yellow"
                >
                  Nhập lại
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
