import React, { useEffect, useState } from "react";
import AkkhorLayout from '../../layout/Akkhor';
import moment from "moment";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import ListActivity from "./ListActivity";

export default function ReportActivityPage() {
  const [moreSearch, setMoreSearch] = useState(null)
  const [reload, setReload] = useState(false)
  const [startDate, setStartDate] = useState(moment(new Date()))
  const [endDate, setEndDate] = useState(moment(new Date()))
  useEffect(() => {
    if(startDate !== null && endDate !== null && (reload===true || moreSearch===null))
    {
      const begin = startDate.format('YYYY-MM-DD');
      const end = endDate.format('YYYY-MM-DD');
      setMoreSearch({begin,end})
      setReload(false)
    }
  },[startDate,endDate, reload])// eslint-disable-line

  const handleCallback = (start, end, label) =>{
    setStartDate(start)
    setEndDate(end)
  }

  return (
    <AkkhorLayout idrole="report.report-activity">
      <h4 className="mt-2" >Báo cáo hoạt động giáo viên </h4>
      <div className='d-flex align-items-center mt-3'>
      <DateRangePicker initialSettings={{ startDate: startDate, endDate: endDate, maxDate: moment() }}
       onCallback={handleCallback}
      >
       <div className='d-flex align-items-center'>
          <span style={{whiteSpace:'nowrap'}} className="mr-2">Từ ngày</span>
          <input
            className="form-control form-control-sm"
            placeholder='Từ ngày'
            value={startDate.format('DD-MM-YYYY')}
            readOnly={true}
            onChange={()=>null}
          />
          <span className='fas fa-arrow-right mx-4' />
          <span style={{whiteSpace:'nowrap'}} className="mr-2">Đến ngày</span>
          <input
           className="form-control form-control-sm"
            placeholder='Đến ngày'
            value={endDate.format('DD-MM-YYYY')}
            readOnly={true}
            onChange={()=>null}
          />
        </div>
      </DateRangePicker>
      <button type="button" onClick={()=>setReload(true)} className="btn btn-primary btn-sm ml-3">Xem báo cáo</button>
      </div>
      <ListActivity moreSearch={moreSearch} />
    </AkkhorLayout>
  );
}
