import React, { useEffect, useState } from 'react';
import AkkhorLayout from '../layout/Akkhor';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { URL_LIST_ALL } from '../configs/Api';

export default function ListTestsBook() {
    const { user } = useSelector((state) => state.auth);
    const [list, setList] = useState([]);
    useEffect(() => {
        if (user) {
            let mounted = true;
            let url = URL_LIST_ALL + "at_exam_students?join=1&orderby=id-ASC&limit=60&type=books&student_id=" + user.data.id
            Axios.get(url, user.config).then(async (res) => {
                if (res.data.status === "success" && mounted) setList(res.data.data);
                else console.log(res.data.message);
            }).catch((err) => console.log(err));

            return () => (mounted = false);
        }

    }, [user]);

    const statusQues = (_test) => {
        if (_test.status_action > 0) {
            if (_test.status_action == 2) {/* eslint eqeqeq: 0 */
                return <Link to={"/books/" + _test.code}  > <div className="bg-success px-2 py-1 text-light text-center"><b>Tiếp tục</b></div></Link>
            }
            return <Link to={"/books-exercise/" + _test.id}  > <div className="bg-info px-2 py-1 text-light text-center"><b>Xem kết quả</b></div></Link>
        } else {
            return <Link to={"/books/" + _test.code}  > <div className="bg-success px-2 py-1 text-light text-center"><b>Xuât phát</b></div></Link>
        }
    }
    return (
        <AkkhorLayout idrole="examination" roleignone={user.data.typeaccount === 2 || user.data.typeaccount === 4}>
            <div className="breadcrumbs-area py-sm-2">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>Bài tập</li>
                </ul>
            </div>
            {user && <div className="card height-auto">
                <div className="card-body">
                    {list.length > 0 ? <>
                        <div className="row">
                            {list.map((item, i) =>
                                <div className="col-12 col-sm-6 col-md-4" key={i}>
                                    <div className="border rounded shadow mb-3 overflow-hidden">
                                        <div className="w-100">
                                            <div className='p-2' style={{ fontSize: 17, fontWeight: 'bold' }}>{item.title}</div>
                                            <div className="p-2 border-top">Có {item.number} câu hỏi - mỗi câu 1 điểm</div>
                                            <div className="p-2 border-top pt-2">
                                                Trạng thái: {item.status_action === 1 && "Đã hoàn thành"}{item.status_action === 0 && "Chưa hoàn thành"}{item.status_action === 2 && "Đang tiến hành"}
                                            </div>
                                        </div>
                                        {statusQues(item)}
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                        :
                        <h5>Bạn chưa có bài tập nào</h5>
                    }
                </div>
            </div>}
        </AkkhorLayout>
    );
}
