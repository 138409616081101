import React from 'react';

const processCategories = (categories, level = 0, layers = []) => {
  if (!categories || categories.length === 0) return layers;

  if (!layers[level]) layers[level] = [];

  categories.forEach(category => {
    layers[level].push(category);

    if (category.children && category.children.length > 0) {
      processCategories(category.children, level + 1, layers);
    }
  });

  return layers;
};

const drawArrows = (category) => {
  if (!category.children || category.children.length === 0) return [];
  return category.children.flatMap((child) => [
    <line
      key={`arrow-${category.id}-${child.id}`}
      x1={0} // Chỉnh lại giá trị phù hợp cho x1
      y1={0} // Chỉnh lại giá trị phù hợp cho y1
      x2={0} // Chỉnh lại giá trị phù hợp cho x2
      y2={0} // Chỉnh lại giá trị phù hợp cho y2
      markerEnd="url(#arrowhead)"
    />,
    ...drawArrows(child)
  ]);
};

const CategoryTree = ({ data }) => {
  const layers = processCategories(data);
  const check_class = (status) => {
    if (status == 1) {
      return 'text-white bg-success'
    }
    if (status == 2) {
      return 'text-white bg-info'
    }
    return ''
  }

  return (
    <div className="track">
      {layers.map((layer, index) => (
        <div className="layer" key={index}>
          {layer.map((category, idx) => (
            <div className="intent" id={`item-${category.id}`} data-parent={category.parent_id} key={idx}>
              {/* {console.log(category.status_parent)} */}
              {category.status_parent ?
                <a href={category.status_parent == 1  ?  '/tomo-racing-view/' + category.id : '/tomo-racing-detail/' + category.id} className={'insite ' + check_class(category.status_parent)}>
                  {category.title}<br/>
                  <small>({category.status_parent == 1 ? "Đã hoàn thành" : 'Đang diễn ra'})</small>
                </a>
                :
                <div className='insite'>
                  {category.title}
                </div>
              }
            </div>
          ))}
        </div>
      ))}
      <svg className="arrows">
        {data.flatMap((category) => drawArrows(category))}
      </svg>
    </div>
  );
};

export default CategoryTree;
