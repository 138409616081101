import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { useFormik } from "formik";
import Axios from 'axios';
import { URL_LIST_ALL } from '../configs/Api';
import { confirmAlert } from 'react-confirm-alert';
import Pagination from "react-js-pagination";
const _ = require('lodash')


export default function FormAddStudenBus(props) {
    const { user } = useSelector((state) => state.auth);
    const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
    const { year_id } = useSelector((state) => state.save);
    const [show, setShow] = useState(false);
    const [listStudent, setListStudent] = useState([]);
    const [choiceStudent, setChoiceStudent] = useState({});
    const [page, setPage] = useState(1);
    const limit = 10;
    const [totalRecord, setTotalRecord] = useState(10);

    const { tripid, bus_id, setRefresh } = props;

    const formik = useFormik({
        initialValues: { idstudents: [], trip_id: tripid, bus_id: bus_id },
        validationSchema: null,
        onSubmit: (values, { setSubmitting }) => {
            values.idstudents = Object.keys(choiceStudent)
            setShow(false)
            Axios.post(URL_LIST_ALL + "students_to_bus_trip?year_id=" + year_id, values, user.config).then((res) => {
                if (res.data.status === 'success') {
                    setRefresh();
                    confirmAlert({
                        title: 'Thành công',
                        message: 'Bạn thêm học sinh vào lớp thành công',
                        buttons: [{ label: 'OK' }]
                    });
                } else {
                    confirmAlert({
                        title: 'Thông báo',
                        message: res.data.message,
                        buttons: [{ label: 'OK' }]
                    });
                }
            })
                .catch((err) => console.log(err));
        },
    });

    const findStudent = (e) => {
        let keyword = e.target.value;
        loadStudent(keyword)
    }

    const loadStudent = (keyword) => {
        Axios.get(URL_LIST_ALL + "students?page=" + page + "&bus_id=" + bus_id + "school_id=" + school_id + "&limit=10&key=" + keyword, user.config)
            .then((res) => {
                if (res.data && res.data.status === "success") {
                    setListStudent(res.data.data);
                    setTotalRecord(res.data.total);
                } else console.log(res.data.message);
            })
            .catch((err) => console.log(err));
    }

    const setChoice = (id, stt) => {
        let obj = _.assign({}, choiceStudent);
        if (stt) {
            obj[id] = true
        } else {
            delete obj[id]
        }
        setChoiceStudent(obj)
    }
    useEffect(
        function () {
            loadStudent('')
        },
        [page]
    );

    return <>
        <button className="btn btn-sm btn-primary" type='button' onClick={() => { setShow(true); }}>Thêm học sinh</button>
        <Modal show={show} onHide={() => setShow(false)} animation={true} size="md">
            <form method="post" onSubmit={formik.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Tìm kiếm học sinh</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input type="text" className="form-control form-control-sm" placeholder="Tìm kiếm theo mã hoặc tên" onChange={findStudent}></input>
                    {listStudent.length > 0 && <>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Mã</th>
                                    <th>Họ và tên</th>
                                    <th>Lớp</th>
                                    <th>Chọn</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listStudent.map((item, i) => {
                                    return <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{item.code}</td>
                                        <td>{item.fullname}</td>
                                        <td>{item.classname}</td>
                                        <td className='d-flex justify-content-center align-items-center'> <input type="checkbox" name="idstudents" value={item.id} onChange={(e) => setChoice(item.id, e.target.checked)} /></td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        <div className='w-100 d-flex justify-content-center'>
                            <Pagination
                                activePage={page}
                                itemsCountPerPage={limit}
                                totalItemsCount={totalRecord}
                                pageRangeDisplayed={5}
                                itemClass="page-item"
                                linkClass="page-link"
                                prevPageText="<"
                                nextPageText=">"
                                onChange={setPage}
                            />
                        </div>

                    </>}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-sm btn-secondary" onClick={() => setShow(false)}>Đóng</button>
                    <button type="submit" className="btn btn-sm btn-success"> Lưu</button>
                </Modal.Footer>
            </form>
        </Modal>
    </>
}
