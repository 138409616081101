import React, { useEffect, useState } from 'react';
import AkkhorLayout from '../layout/Akkhor';
import UserDropDown from '../component/UserDropDown';
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import Axios from "axios";
import { URL_LIST_ALL } from '../configs/Api';
import { useSelector } from 'react-redux';

export default function XuatDiemDanh(props) {
    const { user } = useSelector((state) => state.auth);
    const [class_id, setClass_id] = useState('all');
    const [classInfo, setClassInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dateCheck, setDateCheck] = useState(moment(new Date()).format('DD-MM-YYYY'))
    const [dateSelect, setDateSelect] = useState({ datecheck: new Date(),datecheck_start: new Date(),datecheck_end: new Date()})
    const [linkDownload, setLinkDownload] = useState('')
    const [exportType, setExportType] = useState('bydate')

    const handleDate = (name, value) => {
        setDateCheck(moment(value).format('DD-MM-YYYY'))
        let newDate = Object.assign({}, dateSelect)
        newDate[name] = value
        setDateSelect(newDate)
    };

    const exportFile = () => {
        let url = "export=1&class_id=" + class_id ;
        if(exportType == 'bydate'){
            url = url + '&datecheck=' + moment(dateSelect.datecheck).format('YYYY-MM-DD')
        }else{
            let datecheck_start = moment(dateSelect.datecheck_start).format('YYYY-MM-DD')
            let datecheck_end = moment(dateSelect.datecheck_end).format('YYYY-MM-DD')
            url = url + '&datecheck_start=' + datecheck_start + '&datecheck_end=' + datecheck_end
        }
        // console.log(url);
        Axios.get(URL_LIST_ALL + "check_student_onday?" + url, user.config).then(async (res) => {
            if (res.data.status === "success") {
                setLinkDownload(res.data.data)
            } else console.log(res.data.message);
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        });
    }
    return (
        <AkkhorLayout idrole="attends">
            <h4 className="mt-2">Xuất dữ liệu điểm danh</h4>
            <div className="card pb-0 height-auto mb-3 mt-5">
                <div className="card-body">
                    <div className='row'>
                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                            <label>Chọn lớp</label>
                            <UserDropDown apiname={"class?key=&orderby=title-ASC&page=1&limit=50"} placeholder="Chọn lớp học" defaultValue={class_id} colvalue={"class.id"} colname={"class.title"} name="class_id"
                                onChange={async (e) => { setClass_id(e); }} all={true}
                            ></UserDropDown>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                            <label>Kiểu xuất</label>
                            <select className="form-control height32" value={exportType} onChange={(e) => setExportType(e.target.value)}>
                                <option value="bydate">Theo ngày cụ thể</option>
                                <option value="bytime">Theo thời gian</option>
                            </select>
                        </div>
                        {exportType === 'bydate' ?
                            <div className="col-xl-3 col-lg-3 col-12 form-group">
                                <label>Ngày điểm danh</label>
                                <DateTimePicker onChange={(value) => { handleDate('datecheck', value) }} value={dateSelect.datecheck ? new Date(dateSelect.datecheck) : null} maxDate={new Date()} format="dd-MM-yy" className="form-control height32 air-datepicker"></DateTimePicker>
                            </div>
                            :<>
                            <div className="col-xl-3 col-lg-3 col-12 form-group">
                                <label>Từ ngày</label>
                                <DateTimePicker onChange={(value) => { handleDate('datecheck_start', value) }} value={dateSelect.datecheck_start ? new Date(dateSelect.datecheck_start) : null} maxDate={new Date()} format="dd-MM-yyyy" className="form-control height32 air-datepicker"></DateTimePicker>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-12 form-group">
                                <label>Tới ngày</label>
                                <DateTimePicker onChange={(value) => { handleDate('datecheck_end', value) }} value={dateSelect.datecheck_end ? new Date(dateSelect.datecheck_end) : null} maxDate={new Date()} minDate={dateSelect.datecheck_start ? dateSelect.datecheck_start : null} format="dd-MM-yyyy" className="form-control height32 air-datepicker"></DateTimePicker>
                            </div>
                            </>
                        }

                        <div className="col-xl-3 col-lg-3 col-12 form-group"></div>
                        <div className="col-12 form-group d-flex justify-content-center">
                            <div><button type='button' onClick={()=>exportFile()} className='btn btn-sm btn-info'>Xuất dữ liệu</button></div>
                        </div>
                        <div className="col-12 form-group text-center">
                            {linkDownload ? 
                            <>
                            <a href={linkDownload} className='btn btn-sm btn-info'>Tải về</a><br/>
                            <span>Nếu click mà không tải được về thì ấn chuột phải vào nút tải về rồi chọn copy link sau đó mở tab mới rồi dán link đó vào tab mới sau đó chạy</span>
                            </>
                            : ''}
                        </div>
                    </div>
                </div>
            </div>

        </AkkhorLayout>
    );
}
