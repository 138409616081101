import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { LIST_ALPHA } from "../../../configs";
import { saveAnswers } from "../../../redux/Actions";
import parse from 'html-react-parser'
import GenderCode from "../../../component/GenderCode";

export default function QChooseOne(props) {
    const { item, currentAnswer } = props; // LNT
    const { Answers } = useSelector(state => state.save);
    const dispatch = useDispatch();

    const answers = item.answers

    const chooseItem = (index) => {
        let newListAnswers = { ...Answers }
        newListAnswers[item.id] = { question: item, answers: index, percent: (index + '') === (answers.correct + '') ? 100 : 0 };
        // console.log(newListAnswers[item.id]);
        dispatch(saveAnswers(newListAnswers))
    }

    return (<div className="col-12 form-group">
        <ul>
            {answers && <>
                {answers.list ?
                    answers.list.map((item, i) =>
                        <li key={i} className="d-flex mb-2 align-items-center item-choice-one" >
                            {LIST_ALPHA[i]}. <div className={"w-100 py-1 px-2 cursor-pointer rounded border " + (currentAnswer === i ? ' border-info bg-info text-white' : ' border-grey')} onClick={() => chooseItem(i)}>
                                {/* {item.includes("<class") && item.includes(">") ? item : parse(item)} */}
                                <GenderCode text_string={item} />
                            </div>
                        </li>)
                    :
                    Array.isArray(answers) && answers.map((item, i) =>
                        <li key={i} className="d-flex mb-2 align-items-center item-choice-one " >
                            {LIST_ALPHA[i]}. <div className={"w-100 py-1 px-2 cursor-pointer rounded border " + (currentAnswer === i ? ' border-info bg-info text-white' : ' border-grey')} onClick={() => chooseItem(i)}>
                                {/* {item.includes("<class") && item.includes(">") ? item : parse(item)} */}
                                <GenderCode text_string={item} />
                            </div>
                        </li>)
                }
            </>}
        </ul>
    </div>);
}
