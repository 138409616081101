import React from "react";
import {Spinner as ReactSpinner} from   "react-bootstrap";

export default function SpinnerCenter({title}) {
  return (
    <div className="py-5  w-100 text-center">
      {title&&<div className="pb-2" style={{fontSize:20}}>{title}</div>}
      <ReactSpinner animation="border" variant="primary" />
    </div>
  );
}
