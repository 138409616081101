import React, { useState , useEffect} from "react";
import { useSelector } from "react-redux";
import { Modal , Button } from "react-bootstrap";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/Api";
import { confirmAlert } from "react-confirm-alert";
import ButtonCheck from "./ButtonCheck";
import {Cell} from 'react-sticky-table';

export default function Violation(props) {
  const { data, updateData , isaction} = props;
  const { user } = useSelector((state) => state.auth);
  const [violation, setViolation] = useState(data.violation===1?true:false);
  const [show, setShow] = useState(false);
  const [notes, setNotes] = useState('');

  useEffect(() => {
    setViolation(data.violation===1?true:false)
    setNotes(data.violation===1?data.notes:'')
  }, [data]);
    
  const actionSend=(stt)=> {
    
    const newdata={...data, notes: stt===true?notes:'' , violation: stt===true?1:0};
    delete newdata.id;
    Axios.post(URL_LIST_ALL + "students_onday", newdata,user.config).then((res)=>{
      setShow(false);
      if(res.data.status === "success")
      {
        setViolation(stt)
        updateData(newdata)
        if(stt===false)setNotes('')
        let message = "Đã lưu vi phạm của học sinh "+data.fullname;
        if(stt===false)message = "Đã bỏ qua vi phạm của học sinh "+data.fullname;
        confirmAlert({title: "Thông báo",  message, buttons: [ { label: "Đóng" }]});
      }else{
        confirmAlert({title: "Lỗi",  message: res.data.message, buttons: [ { label: "Đóng" }]});
      }
    }).catch((err)=>{console.log(err)});
    
  }
  return (
    <>
      <Cell >
        <div className="text-center w-100">
        <span onClick={(e) => isaction?setShow(true):null}><ButtonCheck checked={violation===true} color='#db0d18'></ButtonCheck></span>
        </div>
        <Modal show={show} onHide={() => setShow(false)} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Vi phạm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <textarea className="form-control" rows={4} placeholder="Nhập nội dung vi phạm ở đây" value={notes}  onChange={(e)=>setNotes(e.target.value)} ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => actionSend(false)}>Bỏ qua</Button>
          <Button variant="success" onClick={()=>actionSend(true)}>Lưu</Button>
        </Modal.Footer>
      </Modal>
      </Cell>

      
    </>
  );
}
