import React from 'react';
import QTrueFlase from "./QTrueFlase";
import QChooseMulti from "./QChooseMulti";
import QChooseOne from "./QChooseOne";
import QDropDrag from "./QDropDrag";
import QEssay from "./QEssay";
import QFillTo from "./QFillTo";
import QMatching from "./QMatching";

export default function ShowQuestion(props) {
  const {type,item,answers} = props;
  
  const QsView = () =>{
    if(item.code === "QTrueFlase" || item.type_id === 6) return <QTrueFlase type={type} answers={answers}/>;
    if(item.code === "QChooseMulti" || item.type_id === 8) return <QChooseMulti type={type} item={item} answers={answers}/>;
    if(item.code === "QChooseOne" || item.type_id === 7) return <QChooseOne type={type} items={item} answers={answers}/>;
    if(item.code === "QDropDrag" || item.type_id === 11) return <QDropDrag type={type} answers={answers}/>;
    if(item.code === "QEssay" || item.type_id === 12) return <QEssay type={type} item={item} answers={answers}/>;
    if(item.code === "QFillTo" || item.type_id === 9) return <QFillTo type={type} answers={answers}/>;
    if(item.code === "QMatching" || item.type_id === 10) return <QMatching type={type} item={item} currentAnswer={answers}/>;
    return null;
  }
  return (
    <QsView />
  );
}
