import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CodeMirror from '@uiw/react-codemirror';
import { langs } from '@uiw/codemirror-extensions-langs';
import { saveAnswers } from "../../../redux/Actions";
import { githubLightInit } from "@uiw/codemirror-theme-github";

export default function QCode(props) {
    const { item, currentAnswer } = props;
    const { Answers } = useSelector(state => state.save);
    const dispatch = useDispatch();

    const onBlur = (data) => {
        let newListAnswers = { ...Answers }
        newListAnswers[item.id] = { question: item, answers: data, percent: 0 };
        dispatch(saveAnswers(newListAnswers))
    }


    return (<div className="col-12 mt-3">
        <div className="w-100 border mb-2">
            
            <CodeMirror
                height="400px"
                theme={githubLightInit({
                    settings: {
                        caret: '#c6c6c6',
                        fontFamily: 'monospace',
                    }
                })}
                readOnly={true}
                extensions={[langs.python()]}
                value={currentAnswer || ""}
                // onChange={(value, viewUpdate) => {
                //     onBlur(value)
                // }}

            />
        </div>
    </div>);

}
