
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SRList from "../component/List";
import Moment from "react-moment";
import AkkhorLayout from "../layout/Akkhor";
import UserDropDown from "../component/UserDropDown";
export default function ListDanhMuc() {
  const { user } = useSelector((state) => state.auth);
  const [grade_id, setGrade_id] = useState('');
  const [params, setParam] = useState('&type=1');
  const [keyword, setKeyword] = useState('');

  const columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "title", label: "Tên danh mục", options: { filter: true, sort: true } },
    { name: "parent", label: "Danh mục cha", options: { filter: true, sort: true } },
    { name: "khoahoc", label: "Thuộc khóa học", options: { filter: true, sort: true } },
    {
      name: "datecreate", label: "Ngày tạo",
      options: {
        filter: false, sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const time = new Date(value * 1000);
          return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
        },
      }
    }
  ];

  const createParam = () => {
    let _param = ''
    if (keyword !== '') _param += "&key=" + keyword
    if (grade_id > 0) _param += "&grade_id=" + grade_id
    setParam(_param);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    createParam();
  };

  return (
    <AkkhorLayout idrole="list-danhmuc">
      <SRList name="at_exam_category" idrole="list-danhmuc" linkaction="list-danhmuc" params={params} title="Danh mục" defaultLimit={50} serverSide={true} columns={columns}>
        <div className="card pb-0 height-auto mb-3 mt-5">
          <div className="card-body">
            <div className="d-flex pb-2">
              <div className="mr-auto">
                <h3>Tìm kiếm</h3>
              </div>

            </div>
            <form className="new-added-form" method="get" >
              <div className="row">
                <div className="col-lg-3 col-sm-6 col-12 form-group">
                  <label>Từ khoá </label>
                  <input type="text" placeholder="Tìm theo từ khoá" value={keyword} onChange={(e) => setKeyword(e.target.value)} className="form-control height32" autoComplete="off" />
                </div>
                <div className="col-lg-3 col-sm-6 col-12 form-group">
                  <label>Khóa học </label>
                  <UserDropDown apiname={"khoahoc?orderby=title-ASC"} placeholder="Chọn khóa học" colvalue={"khoahoc.id"} colname={"khoahoc.title"} name="grade_id" defaultValue={grade_id}
                    onChange={async (e) => { setGrade_id(e) }} all={true} disabled={user.data.typeaccount === 2}
                  ></UserDropDown>
                </div>

                <div className="col-12 ">
                  <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </SRList>
    </AkkhorLayout>
  );
}
