import React, {  useState } from "react";
import moment from "moment";
import SelectWeek from "./SelectWeek";
//SELECT  SUBSTRING_INDEX(fullname, ' ', 2)
export default function FilterWeek(props) {
    const {  weekChoice, setWeekChoice ,dayChoice, setDayChoice  } = props;
    const [listDay, setListDay] = useState([]);
    

    const createDayFromWeek=(week) => {
        if(week)
        { 
            let arrDay=[];
            const now = moment().format('YYYY-MM-DD');
            for (let index = 0; index < 5; index++) {
                let time = Date.parse(week.begin)+ index * 24 *60*60 * 1000;
                const date = moment(time).format('YYYY-MM-DD');
                if(now===date) setDayChoice(date)
                else if(index===0) setDayChoice(date)
                arrDay.push({ date, title:moment(time).format('dddd')+' - '+moment(time).format('DD-MM-YYYY')})
            }
            setListDay(arrDay);
        }
    }
    

    return (
        <form className="form-inline">
            <label className="mr-2  mt-2 mt-sm-0">Tuần thứ</label> 
            <SelectWeek  weekChoice={weekChoice} setWeekChoice={setWeekChoice} setWeek={(e)=>{createDayFromWeek(e);}}></SelectWeek>
            <label className="mr-2  mt-2 mt-sm-0  ml-md-2">Ngày</label> 
            <select className="form-control form-control-sm text-capitalize" value={dayChoice?dayChoice:0} onChange={(e)=>setDayChoice(e.target.value)}>
                <option value={0} disabled>Chọn ngày</option>
                {listDay.map((item, index)=>{
                    return  <option key={index} value={item.date} >{item.title}</option>
                })}
            </select>
        </form>
    );
}
