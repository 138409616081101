import React from "react";
import QTrueFlase from "./QTrueFlase";
import QChooseMulti from "./QChooseMulti";
import QChooseOne from "./QChooseOne";
import QDropDrag from "./QDropDrag";
import QEssay from "./QEssay";
import QFillTo from "./QFillTo";
import QMatching from "./QMatching";

export default function ViewQuestion(props) {
  const {item} = props;
  return (
    <>
     {item.code === 'QTrueFlase' && <QTrueFlase currentAnswer={item.corrects} />}
     {item.code === 'QChooseMulti' && <QChooseMulti item={item} currentAnswer={item.corrects} />}
     {item.code === 'QChooseOne' && <QChooseOne item={item} currentAnswer={item.corrects} />}
     {item.code === 'QDropDrag' && <QDropDrag item={item} currentAnswer={item.corrects} />}
     {item.code === 'QEssay' && <QEssay item={item} currentAnswer={item.corrects} />}
     {item.code === 'QFillTo' && <QFillTo item={item} currentAnswer={item.corrects} />}
     {item.code === 'QMatching' && <QMatching item={item} currentAnswer={item.corrects} />}
    </>
  );
}
