import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveAnswers } from "../../../redux/Actions";
import parse from 'html-react-parser'
import LineTo from 'react-lineto';
const _ = require('lodash');
const style = {
    delay: true,
    borderColor: '#6c757d',
    borderStyle: 'solid',
    borderWidth: 3,
};

export default function QMatching(props) {
    const {item,currentAnswer,onChoice} = props;
    const {Answers} = useSelector(state=>state.save);
    const dispatch = useDispatch();

    const answers = item.answers
    const [temp, setTemp] = useState(null)
    const [choices, setChoices] = useState(currentAnswer||[])

   
      
    const checkValid= (_choices) => {
        let arr = [];
        _choices.map(choice => {
            arr[choice.right] = choice.label
            return choice;
        })
        if(_.isEqual(arr,answers.correct))return 100
        return 0
    }
    
    const finishChoice = (iright,label) => {
        if(temp !==null)
        {
            const localchoice = {left:temp, right:iright,label}
            let newChoices = []
            if(choices.length > 0)
            {
                let find = false;
                choices.map(choice => {
                    if(choice.left === temp ||  choice.right === iright)
                    {
                        find = true;
                        newChoices.push(localchoice)
                    }else{
                        newChoices.push(choice)
                    }
                    return choice;
                })
                if(find===false)
                {
                    newChoices.push(localchoice)
                }
            }
            else {
                newChoices.push(localchoice)
            }
            let newListAnswers = {...Answers}
            newListAnswers[item.id] = {question:item, answers:newChoices, percent:checkValid(newChoices)};
            dispatch(saveAnswers(newListAnswers))
            onChoice(newListAnswers)
            setTemp(null)
        }
    }
     
    return (<div className="col-12 form-group mt-3">
         <div className="d-flex">
        {answers &&<div>
        {answers.list.map((itans,i)=><div key={i} className="mb-2 ">
           <button className={"btn btn-sm  "+item.id+"-lineleft"+(i)+" "+(temp===i?"btn-info":"btn-secondary")} onClick={()=>setTemp(i)}>{itans!==''?parse(itans+''):itans}</button>
        </div>)}
        </div>}

        {answers &&<div className="ml-5">
        {answers.correct.map((itans,i)=><div key={i} className="mb-2 ">
           <button className={"btn btn-sm btn-secondary "+item.id+"-lineright"+(i)}  onClick={()=>finishChoice(i,itans)}>{itans!==''?parse(itans+''):itans}</button>
        </div>)}
        </div>}
        {choices.map((itans,i)=><LineTo key={i} from={item.id+"-lineleft"+itans.left} to={item.id+"-lineright"+itans.right} fromAnchor="right" toAnchor="left"  {...style} />)}
        
        </div>
        <div className="mt-3"><i>Click chọn cột bên trái sau đó chọn cột tương ứng bên phải</i></div>
    </div>);
}
//_.shuffle(