import Axios from "axios";
import AkkhorLayout from '../layout/Akkhor';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { URL_LIST_ALL } from "../configs/Api";
import ViewQuestionRacing from "../examination/questions/ViewQuestionRacing";
import { Link } from 'react-router-dom';
import parse from 'html-react-parser'
import { confirmAlert } from "react-confirm-alert";
import GenderCode from "../component/GenderCode";

export default function ClientBooksExView(props) {
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const [detail, setDetail] = useState(null);
  const [Answers, setAnswers] = useState(null);

  useEffect(() => {
    if (id > 0) {
      let mounted = true;
      Axios.get(URL_LIST_ALL + "at_exam_students/" + id, user.config).then(async (res) => {
        if (res.data.status === "success" && mounted) {
          const _detail = res.data.data;
          setDetail(_detail)
          let info = _detail.info
          let listCorrect = []

          info.forEach(item => {
            let _corrects = item.corrects
            if (item.code === "QMatching") {
              let _x = [];
              _corrects.map((item) => {
                _x[item.right] = item.label;
                return item;
              })
              _corrects.corrects = _x;
            }
            item.corrects = _corrects
            listCorrect.push(item)
          });
          setAnswers(listCorrect);
        }
        else console.log(res.data.message);
      }).catch((err) => console.log(err));
      return () => (mounted = false);
    }
  }, [user, id]);

  const LamLaiBai = (type) => {
    let str_alert = type === 1 ? "Làm lại các câu sai" : "Làm lại tất cả"
    confirmAlert({
      title: 'Thông báo',
      message: 'Bạn có muốn ' + str_alert + ' ?',
      buttons: [
        {
          label: 'Có',
          onClick: () => {
            Axios.post(URL_LIST_ALL + 'lam-lai-bai', { type: type, exam_students_id: id }, user.config).then(res => {
              if (res.data.status === 'success') {
                window.location.href = '/books/' + detail.code
              }
            }).catch(err => console.log(err));
          }
        },
        {
          label: 'Không'

        }
      ]
    });

  }

  return (
    <AkkhorLayout idrole="list-books-exercise" roleignone={user.data.typeaccount === 2 || user.data.typeaccount === 4}>
      {(detail && Answers) ? (<div className="container-fluid">
        <div className="breadcrumbs-area py-3">
          <ul>
            <li>
              <Link to="/">Trang chủ</Link>
            </li>
            <li>Bài tập</li>
          </ul>
        </div>
        <div className="card height-auto">
          <div className="card-body">
            <div>
              <h2 className="text-center mb-3">{detail.title}</h2>
              <div className="row">
                <div className="col-sm-4 d-flex">
                  <div className="text-primary" style={{ fontSize: 50 }}><i className="fas fa-medal"></i></div>
                  <div className="py-1 px-2">
                    <div style={{ fontSize: 25, fontWeight: 'bold' }}>{detail.point}/{detail.total_number}</div>
                    <div>Số câu trả lời đúng</div>
                  </div>
                </div>
                <div className="col-sm-4 d-flex">
                  <div className="text-success" style={{ fontSize: 50 }}><i className="fas fa-clipboard-list"></i></div>
                  <div className="py-1 px-2">
                    <div style={{ fontSize: 25, fontWeight: 'bold' }}>{detail.point}</div>
                    <div>Điểm đạt được</div>
                  </div>
                </div>
                {user && (user.data.typeaccount === 2 || user.data.typeaccount === 4)&&
                  <div className="col-sm-4 d-flex">
                    <div className="text-info" style={{ fontSize: 50 }}><i className="fas fa-clock"></i></div>
                    <div className="py-1 px-2">
                      <button type="button" className="w-100 btn btn-sm btn-info text-white" onClick={() => LamLaiBai(1)}>Làm lại các câu sai</button><br />
                      <button type="button" className="w-100 btn btn-sm btn-info text-white mt-1" onClick={() => LamLaiBai(2)}>Làm lại tất cả</button>
                    </div>
                  </div>
                }
              </div>

              {Answers && Object.values(Answers).map((ans, index) => (
                <div key={index} className={"mb-2 border rounded overflow-hidden " + (ans.id && ans.point === 1 ? "answer-true" : "answer-false")} id={"nuques-" + index}>
                  <div className="racing-view-test">
                    <div className={"font-weight-bold p-2 " + (ans.id && ans.point === 1 ? 'bg-success' : 'bg-danger') + " text-light d-flex justify-content-between"}>
                      <div>Câu hỏi {index + 1}</div>
                      <div className="">
                        {ans.point && ans.point === 1 ? <> {ans.point} điểm </> : <> 0 điểm </>}
                      </div>
                    </div>
                    {ans.parent_title && <div className="p-2 border-bottom"><GenderCode text_string={ans.title} /></div>}
                    <div className="p-3 border-bottom mb-3">
                      <GenderCode text_string={ans.title} />
                    </div>
                    <div className="">
                      <ViewQuestionRacing item={ans}></ViewQuestionRacing>
                    </div>
                    {ans.comments &&
                      <div className="comment border-top p-2 mt-3">
                        <span>Nhận xét</span>
                        <div className="w-100 mt-2 d-flex">
                          <strong>{ans.teacher_comment}</strong>: {parse(ans.comments + '')}
                        </div>
                      </div>
                    }
                  </div>
                </div>)
              )}
            </div>
          </div>
        </div>
      </div>
      ) : (<>
        <div className="breadcrumbs-area py-3">
          <ul>
            <li>
              <Link to="/">Trang chủ</Link>
            </li>
          </ul>
        </div>
      </>)}
    </AkkhorLayout>
  );
}
