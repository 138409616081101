import React, { useState } from "react";
import AkkhorLayout from "../../layout/Akkhor";
import "moment/locale/vi";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import SRList from "../../component/List";
import Axios from "axios";
import { URL_LIST_ALL } from "../../configs/Api";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";

export default function RegistryLunch() {
  const now = moment().format('YYYY-MM-DD')
  let params = 'date='+now;
  const [refresh, setRefresh] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const school_id = user && user.data && user.data.school_id>0?user.data.school_id:0;
  params += user && user.data && user.data.school_id>0?'&school_id='+user.data.school_id:0;
  const columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "fullname", label: "Họ và tên", options: { filter: true, sort: true } },
    { name: "date", label: "Ngày đăng ký ăn trưa", options: { filter: true, sort: true ,
      customBodyRender: (value, tableMeta, updateValue) => {
        const time = Date.parse(value)
        return <Moment format="DD/MM/YYYY">{time}</Moment>;
      },} },
    { name: "datecreate",label: "Thời gian đăng ký",
      options:{
        filter: false,sort: true ,
        customBodyRender: (value, tableMeta, updateValue) => {
          const time = new Date(value * 1000);
          return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
        },
      }
    }
  ];

  const runRegLunch = () => {
    Axios.post(URL_LIST_ALL+'users_lunch',{ school_id,user_id:user.data.id,date:now }, user.config).then(res=>{
      setRefresh(!refresh)
      if (res.data.status === "success") {
        confirmAlert({
            title: 'Thành công',
            message: 'Bạn đăng ký suất ăn trưa nay thành công',
            buttons: [{ label: 'OK' }]
        }); }else{
            confirmAlert({
                title: 'Lỗi',
                message: res.data.message,
                buttons: [{ label: 'OK' }]
            });
        }
    }).catch(err => { console.log(err);})
  }

  return (
    <AkkhorLayout idrole="internal.registry-lunch">
      <SRList refresh={refresh} name="users_lunch"  params={params}  title="Đăng ký suất ăn" defaultLimit={50}  serverSide={true} columns={columns} TopBar={<div className="my-2">
      <button type="button" className="btn btn-success btn-sm" onClick={runRegLunch} >Đăng ký/ Hủy đăng ký</button>
      </div>}></SRList>
    </AkkhorLayout>
  );
}
