import React, { useEffect, useRef, useState } from "react";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/Api";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Editor } from '@tinymce/tinymce-react';
import { confirmAlert } from "react-confirm-alert";
import avatar_student from "../assets/img/avata_student.png"
import men_teacher from "../assets/img/men-teacher.png"
import girl_teacher from "../assets/img/girl-teacher.png"
import anhnencode from "../assets/img/anhnencode.jpg"
import parse from 'html-react-parser'
import moment from "moment";
import UploadModal from "../component/UploadModal";
import UserDropDown from "../component/UserDropDown";
import { Modal } from "react-bootstrap";
import AlertErrorForm from "../component/AlertErrorForm";
import PostsComment from "../component/PostsComment";
import { useParams } from "react-router-dom";
import { setResetPost } from "../redux/Actions";

export default function PostsList(props) {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { resetpost } = useSelector((state) => state.save);
    const [listClass, setListClass] = useState([]);
    const [listPost, setListPost] = useState([]);
    const [classChoice, setClassChoice] = useState(0);
    const [reloadCate, setReloadCate] = useState(false);
    const [typePost, setTypePost] = useState('post');
    const [typePostLoad, setTypePostLoad] = useState('post');
    const editorRef = useRef(null);
    const inputRef = useRef(null);
    const [loadingImage, setLoadingImage] = useState(false)
    const [listFile, setListFile] = useState([])
    const [modalCate, setModalCate] = useState(false)
    const [modalFormPost, setModalFormPost] = useState(false)
    const [toogleAction, setToogleAction] = useState(null)
    const [listCategory, setListCategory] = useState([]);
    const [categoryChoice, setCategoryChoice] = useState(0);
    const { id } = useParams();
    const [classInfo, setClassInfo] = useState(null)

    useEffect(() => {
        Axios.get(URL_LIST_ALL + 'list_posts_category?class_id=' + classChoice, user.config).then((res) => {
            if (res.data.status === 'success') {
                setListCategory(res.data.data)
            } else alert(res.data.message)
        }).catch((e) => {
            console.log(e);
        });
        if (classChoice) {
            listClass.forEach((item) => {
                if (item.id === classChoice) {
                    setClassInfo(item)
                }
            })
        } else {
            setClassInfo(null)
        }
    }, [classChoice])


    const uploadImage = (event) => {
        const file = event.target.files[0]
        if (file) {
            let data = new FormData()
            data.append("type", "s3")
            data.append("name", "file")
            data.append("file", file)
            data.append("filetype", file.type)
            let file_size = file.size;
            if (file_size > 3145728) {
                confirmAlert({
                    title: 'Lỗi',
                    message: "File không được lớn hơn 3 Mb",
                    buttons: [{ label: 'OK' }]
                })
                return
            } else {
                setLoadingImage(true)
                Axios.post(URL_LIST_ALL + "fileupload", data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: user.config.headers.Authorization,
                    }
                }).then(async (res) => {
                    if (res.data.status === "success") {
                        const url = res.data.message
                        if (url) {
                            let oldValue = formik.values.post_content.slice()
                            let htmlAudio = '<img src="' + url + '" alt=""/>'
                            formik.setFieldValue('post_content', oldValue + htmlAudio)
                            setLoadingImage(false)
                        }
                    } else {
                        setLoadingImage(false)
                        return;
                    }
                }).catch((err) => { console.log(err); setLoadingImage(false) })
            }
        }
    }

    useEffect(() => {
        Axios.get(URL_LIST_ALL + 'class?limit=1000&status=1', user.config).then((res) => {
            if (res.data.status === 'success') {
                setListClass(res.data.data)
            } else alert(res.data.message)
        }).catch((e) => {
            console.log(e);
        });
    }, [])

    useEffect(() => {
        if (id) {
            Axios.get(URL_LIST_ALL + 'posts?id=' + id, user.config).then((res) => {
                if (res.data.status === 'success') {
                    let data = res.data.data
                    setClassChoice(data[0].class_id)
                    setListPost(data)
                } else alert(res.data.message)
            }).catch((e) => {
                console.log(e);
            });
        } else {
            let param = ''
            param += (classChoice !== 0 ? '&class_id=' + classChoice : '')
            param += (typePostLoad === 'file' ? '&post_type=' + typePostLoad : '')
            param += (categoryChoice !== 0 ? '&file_category=' + categoryChoice : '')

            Axios.get(URL_LIST_ALL + 'posts?limit=20&orderby=id-desc&status=1' + param, user.config).then((res) => {
                if (res.data.status === 'success') {
                    let data = res.data.data
                    setListPost(data)
                } else alert(res.data.message)
            }).catch((e) => {
                console.log(e);
            });
        }

    }, [classChoice, typePostLoad, resetpost, categoryChoice])

    const formik = useFormik({
        initialValues: { id: 0, post_content: '', file: '' },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({

        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            if (values.post_content) {
                if (values.id && values.id > 0) {
                    values.user_id = user.data.id
                    values.post_type = typePost
                    values.class_id = classChoice
                    values.file = listFile
                    values.status = 1
                    Axios.put(URL_LIST_ALL + 'posts/' + values.id, values, user.config).then((res) => {
                        if (res.data.status === 'success') {
                            confirmAlert({
                                title: 'Thành công',
                                message: "Bạn đã sửa tin thành công",
                                buttons: [{ label: 'OK' }]
                            })
                            setReloadCate(!reloadCate)
                            setModalCate(false)
                            setListFile([])
                            resetForm()
                        } else {
                            alert(res.data.message)
                        }
                    }).catch((e) => {
                        console.log(e);
                    });
                } else {
                    values.user_id = user.data.id
                    values.post_type = typePost
                    values.class_id = classChoice
                    values.file = listFile

                    values.status = 1
                    Axios.post(URL_LIST_ALL + 'posts', values, user.config).then((res) => {
                        if (res.data.status === 'success') {
                            confirmAlert({
                                title: 'Thành công',
                                message: "Bạn đã đăng tin thành công",
                                buttons: [{ label: 'OK' }]
                            })
                            setReloadCate(!reloadCate)
                            setModalCate(false)
                            dispatch(setResetPost(!resetpost));
                            setListFile([])
                            resetForm()
                        } else {
                            alert(res.data.message)
                        }
                    }).catch((e) => {
                        console.log(e);
                    });
                }
                setModalFormPost(false)
            }
        },
    });
    const actionListFile = (data) => {
        let newData = listFile.slice();
        data.forEach(item => {
            let filename = item.split('/')
            let filetype = item.split('.').pop()
            newData.push({ title: "", value: item, filename: filename.pop(), filetype: filetype })
        });
        setListFile(newData);
    }
    const deleteItem = (id) => {
        let newList = []
        listFile.forEach((item, i) => {
            if (i !== id) newList.push(item)
        })
        setListFile(newList)
    }

    const formCate = useFormik({
        initialValues: { title: '', parent_id: 0 },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            title: Yup.string().trim().nullable().required('Nhập tiêu đề').max(150, "Độ dài không quá 150 ký tự")
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            Axios.post(URL_LIST_ALL + 'posts_file_category', values, user.config).then((res) => {
                if (res.data.status === 'success') {
                    confirmAlert({
                        title: 'Thành công',
                        message: "Bạn đã thêm danh mục thành công",
                        buttons: [{ label: 'OK' }]
                    })
                    setModalCate(false)
                    resetForm()
                } else {
                    alert(res.data.message)
                }
            }).catch((e) => {
                console.log(e);
            });
        },
    });

    const actionEdit = (info) => {
        formik.setFieldValue('id', info.id)
        formik.setFieldValue('post_content', info.post_content)
        formik.setFieldValue('post_type', info.post_type)
        setTypePost(info.post_type)
        var list_file = info.list_file

        if (list_file) {
            var newFiles = []
            var fileCate = null
            list_file.forEach((item) => {
                fileCate = item.file_category
                newFiles.push({ filename: item.file_title, value: item.file_url, filetype: item.file_type })
            });
            setListFile(newFiles)
            formik.setFieldValue('file_category', fileCate)
        }
        setModalFormPost(true)
    }

    const deletePost = (id) => {
        confirmAlert({
            title: 'Thành công',
            message: "Bạn có muốn xóa bài viết này không ?",
            buttons: [{
                label: 'Có', onClick: () => {
                    Axios.delete(URL_LIST_ALL + 'posts/' + id, user.config).then((res) => {
                        if (res.data.status === 'success') {
                            confirmAlert({
                                title: 'Thành công',
                                message: "Bạn đã xóa thành công",
                                buttons: [{ label: 'OK' }]
                            })
                            dispatch(setResetPost(!resetpost));
                        } else {
                            alert(res.data.message)
                        }
                    }).catch((e) => {
                        console.log(e);
                    });
                }
            }, { label: 'Không' }]
        })
    }
    const actionLike = (idpost, liked = false) => {
        Axios.post(URL_LIST_ALL + 'posts_like', { user_id: user.data.id, post_id: idpost, status: !liked ? 1 : 0 }, user.config).then((res) => {
            if (res.data.status === 'success') {
                dispatch(setResetPost(!resetpost));
            }
        })
    }

    return (
        <div className="row dashboard-post" style={classInfo && classInfo.anhnen ? { backgroundImage: 'url(' + classInfo.anhnen + ')' } : {}}>
            <div className="post-dashboard">
                <div className="dashboard-wrapper">
                    <div className="breadcrumbs-area pb-2">
                        <img src={classInfo && classInfo.anhbia ? classInfo.anhbia : anhnencode} />
                        <h3 className="mb-0">{classInfo && classInfo.title ? 'Lớp ' + classInfo.title : 'Bản tin Tomo'}</h3>
                    </div>
                    <div className="post-template">
                        {id ? '' :
                            <>
                                <div className="w-100 d-flex mb-2">
                                    <button className={"btn btn-sm " + (typePostLoad === 'post' ? "btn-info" : "btn-light")} onClick={() => setTypePostLoad('post')} type="button">Bản tin</button>
                                    <button className={"btn btn-sm " + (typePostLoad !== 'post' ? "btn-info" : "btn-light")} onClick={() => setTypePostLoad('file')} type="button">Tài liệu</button>
                                    {typePostLoad && typePostLoad == 'file' ?
                                        <select className="ml-2 form-control" defaultValue={categoryChoice} onChange={(e) => setCategoryChoice(e.target.value)}>
                                            <option value={0}>Chọn danh mục tài liệu</option>
                                            {listCategory.map((item, i) => {
                                                return <option value={item.id} key={i}>
                                                    {item.title}
                                                </option>
                                            })}
                                        </select>
                                        : ''
                                    }
                                </div>
                                <div className=" wrapper-listclass">
                                    <div className="list-item d-block d-sm-none">
                                        <div className="class-item"><button className={"btn btn-sm " + (classChoice === 0 ? 'item-active' : '')} type="button" onClick={() => setClassChoice(0)}>Tất cả</button></div>
                                        {listClass && listClass.map((item, i) => {
                                            return <div className="class-item" key={i}>
                                                <button className={"btn btn-sm " + (item.id === classChoice ? 'item-active' : '')} type="button" onClick={() => setClassChoice(item.id)}>{item.title}</button>
                                            </div>
                                        })}
                                    </div>
                                    <hr className="m-0" />
                                    {classChoice && user.data.typeaccount !== 2 ?
                                        <div className="w-100 pb-2">
                                            <div className="outer-form shadow mt-2" onClick={() => {
                                                setModalFormPost(true)
                                                formik.setFieldValue('id', 0)
                                                formik.setFieldValue('post_content', '')
                                                setListFile([])
                                            }}>
                                                Đăng bài viết...
                                            </div>
                                            <Modal show={modalFormPost} onHide={() => setModalFormPost(false)} animation={true} size="xl">
                                                <Modal.Header closeButton>
                                                    <Modal.Title>
                                                        {formik.values.id && formik.values.id > 0 ? 'Sửa bài viết' : 'Đăng bài viết'}
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <form method="post" onSubmit={formik.handleSubmit}>
                                                        <div className={"form-editor p-2 "}>
                                                            <div className="w-100 d-flex justify-content-between">
                                                                <ul className="nav nav-tabs">
                                                                    <li className="nav-item">
                                                                        <button type="button" className={"nav-link " + (typePost === 'post' ? "active" : "")} onClick={() => setTypePost('post')}>Bài đăng</button>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                        <button type="button" className={"nav-link " + (typePost === 'file' ? "active" : "")} onClick={() => setTypePost('file')}>Tài liệu</button>
                                                                    </li>
                                                                </ul>
                                                                <div className="text-right">
                                                                    <button type="button" className="btn btn-sm btn-success" onClick={() => inputRef.current.click()}>{loadingImage ? "Đang tải..." : <span className="fas fa-image"></span>}</button>
                                                                    <input type="file" id="file" ref={inputRef} style={{ display: "none" }} accept="image/*" onChange={uploadImage} />
                                                                </div>
                                                            </div>

                                                            <Editor apiKey="vswj07wyg97x3fp9hrymkie5h0ixrlu72ps7m75rr9rxr6qh"
                                                                onInit={(evt, editor) => (editorRef.current = editor)}
                                                                value={formik.values.post_content}
                                                                onFocusOut={() => {
                                                                    const valuecontent = editorRef.current.getContent()
                                                                    if (valuecontent && valuecontent !== formik.values.post_content) {
                                                                        formik.setFieldValue('post_content', valuecontent)
                                                                    }
                                                                }}
                                                                onEditorChange={(newValue, editor) => {
                                                                    formik.setFieldValue('post_content', newValue)
                                                                }}
                                                                init={{
                                                                    height: 250,
                                                                    menubar: false,
                                                                    inline: false,
                                                                    verify_html: false,
                                                                    verify_css_classes: true,
                                                                    cleanup: false,
                                                                    forced_root_block: false,
                                                                    valid_children: "+body[style]",
                                                                    plugins: 'anchor autolink charmap codesample emoticons image link lists media table visualblocks wordcount',
                                                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                                }}
                                                            />
                                                            {typePost == 'file' ?
                                                                <div className="w-100 mt-1">
                                                                    <div className="w-100 border">
                                                                        <UploadModal defaultValue="" multiple="true" button_title="Upload file" onSellect={(val) => { actionListFile(val) }} />
                                                                    </div>
                                                                    <div className="w-100">
                                                                        <div className="row mt-2">
                                                                            <div className="col-7">
                                                                                <UserDropDown apiname={"posts_file_category?orderby=title-ASC&parent_id=0"} placeholder="Chọn danh mục file" formik={formik}
                                                                                    colvalue={'posts_file_category.id'} colname={'posts_file_category.title'} name='file_category' reload={reloadCate}
                                                                                    onChange={async (e) => {
                                                                                        await formik.setFieldValue('file_category', e)
                                                                                    }}></UserDropDown>
                                                                            </div>
                                                                            <div className="col-5">
                                                                                <button type="button" className="btn btn-sm btn-success" onClick={() => setModalCate(true)}>Thêm danh mục</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-100 list-file mt-3">
                                                                        {listFile && listFile.map(function (itFile, i) {
                                                                            return <div className={"col-12 itemFile item-" + i} key={i}>
                                                                                <div className="input-group mb-3">
                                                                                    <input type="text" className="form-control" placeholder="File" defaultValue={itFile.filename} />
                                                                                    <div className="input-group-append">
                                                                                        <button type="button" className="btn btn-sm btn-danger" onClick={() => deleteItem(i)}>Xóa</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        })}
                                                                    </div>
                                                                </div>
                                                                :
                                                                <></>
                                                            }
                                                            <div className="w-100 d-flex mt-2">
                                                                <button type="submit" className="btn btn-sm btn-success ml-2">
                                                                    {formik.values.id && formik.values.id > 0 ? "Cập nhật" : 'Đăng tin'}
                                                                </button>
                                                                <button type="button" className="btn btn-sm btn-outline-secondary ml-2" onClick={() => setModalFormPost(false)}>
                                                                    Hủy bỏ
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                        : ""
                                    }
                                </div>
                            </>
                        }

                        <div className="w-100 mt-3 time-line">
                            {listPost.map((item, i) => {
                                return <div className="dashboard-summery-one mt-1 mb-3" key={i}>
                                    <div className="w-100 d-flex align-items-center user-info">
                                        {item.avatar ?
                                            <img src={item.avatar} alt="" className="avatar" />
                                            :
                                            <img src={item.typeaccount === 2 ? avatar_student : (item.gender === 1 ? men_teacher : girl_teacher)} alt="" className="avatar" />
                                        }
                                        <div className="in-right ml-2">
                                            <p className="user-fullname">
                                                <b>{item.fullname}</b>
                                                <span className="ml-2 mr-2"><i className="fas fa-arrow-right"></i> </span>
                                                <b>{item.class_name}</b>
                                            </p>
                                            <small className="time">
                                                Lúc {moment(new Date(item.datecreate * 1000)).format('H:m DD/MM/YYYY')}
                                            </small>
                                        </div>
                                        {item.user_id == user.data.id ?
                                            <div className="group-action">
                                                <button className="btn btn-action btn-sm" type="button" onClick={() => {
                                                    if (toogleAction && toogleAction === item.id) {
                                                        setToogleAction(null)
                                                    } else {
                                                        setToogleAction(item.id)
                                                    }
                                                }}>
                                                    <span className="fas fa-ellipsis-h"></span>
                                                </button>
                                                <div className={"action-content border shadow rounded " + (toogleAction && toogleAction === item.id ? 'd-block' : 'd-none')}>
                                                    <div className="w-100 border-bottom">
                                                        <button className="btn btn-sm" type="button" onClick={() => actionEdit(item)}>Sửa</button>
                                                    </div>
                                                    <div className="w-100">
                                                        <button className="btn btn-sm" type="button" onClick={() => deletePost(item.id)}>Xóa</button>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                        }
                                    </div>
                                    <div className="w-100 mt-2 post-content">
                                        {parse(item.post_content)}
                                    </div>
                                    {item.list_file ?
                                        <div className="w-100 mt-2 post-file">
                                            {item.list_file.map((file, ix) => {
                                                return <div className="pl-2 pr-2 mt-1 file-item border rounded shadow-sm" key={ix}>
                                                    <a className="w-100 d-flex" href={file.file_url} target="_blank">
                                                        {file.file_category_name ? <span className="d-block border-right text-warning pr-2">{file.file_category_name + ''}</span> : ''}
                                                        <span className="pl-1 pt-1 pb-1"> {file.file_title}</span>
                                                        <span className="fas fa-download"></span>
                                                    </a>
                                                </div>
                                            })}
                                        </div>
                                        : ""}

                                    <PostsComment post_id={item.id} post_info={item} actionLike={(idpost) => actionLike(idpost, item.youlike)} />
                                </div>
                            })}
                        </div>

                        <Modal show={modalCate} onHide={() => setModalCate(false)} animation={true} size="md">
                            <Modal.Header closeButton>
                                <Modal.Title>Quản lý danh mục file</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form className="new-added-form" method="post" onSubmit={formCate.handleSubmit}>
                                    <div className="row">
                                        <div className="col-12 form-group">
                                            <label>Tên danh mục<span className="text-danger">*</span></label>
                                            <input type="text" placeholder="" className="form-control height32" {...formCate.getFieldProps("title")} autoComplete="off" />
                                            <AlertErrorForm formik={formCate} name="title"></AlertErrorForm>
                                        </div>
                                        <div className="col-12 form-group">
                                            <label>Danh mục cha</label>
                                            <UserDropDown apiname={"posts_file_category?orderby=title-ASC"} placeholder="Chọn danh mục cha"
                                                colvalue={'posts_file_category.id'} colname={'posts_file_category.title'} name='parent_id' formik={formCate}
                                                onChange={async (e) => {
                                                    await formCate.setFieldValue('parent_id', e)
                                                }}></UserDropDown>
                                        </div>
                                        <div className="col-12 form-group mg-t-8">
                                            <button type="submit" disabled={formCate.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">Lưu lại</button>
                                            <button type="reset" onClick={formCate.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                                        </div>
                                    </div>
                                </form>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>

            <div className="d-none d-sm-block listclass-right wrapper-listclass">
                <div className="list-item">
                    <div className="class-item"><button className={"btn btn-sm " + (classChoice === 0 ? 'item-active' : '')} type="button" onClick={() => setClassChoice(0)}>Tất cả</button></div>
                    {listClass && listClass.map((item, i) => {
                        return <div className="class-item" key={i}>
                            <button className={"btn btn-sm " + (item.id === classChoice ? 'item-active' : '')} type="button" onClick={() => setClassChoice(item.id)}>{item.title}</button>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
};