import React, { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import AuthPage from "./auth";
import Loading from "./component/Loading";
import ExamOfExercise from "./check/examofexercise";
import ExamOfNotebook from "./check/examofnotebook";
import HomePage from "./home";
import ProfilePage from "./profile";
import Teaching from "./calendar/teaching";
import TimeTable from "./calendar/timetable";
import ListClassPage from "./lop-hoc/ListClass";
import ListKhoaHoc from "./khoa-hoc/ListKhoaHoc";
import ListStudentsPage from "./hoc-sinh/ListStudents";
import ListSchoolsPage from "./list/ListSchools";
import ListSubjectsPage from "./list/ListSubjects";
import ListUsersPage from "./tai-khoan/ListUsers";
import ListYearsPage from "./list/ListYears";

import FormClassPage from "./lop-hoc/FormClass";
import FormGradesPage from "./khoa-hoc/FormGrades";
import FormStudentsPage from "./hoc-sinh/FormStudents";
import FormSubjectsPage from "./form/FormSubjects";
import FormUsersPage from "./tai-khoan/FormUsers";
import FormYearsPage from "./form/FormYears";
import FormSchoolsPage from "./form/FormSchools";
import ListTeachersPage from "./list/ListTeacher";
import FormTeachersPage from "./form/FormTeachers";
import ImportData from "./setting/import-data";
import ListExtracPage from "./list/ListExtrac";
import FormExtracPage from "./form/FormExtrac";
import ExtracPage from "./check/extrac";
import FormProfilePage from "./profile/FormProfile";
import FormPasswordPage from "./profile/FormPassword";
import ReportAttendsPage from "./report/attends";
import ReportTimeKeepingPage from "./report/timekeeping";
import ListRolesPage from "./list/ListRoles";
import FormRolesPage from "./form/FormRoles";
import ListGroupExtracPage from "./list/ListGroupExtrac";
import FormGroupExtracPage from "./form/FormGroupExtrac";
import ListPartsPage from "./list/ListParts";
import FormPartsPage from "./form/FormParts";
import CreateTimeTable from "./setting/create-timetable";
import ListTestsPage from "./examination/exam-test/ListTests";
import DetailExam from "./examination/examination/DetailExam";
import MarkTests from "./examination/marktests/MarktestList";
import MarkTestsStudent from "./examination/marktests/MarkTestsStudent";
import MarkTestView from "./examination/marktests/MarkTestView";
import QuestionsPage from "./examination/questions";

import LunchPage from "./check/lunch";
import FormGeneralSettingPage from "./setting/general";
import RegistryLunch from "./internal/registry-lunch";
import HistoryAction from "./internal/history-action";
import HistoryLogin from "./internal/history-login";
import ReportLunchPage from "./report/lunch";
import ReportCheckPage from "./report/check";
import ListQuestion from "./examination/exam-questions/ListQuestion";
import FormQuestionBank from "./examination/exam-questions/FormQuestionBank";
import FormAddTests from "./examination/tests";

import HistoryActionUser from "./internal/history-action-user";
import ReportLunchMonthPage from "./report/lunch-month";
import DiaryPage from "./report/diary";
import ReportActivityPage from "./report/activity";
import ReportAllSchoolPage from "./report/allschool";
import ListParamsPage from "./examination/ListParams";
import FormParamsPage from "./examination/param/FormParams";
import UploadQuestion from "./examination/UploadQuestion";
import DetailStudentsPage from "./details/DetailsStudents";
import DiligenPage from "./report/diligence";
import TreePage from "./examination/exam-tree/Tree";
import ExaminationPage from "./examination/examination";
import DetailExamView from "./examination/examination/DetailExamView";
import ReportExam from "./examination/examination/ReportExam";
import ReportExamDetail from "./examination/examination/ReportExamDetail";
import ReportExamQuestions from "./examination/examination/ReportExamQuestions";
import ReportExamQuestionsView from "./examination/examination/ReportExamQuestionsView";
import ListTeachersManager from "./list/ListTeacherManager";
import FormAddTeachersManager from "./form/FormAddTeacherManager";
import ReportAttendMonthPage from "./report/attends-month";

import ListTags from "./examination/tags/ListTags";
import FormTags from "./examination/tags/FormTags";
// import TomoImageEditor from "./TomoImageEditor";
import TestAzota from "./examination/TestAzota";
import FormTestAzota from "./examination/TestAzota/FormTestAzota";
import DetailExamAzota from "./examination/examination/DetailExamAzota";
import DetailExamViewAzota from "./examination/examination/DetailExamViewAzota";
import MarkTestViewAzota from "./examination/marktests/MarkTestViewAzota";
import ListBusManager from "./bus/ListBusManager";
import FormBusClass from "./bus/FormBusClass";
import BusCheckin from "./bus/BusCheckin";
import ListBaiHoc from "./khoa-hoc/ListBaiHoc";
import FormBaiHoc from "./khoa-hoc/FormBaiHoc";
import FormManHinh from "./khoa-hoc/FormManHinh";
import ListManHinh from "./khoa-hoc/ListManHinh";
import ListLopHoc from "./khoa-hoc-cua-toi/ListLopHoc";
import ListBaiHocCuaToi from "./khoa-hoc-cua-toi/ListBaiHocCuaToi";
import BaiHocChiTiet from "./khoa-hoc-cua-toi/bai-hoc-chi-tiet";
import ListRacing from "./tomo-racing/ListRacing";
import RacingDetail from "./tomo-racing/RacingDetail";
import RacingDetailView from "./tomo-racing/RacingDetailView";
import ListStudentsInClass from "./hoc-sinh/ListStudentsInClass";
import ListTestsRacing from "./tomo-racing/ListTestsRacing";
import FormRacing from "./tomo-racing/FormRacing";
import DiemDanhTietHoc from "./diem-danh/DiemDanhTietHoc";
import ListBookExercise from "./books-exercise/ListBookExercise";
import FormBookExercise from "./books-exercise/FormBookExercise";
import { useState } from "react";
import ClientBooksExView from "./books-exercise/ClientBooksExView";
import ClientBooksExDetail from "./books-exercise/ClientBooksExDetail";
import ListTestsBook from "./books-exercise/ListTestsBook";
import QrCodeGender from "./qrcode/QrCodeGender";
import ListChuongTrinhHoc from "./chuong-trinh-hoc/ListChuongTrinhHoc";
import ListTreeChuongTrinh from "./chuong-trinh-hoc/ListTreeChuongTrinh";
import FormTreeChuongTrinh from "./chuong-trinh-hoc/FormTreeChuongTrinh";
import FormChuongTrinh from "./chuong-trinh-hoc/FormChuongTrinh";
import CodeEditor from "./codeEditor/CodeEditor";
import XuatDiemDanh from "./diem-danh/XuatDiemDanh";
import ListKhoaHocRc from "./tomo-racing/ListKhoaHocRc";
import ListDanhMuc from "./danhmuc/ListDanhMuc";
import FormDanhMuc from "./danhmuc/FormDanhMuc";
import ReportExamRacing from "./tomo-racing/ReportExamRacing";
import ReportRacing from "./tomo-racing/ReportRacing";


function App(props) {
  const { user } = useSelector((state) => state.auth);
  const isAuthorized = user != null;
  const now = parseInt(new Date().getTime() / 1000) + 1 * 60 * 60;

  useEffect(function () {
    let web_url = window.location.hostname
    let pathname = window.location.pathname
    if (web_url === 'course.tomocode.net') window.location.href = 'https://course.tomotech.vn' + pathname
  }, [user])

  const Checker = () => {
    let url = window.location.href
    if (url) {
      return <Redirect to={'/auth/login?redirect_url=' + url} />
    }

    return <Redirect to="/auth/login" />
  }
  return (
    <BrowserRouter basename={""}>
      <Suspense fallback={<Loading color="secondary" />} >
        <Switch>
          <Route path="/auth">
            <AuthPage></AuthPage>
          </Route>
          {!isAuthorized ? <>
            <Checker />
          </>
            : (
              <>
                {user.expires > now ? <>
                  <Route exact path="/"><Redirect to="/dashboard" /></Route>
                  <Route path="/list-class"><ListClassPage></ListClassPage></Route>
                  <Route path="/list-extrac"><ListExtracPage></ListExtracPage></Route>
                  <Route path="/list-group-extrac"><ListGroupExtracPage></ListGroupExtracPage></Route>
                  <Route path="/khoa-hoc"><ListKhoaHoc></ListKhoaHoc></Route>
                  <Route path="/list-schools"><ListSchoolsPage></ListSchoolsPage></Route>
                  <Route path="/list-students"><ListStudentsPage></ListStudentsPage></Route>
                  <Route path="/list-subjects"><ListSubjectsPage></ListSubjectsPage></Route>
                  <Route path="/list-teachers"><ListTeachersPage></ListTeachersPage></Route>
                  <Route path="/list-teachers-manager"><ListTeachersManager></ListTeachersManager></Route>
                  <Route path="/add-teachers-manager"><FormAddTeachersManager></FormAddTeachersManager></Route>
                  <Route path="/list-users"><ListUsersPage></ListUsersPage></Route>
                  <Route path="/list-years"><ListYearsPage></ListYearsPage></Route>
                  <Route path="/list-roles"><ListRolesPage></ListRolesPage></Route>
                  <Route path="/list-parts"><ListPartsPage></ListPartsPage></Route>
                  <Route path="/list-exercise"><ListTestsPage type={3} /></Route>
                  <Route path="/list-tests"><ListTestsPage type={2} /></Route>
                  <Route path="/list-param"><ListParamsPage /></Route>
                  <Route path="/tree"><TreePage /></Route>

                  <Route path="/add-class"><FormClassPage></FormClassPage></Route>
                  <Route path="/add-extrac"><FormExtracPage></FormExtracPage></Route>
                  <Route path="/add-group-extrac"><FormGroupExtracPage></FormGroupExtracPage></Route>
                  <Route path="/add-khoahoc"><FormGradesPage></FormGradesPage></Route>
                  <Route path="/add-schools"><FormSchoolsPage></FormSchoolsPage></Route>
                  <Route path="/add-students"><FormStudentsPage></FormStudentsPage></Route>
                  <Route path="/add-subjects"><FormSubjectsPage></FormSubjectsPage></Route>
                  <Route path="/add-teachers"><FormAddTeachersManager></FormAddTeachersManager></Route>
                  <Route path="/add-users"><FormUsersPage></FormUsersPage></Route>
                  <Route path="/add-years"><FormYearsPage></FormYearsPage></Route>
                  <Route path="/add-roles"><FormRolesPage></FormRolesPage></Route>
                  <Route path="/add-parts"><FormPartsPage></FormPartsPage></Route>
                  <Route path="/add-at_exam_questions"><FormQuestionBank /></Route>
                  <Route path="/add-tests"><FormAddTests /></Route>
                  <Route path="/add-param"><FormParamsPage /></Route>

                  <Route path="/update-profile"><FormProfilePage></FormProfilePage></Route>
                  <Route path="/change-password"><FormPasswordPage></FormPasswordPage></Route>
                  <Route path="/examination"><ExaminationPage /></Route>
                  <Route path="/examination-detail/:id"><DetailExam></DetailExam></Route>
                  <Route path="/examination-detail-azota/:id"><DetailExamAzota></DetailExamAzota></Route>
                  <Route path="/examination-report"><ReportExam /></Route>
                  <Route path="/examination-report-detail/:id"><ReportExamDetail /></Route>
                  <Route path="/examination-report-question/:id"><ReportExamQuestions /></Route>
                  <Route path="/examquestion-view/:idtest/:id"><ReportExamQuestionsView /></Route>
                  <Route path="/examination-view/:id"><DetailExamView /></Route>
                  <Route path="/examination-azota-view/:id"><DetailExamViewAzota /></Route>

                  <Route path="/examination-report-racing/:id"><ReportExamRacing /></Route>
                  <Route path="/racing-report/:id"><ReportRacing /></Route>


                  <Route path="/edit-param/:id"><FormParamsPage /></Route>
                  <Route path="/edit-class/:id"><FormClassPage></FormClassPage></Route>
                  <Route path="/edit-extrac/:id"><FormExtracPage></FormExtracPage></Route>
                  <Route path="/edit-group-extrac/:id"><FormGroupExtracPage></FormGroupExtracPage></Route>
                  <Route path="/edit-khoahoc/:id"><FormGradesPage></FormGradesPage></Route>
                  <Route path="/edit-schools/:id"><FormSchoolsPage></FormSchoolsPage></Route>
                  <Route path="/edit-students/:id"><FormStudentsPage></FormStudentsPage></Route>
                  <Route path="/edit-subjects/:id"><FormSubjectsPage></FormSubjectsPage></Route>
                  <Route path="/edit-teachers/:id"><FormTeachersPage></FormTeachersPage></Route>
                  <Route path="/edit-users/:id"><FormUsersPage></FormUsersPage></Route>
                  <Route path="/edit-years/:id"><FormYearsPage></FormYearsPage></Route>
                  <Route path="/edit-roles/:id"><FormRolesPage></FormRolesPage></Route>
                  <Route path="/edit-parts/:id"><FormPartsPage></FormPartsPage></Route>
                  <Route path="/edit-tests/:id"><FormAddTests /></Route>
                  <Route path="/edit-at_exam_questions/:id"><FormQuestionBank /></Route>
                  {/* <Route path="/attends"><AttendsPage title="Điểm danh tiết học"></AttendsPage></Route> */}
                  <Route path="/lunch"><LunchPage></LunchPage></Route>
                  <Route path="/sleep"><ExtracPage idgroup={3} title="Điểm danh ngủ trưa"></ExtracPage></Route>
                  <Route path="/extrac"><ExtracPage idgroup={2} title="Điểm danh ngoại khoá"></ExtracPage></Route>
                  <Route path="/special"><ExtracPage idgroup={4} title="Điểm danh lớp chuyên"></ExtracPage></Route>
                  {/*<Route path="/special"><AttendsPage idgroup={4} title="Điểm danh lớp chuyên" ></AttendsPage></Route>*/}
                  <Route path="/bus"><BusCheckin /></Route>
                  <Route path="/bus_manager"><ListBusManager></ListBusManager></Route>
                  <Route path="/add-bus_class"><FormBusClass></FormBusClass></Route>
                  <Route path="/edit-bus_class/:id"><FormBusClass></FormBusClass></Route>
                  <Route path="/examofnotebook"><ExamOfNotebook></ExamOfNotebook></Route>
                  <Route path="/examofexercise"><ExamOfExercise></ExamOfExercise></Route>
                  <Route path="/timetable"><TimeTable></TimeTable></Route>
                  <Route path="/teaching"><Teaching></Teaching></Route>
                  <Route path="/import-data"><ImportData></ImportData></Route>
                  <Route path="/create-timetable"><CreateTimeTable></CreateTimeTable></Route>
                  <Route path="/questions-in-test/:id"><QuestionsPage></QuestionsPage></Route>
                  <Route path="/report-check"><ReportCheckPage /></Route>
                  <Route path="/report-lunch"><ReportLunchPage /></Route>
                  <Route path="/report-lunch-month"><ReportLunchMonthPage /></Route>
                  <Route path="/report-attends"><ReportAttendsPage></ReportAttendsPage></Route>
                  <Route path="/report-timekeeping"><ReportTimeKeepingPage></ReportTimeKeepingPage></Route>
                  <Route path="/report-diary"><DiaryPage /></Route>
                  <Route path="/report-activity"><ReportActivityPage /></Route>
                  <Route path="/report-allschool"><ReportAllSchoolPage /></Route>
                  <Route path="/report-attends-month"><ReportAttendMonthPage /></Route>

                  <Route path="/general-setting"><FormGeneralSettingPage /></Route>
                  <Route path="/registry-lunch"><RegistryLunch /></Route>
                  <Route path="/history-action"><HistoryAction /></Route>
                  <Route path="/history-action-user"><HistoryActionUser /></Route>
                  <Route path="/history-login"><HistoryLogin /></Route>

                  <Route path="/exam_question"><ListQuestion /></Route>
                  <Route path="/marktests"><MarkTests /></Route>
                  <Route path="/marktests-student/:id"><MarkTestsStudent /></Route>
                  <Route path="/marktests-view/:id"><MarkTestView /></Route>
                  <Route path="/marktests-azota-view/:id"><MarkTestViewAzota /></Route>


                  <Route path="/dashboard"><HomePage></HomePage></Route>
                  <Route path="/posts/:id"><HomePage></HomePage></Route>
                  <Route path="/my-profile"><ProfilePage></ProfilePage></Route>
                  <Route path="/detail-students/:id"><DetailStudentsPage></DetailStudentsPage></Route>
                  <Route path="/report-diligen"><DiligenPage></DiligenPage></Route>
                  <Route path="/upload-question"><UploadQuestion /></Route>

                  <Route path="/teachers-manager"><ListTeachersManager></ListTeachersManager></Route>
                  <Route path="/add-teachers_manager"><FormAddTeachersManager /></Route>
                  <Route path="/edit-teachers_manager/:id"><FormAddTeachersManager></FormAddTeachersManager></Route>

                  <Route path="/list-tags"><ListTags /></Route>
                  <Route path="/add-tags"><FormTags /></Route>
                  <Route path="/edit-tags/:id"><FormTags /></Route>

                  {/* <Route path="/edit-image"><TomoImageEditor /></Route> */}

                  <Route path="/list-tests-azota"><TestAzota /></Route>
                  <Route path="/add-test-azota"><FormTestAzota /></Route>
                  <Route path="/edit-tests-azota/:id"><FormTestAzota /></Route>

                  <Route path="/danh-sach-bai-hoc/:id"><ListBaiHoc /></Route>
                  <Route path="/add-khoahoc_baihoc"><FormBaiHoc /></Route>
                  <Route path="/edit-khoahoc_baihoc/:id"><FormBaiHoc /></Route>
                  <Route path="/edit-khoahoc_param/:id"><FormBaiHoc /></Route>
                  <Route path="/add-manhinh"><FormManHinh /></Route>
                  <Route path="/edit-manhinh/:id"><FormManHinh /></Route>
                  <Route path="/man-hinh"><ListManHinh /></Route>

                  <Route path="/khoa-hoc-cua-toi"><ListLopHoc /></Route>
                  <Route path="/khoa-hoc-cua-toi/:alias"><ListBaiHocCuaToi /></Route>
                  <Route path="/bai-hoc-chi-tiet/:alias"><BaiHocChiTiet /></Route>

                  <Route path="/tomo-racings/:id"><ListRacing /></Route>
                  <Route path="/tomo-racing"><ListKhoaHocRc /></Route>
                  <Route path="/tomo-racing-detail/:id"><RacingDetail /></Route>
                  <Route path="/tomo-racing-view/:id"><RacingDetailView /></Route>
                  <Route path="/list-tomo-racing"><ListTestsRacing /></Route>
                  <Route path="/add-tomo-racing"><FormRacing /></Route>
                  <Route path="/edit-racing/:id"><FormRacing /></Route>
                  <Route path="/list-danhmuc"><ListDanhMuc /></Route>
                  <Route path="/add-list-danhmuc"><FormDanhMuc /></Route>
                  <Route path="/edit-list-danhmuc/:id"><FormDanhMuc /></Route>

                  <Route path="/attends"><DiemDanhTietHoc /></Route>
                  <Route path="/xuat-diem-danh"><XuatDiemDanh /></Route>

                  <Route path="/list-books-exercise"><ListBookExercise /></Route>
                  <Route path="/add-books-exercise"><FormBookExercise /></Route>
                  <Route path="/edit-books-exercise/:id"><FormBookExercise /></Route>
                  <Route path="/books/:code"><ClientBooksExDetail /></Route>
                  <Route path="/books-exercise/:id"><ClientBooksExView /></Route>
                  <Route path="/books-exercise"><ListTestsBook /></Route>

                  <Route path="/demo-ide"><ListTestsBook /></Route>

                  <Route path="/tao-qrcode"><QrCodeGender /></Route>

                  <Route path="/list-books"><ListChuongTrinhHoc /></Route>
                  <Route path="/add-books"><FormChuongTrinh /></Route>
                  <Route path="/edit-books/:id"><FormChuongTrinh /></Route>
                  <Route path="/list-books-tree"><ListTreeChuongTrinh /></Route>
                  <Route path="/add-books_tree"><FormTreeChuongTrinh /></Route>
                  <Route path="/edit-books_tree/:id"><FormTreeChuongTrinh /></Route>
                  <Route path="/code-editor"><CodeEditor /></Route>

                  <Route path="/list-students-in-class/:id"><ListStudentsInClass></ListStudentsInClass></Route>

                </> : <Loading color="secondary" expire={user.expires <= now} />}
              </>
            )}
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
