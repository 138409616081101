
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../../layout/Akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../../component/AlertErrorForm";
import Spinner from "../../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../../configs/Api";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import UserDropDown from "../../component/UserDropDown";

export default function FormTags() {
    const { user } = useSelector((state) => state.auth);
    const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);

    const defaultdetail = {
        school_id, author_id: user.data.id, title: '', status: 1, idtype: 1,
    }
    useEffect(
        function () {
            if (id > 0) {
                let mounted = true;
                Axios.get(URL_LIST_ALL + "at_tags_questions/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            if (mounted) {
                                setDetail(res.data.data);
                            }
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [user, id, refresh]
    );
    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            title: Yup.string().trim().nullable().required('Nhập tên Thông số khảo thí').max(250, "Độ dài không quá 250 ký tự")
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'at_tags_questions/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật Thông số khảo thí thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                Axios.post(URL_LIST_ALL + 'at_tags_questions', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới Thông số khảo thí thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    return (
        <AkkhorLayout idrole="list-param">
            <div className="card height-auto mt-5">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>{id > 0 ? "Sửa thẻ câu hỏi" : "Thêm mới thẻ câu hỏi"}</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">

                            <div className="col-xl-3 col-xl-3 col-lg-6 col-12 form-group">
                                <label>Tên thẻ <span className="text-danger">*</span></label>
                                <input type="text" placeholder="Tên thẻ" className="form-control height32" {...formik.getFieldProps("title")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                            </div>

                            <div className="col-xl-3 col-lg-3 col-sm-6 col-12 form-group">
                                <label>Khối <span className="text-danger">*</span></label>
                                <UserDropDown apiname={"grades?orderby=title-ASC&school_id=" + school_id} placeholder="Chọn khối" colvalue={"grades.id"} colname={"grades.title"} formik={formik} name="grade_id"
                                    onChange={async (e) => { await formik.setFieldValue("grade_id", e); }}
                                ></UserDropDown>
                                <AlertErrorForm formik={formik} name="grade_id"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-sm-6 col-12 form-group">
                                <label> Môn học <span className="text-danger">*</span></label>
                                <UserDropDown apiname={"subjects?key=&page=1&istree=1&orderby=title-ASC&limit=50&school_id=" + school_id + "&grade_id=" + formik.values.grade_id} placeholder="Chọn môn học" colvalue={"subjects.id"} colname={"subjects.title"} formik={formik}
                                    name="subject_id" onChange={async (e) => { await formik.setFieldValue("subject_id", e); }}
                                ></UserDropDown>
                                <AlertErrorForm formik={formik} name="subject_id"></AlertErrorForm>
                            </div>

                            <div className="col-xl-3 col-xl-3 col-lg-6 col-12 form-group">
                                <label>Trạng thái</label>
                                <select className="form-control height32" {...formik.getFieldProps("status")}>
                                    <option value="0">Khoá</option>
                                    <option value="1">Hoạt động</option>
                                </select>
                            </div>

                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu Thông số khảo thí</>}</button>
                                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
