import React, { useState } from "react";
import MyEditor2 from "../../../component/MyEditor2";

export default function QChooseOne(props) {
    const {onAnswers,answers,maxNumber,setMaxNumber} = props;
    const [keywords] = useState(answers && answers.list?answers.list:[]);//
   
    const getList=()=>{
        let list = [];
        for(let i=1; i<= maxNumber; i++) {
            list.push(i)
        }
        return list
    }

    const onChange = (value,i) => {
        let newList = [...keywords]
        newList[i] =value
        onAnswers({ ...answers,list: newList})
    }

    const onCheckCorrect=(e)=>{
        onAnswers({ ...answers,correct:parseInt(e.target.value)})
    }

    return (<div className="col-12 form-group">
        <button type="button" className="btn btn-sm btn-info mb-3" onClick={()=>setMaxNumber(maxNumber+1)}>Thêm câu trả lời</button>
        {getList().map((item,i)=><div key={i} className="mb-2 ">
            <label>Câu trả lời {i+1} </label>
            <div className="d-flex align-items-center">
            <div className="w-100"><MyEditor2 height={120} initialValue={keywords[i]?keywords[i]:''} onValue={(e)=>onChange(e,i)} /></div>
            <input type="radio" name="correct"  value={i}  checked={answers.correct===i}  className="ml-2 " onChange={onCheckCorrect}/> <span className="ml-2 ">Đúng</span>
            </div>
        </div>)}
    </div>);
}
