import React from "react";
import SRList from "../../component/List";

export default function ListAllSchool1(props) {
  const {moreSearch} = props;
  
  const title = 'Báo cáo điểm danh toàn trường'
  const columns = [
    { name: "id", label: "ID" ,options: { filter: true,sort: false }},
    { name: "title", label: "Lớp" ,options: { filter: true,sort: false }},
    { name: "total_student", label: "Tổng số học sinh", options: { filter: true,sort: true } },
    { name: "total_2", label: "Đi muộn",options: { filter: true,sort: true } },
    { name: "total_3", label: "Nghỉ có phép" ,options: { filter: false,sort: true , }},
    { name: "total_4", label: "Nghỉ không phép" ,options: { filter: false,sort: true , }},
  ];
  return (
    <>
      <SRList name="report_attends_class" moresearch={moreSearch} params={moreSearch?'begin='+moreSearch.begin+'&end='+moreSearch.end+'&type='+moreSearch.type:''} title={title} defaultLimit={200} serverSide={false} columns={columns} noAction={true}></SRList>
    </>
  );
}
