import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { URL_LIST_ALL } from "../configs/Api";
import ShowQuestion from "../examination/questions/ShowQuestion";
import parse from 'html-react-parser'
import Spinner from "./Spinner";
import GenderCode from "./GenderCode";


export default function PreviewQuestion(props) {
  const { index, id } = props;
  const { user } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const [questionDetail, setQuestionDetail] = useState({});
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    let mounted = true;
    if (show) {
      setLoading(true)
      Axios.get(URL_LIST_ALL + "at_exam_questions/" + id, user.config).then((res) => {
        if (res.data && res.data.status === "success") {
          if (mounted) {
            const data = res.data.data
            const _answer = data.decode.answers
            const _correct = data.decode.corrects
            setAnswers({ list: _answer, correct: _correct })
            setQuestionDetail(res.data.data)
          }
        } else console.log(res.data.message);
        setLoading(false)
      }).catch((err) => { console.log(err); setLoading(false) });
    }


    return () => (mounted = false);
  }, [user, id,show]);

  return (
    <>
      <a href={"#title" + index} className="text-info" onClick={() => setShow(true)}>Xem</a>
      <Modal show={show} onHide={() => setShow(false)} animation={true} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Xem trước câu hỏi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? 
            <div className="d-flex justify-content-center"><Spinner /></div>
          :
            <div className="d-flex justify-content-start align-items-center row p-2">
              <div className="w-100 mb-2 text-pre-wrap"><GenderCode text_string={questionDetail.title+""} /></div>
              <div className="w-100">
                <ShowQuestion type="question" item={questionDetail} answers={answers} />
              </div>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>Đóng</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
