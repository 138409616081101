import React, { useEffect, useState } from "react";
import AkkhorLayout from "../../layout/Akkhor";
import { useLocation, useParams, Redirect } from "react-router-dom";
import PhieuBaiTapStep1 from "./PhieuBaiTapStep1";
import PhieuBaiTapStep2 from "./PhieuBaiTapStep2";
import PhieuBaiTapStep3 from "./PhieuBaiTapStep3";

import BaiKiemTraStep1 from "./BaiKiemTraStep1";
import BaiKiemTraStep2 from "./BaiKiemTraStep2";
import BaiKiemTraStep3 from "./BaiKiemTraStep3";
import { useSelector } from "react-redux";
import { URL_LIST_ALL } from "../../configs/Api";
import Axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import SpinnerCenter from "../../component/SpinnerCenter"

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function FormAddTests(props) {
  let query = useQuery();
  const { user } = useSelector((state) => state.auth);
  const { id } = useParams();
  const [runFinish, setRunFinish] = useState(false);
  const [step, setStep] = useState(1);
  const [detail, setDetail] = useState(null);
  const [type, setType] = useState(parseInt(query.get("type")));

  useEffect(() => {
    if (id > 0) {
      let mounted = true;
      Axios.get(URL_LIST_ALL + "at_exam_tests/" + id, user.config)
        .then(async (res) => {
          if (res.data.status === "success") {
            if (mounted) {
              let _detail = { ...res.data.data };
              _detail.total_point = _detail.total_point ? _detail.total_point : 10
              setType(_detail.type)
              setDetail(_detail);
            }
          } else console.log(res.data.message);
        })
        .catch((err) => console.log(err));
      return () => (mounted = false);
    }
  }, [user, id]);

  const addTest = (values) => {
    if (id > 0) {
      Axios.put(URL_LIST_ALL + 'at_exam_tests/' + id, values, user.config).then(res => {
        // console.log(res);
        if (res.data.status === "success") {
          setRunFinish(true)
          confirmAlert({
            title: 'Thành công',
            message: 'Bạn cập nhật ' + (type === 3 ? ' phiếu bài tập' : 'bài kiểm tra') + ' thành công',
            buttons: [{ label: 'OK' }]
          });
        } else {
          confirmAlert({
            title: 'Lỗi',
            message: res.data.message,
            buttons: [{ label: 'OK' }]
          });
        }
      }).catch(err => console.log(err));
    } else {
      values.type=type;
      //console.log(values);
      Axios.post(URL_LIST_ALL + 'at_exam_tests', values, user.config).then(res => {
        if (res.data.status === "success") {
          setRunFinish(true)
          confirmAlert({
            title: 'Thành công',
            message: 'Bạn thêm mới ' + (type === 3 ? ' phiếu bài tập' : 'bài kiểm tra') + ' thành công',
            buttons: [{ label: 'OK' }]
          });
        } else {
          confirmAlert({
            title: 'Lỗi',
            message: res.data.message,
            buttons: [{ label: 'OK' }]
          });
        }
      }).catch(err => console.log(err));
    }

  }
  return (
    <AkkhorLayout idrole="list-tests">
      {runFinish && <Redirect to={type === 3 ? "/list-exercise" : "/list-tests"} />}
      <div className="card height-auto mt-5">
        <div className="card-body ">
          <div className="heading-layout1">
            <div className="item-title">
              <h3>{id > 0 ? "Sửa" : "Tạo"} {type === 3 ? " phiếu bài tập" : "bài kiểm tra"}</h3>
            </div>
          </div>
          {(type > 0) ? (
            <>
              {type === 3 ? (
                <>
                  {step === 1 && <PhieuBaiTapStep1 detail={detail} nextStep={(e) => { setDetail(e); setStep(step + 1); }} />}
                  {(step === 2 && detail) && <PhieuBaiTapStep2 detail={detail} nextStep={(e) => { setDetail(e); setStep(step + 1); }} previewStep={(e) => setStep(step - 1)} />}
                  {(step === 3 && detail) && <PhieuBaiTapStep3 detail={detail} nextStep={addTest} previewStep={(e) => setStep(step - 1)} />}
                </>
              ) : (
                <>
                  {step === 1 && <BaiKiemTraStep1 detail={detail} nextStep={(e) => { setDetail(e); setStep(step + 1); }} />}
                  {(step === 2 && detail) && <BaiKiemTraStep2 detail={detail} nextStep={(e) => { setDetail(e); setStep(step + 1); }} previewStep={(e) => setStep(step - 1)} />}
                  {(step === 3 && detail) && <BaiKiemTraStep3 detail={detail} nextStep={addTest} previewStep={(e) => setStep(step - 1)} />}
                </>
              )}
            </>
          ) : (<SpinnerCenter />)}
        </div>
      </div>
    </AkkhorLayout>
  );
}
