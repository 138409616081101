import React, { useRef, useState } from "react";
import { Editor } from '@tinymce/tinymce-react';
import { URL_LIST_ALL } from "../configs/Api";
import axios from "axios";
import { useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import CodeMirror from '@uiw/react-codemirror';
import { langs } from '@uiw/codemirror-extensions-langs';
import { githubLightInit } from "@uiw/codemirror-theme-github";


export default function MyEditor2(props) {
  const editorRef = useRef(null);
  const inputRef = useRef(null);
  const { height, initialValue, onValue,placeholder,textImage } = props;
  const [value, setValue] = useState(initialValue);
  const [type, setType] = useState(1);
  const { user } = useSelector((state) => state.auth);
  const [loadingImage, setLoadingImage] = useState(false)
  const uploadImage = (event) => {
    const fileAudio = event.target.files[0]
    if (fileAudio) {
      let data = new FormData()
      data.append("type", "s3")
      data.append("name", "file")
      data.append("file", fileAudio)
      data.append("filetype", fileAudio.type)
      let file_size = fileAudio.size;
      if (file_size > 3145728) {
        confirmAlert({
          title: 'Lỗi',
          message: "File không được lớn hơn 3 Mb",
          buttons: [{ label: 'OK' }]
        })
        return
      } else {
        setLoadingImage(true)
        axios.post(URL_LIST_ALL + "fileupload", data, {headers: {
          "Content-Type": "multipart/form-data",
          Authorization: user.config.headers.Authorization,
        }
      }).then(async (res) => {
          if (res.data.status === "success") {
            const url = res.data.message
            if (url) {
              let oldValue = value.slice()
              let htmlAudio = '<img src="' + url + '" alt=""/>'
              setValue(oldValue + htmlAudio)
              setLoadingImage(false)
            }
          } else {
            setLoadingImage(false)
            return;
          }
        }).catch((err) => { console.log(err); setLoadingImage(false) })
      }
    }
  }

  return (<>

    <div className="tab-group">
      <div className="w-100 d-flex justify-content-between">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button type="button" className={"nav-link " + (type === 1 ? "active" : "")} onClick={() => setType(1)}>Văn bản</button>
          </li>
          <li className="nav-item">
            <button type="button" className={"nav-link " + (type === 2 ? "active" : "")} onClick={() => setType(2)}>Html</button>
          </li>

        </ul>
        <div className="text-right">
          <button type="button" className="btn btn-sm btn-success" onClick={() => inputRef.current.click()}>{loadingImage ? "Đang tải..." : textImage ? textImage : "Chèn ảnh"}</button>
          <input type="file" id="file" ref={inputRef} style={{ display: "none" }} accept="image/*" onChange={uploadImage} />
        </div>
      </div>
      
      {type === 2 &&
        <CodeMirror
          height="500px"
          theme={githubLightInit({
            settings: {
                caret: '#c6c6c6',
                fontFamily: 'monospace',
            }
        })}
          extensions={[langs.html()]}
          value={value}
          onChange={(value, viewUpdate) => {
            setValue(value)
          }}
          
        />
      }
      {type === 1 &&
        <Editor apiKey="vswj07wyg97x3fp9hrymkie5h0ixrlu72ps7m75rr9rxr6qh"
          onInit={(evt, editor) => (editorRef.current = editor)}
          value={value ? value : " "}
          onFocusOut={() => {
            const valuecontent = editorRef.current.getContent()
            if (valuecontent && valuecontent !== initialValue) {
              onValue(valuecontent)
            }
          }}
          onEditorChange={(newValue, editor) => {
            if(newValue !== null)setValue(newValue);
          }}
          init={{
            height: height ? height : 500,
            menubar: false,
            inline: false,
            verify_html:false,
            placeholder:placeholder,
            verify_css_classes : true,
            cleanup : false,
            forced_root_block : false,
            valid_children : "+body[style]",
            plugins: 'anchor autolink charmap codesample emoticons image link lists media table visualblocks wordcount',
            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          }}
        />
      }
    </div>
  </>
  );
}
