import React, { useState } from "react";
import { LIST_ALPHA } from "../../../configs";
import parse from 'html-react-parser'

export default function QChooseMulti(props) {
    const {type,item,answers} = props;
    const [choice] = useState(answers.correct||{})

    return (<div className="col-12 form-group mt-3">
        {type === 'question' && 
            <ul>
                {answers && answers.list.map((items,i)=>{
                    return (
                        <li key={i} className="d-flex mb-2 align-items-center">
                            {LIST_ALPHA[i]}. <div className={"py-1 px-2 cursor-pointer text-pre-wrap rounded " + (choice.indexOf(i) > -1 || choice.indexOf(i+'') > -1 ? ' btn-info' : ' btn-secondary')} >
                                {parse(items)}
                            </div>
                        </li>
                    )
                })
                }
            </ul>
        } 
      
        {type === 'answer' && item && item.decode && item.decode.answers &&
            item.decode.answers.map((items,i)=>{
                return (
                    <li key={i} className="d-flex mb-2 align-items-center">
                        {LIST_ALPHA[i]}. <div className={"py-1 px-2 cursor-pointer text-pre-wrap rounded " + (choice[i] ? ' btn-info' : ' btn-secondary')} >
                            {parse(items)}
                        </div>
                    </li>
                )
            })
        }
    </div>);
}