
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AkkhorLayout from "../layout/Akkhor";
import CustomList from "../component/CustomList";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/Api";

export default function ListBaiHocCuaToi() {
  const { user } = useSelector((state) => state.auth);
  const { alias } = useParams();
  const [params, setParam] = useState();

  const [khInfo,setKhInfo] = useState();
  const columns = [
    { name: "id", label: "ID" },
    { name: "title", label: "Tên bài học" },
    { name: "khoahoc_title", label: "Thuộc khóa học" },
    { name: "datejoin", label: "Ngày tham gia" }
  ];
  useEffect(
    function () {
        
    }, [user, alias]
);
  return (
    <AkkhorLayout idrole="khoa-hoc">
      <h4 className="w-100 mt-5 mb-2">Khóa học: {khInfo && khInfo.title}</h4>
      <CustomList name="khoahoc_baihoc" idrole="khoa-hoc" params={params} title="Bài học" defaultLimit={50} serverSide={true} columns={columns}></CustomList>
    </AkkhorLayout>
  );
}
