import React, { useEffect, useState } from 'react';
import AkkhorLayout from '../../layout/Akkhor';
import { useSelector } from "react-redux";
import { Link, useParams } from 'react-router-dom';
import Axios from 'axios';
import { URL_LIST_ALL } from '../../configs/Api';
import SRList from "../../component/List";
import parse from 'html-react-parser'

export default function ReportExamQuestions() {
    const { id } = useParams();
    const { user } = useSelector((state) => state.auth);
    const [ detail, setDetail ] = useState([]);

    useEffect(() => {
        let mounted = true;
        Axios.get( URL_LIST_ALL + "at_exam_tests/" + id + '?info=1', user.config).then(async (res) => {
            if (res.data.status === "success" && mounted) {
                const _detail = res.data.data;
                setDetail(_detail);
            }
            else console.log(res.data.message);
        }).catch((err) => console.log(err));
        return () => (mounted = false);
    }, [user, id]);

    let columns = [
        { name: "id", label: "ID", options: { filter: false, sort: true } },
        { name: "typetitle", label: "Dạng câu hỏi", options: { filter: true, sort: true } },
        { name: "title", label: "Tiêu đề", options: { 
                filter: true, sort: true ,
                customBodyRender: (value) => parse(value),
            } 
        },
        { name: "user_true", label: "Số học sinh làm đúng", options: { filter: false, sort: false } },
        { name: "user_false", label: "Số học sinh làm sai", options: { filter: false, sort: false } },
        { name: "user_none", label: "Số học sinh chưa làm", options: { filter: false, sort: false } },
        {
            name: "id", label: "#", options: {
                filter: true, sort: true, customBodyRender: (value, tableMeta) => {
                    return <Link to={"/examquestion-view/"+id + '/' + value}><b className="text-info">Chi tiết</b></Link>
                }
            },
        }
    ];
  
    //lastname-ASC_fullname-ASC
    return (
        <AkkhorLayout idrole="examination-report" roleignone={user.data.typeaccount === 2}>
            
            {detail && <>
                <div className="breadcrumbs-area py-2">
                    <ul>
                        <li>
                            <Link to="/">Trang chủ</Link>
                        </li> 
                        <li>
                            <Link to="/examination-report">Thống kê</Link>
                        </li>
                        <li>{detail.title}</li>
                    </ul>
                </div>
                {user && <div className="card height-auto">
                    <SRList name={'report_exam_test_question/'+id} idrole={"list-tests"} linkaction={'tests'} params={""} title={""} defaultLimit={100} serverSide={true} noAction={true} onlyList={true} columns={columns} />
                </div>}
            </>}
        </AkkhorLayout>
    );
}
