import React from "react";

export default function QTrueFlase(props) {
    const { currentAnswer } = props;

    return (<div className="col-12 form-group mt-3">
        <button className={"btn btn-sm " + (currentAnswer === false ? ' btn-info' : ' btn-secondary')}  > Sai </button>
        <button className={"btn btn-sm ml-3 " + (currentAnswer === true ? ' btn-info' : ' btn-secondary')} > Đúng </button>
    </div>);
}
