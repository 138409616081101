import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { URL_LIST_ALL } from "../configs/Api";
import { confirmAlert } from "react-confirm-alert";

export default function ListStudentToolbar(props) {
  const { selectedRows, displayData, refreshData, myclass, refresh,type,onComplete } = props;
  const { user } = useSelector((state) => state.auth);
  const { year_id } = useSelector((state) => state.save);
  const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
  const [show, setShow] = useState(false);
  const [listClass, setListClass] = useState([]);
  const [classChoice, setClassChoice] = useState([]);

  //Lấy danh sách lớp
  useEffect(() => {
   
    let mounted = true;
    setListClass([]);
    Axios.get(URL_LIST_ALL + "class?page=1&limit=500&school_id=" + school_id + "&year_id=" + year_id, user.config).then((res) => {
      if (res.data && res.data.status === "success") {
        if (mounted) setListClass(res.data.data);
      } else console.log(res.data.message);
    }).catch(err => console.log(err));
    return () => (mounted = false);
  }, [user, school_id, year_id]);

  //Sự kiện chọn lớp trên danh sách
  const onChoiceClass = (id, stt) => {
    let arr = classChoice || [];
    if (stt === true) arr.push(id);
    else {
      var index = arr.indexOf(id);
      if (index !== -1) {
        arr.splice(index, 1);
      }
    }
    setClassChoice(arr);
  }

  //Sự kiện lưu học sinh vào lớp đã Chọn
  const addStudentToClass = () => {
    setShow(false);
    if (classChoice.length > 0) {
      let arrStudents = [];
      displayData.map(i => { if (selectedRows.lookup[i.dataIndex] === true) arrStudents.push(i.data[0]); return i });
      const data = { listclass: classChoice, liststudent: arrStudents }
      Axios.post(URL_LIST_ALL + "studentclass?year_id=" + year_id, data, user.config).then((res) => {
        if (res.data && res.data.status === "success") {
          confirmAlert({
            title: 'Thành công',
            message: 'Bạn đã thêm học sinh vào các lớp thành công',
            buttons: [{ label: 'OK', onClick: () => { if (refreshData) refreshData(); } }]
          });
        } else confirmAlert({
          title: 'Lỗi',
          message: res.data.message,
          buttons: [{ label: 'OK' }]
        });
      }).catch(err => console.log(err));
    } else {
      confirmAlert({
        title: 'Lỗi',
        message: 'Bạn chưa chọn lớp',
        buttons: [{ label: 'OK', onClick: () => setShow(true) }]
      });
    }
  }


  const deleteFromClass = () => {
    if (myclass !== null) {
      confirmAlert({
        title: 'Xác nhận',
        message: 'Bạn có chắc muốn xoá học sinh ra khỏi lớp ' + myclass.title,
        buttons: [{
          label: 'Có', onClick: () => {
            let arrStudents = [];
            displayData.map(i => { if (selectedRows.lookup[i.dataIndex] === true) arrStudents.push(i.data[0]); return i });
            Axios.put(URL_LIST_ALL + "students_to_class/" + myclass.id+ '&type='+type, { class_id: myclass.id, idstudents: arrStudents }, user.config).then((res) => {
              if (res.data.status === 'success') {
                if (refresh) refresh();
              } else {
                confirmAlert({
                  title: 'Thông báo',
                  message: res.data.message,
                  buttons: [{ label: 'OK' }]
                });
              }
            })
          }
        }, { label: 'Không' }]
      });
    }
  }

  return (
    <div className="p-2">
      {myclass && <Button style={{ fontSize: 14 }} onClick={deleteFromClass} className="mr-2 bg-danger border-0">Xoá khỏi lớp</Button>}
      <Button style={{ fontSize: 14 }} onClick={() => setShow(true)} >Thêm vào lớp</Button>
      <Modal show={show} onHide={() => setShow(false)} animation={true} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Chọn các lớp</Modal.Title>
        </Modal.Header>
        <Modal.Body>Bạn đang thêm {displayData.length} học sinh vào các lớp
          <ul className="row">
            {listClass.map(item => <li key={item.id} className="col-6 col-sm-3 col-md-4" id={"class" + item.id}>
              <input type="checkbox" htmlFor={"class" + item.id} value={item.id} style={{ margin: 5 }} onChange={(e) => onChoiceClass(item.id, e.target.checked)} /> <label>{item.title}</label>
            </li>)}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Đóng
          </Button>
          <Button variant="primary" onClick={addStudentToClass}>
            Lưu
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
