
import React from "react";
import { useSelector } from "react-redux";
import SRList from "../component/List";
import Moment from "react-moment";
import AkkhorLayout from "../layout/Akkhor";

export default function ListKhoaHoc() {
  let params = '';
  const columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "title", label: "Tên khóa học", options: { filter: true, sort: true } },
    // { name: "schoolstitle", label: "Trường",options: { filter: true, sort: true }},
    {
      name: "id", label: "Bài học",
      options: {
        filter: false, sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <a className="btn btn-success" href={"/danh-sach-bai-hoc/"+value}>Danh sách bài học</a>;
        },
      }
    },
    {
      name: "datecreate", label: "Ngày tạo",
      options: {
        filter: false, sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const time = new Date(value * 1000);
          return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
        },
      }
    }
  ];


  return (
    <AkkhorLayout idrole="khoa-hoc">
      <SRList name="khoahoc" idrole="khoa-hoc" params={params} title="Khóa học" defaultLimit={50} serverSide={true} columns={columns}></SRList>
    </AkkhorLayout>
  );
}
