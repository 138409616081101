
import React from "react";
import SRList from "../component/List";
import Moment from "react-moment";
import AkkhorLayout from "../layout/Akkhor";
import { useSelector } from "react-redux";

export default function ListParamsPage() {
  const { user } = useSelector((state) => state.auth);
  const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
  const columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true }  },
    { name: "title", label: "Tiêu đề", options: { filter: true, sort: true }  },
    { name: "idtype",label: "Loại",
      options:{
        filter: false,sort: true ,
        customBodyRender: (value) => {
          if(value===1)return 'Loại bài kiểm tra';
          if(value===4)return 'Mức độ nhận biết';
          if(value===5)return 'Phạm vi sử dụng';
        },
      }
    },
    { name: "datecreate",label: "Ngày đăng",
    options:{
      filter: false,sort: true ,
      customBodyRender: (value) => {
        const time = new Date(value * 1000);
        return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
      },
    }
  }
  ];
  
  return (<AkkhorLayout idrole="list-param"><SRList name="exam_param"  params={'school_id='+school_id}  linkaction={'param'} idrole="list-param"  title="Thông số khảo thí"  defaultLimit={50}  serverSide={true} columns={columns}></SRList></AkkhorLayout>);
}
