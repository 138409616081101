
import React, { useState } from "react";
import SRList from "../../component/List";
import Moment from "react-moment";
import AkkhorLayout from "../../layout/Akkhor";
import { useSelector } from "react-redux";
import UserDropDown from "../../component/UserDropDown";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Link } from "react-router-dom";

export default function ListTags() {
  const { user } = useSelector((state) => state.auth);
  const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
  const [params, setParam] = useState('join=1&school_id=' + school_id);

  const localgrade_id = user && user.data && user.data.class && user.data.class.grade_id > 0 ? user.data.class.grade_id : 0;
  const [grade_id, setGrade_id] = useState(localgrade_id > 0 ? localgrade_id : 'all');
  const [subject_id, setSubject_id] = useState(0);
  const [startDate, setStartDate] = useState(0)
  const [endDate, setEndDate] = useState(0)

  const columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "title", label: "Tiêu đề", options: { filter: true, sort: true } },
    { name: "gradetitle", label: "Khối", options: { filter: true, sort: true } },
    { name: "subjecttitle", label: "Môn học", options: { filter: true, sort: true } },
    {
      name: "datecreate", label: "Ngày đăng",
      options: {
        filter: false, sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const time = new Date(value * 1000);
          return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
        },
      }
    }
  ];
  const handleCallback = (start, end, label) => {
    setStartDate(start)
    setEndDate(end)
  }
  const tableFilter = (e) => {
    e.preventDefault();
    let _param = 'join=1&school_id=' + school_id
    if (grade_id > 0) _param += "&grade_id=" + grade_id
    if (subject_id > 0) _param += "&subject_id=" + subject_id
    if (startDate > 0 && endDate > 0) _param += "&daterange=" + startDate.format('YYYY-MM-DD') + "_" + endDate.format('YYYY-MM-DD')
    setParam(_param);
  };

  return (<AkkhorLayout idrole="list-tags">
    <SRList name="at_tags_questions" params={params} linkaction={'tags'} idrole="list-tags" title="Thẻ câu hỏi" defaultLimit={50} serverSide={true} columns={columns}>
      <div className="card pb-0 height-auto mb-3 mt-5">
        <div className="card-body">
          <div className="d-flex pb-2">
            <div className="mr-auto">
              <h3>Tìm kiếm câu hỏi</h3>
            </div>
            <div className="p-2">
              <Link to={"/add-tags"}>
                <button className="btn btn-sm btn-success">Thêm mới</button>
              </Link>
            </div>
           
          </div>
          <form className="new-added-form" method="get" >
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-12 form-group">
                <label>Khối <span className="text-danger">*</span></label>
                <UserDropDown apiname={"grades?orderby=title-ASC&school_id=" + school_id} placeholder="Chọn khối" colvalue={"grades.id"} colname={"grades.title"} defaultValue={grade_id} name="grade_id"
                  onChange={async (e) => { setGrade_id(e); setSubject_id('all') }} all={true}
                ></UserDropDown>
              </div>
              <div className="col-xl-3 col-lg-3 col-12 form-group">
                <label> Môn học <span className="text-danger">*</span></label>
                <UserDropDown apiname={"subjects?key=&istree=1&orderby=title-ASC&page=1&limit=50&school_id=" + school_id + "&grade_id=" + grade_id} placeholder="Chọn môn học" defaultValue={subject_id} colvalue={"subjects.id"} colname={"subjects.title"} name="subject_id"
                  onChange={async (e) => { setSubject_id(e) }} all={true}
                ></UserDropDown>
              </div>
              <div className="col-lg-6 col-12 form-group">
                <label> Thời gian khởi tạo <span className="text-danger">*</span></label>
                <DateRangePicker initialSettings={{ startDate: startDate, endDate: endDate, maxDate: "", }}
                  onCallback={handleCallback}
                >
                  <div className='d-flex align-items-center'>
                    <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Từ ngày</span>
                    <input
                      className="form-control h-31 form-control-sm"
                      placeholder='Từ ngày'
                      readOnly={true}
                      onChange={(e) => setStartDate(e)}
                      value={startDate > 0 ? startDate.format('YYYY-MM-DD') : ""}
                    />
                    <span className='fas fa-arrow-right mx-4' />
                    <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Đến ngày</span>
                    <input
                      className="form-control h-31 form-control-sm"
                      placeholder='Đến ngày'
                      readOnly={true}
                      onChange={(e) => setEndDate(e)}
                      value={endDate > 0 ? endDate.format('YYYY-MM-DD') : ""}
                    />
                  </div>
                </DateRangePicker>
              </div>
              <div className="col-12 ">
                <button type="submit" onClick={tableFilter} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
              </div>
            </div>
          </form>
        </div>
      </div>

    </SRList>
  </AkkhorLayout>);
}
