import React, { useState } from "react";
import ReactHtmlParser from 'react-html-parser'

export default function QEssay(props) {
    const {type,answers} = props;
    const [choice] = useState( type === "answer" && !!answers ? answers.correct : '')
    
    return (<div className="col-12 mt-3 mb-3">
        {choice ?
            <div className="w-100" style={{maxHeight:150,overflow:"hidden"}}>
                { ReactHtmlParser(choice) }
            </div>
            :
            <textarea className="form-control form-control-sm border" readOnly value={choice||''} style={{height:70}} placeholder="Bài làm ..." ></textarea>
        }
        
    </div>);
}
