import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LIST_ALPHA } from "../../../configs";
import { saveAnswers } from "../../../redux/Actions";
import parse from 'html-react-parser'

export default function QChooseMulti(props) {
    const { item, currentAnswer,checkQuestions,objAnswers } = props;
    const { Answers } = useSelector(state => state.save);
    const dispatch = useDispatch();
    const answers = item.answers
    const [choice, setChoice] = useState(currentAnswer || {})
    const [objAnswersCorrects, setObjAnswersCorrects] = useState({})
    useEffect(()=>{
   
        let arrAnswers = item.corrects
        let objAnswers = {}
        if(arrAnswers.length > 0){
            let objectFromArray = convertArrayAnnswwers(arrAnswers)
              setObjAnswersCorrects(objectFromArray)
             
        }

      
    },[])
    const convertArrayAnnswwers = (arrAnswers) =>{
        const objectFromArray = arrAnswers.reduce((acc, currentValue) => {
            acc[currentValue] = true;
            return acc;
          }, {});
          return objectFromArray
    }
    const chooseItem = (index, stt) => {
        let newChoice = { ...choice }
        if (stt) newChoice[index] = true;
        else delete newChoice[index]
        setChoice(newChoice)
        let percent = 0
        if (answers.correct && answers.correct.length === Object.keys(newChoice).length) {
            answers.correct.map((i) => {
                if (newChoice[i] === true) percent += 100 / answers.correct.length
                return i
            });
        }
        let newListAnswers = { ...Answers }
        let convertArr = convertArrayAnnswwers(item.corrects)
        newListAnswers[item.id] = { question: item, answers: newChoice, percent: percent === 100 ? 100 : 0,point:JSON.stringify(convertArr) == JSON.stringify(newChoice)?1:0  };
        dispatch(saveAnswers(newListAnswers))
    }
    return (
    <>  
     {objAnswers[item.id] ? 
     <>
         {(JSON.stringify(choice) == JSON.stringify(objAnswersCorrects)) ? 
            <div className="col-12 form-group mt-3">
                <ul>  
                    {answers && answers.map((item, i) => <li key={i} className="d-flex mb-2 align-items-center " >
                        {LIST_ALPHA[i]}. <div className={"w-100 py-1 px-2 cursor-pointer rounded border " + (objAnswersCorrects[i] ? ' border-success bg-success text-white' : ' border-grey')} >
                            {item.includes("<class") && item.includes(">") ? item : parse(item)}
                        </div>
                    </li>)}
                </ul>
            </div>
            :
            <>
                 <div className="col-12 form-group mt-3">
                    <ul>  
                        {answers && answers.map((item, i) => <li key={i} className="d-flex mb-2 align-items-center " >
                            {LIST_ALPHA[i]}. <div className={"w-100 py-1 px-2 cursor-pointer rounded border " + (objAnswersCorrects[i]&&choice[i] ? ' border-success bg-success text-white' : choice[i]?'border-danger bg-danger text-white':' border-grey')} >
                                {item.includes("<class") && item.includes(">") ? item : parse(item)}
                            </div>
                        </li>)}
                    </ul>
                </div>
                <div className="col-12 rounded pt-1 pb-1" style={{ backgroundColor: "rgba(255,223,224)" }}>
                    
                    <div className="row">
                        <div className="col-12 col-sm-3">
                            <strong>Đáp án đúng là: </strong> 
                            <strong >{ item.corrects.map((arr, i)=>
                                    <span className="text-success">
                                        {item.corrects.length == i+1?LIST_ALPHA[parseInt(arr)]+ ". ":LIST_ALPHA[parseInt(arr)]+ ", "}
                                    </span>
                                )
                            }</strong>
                            </div>
                       
                    </div>
                </div>
            </>
           
        }
     </>
       
       
     :
        <div className="col-12 form-group mt-3">
            <ul>
                {answers && answers.map((item, i) => <li key={i} className="d-flex mb-2 align-items-center " >
                    {LIST_ALPHA[i]}. <div className={"w-100 py-1 px-2 cursor-pointer rounded border " + (choice[i] ? ' border-info bg-info text-white' : ' border-grey')} onClick={() => chooseItem(i, choice[i] ? false : true)}>
                        {item.includes("<class") && item.includes(">") ? item : parse(item)}
                    </div>
                </li>)}
            </ul>
            
        </div>
    }
    </>
   );
}