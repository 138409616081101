import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserDropDown from "./UserDropDown";

export default function ExamTree(props) {
  const { grade_id, value, onChange,subject_id } = props;
  const { user } = useSelector((state) => state.auth);
  const [parent, setParent] = useState(value ? value : [0, -1, -1, -1, -1, -1]);
  const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;

  let arr = ['Cây kiến thức cấp 1 ', 'Cây kiến thức cấp 2', 'Cây kiến thức cấp 3', 'Cây kiến thức cấp 4', 'Cây kiến thức cấp 5'];

  const chanegParent = (_index, _value) => {
    if (_index < parent.length - 1) {
      let _newparent = [...parent];
      _newparent[_index + 1] = _value;
      setParent(_newparent)
    }
    onChange(_value)
  }

  useEffect(() => { if (value) setParent(value) }, [value])

  return (
    <>
      {arr.map((title, index) => {
        return (<div key={index} className="col-xl-3 col-lg-4 col-sm-6 col-12 form-group">
          <label> {title} </label>
          <UserDropDown apiname={"exam_tree?key=&page=1&orderby=title-ASC&limit=50&idparent=" + (parent[index] ? parent[index] : 0) +  "&grade_id=" + grade_id+ "&subject_id=" + subject_id} placeholder={"Chọn " + title} colvalue={"exam_tree.id"} colname={"exam_tree.title"}
            onChange={async (e) => chanegParent(index, e)}
            defaultValue={parent[index + 1]}
          ></UserDropDown>
        </div>)
      })}
    </>
  );
}
