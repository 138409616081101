import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { URL_LIST_ALL } from "../configs/Api";
import { confirmAlert } from "react-confirm-alert";
import CodeMirror from '@uiw/react-codemirror';
import { githubLightInit } from '@uiw/codemirror-theme-github';
import { langs } from '@uiw/codemirror-extensions-langs';
import parse from 'html-react-parser';
import { saveAnswers } from "../redux/Actions";
import ChoiceQuestion from "../khoa-hoc-cua-toi/questions/ChoiceQuestion";
import ShowTab2 from "../component/ShowTab2";
import ShowCodePython from "../component/ShowCodePython";
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
export default function ViewScreenKh(props) {
    const { alias } = useParams();
    const { currentScreen, onNextScreen, onPrevScreen, arrListManHinh, maxPosition, hideNextButton } = props;
    const [listParam, setListParam] = useState(null)
    const [checkQuestions, setCheckQuestions] = useState(false)
    const [objAnswers, setObjAnswers] = useState({})
    const { user } = useSelector((state) => state.auth);
    const { Answers } = useSelector(state => state.save);
    const dispatch = useDispatch();
    let query = useQuery();
    const idmanhinh = parseInt(query.get("id_mh"));

    useEffect(
        function () {
            dispatch(saveAnswers([]))
            let mounted = true;
            if (mounted) {

                if (user && currentScreen && currentScreen.position == 1) {
                    axios.get(URL_LIST_ALL + "khoahoc_param?manhinh_id=" + currentScreen.id + "&orderby=khoahoc_lk.position-asc&limit=1000", user.config).then(async (res) => {
                        if (res.data.status === "success") {
                            setListParam(res.data.data)
                        }
                    }).catch((err) => console.log(err));
                }
            }
            if (idmanhinh > 0) {
                axios.get(URL_LIST_ALL + "khoahoc_param?manhinh_id=" + idmanhinh + "&orderby=khoahoc_lk.position-asc&limit=1000", user.config).then(async (res) => {
                    if (res.data.status === "success") {

                        setListParam(res.data.data)
                    }
                }).catch((err) => console.log(err));
            } else if (arrListManHinh.length == 0) {
                axios.get(URL_LIST_ALL + "khoahoc_param?manhinh_id=" + currentScreen.id + "&orderby=khoahoc_lk.position-asc&limit=1000", user.config).then(async (res) => {
                    if (res.data.status === "success") {
                        setListParam(res.data.data)

                    }
                }).catch((err) => console.log(err));
            } else {
                setListParam(arrListManHinh)

            }
            return () => (mounted = false);
        }, [user, currentScreen]
    );
    useEffect(() => {
        axios.get(URL_LIST_ALL + "history_khoahoc_student?id_student=" + user.data.id + "&manhinh_id=" + currentScreen.id, user.config).then(async (res) => {
            if (res.data.status === "success") {
                setObjAnswers(res.data.data)
                dispatch(saveAnswers(res.data.data))
            }
        }).catch((err) => console.log(err));
    }, [checkQuestions])

    const selectLang = (type) => {
        if (type === 'javascript') return langs.jsx()
        if (type === 'html') return langs.html()
        if (type === 'python') return langs.python()
        if (type === 'php') return langs.php()
        if (type === 'css') return langs.css()
        if (type === 'java') return langs.java()
        if (type === 'sql') return langs.sql()
        if (type === 'kotlin') return langs.kotlin()
        if (type === 'c') return langs.c()
        return langs.html()
    }
    const checkQuestion = (kh_value, id) => {
        let allAnswer = { ...Answers }
        let newArr = Object.values(allAnswer)
        let arrAnswers = []
        if (newArr.length > 0) {
            if (newArr.length < kh_value.length) {
                confirmAlert({ title: "Thông báo", message: "Bạn chưa hoàn thành hết các câu hỏi", buttons: [{ label: "OK" }], });
            } else {
                setCheckQuestions(id)
                kh_value.map((item) => {

                    arrAnswers.push(allAnswer[item.id])
                })

                let values = {
                    newArr: arrAnswers,
                    idBaiTap: id,
                    id_mh: currentScreen.id,
                }
                axios.post(URL_LIST_ALL + 'add_students_answers', values, user.config).then(res => {
                    if (res.data.status === "success") {
                        setCheckQuestions(!checkQuestions)
                    }


                }).catch(err => console.log(err));
            }
        } else {
            confirmAlert({ title: "Thông báo", message: "Bạn chưa hoàn thành hết các câu hỏi", buttons: [{ label: "OK" }], });
        }


    }
    const resetQuestion = (kh_value, id) => {
        let allAnswer = { ...Answers }

        let arrAnswers = []
        kh_value.map((item) => {
            arrAnswers.push(allAnswer[item.id])
        })

        let values = {
            newArr: arrAnswers,
            idBaiTap: id,
            id_mh: currentScreen.id,
        }
        axios.post(URL_LIST_ALL + 'reset_students_answers', values, user.config).then(res => {
            if (res.data.status === "success") {
                setCheckQuestions(!checkQuestions)
            }
        }).catch(err => console.log(err));
    }
    const checkDapAn = (item) => {

        let checkQuestions = null
        if (item.point == 1) {
            checkQuestions = true
        } else {
            checkQuestions = false
        }
        return checkQuestions;
    }
    return (
        <div className="ld-focus-content">
            <h1>{currentScreen && currentScreen.baihoc_title}</h1>
            <div className="ld-tabs ld-tab-count-1 mb-5">
                <div className="ld-tabs-content">
                    <div className="w-100">
                        {listParam && listParam.map((item, i) => {
                            var kh_value = item.kh_type === 'quest' ? JSON.parse(item.kh_value) : item.kh_value
                            return (<div key={i} className="view-question">
                                {item.kh_type === 'quest' &&
                                    <div className="baitap-kh">

                                        {kh_value.map((ans, index) => {
                                            return <div key={index} className="mb-4 border rounded overflow-hidden " id={"nuques-" + index}>
                                                <div>
                                                    {objAnswers && objAnswers[ans.id] ?
                                                        <div className={"font-weight-bold p-2 d-flex justify-content-between" + (checkDapAn(objAnswers[ans.id]) == true ? " bg-success text-white" : " bg-danger text-white")}>
                                                            <div>Câu hỏi {index + 1 + checkDapAn(ans.id)} </div>
                                                        </div>
                                                        :
                                                        <div className={"font-weight-bold p-2 bg-info d-flex justify-content-between"}>
                                                            <div>Câu hỏi {index + 1}</div>
                                                        </div>
                                                    }

                                                    {ans.parent_title && <div className="p-2 border-bottom">{parse(ans.parent_title)}</div>}
                                                    <div className="p-3 border-bottom mb-3">{parse(ans.title)}</div>
                                                    <div>
                                                        <ChoiceQuestion
                                                            key={index}
                                                            index={index}
                                                            item={ans}
                                                            test={null}
                                                            action={false}
                                                            idBaiTap={item.id}
                                                            objAnswers={objAnswers}
                                                            checkQuestions={checkQuestions}
                                                        ></ChoiceQuestion>
                                                    </div>
                                                    {ans.comments &&
                                                        <div className="comment border-top p-2 mt-3">
                                                            <span>Nhận xét</span>
                                                            <div className="w-100 mt-2 d-flex">
                                                                <strong>{ans.teacher_comment}</strong>: {parse(ans.comments + '')}
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        })}

                                        <div className="w-100 d-flex justify-content-between align-items-center mt-2">

                                            <button className="btn btn-success" type="button" onClick={() => checkQuestion(kh_value, item.id)}>Kiểm tra</button>
                                            <button className="btn btn-warning" type="button" onClick={() => resetQuestion(kh_value, item.id)}>Làm lại</button>

                                        </div>

                                    </div>
                                }
                                <div className="custom-list-content"> {item.kh_type === 'text' && parse(kh_value)}</div>

                                {item.kh_type === 'code' &&
                                    <CodeMirror
                                        height="auto"
                                        theme={githubLightInit({
                                            settings: {
                                                caret: '#c6c6c6',
                                                fontFamily: 'monospace',
                                            }
                                        })}
                                        readOnly={true}
                                        extensions={[selectLang(item.khmeta)]}
                                        value={item.kh_value}
                                        onChange={(value, viewUpdate) => {
                                            // setValueMH(value)
                                        }}
                                    />
                                }
                                {item.kh_type == 'tab' && parse(kh_value) &&
                                    <>
                                        {JSON.parse(item.kh_value)[0].type == "python" ?
                                            <ShowCodePython arrTab={JSON.parse(item.kh_value)} />
                                            :
                                            <>
                                                <ShowTab2
                                                    id_param={item.id}
                                                    arrTab={JSON.parse(item.kh_value)}
                                                />
                                            </>
                                        }
                                    </>
                                }
                                {item.kh_type == 'importhtml' &&
                                    <div className="col-12">
                                        <div className="view-show-html2" style={{height:item.khmeta}}>
                                            <iframe id="iframehtml" width={"100%"}  title="iframe" src={item.kh_value}></iframe>
                                        </div>
                                    </div>
                                        
                                }
                            </div>)
                        })}
                    </div>
                </div>
            </div>
            <hr />
            {!hideNextButton ?
                <div className="w-100">
                    {currentScreen.prev == 1 && <button type="button" className="btn-prev btn btn-warning text-white float-left" onClick={() => onPrevScreen()}>Quay lại</button>}
                    {currentScreen.next == 1 && currentScreen.position < maxPosition && maxPosition > 0 && <button type="button" className="btn-next btn btn-success float-right" onClick={() => onNextScreen()}>Tiếp theo</button>}
                </div>
                : <></>
            }

        </div>
    );
}
