
import React from "react";
import SRList from "../component/List";
import Moment from "react-moment";
import AkkhorLayout from "../layout/Akkhor";
import { useSelector } from "react-redux";

export default function ListYearsPage() {
  let params = '';
  const { user } = useSelector((state) => state.auth);
  params += user && user.data && user.data.school_id>0?'school_id='+user.data.school_id:0;
  
  const columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "title", label: "Tiêu đề", options: { filter: true, sort: true } },
    { name: "datecreate",label: "Ngày đăng",
      options:{
        filter: false,sort: true ,
        customBodyRender: (value, tableMeta, updateValue) => {
          const time = new Date(value * 1000);
          return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
        },
      }
    }
  ];
  
  return (<AkkhorLayout idrole="list-years"><SRList name="years" idrole="list-years" params={params} title="Năm học" defaultLimit={50}  serverSide={true} columns={columns}></SRList></AkkhorLayout>);
}
