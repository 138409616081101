
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/Akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/Api";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { useRef } from "react";
import thumb from "../assets/img/thumb-icon.png"

export default function FormGradesPage() {
    const { user } = useSelector((state) => state.auth);
    const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false)
    const nenRef = useRef(null);

    const defaultdetail = {
        school_id, author_id: user.data.id, title: '', status: 1, thumbnail: ''
    }
    useEffect(
        function () {
            if (id > 0) {
                let mounted = true;
                Axios.get(URL_LIST_ALL + "khoahoc/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            if (mounted) {
                                setDetail(res.data.data);
                            }
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [user, id, refresh]
    );
    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            title: Yup.string().trim().nullable().required('Nhập tên khóa học').max(150, "Độ dài không quá 150 ký tự")
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'khoahoc/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật khóa học thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                Axios.post(URL_LIST_ALL + 'khoahoc', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới khóa học thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });
    const uploadImage = (event, type) => {
        const file = event.target.files[0]

        if (file) {
            let data = new FormData()
            data.append("type", "s3")
            data.append("name", "file")
            data.append("file", file)
            data.append("filetype", file.type)
            let file_size = file.size;
            if (file_size > 3145728) {
                confirmAlert({
                    title: 'Lỗi',
                    message: "File không được lớn hơn 3 Mb",
                    buttons: [{ label: 'OK' }]
                })
                return
            } else {
                setLoadingImage(true)
                Axios.post(URL_LIST_ALL + "fileupload", data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: user.config.headers.Authorization,
                    }
                }).then(async (res) => {
                    if (res.data.status === "success") {
                        const url = res.data.message
                        if (url) {
                            formik.setFieldValue(type, url)
                            setLoadingImage(false)
                        }
                    } else {
                        setLoadingImage(false)
                        return;
                    }
                }).catch((err) => { console.log(err); setLoadingImage(false) })
            }
        }
    }
    return (
        <AkkhorLayout idrole="khoa-hoc">
            <div className="card height-auto mt-5">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Khóa học</h3>
                        </div>
                    </div>
                    <form className="new-added-form  form-info" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-lg-6 col-12 form-group">
                                <label>Tên khóa học <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("title")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                            </div>
                            <div className="col-12 form-group"></div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Ảnh đại diện</label>
                                <div className="text-left">
                                    <button type="button" className="btn p-0 btn-avatar" onClick={() => nenRef.current.click()}>
                                        {loadingImage ? "Đang tải..." : <>
                                            {formik.values && formik.values.thumbnail ? <img src={formik.values.thumbnail} alt="" className="anhnen" />
                                                :
                                                <img src={thumb} alt="" className="avatar" />
                                            }
                                        </>}
                                        <span className="fas fa-upload upload-icon"></span>
                                    </button>
                                    <input type="file" id="file" ref={nenRef} style={{ display: "none" }} accept="image/*" onChange={(e) => uploadImage(e, 'thumbnail')} />
                                </div>
                            </div>
                            <div className="col-12 form-group"></div>
                            <div className="col-lg-6 col-12 form-group">
                                <label>Trạng thái</label>
                                <select className="form-control height32" {...formik.getFieldProps("status")}>
                                    <option value="0">Khoá</option>
                                    <option value="1">Hoạt động</option>
                                </select>
                            </div>

                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu khóa</>}</button>
                                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
