import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import QChooseMulti from "../../examination/questions/FormQuestion/QChooseMulti";
import QDropDrag from "../../examination/questions/FormQuestion/QDropDrag";
import QEssay from "../../examination/questions/FormQuestion/QEssay";
import QFillTo from "../../examination/questions/FormQuestion/QFillTo";
import QMatching from "../../examination/questions/FormQuestion/QMatching";
import QTrueFlase from "../../examination/questions/FormQuestion/QTrueFlase";
import QChooseOne from "../../examination/questions/FormQuestion/QChooseOne";
import QGeneral from "../../examination/questions/FormQuestion/QGeneral";

import Axios from "axios";

import MyEditor2 from "../../component/MyEditor2"
import AlertErrorForm from "../../component/AlertErrorForm";
import { URL_LIST_ALL } from "../../configs/Api";
import QCode from "../questions/FormQuestion/QCode";

export default function FormQuestionFormat(props) {

  const { detail, formik, answers, setAnswers, maxNumber, setMaxNumber} = props;
  const { user } = useSelector((state) => state.auth);
  const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
  const [listType, setListType] = useState([]);
  const [typechoice, setTypeChoice] = useState(null);


  useEffect(() => {
    if (user) {
      let mounted = true;
      Axios.get(URL_LIST_ALL + "exam_param?idtype=2&orderby=id-ASC", user.config)
        .then(async (res) => {
          if (res.data.status === "success" && mounted)
            setListType(res.data.data);
          else console.log(res.data.message);
        })
        .catch((err) => console.log(err));
      return () => (mounted = false);
    }
  }, [user, school_id]);

  useEffect(() => {
    if (user && detail) {
      let mounted = true;
      Axios.get(URL_LIST_ALL + "exam_param/" + detail.type_id, user.config)
        .then(async (res) => {
          if (res.data.status === "success" && mounted)
            setTypeChoice(res.data.data);
          else console.log(res.data.message);
        })
        .catch((err) => console.log(err));
      return () => (mounted = false);
    }
  }, [user, detail]);

  const objQues = {
    QTrueFlase: (props) => (<QTrueFlase {...props} item={typechoice} onAnswers={setAnswers} answers={answers} />),
    QChooseMulti: (props) => (<QChooseMulti {...props} item={typechoice} onAnswers={setAnswers} answers={answers} maxNumber={maxNumber} setMaxNumber={setMaxNumber} />),
    QChooseOne: (props) => (<QChooseOne {...props} item={typechoice} onAnswers={setAnswers} answers={answers} maxNumber={maxNumber} setMaxNumber={setMaxNumber} />),
    QDropDrag: (props) => (<QDropDrag {...props} item={typechoice} onAnswers={setAnswers} answers={answers} maxNumber={maxNumber} setMaxNumber={setMaxNumber} />),
    QEssay: (props) => (<QEssay {...props} item={typechoice} onAnswers={setAnswers} answers={answers} maxNumber={maxNumber} setMaxNumber={setMaxNumber} />),
    QFillTo: (props) => (<QFillTo {...props} item={typechoice} onAnswers={setAnswers} answers={answers} maxNumber={maxNumber} setMaxNumber={setMaxNumber} />),
    QMatching: (props) => (<QMatching {...props} item={typechoice} onAnswers={setAnswers} answers={answers} maxNumber={maxNumber} setMaxNumber={setMaxNumber} />),
    QCode: (props) => (<QCode {...props} item={typechoice} onAnswers={setAnswers} answers={answers} maxNumber={maxNumber} setMaxNumber={setMaxNumber} />),
    QGeneral: (props) => (<QGeneral  />),
  };
  const Tab = typechoice ? objQues[typechoice.code] : null;

  const choiceTab = (item) => {
    setTypeChoice(item);
    formik.setFieldValue("type_id", item.id);
  }
  return (
    <div className="container-fluid border">
      <div className="bg-info d-flex align-items-center row">
        <h4 className="text-white font-weight-normal p-2">
          Thêm dạng câu hỏi
        </h4>
      </div>
      <div className="row">
        <div className="col-lg-2 col-12 pt-3">
          {listType.map((item, index) => (<div className="p-1" key={index}>
            <button type="button" className={'btn  btn-question-format mr-2 mb-2 ' + (typechoice && typechoice.code === item.code ? 'btn-danger' : 'btn-primary')} onClick={() => choiceTab(item)} >
              {item.title}
            </button>
          </div>))}
        </div>
        <div className="col-lg-10 col-12  border-left  pt-3">
          {typechoice !== null && <>
            <div className="form-group">
              <label>Nội dung câu hỏi <span className="text-danger">*</span></label>
              <MyEditor2 height={150} key={Math.random()}
                initialValue={formik.values.title}
                onValue={(value) => formik.setFieldValue("title", value)}
              />
              <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
            </div>
            <div className="row">
              <Tab />
            </div>
          </>}
        </div>
      </div>
    </div>
  );
}
