import React from "react";
import SRList from "../../component/List";
import Moment from "react-moment";
import moment from "moment";

export default function ListAttendsDetail(props) {
  const { moreSearch } = props;
  const columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "date", label: "Ngày", options: { filter: true, sort: true } },
    {
      name: "orderonday", label: "Tiết học",
      options: {
        filter: false, sort: true,
        customBodyRender: (value) => {
          return <>Tiết {value}</>;
        },
      }
    },

    { name: "time", label: "Giờ học", options: { filter: true, sort: true } },
    { name: "classtitle", label: "Lớp học", options: { filter: true, sort: true } },
    { name: "subject", label: "Môn học", options: { filter: true, sort: true } },
    { name: "fullname", label: "Giáo viên", options: { filter: true, sort: false } },
    {
      name: "datecreate", label: "Điểm danh",
      options: {
        filter: false, sort: true,
        customBodyRender: (value, tableMeta) => {
          if (value) {
            const time = new Date(value * 1000);
            try {
              const datetime = moment(new Date(value * 1000))
              const date = datetime.format('YYYY-MM-DD')
              const localDate = tableMeta.rowData[1];
              if (localDate === date) {
                const localTime = tableMeta.rowData[3].split('-')
                const arTime = localTime[0].split('h');

                const minuteRequire = parseInt(arTime[0]) * 60 + parseInt(arTime[1])
                const minuteCheck = parseInt(datetime.format('H')) * 60 + parseInt(datetime.format('m'))
                if (minuteRequire <= minuteCheck) {
                  if ((minuteCheck - minuteRequire) <= 10)
                    return <><b className="text-success">Đã điểm danh</b><br /><Moment format="HH:mm DD/MM/YYYY">{time}</Moment></>;
                  else return <><b className="text-warning">Điểm danh muộn</b><br /><Moment format="HH:mm DD/MM/YYYY">{time}</Moment></>;
                } else return <><b className="text-warning">Điểm danh sớm</b><br /><Moment format="HH:mm DD/MM/YYYY">{time}</Moment></>;
              }
              else return <><b className="text-info">Đã điểm danh</b><br /><Moment format="HH:mm DD/MM/YYYY">{time}</Moment></>;
            } catch (e) {
              return <b className="text-danger">Lỗi điểm danh</b>;
            }
          } else return <b className="text-danger">Không điểm danh</b>;
        },
      }
    },
    {
      name: "content", label: "Báo giảng",
      options: {
        filter: false, sort: true,
        customBodyRender: (value,tableMeta) => {
        
          if (value) {
            const rowIndex = tableMeta.rowIndex
            const tableData = tableMeta.tableData
            const datecreate = tableData[rowIndex].datename
            const time = datecreate ? new Date(datecreate * 1000) : null
            return(<>
              <b className="text-success">Đã báo giảng</b><br/>
              {datecreate && <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>}
            </>)
          } else {
            return <b className="text-warning">Chưa báo giảng</b>
          }
        },
      }
    },
    {
      name: "note", label: "Nhận xét",
      options: {
        filter: false, sort: true,
        customBodyRender: (value,tableMeta) => {
          const rowIndex = tableMeta.rowIndex
          const tableData = tableMeta.tableData
          const datecreate = tableData[rowIndex].datenotes
          const time = datecreate ? new Date(datecreate * 1000) : null
          if (tableData[rowIndex].comment_advantages || tableData[rowIndex].comment_defect) {
            return(<>
              <b className="text-success">Đã nhận xét</b><br/>
              {datecreate && <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>}
            </>)
          } else {
            return <b className="text-warning">Chưa nhận xét</b>
          }
        },
      }
    },
    { name: "datecreate",label: "Thời gian",
      options:{
        filter: false,sort: true ,
        customBodyRender: (value) => {
          if(value)
          {
            const time = new Date(value * 1000);
            return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
          }else return null;
        },
      }
    },
    { name: "notes", label: "Ghi chú" ,options: { filter: false,sort: true , }},

  ];

  return (
    <>
      <SRList name="reportusers_onday" moresearch={moreSearch} params='' title="Giáo viên điểm danh theo ngày" defaultLimit={20} serverSide={true} columns={columns} noAction={true}></SRList>
    </>
  );
}
