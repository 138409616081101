import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Moment from "react-moment";
import UserDropDown from "../component/UserDropDown";
import AkkhorLayout from "../layout/Akkhor";
import SRList from "../component/List";

export default function ClientBooksEx(props) {
  const { disableAction } = props;
  const type = 4
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const { user } = useSelector((state) => state.auth);
  const [params, setParam] = useState('&type=4');
  const [keyword, setKeyword] = useState('');
  const [type_id, setType_id] = useState('all');
 

  const handleCallback = (start, end, label) => {
    setStartDate(start)
    setEndDate(end)
  }

  const createParam = () => {
    let _param =  '&type=' + type
    if (type_id > 0) _param += "&type_id=" + type_id
    if (keyword !== '') _param += "&key=" + keyword
    if (startDate > 0 && endDate > 0) _param += "&daterange=" + startDate.format('YYYY-MM-DD') + "_" + endDate.format('YYYY-MM-DD')
    setParam(_param);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    createParam();
  };

  let columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "title", label: "Tên", options: { filter: true, sort: true } },
    { name: "fullname", label: "Người tạo", options: { filter: true, sort: true } },
    { name: "code", label: "Mã bài tập", options: { filter: true, sort: true } },
    {
      name: "datecreate", label: "Ngày đăng", options: {
        filter: false, sort: true,
        customBodyRender: (value) => {
          const time = new Date(value * 1000);
          return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
        },
      },
    }
  ];

  columns.push({
    name: "id", label: "Xem trước", options: {
      filter: false, sort: true,
      customBodyRender: (value) => {
        return <Link to={"/examination-detail/" + value} className="btn btn-sm btn-info" >{type === 1 ? "Xem trước" : "Xem trước"}</Link>;
      }
    }
  })
  //xem báo cáo chi tiết một bài kiểm tra
  columns.push({
    name: "id", label: "Thống kê", options: {
      filter: false, sort: true,
      customBodyRender: (value) => {
        return <Link to={"/examination-report?test_id=" + value} className="btn btn-sm btn-success" >Báo cáo</Link>;
      }
    }
  })

  return (
    <AkkhorLayout idrole="list-books-exercise" roleignone={user.data.typeaccount === 2}>
      <SRList name="at_exam_tests" idrole="list-books-exercise" linkaction={'books-exercise'} params={params} title={ "Danh sách bài tập cho sách"} defaultLimit={50} serverSide={true} onlyList={true} columns={columns}>
        <div className="card pb-0 height-auto mb-3 mt-5">
          <div className="card-body">
            <div className="d-flex pb-2">
              <div className="mr-auto">
                <h3>Tìm kiếm</h3>
              </div>
              {!disableAction && <div className="p-2">
                <Link to={"/add-books-exercise"}>
                  <button className="btn btn-success">{"Tạo bài tập cho sách"}</button>
                </Link>
              </div>}
            </div>
            <form className="new-added-form" method="get" >
              <div className="row">
                <div className="col-lg-3 col-sm-6 col-12 form-group">
                  <label>Từ khoá </label>
                  <input type="text" placeholder="Tìm theo từ khoá" value={keyword} onChange={(e) => setKeyword(e.target.value)} className="form-control height32" autoComplete="off" />
                </div>
               
                <div className="col-lg-3 col-sm-6 col-12 form-group">
                  <label>Phân loại </label>
                  <UserDropDown apiname={"exam_param?page=1&orderby=title-ASC&limit=500&idtype=1"} placeholder="Chọn loại" colvalue={"exam_param.id"} defaultValue={type_id} colname={"exam_param.title"} name='type_id'
                    onChange={async (e) => { setType_id(e) }} all={true}></UserDropDown>
                </div>
                <div className="col-sm-6 col-12 form-group">
                  <label> Thời gian </label>
                  <DateRangePicker initialSettings={{ startDate: startDate, endDate: endDate, maxDate: "" }}
                    onCallback={handleCallback}
                  >
                    <div className='d-flex align-items-center'>
                      <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Từ ngày</span>
                      <input
                        className="form-control h-31 form-control-sm"
                        placeholder='Từ ngày'
                        readOnly={true}
                        onChange={(e) => setStartDate(e)}
                        value={startDate > 0 ? startDate.format('YYYY-MM-DD') : ""}
                      />
                      <span className='fas fa-arrow-right mx-4' />
                      <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Đến ngày</span>
                      <input
                        className="form-control h-31 form-control-sm"
                        placeholder='Đến ngày'
                        readOnly={true}
                        onChange={(e) => setEndDate(e)}
                        value={endDate > 0 ? endDate.format('YYYY-MM-DD') : ""}
                      />
                    </div>
                  </DateRangePicker>
                </div>
                <div className="col-12 ">
                  <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </SRList>
    </AkkhorLayout>
  );
}
