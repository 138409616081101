
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SRList from "../component/List";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import AkkhorLayout from "../layout/Akkhor";

export default function ListClassPage() {
  const { user } = useSelector((state) => state.auth);
  const [params, setParams] = useState('type=1&viewtype=main')

  const columns = [
    { name: "id", label: "ID",options: { filter: false,sort: true } },
    { name: "title", label: "Tên lớp", width: 250 ,options: { filter: true,sort: true } },
    { name: "khoahoc", label: "Thuộc khóa học" ,options: { filter: true,sort: false }},
    { name: "datecreate",label: "Ngày đăng",
      options:{
        filter: false,sort: true ,
        customBodyRender: (value, tableMeta, updateValue) => {
          const time = new Date(value * 1000);
          return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
        },
      }
    },
    { name: "id", label: "Học sinh",options: { filter: false,sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
      
        return <Link to={"/list-students-in-class/"+value}><span className="text-info">Danh sách</span></Link>;
      } } },
  ];
  
  return (
    <AkkhorLayout idrole="list-class">
      <SRList name="class" idrole="list-class" params={params} title="Lớp học" defaultLimit={50} serverSide={true} columns={columns} disabledLayout={true}></SRList>
    </AkkhorLayout>
  );
}
