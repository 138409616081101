import React, { useEffect, useRef, useState } from "react";
import { URL_LIST_ALL } from "../configs/Api";
import Axios from "axios";
import { useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import Spinner from "./Spinner";
import avatar_student from "../assets/img/avata_student.png"
import men_teacher from "../assets/img/men-teacher.png"
import girl_teacher from "../assets/img/girl-teacher.png"
import iconsend from "../assets/img/icon-send.png"
import Moment from "react-moment";
import ContentEditable from "react-contenteditable";
import parse from 'html-react-parser'

export default function PostsComment(props) {
    const { post_id, post_info, actionLike } = props;
    const { user } = useSelector((state) => state.auth)
    const { resetcomments } = useSelector((state) => state.save);
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [listComment, setListComment] = useState([])
    const [listUserLike, setListUserLike] = useState([])
    const [youLike, setYouLike] = useState(false)
    const [contentComment, setContentComment] = useState('')
    const inputRef = useRef();

    const sendComment = () => {
        if (contentComment && contentComment !== '') {
            setLoading(true)
            let datasend = { comments: contentComment, user_id: user.data.id, post_id: post_id, status: user.data.id == post_info.user_id ? 1 : 0 }
            Axios.post(URL_LIST_ALL + "posts_comment", datasend, user.config).then(async (res) => {
                if (res.data.status === 'success') {
                    setRefresh(!refresh)
                    setContentComment('')
                } else {
                    alert(res.data.message)
                }
                setLoading(false)
            }).catch((err) => { console.log(err); setLoading(false) });
        }
    }
    
    useEffect(() => {
        if (user) {
            Axios.get(URL_LIST_ALL + "posts_comment?parent_id=0&post_id=" + post_id, user.config).then(async (res) => {
                if (res.data.status === 'success') {
                    let dats = res.data.data
                    dats.reverse()
                    setListComment(dats)
                } else {
                    alert(res.data.message)
                }
            }).catch((err) => { console.log(err); setLoading(false) });
        }
    }, [user, refresh,post_id, resetcomments])

    useEffect(() => {
        if (post_info.list_like) {
            let listLike = []
            if (post_info.youlike) {
                listLike.push('Bạn')
            }
            post_info.list_like.forEach(item => {
                if (item.user_id !== user.data.id) {
                    listLike.push(item.fullname)
                }
            });
            setListUserLike(listLike)
        }
    }, [post_info])

    const deleteComment = (comment_id) => {
        if (comment_id) {
            confirmAlert({
                title: '',
                message: 'Bạn có muốn xóa bình luận này không ?',
                buttons: [{
                    label: 'Có', onClick: () => {
                        Axios.put(URL_LIST_ALL + "posts_comment/" + comment_id, { status: 2 }, user.config).then(async (res) => {
                            if (res.data.status === 'success') {
                                setRefresh(!refresh)
                            } else {
                                alert(res.data.message)
                            }
                        }).catch((err) => { console.log(err); setLoading(false) });
                    }
                },
                {
                    label: 'Không'
                }]
            });
        }
    }
    const checkComment = (comment_id) => {
        if (comment_id) {
            Axios.put(URL_LIST_ALL + "posts_comment/" + comment_id, { status: 1 }, user.config).then(async (res) => {
                if (res.data.status === 'success') {
                    setRefresh(!refresh)
                } else {
                    alert(res.data.message)
                }
            }).catch((err) => { console.log(err); setLoading(false) });
        }
    }

    return (
        <div className="comment-group border-top mt-2">
            <div className="w-100 action-group border-bottom d-flex justify-content-around">
                <button className="btn btn-like shadow-none" type="button" onClick={() => {
                    actionLike(post_id)
                    setYouLike(!youLike)
                }}>
                    {(post_info && post_info.youlike) || youLike ?
                        <>
                            <span className={"fas fa-thumbs-up mr-2 text-info"}></span>
                            <span className="text-info">Bỏ thích</span>
                        </>
                        :
                        <>
                            <span className={"far fa-thumbs-up mr-2"}></span>
                            Thích
                        </>
                    }
                </button>
                <button className="btn btn-comments" type="button" onClick={() => { if(inputRef && inputRef.current)inputRef.current.focus()}}><span className="fas fa-comment-dots mr-2"></span>Bình luận</button>
            </div>
            {post_info && post_info.list_like ?
                <div className="w-100 like-counter-group mt-2 mb-2">
                    {listUserLike.map((item, i) => {
                        if (i < 5) {
                            return (<span className="mr-1" key={i}>{item}{(i + 1) < listUserLike.length ? (item == 'Bạn' ? ' và ' : ', ') : ''}</span>)
                        }
                    })}
                    <span>{listUserLike.length >= 5 ? 'cùng với ' + listUserLike.length - 5 + ' người khác' : ''}</span>
                    {post_info.list_like.length > 0 ? <span>đã thích bài viết này</span> : ''}
                </div>
                : ''}
            <div className="wrapper">
                <p>Bình luận</p>
                <div className="w-100 d-flex mt-2">
                    {listComment && <div className="list-comments w-100">
                        {listComment.map((item, i) => {
                            return (<div className="item-comments w-100 d-flex mb-2" key={i}>
                                {item.status === 1 || user.data.id === item.user_id ? <>
                                    <div className="user-info">
                                        <div className="avatar">
                                            {item.avatar ?
                                                <img src={item.avatar} alt="" className="avatar" />
                                                :
                                                <img src={item.typeaccount === 2 ? avatar_student : (item.gender === 1 ? men_teacher : girl_teacher)} alt="" className="avatar" />
                                            }
                                        </div>
                                    </div>
                                    <div className={"content-comment " + (item.status !== 1 ? 'not-check' : '')}>
                                        <div className="fullname">
                                            {item.fullname}
                                        </div>
                                        <div className="comment-text">
                                            {parse(item.comments)}
                                        </div>
                                        <div className="comment-date">
                                            <Moment format="HH:mm DD/MM/YYYY">{new Date(item.datecreate * 1000)}</Moment>
                                        </div>
                                    </div>
                                    {post_info.user_id == user.data.id || item.user_id == user.data.id ?
                                        <div className="outer">
                                            {item.status !== 1 ? <small>Chưa được duyệt</small> : ''}
                                            <div className="option">
                                                <button className="btn btn-option" type="button" title="Xóa" onClick={() => deleteComment(item.id)}>
                                                    <span className="fas fa-trash-alt text-danger"></span>
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        ''
                                    }
                                </>
                                    :
                                    <>
                                        {post_info.user_id === user.data.id ?
                                            <>
                                                <div className="user-info">
                                                    <div className="avatar">
                                                        {item.avatar ?
                                                            <img src={item.avatar} alt="" className="avatar" />
                                                            :
                                                            <img src={item.typeaccount === 2 ? avatar_student : (item.gender === 1 ? men_teacher : girl_teacher)} alt="" className="avatar" />
                                                        }
                                                    </div>
                                                </div>
                                                <div className={"content-comment not-check"}>
                                                    <div className="fullname">
                                                        {item.fullname}
                                                    </div>
                                                    <div className="comment-text">
                                                        {item.comments}
                                                    </div>
                                                    <div className="comment-date">
                                                        <Moment format="HH:mm DD/MM/YYYY">{new Date(item.datecreate * 1000)}</Moment>
                                                    </div>
                                                </div>

                                                <div className="right-option">
                                                    <div className="w-100 text-center">
                                                        <small>Kiểm duyệt</small>
                                                    </div>
                                                    <button className="btn btn-option" type="button" title="Duyệt" onClick={() => checkComment(item.id)}>
                                                        <span className="fas fa-check-circle text-success"></span>
                                                    </button>
                                                    <button className="btn btn-option" type="button" title="Xóa" onClick={() => deleteComment(item.id)}>
                                                        <span className="fas fa-trash-alt text-danger"></span>
                                                    </button>
                                                </div>

                                            </> : ''
                                        }
                                    </>
                                }
                            </div>)
                        })}
                    </div>}
                </div>

                <div className="comment-form d-flex mt-2">
                    <span className="placeholder">{!contentComment || contentComment === '' ? "Viết bình luận..." : ''}</span>
                    <ContentEditable type="text" innerRef={inputRef} className="form-control" onFocus={()=>setContentComment('')} onChange={(e) => setContentComment(e.target.value)} html={contentComment} />
                    {loading ? <Spinner /> : <button className="btn btn-sender" onClick={() => { sendComment() }}> <img src={iconsend}/></button>}
                </div>
            </div>
        </div>
    );
}
