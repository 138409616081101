import React, {  useState } from "react";
import ButtonCheck from "../../component/ButtonCheck";
import { Cell } from "react-sticky-table";

export default function ItemLunch(props) {
  const { i, data, choiceItem, isaction } = props;
  const [localData, setLocalData] = useState(data);

  const updateOnDay = (stt) => {
    setLocalData({ ...localData, status: data.islunch===1?stt:2 });
    choiceItem({ ...localData, status: data.islunch===1?stt:2 });
  };

  const updateOnDay2 = (stt) => {
    setLocalData({ ...localData, status2: stt });
    choiceItem({ ...localData, status2: stt });
  };

  

  return (
    <>
      <Cell>
        <div className="text-center w-100">{i + 1}</div>
      </Cell>
      <Cell>{data.fullname}</Cell>
      <Cell>
        <div className="text-center w-100">
          <i
            className={
              data.gender === 1
                ? "fas fa-male text-success"
                : "fas fa-female text-danger"
            }
          ></i>
        </div>
      </Cell>
      <Cell>
        <div className="text-center w-100">
          <span onClick={() => (isaction ? updateOnDay2(1) : null)}>
            <ButtonCheck checked={localData.status2 === 1} color="#21c83d"></ButtonCheck>
          </span>
        </div>
      </Cell>
      <Cell>
        <div className="text-center w-100">
          <span onClick={() => (isaction ? updateOnDay2(2) : null)}>
            <ButtonCheck checked={localData.status2 === 2} color="#db0d18"></ButtonCheck>
          </span>
        </div>
      </Cell>
      <Cell>
        <input
          className="form-control form-control-sm"
          value={localData.notes2||''}
          onChange={(e) => setLocalData({ ...localData, notes2: e.target.value })}
          onBlur={(e) => choiceItem({ ...localData, notes2: e.target.value })}
        ></input>
      </Cell>
      <Cell>
       
          <div className="text-center w-100">
            <span onClick={() => (isaction && data.islunch === 1? updateOnDay(1) : null)}>
              <ButtonCheck
                checked={localData.status === 1}
                color="#21c83d"
              ></ButtonCheck>
            </span>
          </div>
      
      </Cell>
      <Cell>
       
          <div className="text-center w-100">
            <span onClick={() => (isaction && data.islunch === 1 ? updateOnDay(2) : null)}>
              <ButtonCheck
                checked={localData.status === 2}
                color="#db0d18"
              ></ButtonCheck>
            </span>
          </div>
       
      </Cell>
      <Cell>
      <input
          disabled={data.islunch !== 1}
          className="form-control form-control-sm"
          value={localData.notes||''}
          onChange={(e) => setLocalData({ ...localData, notes: e.target.value })}
          onBlur={(e) => choiceItem({ ...localData, notes: e.target.value })}
        ></input>
      </Cell>
    </>
  );
}
