import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/Akkhor";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import Axios from "axios";
import * as Yup from "yup";
import AlertErrorForm from "../component/AlertErrorForm";
import UserDropDown from "../component/UserDropDown";
import Spinner from "../component/Spinner";
import { URL_LIST_ALL } from "../configs/Api";
import { confirmAlert } from "react-confirm-alert";

export default function FormChuongTrinh() {
    const { id } = useParams();
    const [detail, setDetail] = useState(null);
    const [loading, setLoading] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const defaultdetail = {

    }
    let validForm = {
        title: Yup.string().trim().nullable().required('Nhập tên chương trình').max(150, "Độ dài không quá 150 ký tự"),
    }

    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape(validForm),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'books/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật chương trình thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                Axios.post(URL_LIST_ALL + 'books', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới chương trình thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });
    return (
        <AkkhorLayout idrole="list-books-tree">
            <div className="card height-auto mt-5">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>{!id ? 'Thêm mới' : 'Sửa'} chương trình học</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-sm-4 col-12 form-group">
                                <label>Tiêu đề <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("title")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                            </div>
                            <div className="w-100"></div>
                            <div className="col-sm-4 col-12 form-group">
                                <label>Mô tả</label>
                                <textarea type="text" placeholder="" className="form-control" {...formik.getFieldProps("description")}></textarea>

                            </div>
                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu lại</>}</button>
                                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}