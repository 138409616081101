import React from "react";
import parse from 'html-react-parser'
import GenderCode from "../../../component/GenderCode";

export default function QDropDrag(props) {
    const {answers} = props;
    
    return (<div className="col-12 form-group mt-3">
        {answers && <>
            {answers.correct.map((item,i)=><button key={i} className={"py-1 px-2 cursor-pointer rounded border-grey d-flex align-items-center"} ><GenderCode text_string={item} hideNumber={true}/></button>)}
        </>}
    </div>);
}