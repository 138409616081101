import React, { useState } from "react";
import MyEditor2 from "../../../component/MyEditor2";


export default function QMatching(props) {
    const {onAnswers,answers,maxNumber,setMaxNumber} = props;
    const [keywords] = useState(answers && answers.list?answers.list:[]);//
    const [corrects] = useState(answers && answers.correct?answers.correct:[]);//
    
    const getList=()=>{
        let list = [];
        for(let i=1; i<= maxNumber; i++) {
            list.push(i)
        }
        return list
    }

    const onChange = (value,i) => {
        let newList = [...keywords]
        newList[i] = value
        onAnswers({ ...answers,list: newList})
    }
    

    const onChangeCorrect = (value,i) => {
        let newList = [...corrects]
        newList[i] = value
        onAnswers({ ...answers,correct: newList})
    }
    

    return (<div className="col-12 form-group">
         <button type="button" className="btn btn-sm btn-info mb-3" onClick={()=>setMaxNumber(maxNumber+1)}>Thêm câu trả lời</button>
        <div className="row">
        <div className="col-6">
        {getList().map((item,i)=><div key={i} className="mb-2 ">
            <label>Câu hỏi phụ {i+1} </label>
            <div className="w-100"><MyEditor2 height={120} initialValue={keywords[i]?keywords[i]+'':''} onValue={(e)=>onChange(e,i)}/></div>
        </div>)}
        </div>
        <div  className="col-6">
        {getList().map((item,i)=><div key={i} className="mb-2 ">
            <label>Câu trả lời {i+1} </label>
            <div className="w-100"><MyEditor2 height={120} initialValue={corrects[i]?corrects[i]+'':''} onValue={(e)=>onChangeCorrect(e,i)}/></div>
        </div>)}
        </div>
        </div>
    </div>);
}
