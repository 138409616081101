import React, { useState } from "react";

export default function QFillTo(props) {
    const { currentAnswer } = props;
  
    const [choice] = useState(currentAnswer?currentAnswer:{})
    return (<div className="container-fluid">
        <div className="row mt-3 mb-3">
        {choice && <>
            {Object.values(choice).map((item, i) => <div className="col-12 mb-2" key={i}><textarea style={{ minHeight: 100 }} className={"form-control col-12 form-control-sm border"} value={item} readOnly></textarea></div>)}
        </>}
    </div></div>);
}