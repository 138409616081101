
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import AkkhorLayout from "../layout/Akkhor";
import { useLocation } from "react-router-dom";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/Api";
import ModalAddStudent from "../list/ListStudent_AddStudent";
import { useParams } from "react-router-dom";
import CustomList from "../component/CustomList";

const queryString = require('query-string');
const _gender = { 'Nữ': 0, 'Nam': 1 };

export default function ListStudentsInClass() {
  const { user } = useSelector((state) => state.auth);
  const { id } = useParams();
  const [myclass, setMyClass] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [filterShow, setFilterShow] = useState(false)
  const [fullname, setFullName] = useState("")
  const [uCode, setUCode] = useState("")

  const [params, setParams] = useState("status=1&class_id=" + (id ? id : ''));


  const columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "code", label: "Mã số", options: { filter: false, sort: true } },
    { name: "fullname", label: "Họ và tên", options: { filter: false, sort: true } },
    {
      name: "gender",
      label: "Giới tính",
      options: {
        filter: true, sort: true,
        customBodyRender: (value) => {
          const icon = value === 1 ? "fas fa-male text-success" : "fas fa-female text-danger";
          return <i className={icon}></i>;
        },
        customFilterListOptions: { render: v => `Giới tinh: ${v}` },
        filterType: 'dropdown',
        filterOptions: {
          names: ['Nữ', 'Nam'],
          logic: (location, filters, row) => {
            if (filters.length && _gender[filters[0]] === location) return false;
            else return true;
          }
        }
      }
    },
    {name: "birthday",label: "Sinh nhật"},
    { name: "mobile", label: "Điện thoại", options: { filter: false, sort: true } },
    {name: "datecreate", label: "Ngày tạo",
      options: {
        filter: false, sort: true,
        customBodyRender: (value) => {
          const time = new Date(value * 1000);
          return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
        },
      }
    },
    {name: "id", label: "Thao tác",
      options: {
        filter: false, sort: true,
        customBodyRender: (value) => {
          
          return <button type="button" className="btn btn-sm btn-danger" onClick={()=>onDelete(value)}>Xóa khỏi lớp</button>
        },
      }
    }
  ];

  const onDelete = (idclass) => {
    Axios.put(URL_LIST_ALL + "students_class/" + idclass,{status:0}, user.config).then(async (res) => {
      if (res.data.status === "success") {
        alert("Xóa thành công")
        setRefresh(!refresh)
      } else console.log(res.data.message);
    }).catch((err) => console.log(err));
  }

  //Lấy danh sách lớp
  useEffect(() => {
    let mounted = true;
    if (id > 0) {
      Axios.get(URL_LIST_ALL + "class/" + id, user.config).then((res) => {
        if (res.data && res.data.status === "success") {
          if (mounted) setMyClass(res.data.data);
        } else console.log(res.data.message);
      }).catch((err) => console.log(err));
    }
    return () => (mounted = false);
  }, []);// eslint-disable-line


  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = params
    if(fullname && fullname !== '')  _param += "&fullname=" + fullname
    if(uCode && uCode !== '')  _param += "&code=" + uCode
    setParams(_param);
  };

  return (<AkkhorLayout idrole="list-students">
    <div className="card card-filter mb-3 mt-3">
      <div className="card-header">
        <div className="d-flex" onClick={() => setFilterShow(!filterShow)}>
          <div className="mr-auto">
            <h3 className="title">Tìm kiếm</h3>
          </div>
          <button className="btn btn-sm btn-defaul" onClick={() => setFilterShow(!filterShow)}><span className={!filterShow ? "fas fa-angle-down" : "fas fa-angle-up"}></span></button>
        </div>
      </div>
      <div className={filterShow ? "card-body" : 'hidden-filter'}>
        <form className="new-added-form" method="get" >
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 form-group">
              <label>Họ tên </label>
              <input type="text" placeholder="Tìm theo họ tên" value={fullname} onChange={(e) => setFullName(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 form-group">
              <label>Mã học sinh </label>
              <input type="text" placeholder="Tìm theo mã học sinh" value={uCode} onChange={(e) => setUCode(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>

            <div className="col-12 ">
              <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div className="row mt-5 ">
      <div className="col-12 col-sm-6">
        <h4 className="w-100 mb-2">Học sinh lớp: {myclass && myclass.title}</h4>
      </div>
      <div className="col-12 col-sm-6 d-flex justify-content-end">
        {myclass &&
          <ModalAddStudent myclass={myclass} classtype={1} btntitle={'Thêm học sinh vào lớp ' + myclass.title} refresh={() => setRefresh(!refresh)}></ModalAddStudent>
        }
      </div>
    </div>
    <CustomList name="students_class" idrole="list-students" noAction={true} params={params} title="Học sinh lớp " defaultLimit={50} refresh={refresh} columns={columns}></CustomList>
  </AkkhorLayout>);
}
