import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/Akkhor";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import Axios from "axios";
import * as Yup from "yup";
import AlertErrorForm from "../component/AlertErrorForm";
import { useSelector } from "react-redux";
import Spinner from "../component/Spinner";
import { confirmAlert } from "react-confirm-alert";
import { URL_LIST_ALL } from "../configs/Api";
import { Modal } from "react-bootstrap";
import ItemFormChild from "./ItemFormChild";

export default function FormTreeChuongTrinh() {
    const { id } = useParams();
    const { user } = useSelector((state) => state.auth);
    const [detail, setDetail] = useState(null);
    const [autoRefresh, setAutoRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const defaultdetail = {}
    const [choice, setChoice] = useState(null);
    const [parentId, setParentId] = useState(0);

    useEffect(
        function () {
            if (id > 0) {
                let mounted = true;
                Axios.get(URL_LIST_ALL + "books_tree/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            if (mounted) {
                                setParentId(id)
                                setDetail(res.data.data);
                            }
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [user, id, autoRefresh]
    );

    let validForm = { title: Yup.string().trim().nullable().required('Nhập tiêu đề').max(150, "Độ dài không quá 150 ký tự"), }
    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape(validForm),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'books_tree/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setAutoRefresh(!autoRefresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật cây kiến thức thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                Axios.post(URL_LIST_ALL + 'books_tree', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới cây kiến thức thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    
    const list_child = () => {
        
    }

    return (
        <AkkhorLayout idrole="list-books-tree">
            <div className="card height-auto mt-5">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>{!id ? 'Thêm mới' : 'Sửa'} cây kiến thức</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-sm-4 col-12 form-group">
                                <label>Tiêu đề <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("title")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                            </div>
                            <div className="w-100"></div>

                            {id > 0 && detail ?
                                <>
                                    <div className="col-12">
                                        <button type="button" className="btn btn-sm btn-info" onClick={() => { setChoice(true); setParentId(id) }}><span className="fas fa-plus"></span> Thêm nhánh</button>
                                    </div>
                                </>
                                :
                                <></>
                            }
                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu lại</>}</button>
                                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                            </div>
                        </div>
                    </form>
                </div>

                <Modal show={choice !== null} onHide={() => setChoice(null)} animation={true} size="lg" >
                    <Modal.Header closeButton>
                        <Modal.Title>Nhánh</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ItemFormChild parent_id={parentId}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary btn-sm" onClick={() => setChoice(null)} >Đóng</button>
                    </Modal.Footer>
                </Modal>
            </div>
        </AkkhorLayout>
    );
}