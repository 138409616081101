import Axios from "axios";
import React, { useEffect, useState } from "react";
import { URL_LIST_ALL } from "../configs/Api";
import { useSelector } from "react-redux";
import moment from "moment";

export default function FilterLunchMonth(props) {
    const { gradeChoice, setGradeChoice, setClassChoice ,classChoice, years, setYears, month, setMonth } = props;
    const { user } = useSelector((state) => state.auth);
    const { year_id } = useSelector((state) => state.save);
    const school_id = user && user.data && user.data.school_id>0?user.data.school_id:0;
    const [listGardes, setListGardes] = useState([]);
    const [listClass, setListClass] = useState([]);
    
   
    //Lấy danh sách khối
    useEffect( () => {
        let mounted = true;
        Axios.get(URL_LIST_ALL + "grades?orderby=title-ASC&page=1&limit=500&school_id=" + school_id, user.config).then((res) => {
            if (res.data && res.data.status === "success") {
                if(mounted)setListGardes(res.data.data);
                if (res.data.data && res.data.data.length > 0)
                if(mounted)setGradeChoice(res.data.data[res.data.data.length-1].id);
            }else console.log(res.data.message);
        }).catch((err) => console.log(err));
        return () =>  (mounted = false);
    }, [school_id,user]);// eslint-disable-line
    //Lấy danh sách lớp
    useEffect(  () => {
        let mounted = true;
        if (gradeChoice) {
            Axios.get(URL_LIST_ALL + "class?page=1&limit=500&grade_id=" + gradeChoice+ "&year_id=" + year_id,user.config).then((res) => {
                if (res.data && res.data.status === "success") {
                    if(mounted)setListClass(res.data.data);
                    if (res.data.data && res.data.data.length > 0)
                    {
                        if(mounted)setClassChoice(res.data.data[res.data.data.length-1].id);
                    }
                }else console.log(res.data.message);
            }).catch((err) => console.log(err));
        }
        return () =>  (mounted = false);
    }, [gradeChoice,year_id,user]);// eslint-disable-line

    let listyear = [];
    for (let i=2021;i<= moment().year();i++)
    {
        listyear.push(i);
    }
    const listmonth = [1,2,3,4,5,6,7,8,9,10,11,12];


    return (
        <form className="form-inline mt-4 mb-4">
            
            <label className="mr-2">Khối</label>   
            <select className="form-control form-control-sm" value={gradeChoice?gradeChoice:0} onChange={(e)=>setGradeChoice(e.target.value)}>
                <option value={0}>Tất cả khối</option>
                {listGardes.map((item)=>{
                    return  <option key={item.id} value={item.id}>{item.title}</option>
                })}
            </select>
            <label className="mr-2  mt-2 mt-sm-0 ml-md-2">Lớp học</label>   
            <select className="form-control form-control-sm" value={classChoice?classChoice:0} onChange={(e)=>setClassChoice(e.target.value)}>
                <option value={''}>Tất cả lớp</option>
                {listClass.map((item,index)=>{
                    return  <option key={index} value={item.id}>{item.title}</option>
                })}
            </select>
            <label className="mr-2  mt-2 mt-sm-0  ml-md-2">Năm</label>
            <select className="form-control form-control-sm" value={years?years:0} onChange={(e)=>setYears(e.target.value)}>
                <option value={0}>Tất cả năm</option>
                {listyear.map((item,index)=>{
                    return  <option key={index} value={item}>{item}</option>
                })}
            </select>
            <label className="mr-2 mt-2 mt-sm-0  ml-md-2">Tháng</label> 
            <select className="form-control form-control-sm text-capitalize" value={month?month:0} onChange={(e)=>setMonth(e.target.value)}>
                <option value={0} disabled>Chọn tháng</option>
                {listmonth.map((item,index)=>{
                    return  <option key={index} value={item}>Tháng {item}</option>
                })}
            </select>
           
        </form>
    );
}
