import Axios from "axios";
import React, { useEffect, useState } from "react";
import { URL_LIST_ALL } from "../configs/Api";
import moment from "moment";
import { useSelector } from "react-redux";
import SelectWeek from "./SelectWeek";
//SELECT  SUBSTRING_INDEX(fullname, ' ', 2)
export default function FilterClassWeek(props) {
    const { setClassChoice, classChoice, weekChoice, setWeekChoice, dayChoice, setDayChoice, idgroup, typeclass, allday, teacher, disableeGrade } = props;
    const { user } = useSelector((state) => state.auth);
    const { year_id } = useSelector((state) => state.save);
    const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
    const typeaccount = user && user.data && user.data.typeaccount > -1 ? user.data.typeaccount : 0;
    const [gradeChoice, setGradeChoice] = useState(null);
    const [listGardes, setListGardes] = useState([]);
    const [listClass, setListClass] = useState([]);
    const [listDay, setListDay] = useState([]);
    //Lấy danh sách khối
    useEffect(() => {
        if (disableeGrade !== true) {
            let mounted = true;
            Axios.get(URL_LIST_ALL + "grades?page=1&limit=500&orderby=title-ASC&school_id=" + school_id, user.config).then((res) => {
                if (res.data && res.data.status === "success") {
                    if (mounted) setListGardes(res.data.data);
                    if (res.data.data && res.data.data.length > 0)
                        if (mounted) setGradeChoice(res.data.data[res.data.data.length - 1].id);
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
            return () => (mounted = false);
        }
    }, [school_id, user]);// eslint-disable-line

    //Lấy danh sách lớp
    useEffect(() => {
        let mounted = true;
        if (school_id > 0 && year_id > 0 ) {
            if (disableeGrade === true || gradeChoice>0) {
                let url = URL_LIST_ALL + "class?page=1&limit=500&orderby=title-ASC&type=" + typeclass + "&school_id=" + school_id + "&year_id=" + year_id
                if( gradeChoice>0)  url +="&grade_id=" + gradeChoice;
                if (idgroup) url += '&group_id=' + idgroup;
                if (typeaccount === 1 && teacher > 0) url += '&teacher=' + teacher;
                Axios.get(url, user.config).then((res) => {
                    if (res.data && res.data.status === "success") {
                        if (mounted) {
                            let objData = {}
                            res.data.data.map((item) => {
                                objData[item.id] = item
                                return item;
                            })
                            setListClass(Object.values(objData));
                        }
                        if (res.data.data && res.data.data.length > 0) {
                            if (mounted) {
                                setClassChoice(res.data.data[res.data.data.length - 1].id);
                            }
                        }
                    } else console.log(res.data.message);
                }).catch((err) => console.log(err));
            }
        }
        return () => (mounted = false);
    }, [school_id, user, year_id, gradeChoice]);// eslint-disable-line

    const createDayFromWeek = (week) => {
        if (week) {
            let arrDay = [];
            const now = moment().format('YYYY-MM-DD');
            for (let index = 0; index < 5; index++) {
                let time = Date.parse(week.begin) + index * 24 * 60 * 60 * 1000;
                const date = moment(time).format('YYYY-MM-DD');
                if (allday !== true) {
                    if (now === date) setDayChoice(date)
                    else if (index === 0) setDayChoice(date)
                } else setDayChoice('all')
                arrDay.push({ date, title: moment(time).format('dddd') + ' - ' + moment(time).format('DD-MM-YYYY') })
            }
            setListDay(arrDay);
        } else {
            setListDay([])
            setDayChoice(null)
        }
    }


    return (
        <form className="form-inline">
            {disableeGrade!==true&&<>
            <label className="mr-2">Khối</label>
            <select className="form-control form-control-sm mr-md-2" value={gradeChoice ? gradeChoice : 0} onChange={(e) => {
                setClassChoice(null);
                setGradeChoice(e.target.value)
            }}>
                <option value={0}>Tất cả các khóa</option>
                {listGardes.map((item) => {
                    return <option key={item.id} value={item.id}>{item.title}</option>
                })}
            </select>
            </>}
            <label className="mr-2 mt-2 mt-sm-0 ">{idgroup === 5 ? 'Chọn xe' : 'Lớp học'}</label>
            <select className="form-control form-control-sm" value={classChoice ? classChoice : 0} onChange={(e) => setClassChoice(e.target.value)}>
                <option value={0} disabled>{idgroup === 5 ? 'Chọn xe' : 'Chọn lớp'}</option>
                {listClass.map((item, index) => {
                    return <option key={index} value={item.id}>{item.title}</option>
                })}
            </select>
            <label className="mr-2  mt-2 mt-sm-0  ml-md-2">Tuần thứ</label>
            <SelectWeek weekChoice={weekChoice} setWeekChoice={setWeekChoice} setWeek={(e) => createDayFromWeek(e)}></SelectWeek>
            <label className="mr-2  mt-2 mt-sm-0  ml-md-2">Ngày</label>
            <select className="form-control form-control-sm text-capitalize" value={dayChoice ? dayChoice : 0} onChange={(e) => setDayChoice(e.target.value)}>
                <option value={0} disabled>Chọn ngày</option>
                {allday === true && <option value={'all'}>Cả tuần</option>}
                {listDay.map((item, index) => {
                    return <option key={index} value={item.date} >{item.title}</option>
                })}
            </select>
        </form >
    );
}
