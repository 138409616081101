import React, { useEffect, useState } from "react";
import Axios from "axios";
import { URL_LIST_ALL } from "../../configs/Api";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import parse from 'html-react-parser'
import { CircularProgress } from "@material-ui/core";

export default function QuestionByTree(props) {
  const { user } = useSelector((state) => state.auth);
  const { tree, finish } = props;
  const [questions, setQuestions] = useState([]);
  const [questionsChoice, setQuestionsChoice] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (tree) {
      let mounted = true;
      setLoading(true);
      Axios.get(URL_LIST_ALL + "at_exam_questions?join=true&orderby=id-DESC&limit=500&tree_id=" + tree.id, user.config).then(async (res) => {
        setLoading(false);
        if (res.data.status === "success") {
          if (mounted) {
            setQuestions(res.data.data);
            if (tree.list) setQuestionsChoice(tree.list);
          }
        } else console.log(res.data.message);
      })
        .catch((err) => {console.log(err);setLoading(false);});
      return () => (mounted = false);
    }
  }, [tree]);// eslint-disable-line

  const addChoice = (_item) => {
    let newChoice = [...questionsChoice, _item];
    setQuestionsChoice(newChoice);
  }
  const removeChoice = (_index) => {
    let newChoice = [...questionsChoice];
    newChoice.splice(_index, 1);
    setQuestionsChoice(newChoice);
  }

  const showAnwser = (answers, correct) => {
    return (<div>
      {answers && Array.isArray(answers) && answers.length > 0 ? <>
        {answers.map((answ, index) => {
          if (answ) return <div key={index} className={(correct && correct.length > 0 && correct.includes(index)) || correct === index ? "text-success" : ''}>{parse(answ + '')}
            {(correct && answers && correct.length === answers.length) && <span className="text-success"> ({parse(correct[index] + '')})</span>}
          </div>
          else return null;
        })}
      </> : <>
        {correct && Array.isArray(correct) && correct.length > 0 ? <>
          {correct.map((answ, index) => {
            if (answ) return <div key={index} className="text-success">{parse(answ + '')}</div>
            else return null;
          })}</> : <>
        </>}
      </>}
    </div>)
  }

  const checkExits = (_quest) => {
    if (questionsChoice.length === 0) return true
    let find = true;
    questionsChoice.map((el) => {
      if (el.id === _quest.id) find = false;
      return el;
    });
    return find;
  }

  const choiceAll = () => {
    setQuestionsChoice(questions);
  }

  return (<>
    <Modal.Header closeButton>
      <Modal.Title>Lựa chọn câu hỏi theo cây kiến thức: {tree.title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="row">
        {loading ? <div className="w-100 d-flex justify-content-center p-5"><CircularProgress color="secondary" size={100} value={100} /></div> :
          <>
            <div className="col-sm-6">
              <div className="d-flex justify-content-between mb-2">
                <b>Danh sách Có {questions.length} câu hỏi</b>
                <button type="button" className="btn btn-sm btn-info" onClick={choiceAll}>Chọn tất cả</button>
              </div>
              <div style={{ height: 500, overflowY: 'auto' }}>
                {questions.map((quest, index) => {
                  return (<div key={index}>
                    {checkExits(quest) && <div key={index} className="border rounded mb-3 mr-2 font14 overflow-hidden">
                      <div className={"p-2 text-light bg-info"}>
                        <div><b>{quest.typetitle}</b></div>
                        <div><b>Tạo bởi : {quest.fullname} ({quest.email})</b></div>
                        {/* {(quest.intest && quest.intest === 1) ? <div className="text-danger">Đã được chọn tại phiếu bài tập khác</div> : ''} */}
                      </div>
                      <div className="p-2">
                        {quest.parent_title &&
                          <div className="border-bottom my-2" >Câu hỏi cha: {parse(quest.parent_title)}</div>
                        }
                        <div>{parse(quest.title)}</div>
                        <div className="border-bottom my-2" />
                        <div>{showAnwser(quest.answers, quest.corrects)}</div>
                        <div className="mt-1 d-flex justify-content-between align-items-center border-top pt-2">
                          <b>ID: {quest.id}  ({quest.leveltitle})</b>
                          <button type="button" onClick={() => addChoice(quest)} className="btn btn-sm btn-light btn-outline-secondary">Chọn câu hỏi</button>
                        </div>
                      </div>
                    </div>}
                  </div>
                  );
                })}
              </div>
            </div>
            <div className="col-sm-6">
              <b>Bạn đã chọn {questionsChoice.length} câu hỏi</b>
              <div style={{ height: 500, overflowY: 'auto' }}>
                {questionsChoice.map((quest, index) => {
                  return (
                    <div key={index} className="border rounded mb-3 mr-2 font14 overflow-hidden">
                      <div className="p-2 bg-info text-light">
                        <div><b>{quest.typetitle}</b></div>
                        <div><b>Tạo bởi : {quest.fullname} ({quest.email})</b></div>
                      </div>
                      <div className="p-2">
                        <div>{parse(quest.title)}</div>
                        <div className="border-bottom my-2" />
                        <div>{showAnwser(quest.answers, quest.corrects)}</div>
                        <div className="mt-1 d-flex justify-content-between align-items-center border-top pt-2">
                          <b>ID: {quest.id} ({quest.leveltitle})</b>
                          <button type="button" onClick={() => removeChoice(index)} className="btn btn-sm btn-danger ">Bỏ chọn câu hỏi</button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        }
      </div>
    </Modal.Body>
    <Modal.Footer>
      <button type="button" className="btn btn-info btn-sm" onClick={() => finish(tree, questionsChoice)} >Hoàn thành</button>
    </Modal.Footer>
  </>
  );
}
