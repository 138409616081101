import React from "react";
import SRList from "../../component/List";

export default function ListAttends(props) {
  const {moreSearch} = props;
  
  const columns = [
    { name: "teacher_id", label: "ID" ,options: { filter: true,sort: false }},
    { name: "fullname", label: "Giáo viên" ,options: { filter: true,sort: false }},
    { name: "shortname", label: "Tên viết tắt", options: { filter: true,sort: true } },
    { name: "mobile", label: "Điện thoại", options: { filter: true,sort: true } },
    { name: "email", label: "Email",options: { filter: true,sort: true } },
    { name: "sotiet", label: "Tổng số tiết" ,options: { filter: false,sort: true , }},
    { name: "diemdanh", label: "Điểm danh" ,options: { filter: false,sort: true , }},
  ];

  return (
    <>
      <SRList name="report_attends_range" moresearch={moreSearch} params={moreSearch?'begin='+moreSearch.begin+'&end='+moreSearch.end+'&type='+moreSearch.type:''} title="Tổng hợp điểm danh" defaultLimit={20} serverSide={true} columns={columns} noAction={true}></SRList>
    </>
  );
}
