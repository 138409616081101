
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SRList from "../component/List";
import Moment from "react-moment";
import AkkhorLayout from "../layout/Akkhor";

export default function ListBusManager() {
  const { user } = useSelector((state) => state.auth);
  const { year_id } = useSelector((state) => state.save);
  const [params, setParams] = useState('')
 

  useEffect(() => {
    let _params = 'type=3&viewtype=main&year_id='+year_id;
    _params += user && user.data && user.data.school_id>0?'&school_id='+user.data.school_id:0;
    setParams(_params)
  }, [year_id])// eslint-disable-line 

  const columns = [
    { name: "id", label: "ID",options: { filter: false,sort: true } },
    { name: "title", label: "Tiêu đề", width: 250 ,options: { filter: true,sort: true } },
    { name: "teachername", label: "Giáo viên phụ trách" ,options: { filter: true,sort: false }},
    { name: "datecreate",label: "Ngày đăng",
      options:{
        filter: false,sort: true ,
        customBodyRender: (value, tableMeta, updateValue) => {
          const time = new Date(value * 1000);
          return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
        },
      }
    },

  ];
  
  return (
    <AkkhorLayout idrole="bus_manager">
      <SRList name="bus_class" idrole="bus_manager" params={params} title="Xe bus" defaultLimit={50} serverSide={true} columns={columns} disabledLayout={true}></SRList>
    </AkkhorLayout>
  );
}
