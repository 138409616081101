
import Axios from "axios";
import React, { useEffect, useState } from "react";
import HtmlParser from "react-html-parser";
import CodeMirror from '@uiw/react-codemirror';
import { langs } from '@uiw/codemirror-extensions-langs';
import { githubLightInit } from '@uiw/codemirror-theme-github';


const Item = ({ item, showCode, onToggleCode }) => {
    return (<div>
        {showCode? 
                (
                    <CodeMirror
                    height="auto"
                    theme={githubLightInit({
                        settings: {
                            caret: '#c6c6c6',
                            fontFamily: 'monospace',
                        }
                    })}
                    readOnly={true}
                    extensions={[langs.python()]}
                    value={item.code}
                    
                    
                    />
                    )
            :
            (
            <CodeMirror
            height="auto"
            theme={githubLightInit({
                settings: {
                    caret: '#c6c6c6',
                    fontFamily: 'monospace',
                }
            })}
            readOnly={true}
            extensions={[langs.python()]}
            value={item.result}
            
            
            />
            )
            }
       
    </div> );
};

export default function ShowCodePython(props) {
    const {arrTab} = props
   
    const [activeTab, setActiveTab] = useState(0);
    
    const [showCode, setShowCode] = useState(true);
    const toggleCodeHandler = () => {
        setShowCode((prevShowCode) => !prevShowCode);
      };
    
  return (
    <div className="tab-group">
    
    <div className="w-100 bg-light header-show-code" >   
            <div className="title-tab">
                 {arrTab[0].text}
            </div>
            <button className="btn btn-sm" type="button" onClick={()=>toggleCodeHandler()} style={{color:showCode ? '#fff' : '#fff', background:showCode ? 'rgb(80 151 227)' : 'rgb(143 183 225)'}}>
                <i className="fas fa-chevron-right"></i>< i class="fas fa-window-minimize fa-xs"></i>
            </button>
        </div>
     
   
    <div class="tab-content" style={{border:"1px solid #ccc", padding:"10px", borderEndStartRadius:"10px",borderEndEndRadius:"10px",borderTop:"none",}}>
    {arrTab && arrTab.length > 0 && 
            arrTab.map((item, i) =>{
              
                return (
                 
                       <Item key={item.id} item={item} showCode={showCode} onToggleCode={toggleCodeHandler} />
                      
                )
                 
            })   
        } 
    </div>
    </div>
  );
}