import React from "react";

export default function ReportAtendsMonth(props) { // Student
  const { i, student, lunchs, dates } = props;
  let total = 0;
  const days = []
  for(let i =1; i<=dates; i++) {
    days.push(null)
  }

  for(const lunchDay in lunchs) {
    const day = lunchDay.split('-')[2]
    const obj = lunchs[lunchDay][student.id];
    days[day - 1] = obj
    if(obj && obj.status !== 1) total ++;
  }
  
  function Day(props) {
    const {d} = props
    if(d === null || d === undefined) return <td></td>
    return <td >{d.status === 1 ? <b className="text-success">x</b> : <b className="text-danger">o</b>}</td>
  }

  return (
    <>
      <td>
        <div className="text-center w-100">{i + 1}</div>
      </td>
      <td >{student.fullname}</td>
      {days.map((d, i) => <Day key={i} d={d}/>)}
      <td >{total>0?<b className="text-danger">{total}</b>:total}</td>
      </>
  )
}
