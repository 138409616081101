
import Axios from "axios";
import React, { useEffect, useState } from "react";
import HtmlParser from "react-html-parser";
import CodeMirror from '@uiw/react-codemirror';
import { langs } from '@uiw/codemirror-extensions-langs';
import { githubLightInit } from '@uiw/codemirror-theme-github';
export default function ShowTab(props) {
    const {arrTab} = props
    const [activeTab, setActiveTab] = useState(0);
    useEffect(()=>{
      if(arrTab  && arrTab.length > 0){
          setActiveTab(arrTab[0].id);
      }
    },[])
    const selectLangs = (type) => {
      if (type == 'javascript') return langs.jsx()
      if (type == 'html') return langs.html()
      if (type == 'python') return langs.python()
      if (type == 'php') return langs.php()
      if (type == 'css') return langs.css()
      if (type == 'java') return langs.java()
      if (type == 'sql') return langs.sql()
      if (type == 'kotlin') return langs.kotlin()
      if (type == 'c') return langs.c()
      return langs.html()
  }
  return (
    <div className="tab-group">
    <div className="w-100 d-flex justify-content-between">

      <ul className="nav nav-tabs">
        {arrTab && arrTab.length > 0 && 
            arrTab.map((tab, i) =>{
              return (
                <li className="nav-item" key={tab.id}>
                  <button type="button" className={"nav-link " + (activeTab === tab.id ? "active" : "")} onClick={() => setActiveTab(tab.id)}>{tab.text}</button>
                </li>
              )
            })    
        } 
      </ul>
    </div>
    <div class="tab-content" style={{border:"1px solid #ccc"}}>
    {arrTab &&activeTab&& arrTab.length > 0 && 
            arrTab.map((tab, i) =>{
              if(activeTab == tab.id){
                return (
                  <div key={tab.id}>
                      {
                          tab.type == "code"?
                          <CodeMirror
                            height="auto"
                            theme={githubLightInit({
                              settings: {
                                  caret: '#c6c6c6',
                                  fontFamily: 'monospace',
                              }
                          })}
                            readOnly={true}
                            extensions={[selectLangs(tab.lang)]}
                            value={tab.content}
                            
                            
                          />
                          :
                          <div className="view-show-html">{HtmlParser(tab.content)}</div>
                      }
                  </div>            
                )
              }    
            })   
        } 
    </div>
    </div>
  );
}