import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import ViewStep from "./ViewStep";
import QuestionByTree from "./QuestionByTree";
import Spinner from "../../component/Spinner";
import { CONFIG_STEP_TEST_1 } from "../../configs";
import Axios from "axios";
import { URL_LIST_ALL } from "../../configs/Api";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";

const ShowTre=({list, questions, onCheck}) => {
  const [show, setShow] = useState(null)
  return <>
   {list.map((item,index)=><div key={index} className="pl-3">
   {item.children && item.children.length >0 && <span className="fas fa-plus-circle text-success mr-2" onClick={()=>setShow(item.id)}></span>}
   <input type="checkbox" value={item.id}  onClick={(e)=>onCheck(e,item)} defaultChecked={(questions && questions[item.id])?true:false}/>
    &nbsp; {item.title} {show === item.id && item.children && item.children.length >0 && <div className="pl-3"><ShowTre list={item.children} onCheck={onCheck} /></div>}
  </div>)}
  </>
}

export default function PhieuBaiTapStep2(props) {
  const { user } = useSelector((state) => state.auth);
  const { detail, nextStep ,previewStep} = props;
  const [tree, setTree] = useState(null);
  const [mytree, setMyTree] = useState(null);

  useEffect( ()=> {
    if (detail) {
      let mounted = true;
      Axios.get(URL_LIST_ALL + "param_trees/1?grade_id=" + detail.grade_id+'&subject_id='+ detail.subject_id, user.config)
        .then(async (res) => {
          if (res.data.status === "success") {
            if (mounted) setTree(res.data.data);
          } else console.log(res.data.message);
        })
        .catch((err) => console.log(err));
      return () => (mounted = false);
    }
  },[detail]);// eslint-disable-line

  const formik = useFormik({
    initialValues:detail,
    enableReinitialize:true,
    validationSchema: Yup.object().shape({
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false)
      nextStep(values)
    },
  });

  const onCheck = (e,_item) => {
    if(e.target.checked) {
      setMyTree(_item)
    }else{
      let questions = {...formik.values.questions};
      delete questions[_item.id];
      formik.setFieldValue('questions',questions);
    }
    
  }

  const actionFinished = (_tree,_question)=>{
    setMyTree(null);
    let questions = {...formik.values.questions};
    questions[_tree.id] = _tree;
    if(_question.length>0)questions[_tree.id].list = _question;
    else delete questions[_tree.id];
    formik.setFieldValue('questions',questions);
  }
  
  return (
    <div className="row">
       <div className="col-sm-12 col-md-6 offset-md-3"><ViewStep step={1} arrStep={CONFIG_STEP_TEST_1}/></div>
      <div className="col-sm-12">
        {(tree && formik )&&<form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-md-4">
              <h4>Cây kiến thức</h4>
              <div className="border rounded p-2">
                <ShowTre list={tree} questions={formik.values.questions} onCheck={onCheck} />
              </div> 
            </div>
            <div className="col-md-8">
            <h4>Các câu hỏi đã chọn</h4>
            {formik.values.questions&&<div>
              {Object.values(formik.values.questions).map((item,index) =>{
                return(<div key={index} className="border rounded mb-3 overflow-hidden">
                  <div className="bg-info p-2 text-light"><b>{item.title}</b></div>
                  <div className="p-2">
                    <div><p>Số câu hỏi đã chọn</p><p><b>{item.list.length}</b></p></div>
                    <div className="mt-2">
                      <button type="button" onClick={()=>setMyTree(item)} className="btn btn-sm btn-light btn-outline-secondary text-dark">Chỉnh sửa câu hỏi</button>
                    </div>
                  </div>
                </div>)
              })}
            </div>}
            </div>
            <div className="col-12 form-group mg-t-8 text-center">
              <button type="button" onClick={previewStep} className="btn-fill-lg bg-blue-dark btn-hover-yellow" >Quay lại</button>
              <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" > {formik.isSubmitting ? <Spinner /> : <>Tiếp tục</>}</button>
            </div>
          </div>
          <Modal show={mytree!==null} onHide={() => setMyTree(null)} animation={true} size="xl" dialogClassName="modal-90w">
            {mytree!=null&&<QuestionByTree tree={mytree} finish={actionFinished}  />}
        </Modal>
        </form>}
      </div>
    </div>
  );
}
