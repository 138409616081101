import React, { useState } from "react";

export default function QFillTo(props) {
    const { type, answers } = props;
    const [choice] = useState(answers.correct)

    return (<div className="container-fluid question-choisce"><div className="row mt-3 mb-3">
        {type === 'question' && <>
            {type === 'question' && answers.correct.map((item, i) => {
                let q_choice = choice[i]
                if(q_choice.includes('||')){
                    q_choice = q_choice.replaceAll('||',' hoặc ');
                }
                return <div className={"col-12 item-fill mt-1 " + (answers.correct.length > 1 ? "col-sm-6" : "")} key={i}>
                    <span className="text-number">{i + 1}</span>
                    <textarea style={{ minHeight: 100 }} className={"form-control w-100 form-control-sm border"} value={q_choice} readOnly></textarea>
                </div>
            })}
        </>}
        {type === 'answer' && Object.entries(answers.correct).map((item, i) => {

            return (<div className={"col-12 item-fill mt-1 " + (Object.keys(answers.correct).length > 1 && "col-sm-6")} key={i}>
                <span className="text-number">{i + 1}</span> <textarea style={{ minHeight: 100 }} className={"form-control col-12 form-control-sm border"} value={answers.correct[i]} readOnly></textarea>
            </div>)
        }
        )}
    </div></div>);
}