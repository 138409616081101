
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import AkkhorLayout from "../layout/Akkhor";
import AlertErrorForm from "../component/AlertErrorForm";
import QRCodeGenerator from "../component/QRCodeGenerator";

export default function QrCodeGender() {
    const [urlImage, setUrlImage] = useState(null)

    const formik = useFormik({
        initialValues: { url: '', size: '250x250' },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            url: Yup.string().trim().nullable().required('Nhập nội dung'),
            size: Yup.string().trim().nullable().required('Nhập kích thước'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setUrlImage("https://chart.googleapis.com/chart?cht=qr&hl=vi&chs=" + values.size + "&chl=" + values.url)
        },
    });

    return (
        <AkkhorLayout idrole="tao-qrcode">
            <div className="card height-auto mt-5">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Tạo mã QRcode</h3>
                        </div>
                    </div>
                    <form className="new-added-form  form-info" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <QRCodeGenerator />
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
