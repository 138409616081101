import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Axios from "axios";
import { CONFIG_STEP_TEST_1 } from "../configs";
import ViewStep from "../examination/tests/ViewStep";
import { URL_LIST_ALL } from "../configs/Api";
import UserDropDown from "../component/UserDropDown";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";

export default function RacingStep1(props) {
  const { user } = useSelector((state) => state.auth);
  const { nextStep, detail } = props
  const defaultdetail = { type:1,author_id: user.data.id, title: '', status: 1, number: 0, total_point: 10, grade_id: 0, subject_id: '', category_id: 0, questions: {} }
  const [gradeID,setGradeID] = useState(detail && detail.grade_id);
  const formik = useFormik({
    initialValues: detail ? detail : defaultdetail,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      title: Yup.string().trim().nullable().required('Nhập tên phiếu bài tập').max(150, "Độ dài không quá 150 ký tự"),
      grade_id: Yup.string().trim().nullable().required('Vui lòng chọn khóa học'),
      category_id: Yup.string().trim().nullable().required('Vui lòng chọn danh mục'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false)
      nextStep(values)
    },
  });

  const setSubject = (value) => {
    Axios.get(URL_LIST_ALL + "subjects?grade_id=" + value, user.config)
      .then(async (res) => {
        if (res.data.status === "success") {
          let data_subject = res.data.data
          if (data_subject) {
            formik.setFieldValue("subject_id", data_subject[0].id)
          }
        } else console.log(res.data.message);
      }).catch((err) => { console.log(err) });
  }

  useEffect(() => {
    if(detail && detail.grade_id > 0){
      setGradeID(detail.grade_id)
    }
    
  }, [ detail]);// eslint-disable-line
  return (
    <div className="row">
      <div className="col-sm-12 col-md-6 offset-md-3">
        <ViewStep step={0} arrStep={CONFIG_STEP_TEST_1} />
        <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-12 form-group">
              <label>Tên bài thi Tomo Racing <span className="text-danger">*</span></label>
              <input type="text" className="form-control h-31" placeholder="Nhập tên bài thi Tomo Racing" name="title"
                {...formik.getFieldProps("title")}
              ></input>
              <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
            </div>
            <div className="col-12 form-group">
              <label>Chọn khóa học <span className="text-danger">*</span></label>
              <UserDropDown apiname={"khoahoc?orderby=title-ASC&"} placeholder="Chọn khóa học" colvalue={"khoahoc.id"} colname={"khoahoc.title"} formik={formik} name="grade_id"
                onChange={async (e) => { 
                  await formik.setFieldValue("grade_id", e); 
                  setSubject(e) 
                  setGradeID(e) 
                }}
              ></UserDropDown>
              <AlertErrorForm formik={formik} name="grade_id"></AlertErrorForm>
            </div>
            <input type="hidden" placeholder="" className="form-control height32" {...formik.getFieldProps("subject_id")} autoComplete="off" />
          
            <div className="col-12 form-group">
              <label>Chọn danh mục <span className="text-danger">*</span> </label>
               <UserDropDown apiname={"at_exam_category?orderby=id-ASC&limit=500&grade_id=" + gradeID} placeholder="Chọn danh mục" colvalue={"at_exam_category.id"} colname={"at_exam_category.title"} formik={formik} name="category_id"
                onChange={async (e) => { 
                  await formik.setFieldValue("category_id", e);
                 }}
              ></UserDropDown>
              <AlertErrorForm formik={formik} name="category_id"></AlertErrorForm>
            </div>
            <div className="col-12 form-group mg-t-8 text-center">
              <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" > {formik.isSubmitting ? <Spinner /> : <>Tiếp tục</>}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
