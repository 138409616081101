
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/Akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/Api";
import { useLocation, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import UserDropDown from "../component/UserDropDown";


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function FormBaiHoc(props) {
    const {khID,id,loadData,closeModal} = props
    let query = useQuery();
    const { user } = useSelector((state) => state.auth);
    const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
   
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);
    

    const defaultdetail = {
        school_id,khoahoc_id:khID,parent_id:0, author_id: user.data.id, title: '', status: 1,
    }
    useEffect(
        function () {
            if (id > 0) {
                let mounted = true;
                Axios.get(URL_LIST_ALL + "khoahoc_baihoc/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            
                            if (mounted) {
                                setDetail(res.data.data);    
                            }
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [user, id, refresh]
    );
    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            title: Yup.string().trim().nullable().required('Nhập tiêu đề').max(150, "Độ dài không quá 150 ký tự")
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'khoahoc_baihoc/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        loadData()
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật bài học thành công',
                            buttons: [{ label: 'OK' }]
                        });
                        closeModal()
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                Axios.post(URL_LIST_ALL + 'khoahoc_baihoc', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        loadData()
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới bài học thành công',
                            buttons: [{ label: 'OK' }]
                        });
                        closeModal()
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    return (
       
        <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
            <div className="row">
                <div className="col-lg-6 col-12 form-group">
                    <label>Nhập tiêu đề <span className="text-danger">*</span></label>
                    <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("title")} autoComplete="off" />
                    <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                </div>
                <div className="col-xl-4 col-lg-6 col-12 form-group">
                    <label>Cha</label>
                    {khID && 
                        <UserDropDown apiname={"khoahoc_baihoc?orderby=title-ASC&khoahoc_id="+khID+"&parent_id=0"} placeholder="Chọn bào học" colvalue={'khoahoc_baihoc.id'} colname={'khoahoc_baihoc.title'} formik={formik} name='parent_id'
                            onChange={async (e) => {
                                await formik.setFieldValue('parent_id', e)
                            }}></UserDropDown>
                    }
                </div>
                <div className="col-lg-6 col-12 form-group">
                    <label>Trạng thái</label>
                    <select className="form-control height32" {...formik.getFieldProps("status")}>
                        <option value="0">Khoá</option>
                        <option value="1">Hoạt động</option>
                    </select>
                </div>

                <div className="col-12 form-group mg-t-8">
                    <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu lại</>}</button>
                    <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                </div>
            </div>
        </form>
          
    );
}
