import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveAnswers } from "../../../redux/Actions";

export default function QFillTo(props) {
    const { item, currentAnswer, checkQuestions,objAnswers } = props;
    const { Answers } = useSelector(state => state.save);
    const dispatch = useDispatch();
    const [checkPercent,setCheckPercent] = useState(false)
    const [answersTrue,setAnswersTrue] = useState("")
    const corrects = item.corrects ? (Array.isArray(item.corrects) ? item.corrects : JSON.parse(item.corrects)) : null;
    const [choice, setChoice] = useState(currentAnswer ? currentAnswer : {})
    useEffect(()=>{
        if(Answers[item.id]){
            if(Answers[item.id].point == 1){
                setCheckPercent(true)
            }else{
                setCheckPercent(false)
                if (corrects) {
                    corrects.map((i, index) => {
                        if (i.includes("||")) {
                            const arr = i.split('||').map(string => string.trim())
                            setAnswersTrue(arr[0])
                            // if (choice[index] && arr.includes(choice[index].trim())) percent += 100 / corrects.length
                        } else {
                            setAnswersTrue(i)
                        }
                        return i
                    });
                }
            }
        }
    },[checkQuestions])
    const chooseItem = (index, str) => {
        let newChoice = { ...choice }
        if (str) newChoice[index] = str;
        else delete newChoice[index]
        setChoice(newChoice)
    }
    const onBlur = (e) => {
        if (e.target.value) {
            let percent = 0
            if (corrects) {
                corrects.map((i, index) => {
                    if (i.includes("||")) {
                        const arr = i.split('||').map(string => string.trim())
                        if (choice[index] && arr.includes(choice[index].trim())) percent += 100 / corrects.length
                    } else {
                        if (choice[index] == i) percent += 100 / corrects.length
                    }
                    return i
                });
            }
            percent == 100 ?setCheckPercent(true):setCheckPercent(false)
            let newListAnswers = { ...Answers }
            newListAnswers[item.id] = { question: item, answers: choice, percent: percent === 100 ? 100 : 0,point: percent === 100 ? 1 : 0};
           
            dispatch(saveAnswers(newListAnswers))
        }
    }

    return (
        <>
                {objAnswers[item.id] ?
                <>
                     <div className="container-fluid question-choisce">
                        <div className="row mt-3 mb-3">
                            {corrects && <>
                                {corrects.map((item, i) =>
                                    <div className={"col-12 item-fill " + (corrects.length > 1 ? "col-sm-6 mt-2" : "")} key={i}>
                                        <span className="text-number">{i + 1}</span> <textarea style={{ minHeight: 100 }} className={"form-control w-100 form-control-sm border"} value={choice[i] || ''} ></textarea>
                                    </div>
                                )}
                            </>}
                        </div>   
                    </div>
                    {checkPercent == true?
                        null
                    :
                        <div className="col-12 rounded pt-1 pb-1" style={{ backgroundColor: "rgba(255,223,224)" }}>
                            <div className="row">
                                <div className="col-12 col-sm-3">
                                    <strong>Đáp án đúng là: </strong> 
                                    <strong >{answersTrue}</strong>
                                    </div>
                                
                            </div>
                        </div>
                    }
                </>
                   
                :
                
                <div className="container-fluid question-choisce">
                    <div className="row mt-3 mb-3">
                        {corrects && <>
                            {corrects.map((item, i) =>
                                <div className={"col-12 item-fill " + (corrects.length > 1 ? "col-sm-6 mt-2" : "")} key={i}>
                                    <span className="text-number">{i + 1}</span> <textarea style={{ minHeight: 100 }} className={"form-control w-100 form-control-sm border"} value={choice[i] || ''} onChange={(e) => chooseItem(i, e.target.value)} onBlur={onBlur} ></textarea>
                                </div>
                            )}
                        </>}
                    </div>
            </div>
                }
        </>
      );
}