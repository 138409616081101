import React, { useEffect, useState } from "react";
import ButtonCheck from "../../component/ButtonCheck";
import Violation from "../../component/Violation";
import {Cell} from 'react-sticky-table';
import Axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";
import { URL_LIST_ALL } from "../../configs/Api";

export default function ItemExamOfExercise(props) {
    const { i, data,isaction } = props;
    const { user } = useSelector((state) => state.auth);
    const [attends, setAttends] = useState(null);
    const [currentData, setCurrentData] = useState(data);
    
    const updateOnDay = (stt) => {
        setAttends(stt);
        let newdata = {...currentData,status: stt}
        setCurrentData(newdata);
        runAPI(newdata)
    };

    useEffect(() => {
        setAttends(parseInt(currentData.status))
    }, [currentData]);

    const setChangeData=(e) => {
        let newdata = {...e,status: attends}
        setCurrentData(newdata);
    }
    
    const runAPI=(_data) => {
        delete _data.id;
        Axios.post(URL_LIST_ALL + "students_onday", _data,user.config).then((res)=>{
        if(res.data.status === "success")
        {
           // confirmAlert({title: "Thông báo", message: 'Lưu thành công', buttons: [ { label: "Đóng" }]});
        }else{
            confirmAlert({title: "Lỗi",  message: data.fullname+': '+res.data.message, buttons: [ { label: "Đóng" }]});
        }
        }).catch((err)=>{console.log(err)});
    }

    return (
        <>
            <Cell>
                <div className="text-center w-100">{i+1}</div>
            </Cell>
            <Cell>
                {data.fullname}
            </Cell>
           
            <Cell>
                <div className="text-center w-100"><i className={data.gender === 1
                    ? "fas fa-male text-success"
                    : "fas fa-female text-danger"}></i>
                </div>
            </Cell>
            <Cell>
                <div className="text-center w-100">
                <span onClick={() => isaction?updateOnDay(1):null}><ButtonCheck checked={attends===1} color='#21c83d' ></ButtonCheck></span>
                </div>
            </Cell>
            <Cell>
                <div className="text-center w-100">
                <span onClick={() => isaction?updateOnDay(2):null}><ButtonCheck checked={attends===2} color='#efd732'></ButtonCheck></span>
                </div>
            </Cell>
            <Cell>
                <div className="text-center w-100">
                <span onClick={() => isaction?updateOnDay(3):null}><ButtonCheck checked={attends===3} color='#db0d18'></ButtonCheck></span>
                </div>
            </Cell>
            <Violation data={currentData} updateData={setChangeData} isaction={isaction}></Violation>
            
        </>
    );
}
