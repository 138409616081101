import React, { useEffect, useState } from "react";
import AkkhorLayout from '../../layout/Akkhor';
import moment from "moment";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import ListAttendsDetail from "./ListAttendsDetail";
import ListAttends from "./ListAttends";



export default function ReportTimeKeepingPage() {
  const [moreSearch, setMoreSearch] = useState(null)
  const [reload, setReload] = useState(false)
  const [startDate, setStartDate] = useState(moment(new Date()))
  const [endDate, setEndDate] = useState(moment(new Date()))
  const [type, setType] = useState(1)
  const [typeCheck, setTypeCheck] = useState(1)
  useEffect(() => { 
    if(startDate !== null && endDate !== null && (reload===true || moreSearch===null))
    {
      const begin = startDate.format('YYYY-MM-DD');
      const end = endDate.format('YYYY-MM-DD');
      setMoreSearch({begin,end,type:typeCheck})
      setReload(false)
    }
  },[startDate,endDate, reload])// eslint-disable-line
  

  const handleCallback = (start, end, label) =>{
    setStartDate(start)
    setEndDate(end)
  }


  return (
    <AkkhorLayout idrole="report.report-timekeeping">
      <h4 className="mt-2" >Báo cáo giáo viên điểm danh</h4>
      <div className='d-flex align-items-center mt-3'>
      <DateRangePicker initialSettings={{ startDate: startDate, endDate: endDate, maxDate: moment() }}
       onCallback={handleCallback}
      >
       <div className='d-flex align-items-center'>
          <span style={{whiteSpace:'nowrap'}} className="mr-2">Từ ngày</span>
          <input
            className="form-control form-control-sm"
            placeholder='Từ ngày'
            value={startDate.format('DD-MM-YYYY')}
            readOnly={true}
            onChange={()=>null}
          />
          <span className='fas fa-arrow-right mx-4' />
          <span style={{whiteSpace:'nowrap'}} className="mr-2">Đến ngày</span>
          <input
           className="form-control form-control-sm"
            placeholder='Đến ngày'
            value={endDate.format('DD-MM-YYYY')}
            readOnly={true}
            onChange={()=>null}
          />
        </div>
      </DateRangePicker>
      <select name="type" className="form-control form-control-sm ml-3" style={{width:'auto'}} value={typeCheck} onChange={(e)=>setTypeCheck(e.target.value)} >
        <option value={1} >Điểm danh học sinh</option>
        <option value={2} >Kiểm tra vở ghi chép</option>
        <option value={3} >Kiểm tra bài tập</option>
      </select>
      <button type="button" onClick={()=>{setType(1);setReload(true)}} className="btn btn-primary btn-sm ml-3">Xem báo cáo tổng hợp</button>
      <button type="button" onClick={()=>{setType(2);setReload(true)}} className="btn btn-primary btn-sm ml-3">Xem báo cáo chi tiết</button>
      </div>
      {type===1?<ListAttends moreSearch={moreSearch} />:<ListAttendsDetail moreSearch={moreSearch} />}
    </AkkhorLayout>
  );
}
