
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import AkkhorLayout from "../../layout/Akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../../component/AlertErrorForm";
import Spinner from "../../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../../configs/Api";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import UserDropDown from "../../component/UserDropDown";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from "moment";
import { Accordion, Card } from "react-bootstrap";
import UserDropDownClass from "../../component/UserDropDownClass";
import StudentByClass from "../tests/StudentByClass";


export default function FormTestAzota() {
    const { user } = useSelector((state) => state.auth);
    const { year_id } = useSelector((state) => state.save);
    const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [dateBegin, setDateBegin] = useState(undefined);
    const [dateEnd, setDateEnd] = useState(undefined);
    const [refresh, setRefresh] = useState(false);
    const [fileUpload, setFileUpload] = useState([]);
    const inputRef = useRef(null);
    const formatDate = 'DD-MM-YYYY'

    const defaultdetail = {
        year_id: year_id, school_id, author_id: user.data.id, title: '', status: 1, grade_id: '', class_id: '', listclass: null, type_id: '', type: 3, datebegin: null, dateend: null, files: null
    }
    useEffect(
        function () {
            if (id > 0) {
                let mounted = true;
                Axios.get(URL_LIST_ALL + "at_exam_tests/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            if (mounted) {
                                let _detail = res.data.data
                                setFileUpload(_detail.files)
                                setDateBegin( moment(new Date(_detail.datebegin*1000)).format(formatDate))
                                setDateEnd( moment(new Date(_detail.dateend*1000)).format(formatDate))
                                setDetail(_detail);
                            }
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [user, id, refresh]
    );
    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            grade_id: Yup.string().trim().nullable().required('Vui lòng chọn khối'),
            title: Yup.string().trim().nullable().required('Nhập tên phiếu bài tập').max(150, "Độ dài không quá 150 ký tự"),
            datebegin: Yup.string().trim().nullable().required('Chọn thời gian bắt đầu và kết thúc'),
            listclass: Yup.string().trim().nullable().required('Bạn chưa chọn lớp')
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            values.number = 1
            values.listclass.map((item, index) => {
                if (values.hasOwnProperty('class_' + item.value)) {
                    values.listclass[index] = values['class_' + item.value]
                }
                return item
            })
            // console.log(values);
            if (id > 0) {
                Axios.put(URL_LIST_ALL + "at_exam_tests/" + id, values, user.config)
                    .then((res) => {
                        setLoading(false);
                        setSubmitting(false);
                        setRefresh(!refresh)
                        if (res.data.status === "success") {
                            if (!refresh) resetForm();
                            confirmAlert({ title: "Thành công", message: "Bạn cập nhật phiếu bài tập thành công", buttons: [{ label: "OK", onClick: () => { window.location.reload() } }], });
                        } else {
                            confirmAlert({ title: "Lỗi", message: res.data.message, buttons: [{ label: "OK" }], });
                        }
                    })
                    .catch((err) => console.log(err));
            } else {
                Axios.post(URL_LIST_ALL + "at_exam_tests", values, user.config)
                    .then((res) => {
                        setLoading(false);
                        setSubmitting(false);
                        setRefresh(!refresh)
                        if (res.data.status === "success") {

                            confirmAlert({ title: "Thành công", message: "Bạn thêm mới phiếu bài tập thành công", buttons: [{ label: "OK", onClick: () => { window.location.reload() } }] });
                        } else {
                            confirmAlert({ title: "Lỗi", message: res.data.message, buttons: [{ label: "OK" }], });
                        }
                    })
                    .catch((err) => console.log(err));
            }
        },
    });

    const handleCallback = (start, end, label) => {
        formik.setFieldValue("datebegin", Math.floor(start / 1000.0))
        formik.setFieldValue("dateend", Math.floor(end / 1000.0))
        setDateBegin(moment(new Date(start)).format(formatDate))
        setDateEnd(moment(new Date(end)).format(formatDate))
        // console.log(moment(new Date(detail.datebegin*1000)).format(formatDate));
    }

    const uploadAudio = async (event) => {

        const fileAudio = event.target.files[0]
        if (fileAudio) {
            let data = new FormData()
            data.append("type", "s3")
            data.append("name", "file")
            data.append("file", fileAudio)
            data.append("filetype", fileAudio.type)
            let file_size = fileAudio.size;
            if (file_size > 3145728) {
                confirmAlert({
                    title: 'Lỗi',
                    message: "File không được lớn hơn 3 Mb",
                    buttons: [{ label: 'OK' }]
                })
                return
            } else {
                Axios.post(URL_LIST_ALL + "upload", data, user.config).then(async (res) => {
                    if (res.data.status === "success") {

                        let newList = fileUpload.slice();
                        newList.push({ url: res.data.url, type: res.data.type })
                        setFileUpload(newList)
                        await formik.setFieldValue('files', newList)
                    } else {
                        return;
                    }
                }).catch((err) => { console.log(err) })
            }

        }
    }

    const removeFile = async (url) => {
        let newList = []
        fileUpload.map(function (item) {
            if (item.url !== url) {
                newList.push(item)
            }
            return item
        })
        if (newList) {
            setFileUpload(newList)
            await formik.setFieldValue('files', newList)
        }
    }

    
    return (
        <AkkhorLayout idrole="list-tests-azota">
            <div className="card height-auto mt-5">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Bài khảo thí</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-12 form-group">
                                <label>Khối <span className="text-danger">*</span></label>
                                <UserDropDown apiname={"grades?orderby=title-ASC&school_id=" + school_id} placeholder="Chọn khối" colvalue={'grades.id'} colname={'grades.title'} formik={formik} name='grade_id'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('grade_id', e)
                                        await formik.setFieldValue('class_id', '')
                                        await formik.setFieldTouched('class_id')
                                    }}></UserDropDown>
                                <AlertErrorForm formik={formik} name="grade_id"></AlertErrorForm>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-12 form-group">
                                <label> Môn học <span className="text-danger">*</span></label>
                                <UserDropDown apiname={"subjects?key=&istree=1&orderby=title-ASC&page=1&limit=50&school_id=" + user.data.school_id + "&grade_id=" + (formik.values.grade_id)} placeholder="Chọn môn học" colvalue={"subjects.id"} colname={"subjects.title"} formik={formik}
                                    name="subject_id" onChange={async (e) => { await formik.setFieldValue("subject_id", e); }}
                                ></UserDropDown>
                                <AlertErrorForm formik={formik} name="subject_id"></AlertErrorForm>
                            </div>
                           
                            <div className="col-xl-6 col-lg-6 col-12 form-group">
                                <label> Thời gian mở <span className="text-danger">*</span></label>
                                <DateRangePicker initialSettings={{ startDate: dateBegin, endDate: dateEnd, minDate: new Date(), timePicker: false }}
                                    onCallback={handleCallback}
                                >
                                    <div className='d-flex align-items-center'>
                                        <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Từ ngày</span>
                                        <input
                                            className="form-control h-31 form-control-sm"
                                            placeholder='Từ ngày'
                                            readOnly={true}
                                            onChange={(e) => setDateBegin(e)}
                                            value={dateBegin}
                                        />
                                        <span className='fas fa-arrow-right mx-4' />
                                        <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Đến ngày</span>
                                        <input
                                            className="form-control h-31 form-control-sm"
                                            placeholder='Đến ngày'
                                            readOnly={true}
                                            onChange={(e) => setDateEnd(e)}
                                            value={dateEnd}
                                        />
                                    </div>
                                </DateRangePicker>
                                <div className="row">
                                    <div className="col-6"><AlertErrorForm formik={formik} name="datebegin"></AlertErrorForm></div>
                                    <div className="col-6"><AlertErrorForm formik={formik} name="dateend"></AlertErrorForm></div>
                                </div>
                            </div>


                            <div className="col-xl-6 col-lg-6 col-12 form-group">
                                <label>Trạng thái</label>
                                <select className="form-control height32" {...formik.getFieldProps("status")}>
                                    <option value="0">Khoá</option>
                                    <option value="1">Hoạt động</option>
                                </select>
                                <AlertErrorForm formik={formik} name="status"></AlertErrorForm>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-12 form-group">
                                <label>Tên phiếu bài tập <span className="text-danger">*</span></label>
                                <input type="text" className="form-control h-31" placeholder="Nhập tên phiếu bài tập" name="title"
                                    {...formik.getFieldProps("title")}
                                ></input>
                                <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-12 form-group">
                                <label>
                                    Upload file
                                </label>
                                <div className="row list-file">
                                    {fileUpload && fileUpload.length > 0 && fileUpload.map(function (item, i) {

                                        return (
                                            <div className="item-upload" key={i} style={{ width: 130, overflow: "hidden", padding: 15, marginBottom: 15 }}>
                                                {item.type === "image" ? <img src={item.url} style={{ width: 100, height: 150, objectFit: "cover" }} alt="" />
                                                    :
                                                    <img src="https://tomoclass2021.s3.ap-southeast-1.amazonaws.com/images/2021/11/08/rules_of_fight_club.png" style={{ width: 100, height: 150, objectFit: "cover" }} alt="" />
                                                }
                                                <button className="btn btn-sm btn-danger text-center w-100" onClick={() => { removeFile(item.url) }}>Xóa</button>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="form-upload">
                                    <input type="file" id="file" ref={inputRef} style={{ display: "none" }} accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={uploadAudio} />
                                    <div className="d-flex justify-content-center align-item-center" style={{ border: "1px dashed", padding: 20, borderRadius: 5, cursor: "pointer" }} onClick={() => { inputRef.current.click() }}>
                                        <span>Tải file lên</span>
                                    </div>
                                </div>
                                <AlertErrorForm formik={formik} name="files"></AlertErrorForm>


                            </div>
                            <div className="col-12 form-group">
                                <label>Chọn lớp <span className="text-danger">*</span> </label>
                                <UserDropDownClass apiname={"class?key=&orderby=title-ASC&page=1&limit=500&grade_id=" + (formik.values.grade_id) + "&year_id=" + year_id} placeholder="Tất cả lớp" detail={detail} colvalue={'class.id'} formik={formik} colname={'class.title'} name='listclass'
                                    onChange={async (e) => { await formik.setFieldValue('listclass', e); }}
                                ></UserDropDownClass>
                                <AlertErrorForm formik={formik} name="listclass"></AlertErrorForm>

                                <Accordion defaultActiveKey={0}>
                                    {formik.values.listclass && formik.values.listclass.map((item, index) => <Card key={index} >
                                        <Accordion.Toggle as={Card.Header} eventKey={index}>{item.label}</Accordion.Toggle>
                                        <Accordion.Collapse eventKey={index}>
                                            <Card.Body><StudentByClass myclass={item} onUpdate={(e) => {
                                                formik.setFieldValue('class_' + e.value, e);
                                            }} /></Card.Body>
                                        </Accordion.Collapse>
                                    </Card>)}
                                </Accordion>
                            </div>
                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" onClick={formik.submitForm} >{loading ? <Spinner /> : <>Lưu lại</>}</button>
                                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
