import React, { useState, useEffect } from "react";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/Api";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import Loading from "./Loading";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { checkRole } from "../model";
import moment from "moment";

function SRList(props) {
  const parsed = window.location.search.replace('?', '');
  let { children, idrole, name, linkaction, title, defaultLimit, serverSide, params, columns, columnsExport, Toolbar, onlyList, noAction, moresearch, refresh, TopBar } = props;
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(defaultLimit ? defaultLimit : 10);
  const [totalRecord, setTotalRecord] = useState(defaultLimit ? defaultLimit : 10);
  const [change, setChange] = useState(false);
  const [keyword, setKeyword] = useState('');
  const allowAdd = checkRole(user, idrole, 'add');
  const allowEdit = checkRole(user, idrole, 'edit');
  const allowDelete = checkRole(user, idrole, 'delete');
  //console.log(parsed)
  useEffect(
    function () {
      let mounted = true;
      if (mounted) {
        setRows([]);
        setLoading(true)
      }
      let config = {
        headers: {
          'Authorization': 'Bearer ' + user.access_token,
          'Content-Type': 'application/json; charset=UTF-8',
          'search': JSON.stringify(moresearch)
        }
      }
      Axios.get(URL_LIST_ALL + name + "?key=" + (keyword || '') + "&page=" + page + "&limit=" + limit + (params ? '&' + params : '') + (parsed ? '&' + parsed : ''), config)
        .then((res) => {
        
          if (mounted) setLoading(false)
          if (res.data.status === "success") {
            if (mounted) {
              setRows(res.data.data);
              setTotalRecord(res.data.total);
            }
          } else console.log(res.data.message);
        })
        .catch((err) => {
          if (mounted) setLoading(false)
        });
      return () => (mounted = false);
    },
    [name, change, user, page, limit, params, autoRefresh, parsed, keyword, moresearch, refresh]
  );

  const options = {
    onSearchChange: (e) => {
      setKeyword(e)
    },
    fixedHeader: true,
    filter: false,
    filterType: 'multiselect',
    download: false,
    resizableColumns: false,
    serverSide: serverSide,
    rowsPerPage: serverSide ? limit : 10,
    count: totalRecord,
    rowsPerPageOptions: [10, 20, 50, 70, 100],
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return Toolbar ? <Toolbar selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} refreshData={() => setAutoRefresh(!autoRefresh)}></Toolbar> : null;
    },
    onTableChange: (action, tableState) => {
      if (serverSide === true) {
        if (action === "changePage") setPage(tableState.page + 1);
        else if (action === "changeRowsPerPage") setLimit(tableState.rowsPerPage)
      }
    },
    textLabels: {
      body: {
        noMatch: "Xin lỗi, không tìm thấy dữ liệu",
        toolTip: "Sắp xếp",
        columnHeaderTooltip: column => `Sắp xếp cho ${column.label}`
      },
      pagination: {
        next: "Trang tiếp",
        previous: "Trang trước",
        rowsPerPage: "Số dòng trên trang:",
        displayRows: "của",
      },
      toolbar: {
        search: "Tìm kiếm",
        downloadCsv: "Tải về CSV",
        print: "In",
        viewColumns: "Hiển thị cột",
        filterTable: "Lọc dữ liệu",
      },
      filter: {
        all: "Tất cả",
        title: "LỌC",
        reset: "LÀM LẠI",
      },
      viewColumns: {
        title: "Hiển thị cột",
        titleAria: "Ẩn hoặc hiện cột trong bảng",
      },
      selectedRows: {
        text: "dòng được chọn",
        delete: "Xoá",
        deleteAria: "Xoá dòng được chọn",
      },
    }
  };

  const actionDelete = (id) => {
    confirmAlert({
      title: "Thông báo",
      message: "Bạn có chắc muốn xoá dữ liệu",
      buttons: [
        {
          label: "Có",
          onClick: () => {
            Axios.delete(URL_LIST_ALL + name + '/' + id, user.config).then(res => {
              if (res.data.status === "success") {
                setChange(!change);
              } else alert(res.data.message);
            }).catch(err => console.log(err));
          },
        },
        { label: "Không" },
      ],
    });
  }
  let localcolumns = [...columns];
  if (allowEdit) {
    localcolumns.push({
      name: "id", label: "Sửa",
      options: {
        filter: false, sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (allowEdit) return <Link to={"/edit-" + (linkaction ? linkaction : name) + "/" + value} className="btn btn-sm btn-warning"><i className="fas fa-pencil-alt"></i></Link>;
          else return <button className="btn btn-secondary btn-sm" disabled ><i className="fas fa-pencil-alt"></i></button>
        }
      }
    })
  }

  if (allowDelete) {
    localcolumns.push({
      name: "id", label: "Xoá",
      options: {
        filter: false, sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (allowDelete) return <button className="btn btn-sm btn-danger" onClick={() => actionDelete(value)} ><i className="fas fa-trash-alt"></i></button>
          else return <button className="btn btn-secondary btn-sm" disabled ><i className="fas fa-trash-alt"></i></button>
        }
      }
    })
  }

  //Hàm lọc các cột dữ liệu trước khi trả về
  const filterDataExport = () => {
    if (columnsExport) {
      let newrows = [];
      rows.map((item) => {
        let newitem = { ...item };
        let newKey = {};
        Object.keys(newitem).map(key => {
          if (columnsExport.hasOwnProperty(key)) {
            if (columnsExport[key] !== true) {
              const newval = columnsExport[key](newitem[key]);
              if (typeof (newval) === 'object' && newval) {
                let changenewval = {}
                Object.keys(newval).map(k => {
                  changenewval[key + '_' + k] = newval[k];
                  return k;
                });
                newKey = Object.assign(newKey, changenewval)
                delete newitem[key];
              }
              else newitem[key] = newval;
            }
          } else delete newitem[key];
          return key;
        });
        newrows.push(Object.assign(newitem, newKey));
        return newitem;
      });
      return newrows;
    } else {
      if (rows && name === 'reportusers_onday') {
      
        let newdata = [];
        rows.forEach((item) => {
          let diemdanh = ''
          const time = item.datecreate ? new Date(item.datecreate * 1000) : null;
        
          try {
            const datetime = moment(new Date(item.datecreate * 1000))
            const date = datetime.format('YYYY-MM-DD')
            const localDate = item.date;
            const timest = time ? convertTime(time) : null
            
            if (localDate === date) {
              const localTime = item.time.split('-')
              const arTime = localTime[0].split('h');
              const minuteRequire = parseInt(arTime[0]) * 60 + parseInt(arTime[1])
              const minuteCheck = parseInt(datetime.format('H')) * 60 + parseInt(datetime.format('m'))
              if (minuteRequire <= minuteCheck) {
                if ((minuteCheck - minuteRequire) <= 10)
                  diemdanh = 'Đã điểm danh - ' + timest
                else diemdanh = 'Điểm danh muộn - ' + timest
              } else diemdanh = 'Điểm danh sớm - ' + timest
            }
            else diemdanh = 'Đã điểm danh - ' + timest
          } catch (e) {
            diemdanh = 'Lỗi điểm danh '
          }
          
          const timeBaogiang = item.datename ? new Date(item.datename * 1000) : null
          let baogiang = item.content ? 'Đã báo giảng -' + convertTime(timeBaogiang) : 'Chưa báo giảng'
          const timeNhanXet = item.datenotes ? new Date(item.datenotes * 1000) : null
          let nhanxet = item.note ? 'Đã nhận xét -' + convertTime(timeNhanXet) : 'Chưa nhận xét'

          newdata.push({ 'ID': item.id, 'Ngày': item.date, 'Tiết học': 'Tiết ' + item.orderonday, 'Giờ học': item.time, 'Lớp học': item.classtitle, 'Môn học': item.subject, 'Giáo viên': item.fullname, 'Điểm danh': diemdanh, 'Báo giảng': baogiang,'Nhận xét': nhanxet,'Ghi chú':item.notes })
        });
        return newdata
      } else {
        return rows;
      }
    }
  }

  const convertTime = (timestamp) => {
    return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(timestamp)
  }

  return (
    <>
      {loading && (<Loading />)}
      {!onlyList && <div className="d-flex justify-content-start">
        {TopBar && <>{TopBar}</>}

        {!noAction && allowAdd &&
          <Link
            to={"/add-" + (linkaction ? linkaction : name)}
            className="btn btn-success m-2"
            style={{ fontSize: 13, fontWeight: '600' }}>
            <i className="fas fa-plus-"></i> Thêm mới
          </Link>}
        <CSVLink
          data={filterDataExport()}
          filename={name + ".csv"}
          className="btn"
          target="_blank"
          style={{ fontSize: 15, fontWeight: '300', alignItems: 'center', display: 'flex' }}
        >
          Tải xuống <span className="fas fa-download text-info ml-2"></span>
        </CSVLink>
      </div>}
      {children}
      <MUIDataTable
        title={title}
        data={rows}
        columns={noAction ? columns : localcolumns}
        options={options}
      />
    </>
  );
}
export default React.memo(SRList)
//https://github.com/gregnb/mui-datatables