import React, { useState } from "react";
import MyEditor2 from "../../../component/MyEditor2";
const _ = require('lodash');

export default function QDropDrag(props) {
    const {onAnswers,answers,maxNumber,setMaxNumber} = props;
    const [keywords] = useState(answers && answers.correct?answers.correct:[]);//
    
    const getList=()=>{
        let list = [];
        for(let i=1; i<= maxNumber; i++) {
            list.push(i)
        }
        return list
    }

    const onChange = (value,i) => {
        let newList = [...keywords]
        newList[i] = value
        onAnswers({ ...answers,correct: newList, list:_.shuffle(newList)})
    }
    
    return (<div className="col-12 form-group">
       <button type="button" className="btn btn-sm btn-info mb-3" onClick={()=>setMaxNumber(maxNumber+1)}>Thêm câu trả lời</button>
       <div className="text-danger">Ghi chú: Thêm câu trả lời đúng lần lượt dưới đây, hệ thống sẽ tự hiển thị random khi học sinh làm bài.</div>
        {getList().map((item,i)=><div key={i} className="mb-2 ">
            <label>Câu trả lời vị trí {i+1} </label>
            <div className="w-100"><MyEditor2 height={120} initialValue={keywords[i]?keywords[i]:''} onValue={(e)=>onChange(e,i)}/></div>
        </div>)}
    </div>);
}
