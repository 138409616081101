import React, { useEffect, useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import FormBookStep1 from "./FormBookStep1";
import FormBookStep2 from "./FormBookStep2";
import FormBookStep3 from "./FormBookStep3";

import { useSelector } from "react-redux";
import Axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import { URL_LIST_ALL } from "../configs/Api";
import AkkhorLayout from "../layout/Akkhor";


export default function FormBookExercise(props) {
    const { user } = useSelector((state) => state.auth);
    const { id } = useParams();
    const [runFinish, setRunFinish] = useState(false);
    const [step, setStep] = useState(1);
    const [detail, setDetail] = useState(null);
    const [type, setType] = useState(4);

    useEffect(() => {
        if (id > 0) {
            let mounted = true;
            Axios.get(URL_LIST_ALL + "at_exam_tests/" + id, user.config)
                .then(async (res) => {
                    if (res.data.status === "success") {
                        if (mounted) {
                            let _detail = { ...res.data.data };
                            _detail.total_point = _detail.total_point ? _detail.total_point : 10
                            setType(_detail.type)
                            setDetail(_detail);
                        }
                    } else console.log(res.data.message);
                })
                .catch((err) => console.log(err));
            return () => (mounted = false);
        }
    }, [user, id]);

    const addTest = (values) => {
        if (id > 0) {
            Axios.put(URL_LIST_ALL + 'at_exam_tests/' + id, values, user.config).then(res => {
             
                if (res.data.status === "success") {
                    setRunFinish(true)
                    confirmAlert({
                        title: 'Thành công',
                        message: 'Bạn cập nhật bài tập cho sách thành công',
                        buttons: [{ label: 'OK' }]
                    });
                } else {
                    confirmAlert({
                        title: 'Lỗi',
                        message: res.data.message,
                        buttons: [{ label: 'OK' }]
                    });
                }
            }).catch(err => console.log(err));
        } else {
            values.type = type;
            //console.log(values);
            Axios.post(URL_LIST_ALL + 'at_exam_tests', values, user.config).then(res => {
                if (res.data.status === "success") {
                    setRunFinish(true)
                    confirmAlert({
                        title: 'Thành công',
                        message: 'Bạn thêm mới bài tập cho sách thành công',
                        buttons: [{ label: 'OK' }]
                    });
                } else {
                    confirmAlert({
                        title: 'Lỗi',
                        message: res.data.message,
                        buttons: [{ label: 'OK' }]
                    });
                }
            }).catch(err => console.log(err));
        }

    }
    return (
        <AkkhorLayout idrole="list-books-exercise">
            {runFinish && <Redirect to={"/list-books-exercise"} />}
            <div className="card height-auto mt-5">
                <div className="card-body ">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>{id > 0 ? "Sửa" : "Tạo"} bài bài tập cho sách</h3>
                        </div>
                    </div>
                    {step === 1 && <FormBookStep1 detail={detail} nextStep={(e) => { setDetail(e); setStep(step + 1); }} />}
                    {(step === 2 && detail) && <FormBookStep2 detail={detail} nextStep={(e) => { setDetail(e); setStep(step + 1); }} previewStep={(e) => setStep(step - 1)} />}
                    {(step === 3 && detail) && <FormBookStep3 detail={detail} nextStep={addTest} previewStep={(e) => setStep(step - 1)} />}

                </div>
            </div>
        </AkkhorLayout>
    );
}
