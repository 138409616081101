
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/Akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/Api";
import { useParams } from "react-router-dom";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import DateTimePicker from "react-datetime-picker";

export default function FormYearsPage() {
    const { user } = useSelector((state) => state.auth);
    const school_id = user && user.data && user.data.school_id>0?user.data.school_id:0;
    const  {id}  = useParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [dateSelect, setDateSelect] = useState({datebegin:null})

    const defaultdetail = {
        school_id, author_id:user.data.id,title:'', status:1,numberweek:35
    }
    useEffect(
        function () {
            if(id>0)
            {
                let mounted = true;
                Axios.get(URL_LIST_ALL  + "years/"+id,user.config)
                    .then( async (res) => {
                    if (res.data.status === "success") {
                        if (mounted){
                            setDetail(res.data.data);
                            setDateSelect({datebegin:res.data.data.datebegin})
                        } 
                    } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                    return () => (mounted = false);
            }
        },[user,id,refresh]
      );
    const formik = useFormik({
        initialValues:detail?detail:defaultdetail,
        enableReinitialize:true,
        validationSchema: Yup.object().shape({
          title: Yup.string().trim().nullable().required('Nhập tên năm học').max(150, "Độ dài không quá 150 ký tự"),
          numberweek: Yup.string().trim().nullable().required('Nhập tên năm học')
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if(id>0)
            {
                Axios.put(URL_LIST_ALL+'years/'+id, values, user.config).then(res => {
                    
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật năm học thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    }else{
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }else{
                
                Axios.post(URL_LIST_ALL+'years', values, user.config).then(res => {
                    
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới năm học thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    }else{
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });
 
    const handleDate = (name, value) => {
        formik.setFieldValue(name, moment(value).format('YYYY-MM-DD'))
        formik.setFieldTouched(name)
        let newDate = Object.assign({}, dateSelect)
        newDate[name] = value
        setDateSelect(newDate)
    };


  return (
    <AkkhorLayout idrole="list-years">
      <div className="card height-auto mt-5">
        <div className="card-body">
            <div className="heading-layout1">
                <div className="item-title">
                    <h3>Năm học</h3>
                </div>
            </div>
            <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-12 form-group">
                        <label>Tên năm học <span className="text-danger">*</span></label>
                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("title")} autoComplete="off"/>
                        <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-12 form-group">
                        <label>Số tuần học <span className="text-danger">*</span></label>
                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("numberweek")} autoComplete="off"/>
                        <AlertErrorForm formik={formik} name="numberweek"></AlertErrorForm>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                        <label>Ngày bắt đầu <span className="text-danger">*</span></label>
                        <DateTimePicker onChange={(value) => { handleDate('datebegin', value) }} value={dateSelect.datebegin ? new Date(dateSelect.datebegin) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                        <AlertErrorForm formik={formik} name="datebegin"></AlertErrorForm>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-12 form-group">
                        <label>Trạng thái</label>
                        <select className="form-control height32" {...formik.getFieldProps("status")}>
                            <option value="0">Khoá</option>
                            <option value="1">Hoạt động</option>
                        </select>
                    </div>
                    
                    <div className="col-12 form-group mg-t-8">
                    <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner />:<>Lưu năm học</>}</button>
                        <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                    </div>
                </div>
            </form>
        </div>
      </div>
    </AkkhorLayout>
  );
}
