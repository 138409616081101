import React from "react";
import parse from 'html-react-parser'

export default function QDropDrag(props) {
    const { item } = props;
    const answers = item && item.student_answer && item.student_answer.corrects ? JSON.parse(item.student_answer.corrects) : []

    return (<div className="col-12 form-group mt-3">
        {answers.map((item, i) => {
            return (
                <div className="col-12 pl-0 pr-0 mt-1 d-flex align-items-center" key={i}>
                    <div className="w-100 py-1 px-2 cursor-pointer rounded border-grey bg-white">
                        {item.includes("<class") && item.includes(">") ? item : parse(item)}
                    </div>
                </div>
            )
        })}
    </div>);
}