import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LIST_ALPHA } from "../../../configs";
import { saveAnswers } from "../../../redux/Actions";
import parse from 'html-react-parser'
import GenderCode from "../../../component/GenderCode";

export default function QChooseMulti(props) {
    const { item, currentAnswer } = props;
    const { Answers } = useSelector(state => state.save);
    const dispatch = useDispatch();
    const answers = item.answers
    const [choice, setChoice] = useState(currentAnswer || {})
    const chooseItem = (index, stt) => {
        let newChoice = { ...choice }
        if (stt) newChoice[index] = true;
        else delete newChoice[index]
        setChoice(newChoice)
        let percent = 0
        if (answers.correct && answers.correct.length === Object.keys(newChoice).length) {
            answers.correct.map((i) => {
                if (newChoice[i] === true) percent += 100 / answers.correct.length
                return i
            });
        }
        let newListAnswers = { ...Answers }
        newListAnswers[item.id] = { question: item, answers: newChoice, percent: percent === 100 ? 100 : 0 };
        dispatch(saveAnswers(newListAnswers))
    }
    return (<div className="col-12 form-group mt-3">
        <ul>
            {answers && answers.list ?
                answers.list.map((item, i) => <li key={i} className="d-flex mb-2 align-items-center " >
                    {LIST_ALPHA[i]}. <div className={"w-100 py-1 px-2 cursor-pointer rounded border " + (choice[i] ? ' border-info bg-info text-white' : ' border-grey')} onClick={() => chooseItem(i, choice[i] ? false : true)}>
                        {/* {item.includes("<class") && item.includes(">") ? item : parse(item)} */}
                        <GenderCode text_string={item} />
                    </div>
                </li>)
                : answers.map((item, i) => <li key={i} className="d-flex mb-2 align-items-center " >
                    {LIST_ALPHA[i]}. <div className={"w-100 py-1 px-2 cursor-pointer rounded border " + (choice[i] ? ' border-info bg-info text-white' : ' border-grey')} onClick={() => chooseItem(i, choice[i] ? false : true)}>
                        {/* {item.includes("<class") && item.includes(">") ? item : parse(item)} */}
                        <GenderCode text_string={item} />
                    </div>
                </li>)
            }
        </ul>
    </div>);
}