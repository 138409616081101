import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { URL_LIST_ALL } from "../configs/Api";

export default function SelectBoxMultiple(props) {
    const { apiname, data, placeholder, formik, name, onChange, colvalue, colname, defaultValue, disabled } = props;
    const { user } = useSelector((state) => state.auth);
    const [list, setList] = useState(data ? data : []);
    const [listLabel, setListLabel] = useState([]);
    const [listSelected, setListSelected] = useState([])
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        const closeOption = e => {
            try {
                if (e.target.classList && e.target.classList.contains('btn-showbox') == false && e.target.offsetParent.classList.contains('list-option') == false) {
                    setIsOpen(false)
                }
            } catch (e) {

            }
        }
        document.body.addEventListener('click', closeOption);
        return () => document.body.removeEventListener('click', closeOption)
    }, [])

    useEffect(() => {
        // setValue(formik ? formik.values[name] : defaultValue)
        let dValue = formik ? formik.values[name] : defaultValue
        if(dValue){
            let newListLabel = []
            dValue.forEach(item => {
                newListLabel.push(item.label)
            });
            setListLabel(newListLabel)
        }
        setListSelected(dValue)
    
    }, [formik, name, defaultValue])
    //Lấy danh sách dữ liệu
    useEffect(() => {
        let mounted = true;
        if (!data) {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token,
                    'asselect': JSON.stringify([colvalue + " as value", colname + " as name"])
                }
            }
            Axios.get(URL_LIST_ALL + apiname, config).then((res) => {
                let localList = []
                localList = localList.concat(res.data.data)
                setList(localList);
            }).catch((err) => { console.log(err) });
        }
        return () => (mounted = false);
    }, [user, apiname, data, colvalue, colname]);

    const checker = (value, label) => {
        let newList = [...listSelected]
        let newListLabel = [...listLabel]
        if (!isChecked(value)) {
            newList.push({ id: value, label: label })
            newListLabel.push(label)
        } else {
            newList.forEach((item, i) => {
                if (item.id === value) {
                    newList.splice(i, 1)
                    newListLabel.splice(i, 1)
                }
            });
        }
        onChange(newList)
        setListLabel(newListLabel)
        setListSelected(newList)
    }

    const isChecked = (value) => {
        let newList = [...listSelected]
        let s = false
        newList.forEach((item, i) => {
            if (item.id === value) {
                s = true
            }
        });
        return s
    }

    return <div className="w-100 selectbox">
        <div className="w-100 select-control h-31">
            <button type="button" className="w-100 d-flex justify-content-between align-items-center btn-showbox" onClick={() => setIsOpen(prev => !prev)}>
                <div>{listLabel && listLabel.length > 0 ? listLabel.map(function (text, i) {
                    return (<span key={i}>{text}; </span>)
                }) : placeholder}</div>
                <span className="fas fa-chevron-down"></span>
            </button>
            <div className={"border list-option " + (!isOpen ? 'd-none' : '')}>
                {list && list.map(function (item, i) {
                    return <button type="button" className="w-100 d-flex p-1 justify-content-between align-items-center select-item border-bottom" key={i} onClick={(e) => checker(item.value, item.name)}>
                        <span>{item.name}</span>
                        <input type="checkbox" value={item.value} onChange={(e) => checker(item.value, item.name)} checked={isChecked(item.value)} />
                    </button>;
                })}
            </div>
        </div>
    </div>;
}