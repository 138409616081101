import React, { useEffect, useState } from 'react';
import AkkhorLayout from '../layout/Akkhor';
import { useSelector } from "react-redux";
import { Link, useParams } from 'react-router-dom';
import Axios from 'axios';
import { URL_LIST_ALL } from '../configs/Api';
import SRList from "../component/List";

export default function ReportRacing() {
    const { id } = useParams();
    const { datasocket } = useSelector((state) => state.save);
    const { user } = useSelector((state) => state.auth);
    const [detail, setDetail] = useState(null);

    const now = (new Date()).getTime() / 1000;
    useEffect(() => {
        let mounted = true;
        let url = URL_LIST_ALL + "at_exam_tests/" + id + '?info=1'
        Axios.get(url, user.config).then(async (res) => {
            if (res.data.status === "success" && mounted) {
                const _detail = res.data.data;
                setDetail(_detail);

            }
            else console.log(res.data.message);
        }).catch((err) => console.log(err));

        return () => (mounted = false);
    }, [user, id]);

    const statusQues = (_test) => {
        if (_test) {
            if (now >= _test.datebegin && now <= _test.dateend) {
                return <b className="text-success">Trong hạn</b>
            } else {
                if (now > _test.dateend) return <b className="text-danger">Hết hạn</b>
                else return <b className="text-info">Chưa đến hạn</b>
            }
        } else return null

    }
    let columns = [
        {
            name: "id", label: "ID", options: {
                filter: false, sort: true,
                customBodyRender: (value) => {
                    return <div className='text-center'>{value}</div>
                },
            }
        },
        { name: "code", label: "Mã số", options: { filter: true, sort: true } },
        { name: "fullname", label: "Họ và tên", options: { filter: true, sort: true } },
        {
            name: "status", label: "Trạng thái", options: {
                filter: true, sort: true, customBodyRender: (value) => {
                    if (value == 0) {
                        return <b className="text-danger">Chưa làm</b>
                    }
                    if (value == 1) {
                        return <b className="text-success">Đã hoàn thành</b>
                    }
                    if (value == 2) {
                        return <b className="text-warning">Đang làm bài</b>
                    }
                }
            }
        },

        {
            name: "ans_true", label: "Số câu đúng", options: {
                filter: true, sort: true,
                customBodyRender: (value) => {
                    return <div className='text-center'>{value}</div>
                },
            }
        },
        {
            name: "ans_false", label: "Số câu sai", options: {
                filter: true, sort: true,
                customBodyRender: (value) => {
                    return <div className='text-center'>{value}</div>
                },
            }
        },
        {
            name: "ans_none", label: "Số câu chưa làm", options: {
                filter: true, sort: true,
                customBodyRender: (value) => {
                    return <div className='text-center'>{value}</div>
                },
            }
        },
        {
            name: "point", label: "Điểm", options: {
                filter: true, sort: true,
                customBodyRender: (value) => {
                    return <div className='text-center'>{value}</div>
                },
            }
        },
        {
            name: "id", label: "#", options: {
                filter: true, sort: true, customBodyRender: (value, tableMeta) => {
                    const status = tableMeta.rowData[3];
                    // console.log(tableMeta);
                    if (status > 0)
                        return <Link to={"/examination-view/" + value} target="_blank"><b className="text-info">Chi tiết</b></Link>
                    else return null;
                }
            },
        }
    ];

    return (
        <AkkhorLayout idrole="examination-report" roleignone={user.data.typeaccount === 2}>

            {detail && <>
                <div className="breadcrumbs-area py-2">
                    <ul>
                        <li>
                            <Link to="/">Trang chủ</Link>
                        </li>
                        <li> <Link to="/examination-report">Thống kê</Link></li>
                        <li>{detail.title}</li>
                    </ul>
                </div>
                <div className="card height-auto">
                    <div className="card-body">
                        <SRList name="at_exam_students" idrole={"list-tests"} linkaction={'tests'} params={"join=2&test_id=" + id + '&checked=1'} title={""} defaultLimit={10} serverSide={true} noAction={true} onlyList={true} columns={columns} />
                    </div>
                </div>
            </>}
        </AkkhorLayout>
    );
}
