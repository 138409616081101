import React, { useEffect, useState } from "react";
import Axios from "axios";
import { URL_LIST_ALL } from "../../configs/Api";
import { useSelector } from "react-redux";
import Tree from 'react-animated-tree'
import { EditText } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import { confirmAlert } from "react-confirm-alert";

export default function TreeModal(props) {
  const { subject } = props;
  const { user } = useSelector((state) => state.auth);
  const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
  const [refresh, setRefresh] = useState(false);
  const [tree, setTree] = useState(null);
  const [open, setOpen] = useState([]);

  useEffect(() => {
    if (subject) {
      let mounted = true;
      Axios.get(URL_LIST_ALL + "param_trees/1?grade_id=" + subject.grade_id + '&subject_id=' + subject.id, user.config)
        .then(async (res) => {
          if (res.data.status === "success") {
            if (mounted) setTree(res.data.data);
          } else console.log(res.data.message);
        })
        .catch((err) => console.log(err));
      return () => (mounted = false);
    }
  }, [subject, refresh]);// eslint-disable-line

  const TextView = ({ title, indextree, idparent, arrid }) => {

    return <>
      <EditText defaultValue={title} style={{ display: 'inline', width: 250 }} onSave={(e) => onSave(e, idparent)} />
      {indextree < 5 && <span className="fas fa-plus-circle text-success cursor-pointer" onClick={() => addTree(idparent, arrid)}></span>}
      <span className="fas fa-trash-alt text-danger cursor-pointer ml-2" onClick={() => deleteTree(idparent, arrid)}></span>
    </>
  }

  const ShowTre = ({ list, indextree, arrid }) => {
    return <>
      {list && list.map((item, index) => {
        let newarrid = [...arrid];
        newarrid.push(item.id)
        return <Tree key={index} open={(open && open.includes(item.id)) ? true : false} content={<TextView title={item.title} indextree={indextree} idparent={item.id} arrid={newarrid} />} >
          {item.children && item.children.length > 0 && <><ShowTre list={item.children} indextree={indextree + 1} arrid={newarrid} /></>}
        </Tree>
      })}
    </>
  }

  //Xử lý thêm mới cây kiến thức
  const addTree = (idparent, arrid) => {
    setOpen(arrid);
    const data = { school_id, idparent, author_id: user.data.id, title: 'Cây kiến thức', status: 1, grade_id: subject.grade_id, subject_id: subject.id }
    Axios.post(URL_LIST_ALL + 'exam_tree', data, user.config).then(res => {
      if (res.data.status === "success") {
        setRefresh(!refresh)
      } else {
        confirmAlert({ title: 'Lỗi', message: res.data.message, buttons: [{ label: 'OK' }] });
      }
    }).catch(err => console.log(err));
  }
  //Xử lý sửa cây kiến thức
  const onSave = (e, id) => {
    Axios.put(URL_LIST_ALL + 'exam_tree/' + id, { title: e.value }, user.config).then(res => {
      if (res.data.status === "success") {
        setRefresh(!refresh)
      } else {
        confirmAlert({ title: 'Lỗi', message: res.data.message, buttons: [{ label: 'OK' }] });
      }
    }).catch(err => console.log(err));
  }
  //Xử lý xoá cây kiến thức
  const deleteTree = (id, arrid) => {
    setOpen(arrid);
    Axios.delete(URL_LIST_ALL + 'exam_tree/' + id, user.config).then(res => {
      if (res.data.status === "success") {
        setRefresh(!refresh)
      } else {
        confirmAlert({ title: 'Lỗi', message: res.data.message, buttons: [{ label: 'OK' }] });
      }
    }).catch(err => console.log(err));
  }

  return <div>
    {tree && <ShowTre parent={0} list={tree} indextree={1} arrid={[]} />}
    <span className="fas fa-plus-circle text-success cursor-pointer" onClick={() => addTree(0, [])}></span>
  </div>;
}
