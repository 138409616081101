import Axios from "axios";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { URL_LIST_ALL } from "../../configs/Api";
import AkkhorLayout from '../../layout/Akkhor';
import 'moment/locale/vi';
import ItemTimeTable from "./ItemTimeTable";
import { useDispatch, useSelector } from "react-redux";
import SelectWeek from "../../component/SelectWeek";
import { checkRole } from "../../model";
import { saveChangeTimeTable } from "../../redux/Actions";


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowDimensions;
}

export default function TimeTable() {
    const { user } = useSelector((state) => state.auth);
    const { year_id } = useSelector((state) => state.save);
    const { width } = useWindowDimensions();
    const { setting } = user.data;
    const dispatch = useDispatch();
    const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
    const grade_id = user && user.data && user.data.class && user.data.class.grade_id > 0 ? user.data.class.grade_id : 0;
    const class_id = user && user.data && user.data.class && user.data.class.class_id > 0 ? user.data.class.class_id : 0;
    const [refresh, setRefresh] = useState(false);
    const [gradeChoice, setGradeChoice] = useState(null);
    const [classChoice, setClassChoice] = useState(class_id);
    const [weekChoice, setWeekChoice] = useState(null);
    const [listGardes, setListGardes] = useState([]);
    const [listClass, setListClass] = useState([]);
    const [listTimeTable, setListTimeTable] = useState([]);
    //Lấy danh sách khối
    useEffect(() => {
        let mounted = true;
        if (user.data.typeaccount !== 2) {
            Axios.get(URL_LIST_ALL + "grades?orderby=title-ASC&page=1&limit=500&school_id=" + school_id, user.config).then((res) => {
                if (res.data && res.data.status === "success") {
                    if (mounted) setListGardes(res.data.data);
                    if (res.data.data && res.data.data.length > 0)
                        if (mounted) {
                            if (grade_id > 0) setGradeChoice(grade_id);
                            else setGradeChoice(res.data.data[res.data.data.length - 1].id);
                        }
                } else console.log(res.data.message);
            }).catch(err => console.log(err));
            dispatch(saveChangeTimeTable(null))
        }

        return () => (mounted = false);
    }, [school_id, user]);// eslint-disable-line
    //Lấy danh sách lớp
    useEffect(() => {
        let mounted = true;
        if (user.data.typeaccount !== 2) {
            if (gradeChoice) {
                setListTimeTable([]);
                setListClass([]);
                Axios.get(URL_LIST_ALL + "class?page=1&limit=500&grade_id=" + gradeChoice, user.config).then((res) => {
                    if (res.data && res.data.status === "success") {
                        if (mounted) setListClass(res.data.data);
                        if (res.data.data && res.data.data.length > 0)
                            if (mounted) {
                                if (class_id > 0) setClassChoice(class_id);
                                else setClassChoice(res.data.data[res.data.data.length - 1].id);
                            }
                    } else console.log(res.data.message);
                }).catch(err => console.log(err));
            }
        } else {
            setListTimeTable([]);
            setListClass([]);
            Axios.get(URL_LIST_ALL + "myclass", user.config).then((res) => {
                if (res.data && res.data.status === "success") {
                    if (mounted) setListClass(res.data.data);
                    if (res.data.data && res.data.data.length > 0)
                        if (mounted) {
                            if (class_id > 0) setClassChoice(class_id);
                            else setClassChoice(res.data.data[res.data.data.length - 1].id);
                        }
                } else console.log(res.data.message);
            }).catch(err => console.log(err));
        }


        return () => (mounted = false);
    }, [gradeChoice, user, year_id]);// eslint-disable-line

    //Lấy danh sách thời khoá biểu
    useEffect(() => {
        let mounted = true;
        if (classChoice) {
            setListTimeTable([]);
            Axios.get(URL_LIST_ALL + "timetable?page=1&limit=500&class_id=" + classChoice + '&week=' + weekChoice + '&orderby=date-asc', user.config).then((res) => {
                if (res.data && res.data.status === "success") {
                    if (mounted) setListTimeTable(res.data.data);
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
        }
        return () => (mounted = false);
    }, [classChoice, weekChoice, user, refresh]);

    //Lay danh sach ngay trong tuan
    const createWeekDate = () => {
        let list = {};
        listTimeTable.forEach(item => {
            list[item.date] = true;
        })
        return list;
    }
    //Lay danh sach tiet hoc trong ngay
    const morning = [1, 2, 3, 4, 5];
    const afternoon = [6, 7, 8, 9];
    //Lọc danh sách thời khoá biểu
    const filterCalendar = (date, orderonday) => {
        let list = {};
        listTimeTable.forEach(item => {
            if (item.date === date && item.orderonday === orderonday) {
                list = item;
                return;
            }
        })
        return list;
    }

    const allowEdit = checkRole(user, 'timetable', 'edit');

    const allowChange = checkRole(user, 'timetable', 'change');

    return (
        <AkkhorLayout idrole="timetable" roleignone={user.data.typeaccount === 2}>
            <h4>Thời khoá biểu</h4>
            <form className="form-inline mt-2">
                {user.data.typeaccount !== 2 &&
                    <>
                        <label className="mr-2">Khóa</label>
                        <select className="form-control form-control-sm" value={gradeChoice ? gradeChoice : 0} onChange={(e) => setGradeChoice(e.target.value)} disabled={user.data.typeaccount === 2}>
                            {listGardes.map((item) => {
                                return <option key={item.id} value={item.id}>{item.title}</option>
                            })}
                        </select>
                    </>
                }

                <label className="mr-2 ml-md-4">Lớp học</label>
                <select className="form-control form-control-sm" value={classChoice ? classChoice : 0} onChange={(e) => setClassChoice(e.target.value)}>
                    {listClass.map((item) => {
                        return <option key={item.id} value={item.id}>{item.title}</option>
                    })}
                </select>
                <label className="mr-2 ml-md-4">Tuần thứ</label>
                <SelectWeek weekChoice={weekChoice} setWeekChoice={setWeekChoice}></SelectWeek>

            </form>
            {setting && <div className="d-flex mt-4">
                <div>Chú giải màu chữ: </div>
                <div className="ml-3" style={{ color: setting.color_isreplace }}>Dạy thay</div>
                <div className="ml-3" style={{ color: setting.color_isrotate }}>Đổi lịch</div>
                <div className="ml-3" style={{ color: setting.color_both }}>Dạy thay và Đổi lịch</div>
            </div>}
            <div className="d-flex mt-3">
                {width > 1439 && <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 80 }}>
                    <div className="text-center w-100">Tuần</div>
                </div>}
                <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 100 }}>
                    <div className="text-center w-100">Ngày</div>
                </div>
                <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 60 }}>
                    <div className="text-center w-100">Buổi</div>
                </div>
                <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 50 }}>
                    <div className="text-center w-100">Tiết</div>
                </div>
                <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 145 }}>
                    <div className="text-center w-100">Môn học</div>
                </div>
                <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 80 }}>
                    <div className="text-center w-100">Tiết thứ</div>
                </div>
                <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 200 }}>
                    <div className="text-center w-100">Giáo viên</div>
                </div>
                {width > 1439 && <>
                    <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 150 }}>
                        <div className="text-center w-100">Tên bài dạy</div>
                    </div>
                    <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 150 }}>
                        <div className="text-center w-100">Ghi chú</div>
                    </div>
                </>}
                {allowEdit && <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 90 }}>
                    <div className="text-center w-100">Dạy thay</div>
                </div>}
                {allowChange && <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 80 }}>
                    <div className="text-center w-100">Đổi lịch</div>
                </div>}
            </div>
            {listTimeTable.length > 0 && <div className="d-flex">
                {width > 1439 && <div className="d-flex border p-2 align-items-center" style={{ minWidth: 80 }}>
                    <div className="text-center w-100">Tuần {weekChoice}</div>
                </div>}
                <div style={{ flex: 0 }}>
                    {Object.keys(createWeekDate()).map((date, index) => {
                        return (<div className="d-flex bg-light" key={index}  >
                            <div className="d-flex border p-2 align-items-center" style={{ minWidth: 100 }}>
                                <div className="text-center w-100">
                                    <div className="text-center text-capitalize"><Moment locale="vi" format="dddd">{date}</Moment></div>
                                    <div className="text-center"><Moment format="DD/MM/YYYY">{date}</Moment></div>
                                </div>
                            </div>
                            <div>
                                <div className="d-flex">
                                    <div className="d-flex border p-2 align-items-center" style={{ minWidth: 60 }}><div className="text-center w-100">Sáng</div></div>
                                    <div>
                                        {morning.map((i) => <div key={i} className="d-flex"><ItemTimeTable i={i} width={width} timeTable={filterCalendar(date, i)} datetimeable={date} allowEdit={allowEdit} allowChange={allowChange} setRefresh={() => setRefresh(!refresh)} gradeChoice={gradeChoice} classChoice={classChoice}></ItemTimeTable></div>)}
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="d-flex border p-2 align-items-center" style={{ minWidth: 60 }}><div className="text-center w-100">Chiều</div></div>
                                    <div>
                                        {afternoon.map((i) => <div key={i} className="d-flex"><ItemTimeTable i={i} width={width} timeTable={filterCalendar(date, i)} datetimeable={date} allowEdit={allowEdit} allowChange={allowChange} setRefresh={() => setRefresh(!refresh)} gradeChoice={gradeChoice} classChoice={classChoice}></ItemTimeTable></div>)}
                                    </div>
                                </div>
                            </div>
                        </div>)
                    })}

                </div>
            </div>}
        </AkkhorLayout>
    );
}
