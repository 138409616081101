import React, { useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { actions } from "../redux/authRedux";
import { resetRedux, saveFollowTest, saveRefreshSocket, setResetComments, setResetPost } from "../redux/Actions";
import onClickOutside from "react-onclickoutside";
import Axios from "axios";
import parse from 'html-react-parser'
import Avatar from "../assets/img/figure/admin.jpg";
import { URL_LIST_ALL } from "../configs/Api";
import avatar_student from "../assets/img/avata_student.png"
import men_teacher from "../assets/img/men-teacher.png"
import girl_teacher from "../assets/img/girl-teacher.png"
import wavFile from "../assets/notification.wav"
import { jwtDecode } from "jwt-decode";
import logo from "../assets/img/logo.png"

function HeaderBar(props) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [showMobile, setShowMobile] = useState(false);
  const [show, setShow] = useState(false);
  const [showNoti, setShowNoti] = useState(false);
  const [refreshNoti, setRefreshNoti] = useState(false);
  const [listNotification, setListNotification] = useState([]);
  const [countNoti, setCountNoti] = useState(0);
  const [soundPlay, setSoundPlay] = useState(false);
  const info = user && user.data ? user.data : null;
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const connectWebSocket = () => {
      // setFlag(true)
      const socketInstance = new WebSocket('wss://socket.tomotech.vn');
      socketInstance.onopen = () => { };
      socketInstance.onmessage = (evt) => {
        if (evt.data) {
          let data = evt.data
          try {
            data = jwtDecode(data)
            if (data) {
              if (data.channel && data.channel == 'notification') {
                if (data.data.send_to === user.data.id) {
                  setRefreshNoti(data.data)
                  dispatch(saveRefreshSocket(data));
                  setSoundPlay(true)
                  if (data.event && data.event == 'post' + user.data.id) {
                    dispatch(setResetPost(data.data));
                  }
                  if (data.event && data.event == 'comments' + user.data.id) {
                    dispatch(setResetComments(data.data));
                  }
                }
              }
              if (data.channel && data.channel == 'followtest') {
                if (data.data.send_to === user.data.id) {
                  dispatch(saveFollowTest(data));
                }
              }
            }
          } catch (error) {
          }
        }
      };
      socketInstance.onclose = (event) => {
        setTimeout(() => {
          connectWebSocket();
        }, 1000);
      };
      setSocket(socketInstance);
    };
    if (!socket) {
      connectWebSocket();
    }
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [socket]);

  //Hàm xử lý đăng xuất
  const dologout = () => {
    confirmAlert({
      title: "Đăng xuất",
      message: "Bạn có chắc muốn đăng xuất",
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: "Đăng xuất",
          onClick: () => {
            dispatch(resetRedux());
            dispatch(actions.login(null));
          },
        },
        { label: "Không" },
      ],
    });
  };
  HeaderBar["handleClickOutside_header"] = () => {
    setShow(false)
    setShowNoti(false)
  };

  const markSeen = (id, href = null) => {
    Axios.put(URL_LIST_ALL + "notifications/" + id, { seen: 1 }, user.config).then(async (res) => {
      if (href) {
        window.location.href = href
      } else {
        setRefreshNoti(!refreshNoti)
      }
    })
  }
  const markSeenAll = (id, href = null) => {
    Axios.post(URL_LIST_ALL + "seenall", {}, user.config).then(async (res) => {
      if (href) {
        window.location.href = href
      } else {
        setRefreshNoti(!refreshNoti)
      }
    })
  }

  useEffect(() => {
    if (user) {
      Axios.get(URL_LIST_ALL + "notifications", user.config).then(async (res) => {
        if (res.data.status === 'success') {
          let datars = res.data.data
          setListNotification(datars)
          let count_no_seen = 0
          datars.forEach((item) => {
            if (item.seen === 0) {
              count_no_seen += 1;
            }
          });
          setCountNoti(count_no_seen)

        } else {
          alert(res.data.message)
        }
      }).catch((err) => { console.log(err); });
    }
  }, [user, refreshNoti])

  useEffect(() => {
    if (soundPlay) {
      const audio = new Audio(wavFile);
      audio.play()
      setSoundPlay(false)
    }
  }, [soundPlay]);


  return (
    <>
      {/* Header Menu Area Start Here */}
      <div className="d-flex justify-content-between navbar navbar-expand-md header-menu-one p-0">
        <div className="nav-bar-header-one d-none d-sm-flex">
          <div className="header-logo ">
            <Link to="/dashboard">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="toggle-button sidebar-toggle">
            <button type="button" className="item-link">
              <span className="btn-icon-wrap">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>
          </div>
        </div>
        <div className="col-12 d-md-none bg-white py-1">
          <div className="row ">
            <div className="col-8 header-logo">
              <Link to="/dashboard" className="d-inline-block">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <div className="col-4 mobile-nav-bar d-flex justify-content-end">
              <button className="navbar-toggler" type="button" onClick={() => setShowMobile(!showMobile)} >
                <i className="fas fa-user-alt"></i>
              </button>
              <button type="button" className="navbar-toggler sidebar-toggle-mobile" >
                <i className="fas fa-bars"></i>
              </button>
            </div>
          </div>
        </div>

        <div className={`header-main-menu collapse navbar-collapse justify-content-end ${showMobile ? "show" : ""}`} id="mobile-navbar">
          {info && (<>
            <ul className="hidden-xs navbar-nav">
              <li className={`navbar-item dropdown header-admin ${show ? "show" : ""}`} >
                <a
                  className="navbar-nav-link dropdown-toggle"
                  href="#shool"
                  role="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  onClick={() => setShow(!show)}
                >
                  <div className="admin-title">
                    <h5 className="item-title">
                      {info.fullname} {" "}
                    </h5>
                    <span>{info.chucvu}</span>
                  </div>
                  <div className="admin-img">
                    <img src={info && info.avatar ? info.avatar : Avatar} alt="Admin" />
                  </div>
                </a>
                <div className={`dropdown-menu dropdown-menu-right ${show ? "show" : ""}`} >
                  <div className="item-header">
                    <h6 className="item-title">
                      {info.fullname} {" "}
                    </h6>
                  </div>
                  <div className="item-content">
                    <ul className="settings-list">
                      <li>
                        <Link to="/my-profile">
                          <i className="flaticon-user"></i>Hồ sơ của tôi
                        </Link>
                      </li>
                      <li>
                        <a href="#logout" onClick={dologout}>
                          <i className="flaticon-turn-off"></i>Đăng xuất
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li className={"navbar-item notification-bar dropdown " + (showNoti ? 'show' : '')}>
                <a className="navbar-nav-link dropdown-toggle"
                  href="#shool"
                  role="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  onClick={() => setShowNoti(!showNoti)}
                >
                  <i className="fas fa-bell"></i>
                  {countNoti > 0 && <span className="count-number">{countNoti}</span>}
                </a>
                <div className={`dropdown-menu dropdown-menu-right ${showNoti ? "show" : ""}`} >
                  <div className="w-100 d-flex justify-content-end">
                    <button className="btn readall" type="button" onClick={() => markSeenAll()}>Đánh dấu đọc tất cả</button>
                  </div>
                  {listNotification && listNotification.length > 0 ? listNotification.map((item, i) => {
                    return (<div className={"notification-item p-2 " + (item.seen === 1 ? "seen" : '')} key={i}>
                      {item.avatar ?
                        <img src={item.avatar} alt="" className="avatar" />
                        :
                        <img src={item.typeaccount === 2 ? avatar_student : (item.gender === 1 ? men_teacher : girl_teacher)} alt="" className="avatar" />
                      }
                      <div className="content pl-2" onClick={() => { markSeen(item.id, item.actions) }}>
                        {parse(item.title)}
                      </div>
                      {item.seen !== 1 &&
                        <div className="btn-seen">
                          <button className="btn p-0" type="button" title="Đánh dấu là đã xem" onClick={() => { markSeen(item.id) }}>
                            <i className="far fa-dot-circle"></i>
                          </button>
                        </div>
                      }

                    </div>)
                  })
                    :
                    <p className="p-2">Không có thông báo nào</p>
                  }
                </div>
              </li>
            </ul>
            <div className="d-md-none info-user-mobile">
              <div className="w-100 d-flex  u-info">
                <div className="admin-img">
                  <img src={info && info.avatar ? info.avatar : Avatar} alt="Admin" />
                </div>

                <div className="admin-title">
                  <h5 className="item-title">
                    {info.fullname} {" "}
                  </h5>
                  <span>{info.chucvu}</span>
                </div>

              </div>
              <ul className="settings-list">
                <li>
                  <Link to="/my-profile">
                    <i className="flaticon-user"></i>Hồ sơ của tôi
                  </Link>
                </li>
                <li>
                  <a href="#logout" onClick={dologout}>
                    <i className="flaticon-turn-off"></i>Đăng xuất
                  </a>
                </li>
              </ul>

            </div>
          </>
          )}
        </div>
      </div>
    </>
  );
}
export default onClickOutside(HeaderBar, {
  handleClickOutside: () => HeaderBar["handleClickOutside_header"],
});
