import React from 'react';
import { Redirect, Switch, Route } from "react-router-dom";
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import Logout from './Logout';
import Registration from './Registration';
import SignUpFinish from './SignUpFinish';

export default function AuthPage() {
 
  return (
    <div>
     <Switch>
        <Redirect exact={true}   from="/auth" to="/auth/login" />
        <Route path="/auth/login" component={Login} />
        <Route path="/auth/logout" component={Logout} />
        <Route path="/auth/sign-up" component={Registration} />
        <Route path="/auth/sign-up-finish" component={SignUpFinish} />
        <Route path="/auth/forgot-password" component={ForgotPassword} />
    </Switch>
    </div>
  );
}
