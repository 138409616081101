import React from "react";
import SRList from "../../component/List";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

export default function ListActivity(props) {
  const {moreSearch} = props;
  
  const columns = [
    { name: "id", label: "ID" ,options: { filter: true,sort: false }},
    { name: "fullname", label: "Giáo viên" ,options: { filter: true,sort: false }},
    { name: "subject_title", label: "Môn", options: { filter: true,sort: true,
      customBodyRender: (value) => {
        return <OverlayTrigger placement="top" overlay={<Tooltip>{value.replace(/,/i, ', ')}</Tooltip>}>
          <span>{value.substring(0, 20)}</span>
          </OverlayTrigger>;
      },
     } },
    { name: "total_notebook", label: "Kiểm tra ghi chép", options: { filter: true,sort: true } },
    { name: "total_notebook_ok", label: "Đáp ứng kiểm tra ghi chép",options: { filter: true,sort: true } },
    { name: "total_exercise", label: "Kiểm tra bài tập" ,options: { filter: false,sort: true , }},
    { name: "total_exercise_ok", label: "Đáp ứng kiểm tra bài tập" ,options: { filter: false,sort: true , }},
  ];

  return (
    <>
      <SRList name="report_activity_range" moresearch={moreSearch} params={moreSearch?'begin='+moreSearch.begin+'&end='+moreSearch.end:''} title="Hoạt động giáo viên" defaultLimit={200} serverSide={false} columns={columns} noAction={true}></SRList>
    </>
  );
}
