
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SRList from "../component/List";
import Moment from "react-moment";
import ListStudentToolbar from "../list/ListStudentToolbar";
import AkkhorLayout from "../layout/Akkhor";
import { useLocation } from "react-router-dom";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/Api";
import ModalAddStudent from "../list/ListStudent_AddStudent";
import UserDropDown from "../component/UserDropDown";
import { ID_TYPE_ACCOUNT } from "../configs";
import { confirmAlert } from "react-confirm-alert";

const queryString = require('query-string');
const _gender = { 'Nữ': 0, 'Nam': 1 };

export default function ListStudentsPage() {
  const query = queryString.parse(useLocation().search);
  const { user } = useSelector((state) => state.auth);
  const user_id = user && user.data && user.data.id > 0 ? user.data.id : 0;
  const typeaccount = user && user.data && user.data.typeaccount > -1 ? user.data.typeaccount : -1;
  const localgrade_id = user && user.data && user.data.class && user.data.class.grade_id > 0 ? user.data.class.grade_id : 0;
  const [myclass, setMyClass] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [grade_id, setGrade_id] = useState(localgrade_id > 0 ? localgrade_id : 'all');
  const [class_id, setClass_id] = useState('all');
  const [status, setStatus] = useState(null);
  let paramDefault = (typeaccount === ID_TYPE_ACCOUNT.GIAO_VIEN ? '&teacher_id=' + user_id : '')
  const [params, setParams] = useState(paramDefault);


  const addAccount = (_id) => {
    Axios.get(URL_LIST_ALL + "account/" + _id, user.config).then(async (res) => {
      if (res.data.status === "success") {
        setRefresh(!refresh)
      } else console.log(res.data.message);
    }).catch((err) => console.log(err));
  }

  const resetPassword = (_id, pass) => {
    confirmAlert({
      title: "Reset Mật khẩu", message: "Mật khẩu tài khoản của học sinh này sẽ là: " + pass, buttons: [{
        label: "Xác nhận", onClick: () => {
          Axios.get(URL_LIST_ALL + "account_reset/" + _id, user.config).then(async (res) => {
            if (res.data.status === "success") {
              setRefresh(!refresh)
            } else console.log(res.data.message);
          }).catch((err) => console.log(err));
        }
      }, {
        label: "Đóng",
      }],
    });

  }

  const columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    {
      name: "username", label: "Tên đăng nhập", options: {
        filter: true, sort: true,
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[tableMeta.columnIndex - 1];
          const code = tableMeta.rowData[tableMeta.columnIndex + 1];
          if (value) return <button className="btn btn-sm btn-info" onClick={() => resetPassword(id, code)} >{value}</button>;
          else return <button className="btn btn-sm btn-success" onClick={() => addAccount(id)} ><i className="fas fa-plus-circle"></i></button>;
        }
      }
    },
    { name: "code", label: "Mã số", options: { filter: false, sort: true } },
    { name: "fullname", label: "Họ và tên", options: { filter: false, sort: true } },
    {
      name: "gender",
      label: "Giới tính",
      options: {
        filter: true, sort: true,
        customBodyRender: (value) => {
          const icon = value === 1 ? "fas fa-male text-success" : "fas fa-female text-danger";
          return <i className={icon}></i>;
        },
        customFilterListOptions: { render: v => `Giới tinh: ${v}` },
        filterType: 'dropdown',
        filterOptions: {
          names: ['Nữ', 'Nam'],
          logic: (location, filters, row) => {
            if (filters.length && _gender[filters[0]] === location) return false;
            else return true;
          }
        }
      }
    },
    {
      name: "birthday",
      label: "Tuổi",
      options:
      {
        filter: true, sort: true,
        customBodyRender: (value) => {
          if (value) {
            const year1 = new Date(value).getFullYear();
            const year2 = new Date().getFullYear();
            return <b className="text-info">{year1 && year2 ? year2 - year1 : 0}</b>;
          } return null;
        }, filterType: 'textField'
      }
    },
    { name: "mobile", label: "Điện thoại", options: { filter: false, sort: true } },
    { name: "classtitle", options: { display: 'excluded' } },
    {
      name: "datecreate", label: "Ngày tạo",
      options: {
        filter: false, sort: true,
        customBodyRender: (value) => {
          const time = new Date(value * 1000);
          return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
        },
      }
    },
    {
      name: "status", label: "Trạng thái",
      options: {
        filter: false, sort: true,
        customBodyRender: (value) => {
          return value === 1 ? "Đang học" : "Đã nghỉ"
        },
      }
    }
  ];

  //Lấy danh sách lớp
  useEffect(() => {

    let mounted = true;
    if (query.class_id > 0 && myclass === null) {
      Axios.get(URL_LIST_ALL + "class/" + query.class_id, user.config).then((res) => {
        if (res.data && res.data.status === "success") {
          if (mounted) setMyClass(res.data.data);
        } else console.log(res.data.message);
      }).catch((err) => console.log(err));
    }
    return () => (mounted = false);
  }, [query]);// eslint-disable-line

  const onDeleteComplete = () => {

  }
  const CutomToolBar = (props) => {
    return <ListStudentToolbar {...props} myclass={myclass} type={''} refresh={() => setRefresh(!refresh)} onComplete={onDeleteComplete} />
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = paramDefault
    if (grade_id > 0) _param += "&grade_id=" + grade_id
    if (class_id > 0) _param += "&class_id=" + class_id
    if (status && status !== '') _param += "&status=" + status
    setParams(_param);
  };

  return (<AkkhorLayout idrole="list-students">
    <SRList idrole="list-students" refresh={refresh} name={"students"} params={params} title={myclass === null ? "Học sinh" : <> {('Học sinh lớp ')} {myclass.title} <ModalAddStudent myclass={myclass} classtype={query.class_id > 0 ? 1 : 2} refresh={() => setRefresh(!refresh)}></ModalAddStudent></>} defaultLimit={10} serverSide={true} columns={columns} Toolbar={CutomToolBar} noAction={false}>
      <div className="card pb-0 height-auto mb-3 mt-5">

        <div className="card-body">
          <div className="d-flex pb-2">
            <div className="mr-auto">
              <h3>Tìm kiếm học sinh</h3>
            </div>
          </div>
          <form className="new-added-form" method="get" >
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-12 form-group">
                <label>Khóa học</label>
                <UserDropDown apiname={"khoahoc?orderby=title-ASC"} placeholder="Chọn khóa học" colvalue={"khoahoc.id"} colname={"khoahoc.title"} defaultValue={grade_id} name="grade_id"
                  onChange={async (e) => { setGrade_id(e); setClass_id('all') }} all={true}
                ></UserDropDown>
              </div>
              <div className="col-xl-3 col-lg-3 col-12 form-group">
                <label> Lớp học</label>
                <UserDropDown apiname={"class?key=&orderby=title-ASC&page=1&limit=50" + "&grade_id=" + grade_id} placeholder="Chọn lớp học" defaultValue={class_id} colvalue={"class.id"} colname={"class.title"} name="class_id"
                  onChange={async (e) => { setClass_id(e); }} all={true}
                ></UserDropDown>
              </div>
              <div className="col-xl-3 col-lg-3 col-12 form-group">
                <label>Trạng thái</label>
                <select className="form-control height32" onChange={(e)=>setStatus(e.target.value)}>
                  <option value="1" >Đang học</option>
                  <option value="0" >Đã nghỉ</option>
                </select>
              </div>
              <div className="col-12 ">
                <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
              </div>
            </div>
          </form>
        </div>

      </div>
    </SRList>
  </AkkhorLayout>);
}
