import React from "react";
import QTrueFlase from "./QTrueFlase";
import QChooseMulti from "./QChooseMulti";
import QChooseOne from "./QChooseOne";
import QDropDrag from "./QDropDrag";
import QEssay from "./QEssay";
import QFillTo from "./QFillTo";
import QGeneral from "./QGeneral";
import QMatching from "./QMatching";
import { useSelector } from "react-redux";
import QCode from "./QCode";


export default function SingleChoiceQuestion(props) {
  const { test, item, onActive } = props;
  const { Answers } = useSelector(state => state.save);

  const objQues = {
    QTrueFlase: (props) => <QTrueFlase {...props} />,
    QChooseMulti: (props) => <QChooseMulti {...props} />,
    QChooseOne: (props) => <QChooseOne {...props} />,
    QDropDrag: (props) => <QDropDrag {...props} />,
    QEssay: (props) => <QEssay {...props} />,
    QFillTo: (props) => <QFillTo {...props} />,
    QMatching: (props) => <QMatching {...props} />,
    QGeneral: (props) => <QGeneral {...props} />,
    QCode: (props) => <QCode {...props} />,
  };
  const Tag = objQues[item.code];

  const currentAnswer = () => {
    let result = null
    if (item && item.id && Answers && Answers[item.id]) {
      result = Answers[item.id].answers
    } else {
      if (item && item.id && item.students_answers) {
        result = item.students_answers
        
      } else {
        if (test.currentAnswer) {
          const itemTest = test.currentAnswer;
          if (itemTest[item.id]) result = item.currentAnswer
        }
      }

    }
    return result
  }

  return (
    <>
    <Tag item={item} currentAnswer={currentAnswer()} onActive={onActive} onAnswer />
    </>
  );
}
