import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveAnswers } from "../../../redux/Actions";
import parse from 'html-react-parser'

export default function QDropDrag(props) {
    const { item, currentAnswer } = props;
    const { Answers } = useSelector(state => state.save);
    const [listCorrect, setListCorrect] = useState(currentAnswer || []);
    let q_corrects = item.corrects
    const dispatch = useDispatch();

    const shuffle = (array) => {
        if(typeof array === 'object') {
            array =  Object.keys(array).map((key) => array[key]);
        }
        let currentIndex = array.length, randomIndex;
        while (currentIndex > 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
        }
        return array;
    }
    const answers = shuffle(q_corrects);
    const changePostion = (it) => {
        let x_corrects = [...listCorrect]
        if (x_corrects.includes(it)) {
            x_corrects.splice(x_corrects.indexOf(it), 1);
        } else {
            x_corrects.push(it)
        }
        setListCorrect(x_corrects)
        let newListAnswers = { ...Answers }
        let ans_true = true
        let percent = 0
        x_corrects.forEach((itA, idx) => {
            if (itA == q_corrects[idx]) {
                percent += 100 / q_corrects.length
            }
        });
        newListAnswers[item.id] = { question: item, answers: x_corrects, percent: 0 };
        dispatch(saveAnswers(newListAnswers))
    }

    return (<div className="col-12 form-group mt-3">
        <div className="row">
            {answers && answers.map((it, i) => {
                if (!listCorrect.includes(it)) {
                    return <div key={i} className="col-auto mt-1">
                        <div className="py-1 px-2 cursor-pointer rounded border-grey" onClick={() => changePostion(it)}>
                            {it.includes("<class") && it.includes(">") ? it : parse(it)}
                        </div>
                    </div>
                }
            })}
            <div className="col-12 mt-3"><i>Click chọn các câu trả lời để đổi chỗ cho nhau</i></div>
            <div className="col-12">
                <div className="w-100 border-bottom p-1"></div>
            </div>
            {listCorrect && listCorrect.map((it, i) => {
                return <div key={i} className="col-auto mt-1">
                    <div className="py-1 px-2 cursor-pointer rounded border-grey" onClick={() => changePostion(it)}>
                        {it.includes("<class") && it.includes(">") ? it : parse(it)}
                    </div>
                </div>
            })}
        </div>

    </div>);
}