import { Modal } from "react-bootstrap";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { URL_LIST_ALL } from "../configs/Api";
import Pagination from "react-js-pagination";
import { useRef } from "react";
import Spinner from "./Spinner";
import { confirmAlert } from "react-confirm-alert";
import pdficon from "../../src/assets/img/pdf-icon.png"
import docicon from "../../src/assets/img/doc-icon.png"
import foldericon from "../../src/assets/img/folder-icon.png"
import excelricon from "../../src/assets/img/excel-icon.png"
import Moment from "react-moment";

export default function UploadModal(props) {
    const { defaultValue, multiple, button_title, button_class, onSellect,onlytype } = props;
    const { user } = useSelector((state) => state.auth)
    const fileUpload = useRef(null);
    const value = defaultValue
    const [show, setShow] = useState(false)
    const [refreshFile, setRefreshFile] = useState(false)
    const [listImage, setListImage] = useState([])
    const [imgPage, setImgPage] = useState(1)
    const [folder, setFolder] = useState(0)
    const [totalImgRecord, setTotalImgRecord] = useState(10)
    const [folderTitle, setFolderTitle] = useState('')
    const [nameFile, setNameFile] = useState('')
    const [uploading, setUploading] = useState(false)
    const [addingFolder, setAddingFolder] = useState(false)
    const [breadcrumb, setBreadcrumb] = useState([{ id: 0, title: "File của tôi" }])
    const [checked, setChecked] = useState([]);

    useEffect(() => {
        let file_type = onlytype ? '&filetype=' + onlytype : ''
        Axios.get(URL_LIST_ALL + 'file_attachment?parent_id=' + folder + file_type, user.config).then((res) => {
            if (res.data && res.data.status === "success") {
                const _data = res.data.data
                setListImage(_data)
                setTotalImgRecord(res.data.total)
            } else console.log(res.data.message);
        }).catch((err) => { console.log(err) });
    }, [user.config, folder, refreshFile,onlytype])

    const addFolder = () => {
        if (folderTitle && folderTitle !== '') {
            setAddingFolder(true)
            Axios.post(URL_LIST_ALL + 'file_attachment', { name: folderTitle, parent_id: folder, type: 2 }, user.config).then(res => {
                if (res.data && res.data.status === "success") {
                    setRefreshFile(!refreshFile)
                    setFolderTitle('')
                    setAddingFolder(false)
                } else console.log(res.data.message); setAddingFolder(false);
            })
        }
    }

    const uploadProfilePic = (e) => {
        // setSelectedFile(e.target.files[0])
        var fileload = e.target.files[0];
        if (fileload) {
            let value = e.target.value
            value = value.split('\\')
            setNameFile(value.slice(-1)[0])
            setUploading(true)
            let formData = new FormData();
            formData.append('file', fileload, fileload.name)
            formData.append('type', '');
            formData.append('name', 'file');
            formData.append('folder', folder);

            Axios.post(URL_LIST_ALL + 'fileupload', formData, {headers: {
                "Content-Type": "multipart/form-data",
                Authorization: user.config.headers.Authorization,
              }
            }).then(async (res) => {
                if (res.data.status === "success") {
                    setUploading(false)
                    setNameFile('');
                } else {
                    setUploading(false)
                    confirmAlert({
                        title: 'Lỗi',
                        message: res.data.message,
                        buttons: [{ label: 'OK' }]
                    });
                }
                setRefreshFile(!refreshFile)
            }).catch((err) => { console.log(err); setUploading(false) });
        }

    };

    const handleUpload = () => {
        fileUpload.current.click()
    };

    const choisce_image = (id) => {
        if (multiple) {
            var updatedList = [...checked];
            if (updatedList.includes(id + '')) {
                updatedList.splice(checked.indexOf(id + ''), 1);
            } else {
                updatedList = [...checked, id + ''];
            }
            setChecked(updatedList);
        } else {
            if (checked.includes(id + '')) {
                setChecked([]);
            } else {
                setChecked([id + '']);
            }

        }

    }
    const open_folder = (id, title, pos = null) => {
        const _breadcrumb = breadcrumb.slice()
        if (pos || pos === 0) {
        
            let newBread = []
            _breadcrumb.forEach((item, i) => {
                if (i <= pos) {
                    newBread.push(item)
                }
            });
            setBreadcrumb(newBread)
        } else {
            _breadcrumb.push({ id: id, title: title })
            setBreadcrumb(_breadcrumb)
        }
        // setBreadcrumb(new_bread)
        setFolder(id)
    }

    const actionDelete = (id) => {
        confirmAlert({
            title: "Thông báo",
            message: "Bạn có chắc muốn xoá ?",
            buttons: [
                {
                    label: "Có",
                    onClick: () => {
                        Axios.delete(URL_LIST_ALL + 'file_attachment/' + id, user.config).then(res => {
                            if (res.data.status === "success") {
                                setRefreshFile(!refreshFile);
                            } else alert(res.data.message);
                        }).catch(err => console.log(err));
                    },
                },
                { label: "Không" },
            ],
        });
    }

    const actionSellect = () => {
        if (checked) {
            if (multiple) {
                onSellect(checked)
            } else {
                onSellect(checked[0])
            }
            setShow(false)
        }
    }

    return (
        <div className="upload-modal height32">
            <div className="mb-3">
                {value && <>
                    <div className="row">
                        {value.map((item, i) => {
                            return <div key={i} className="col-auto">
                                <div className="box-wrapper">
                                    <img src={item.url} alt={item.name} />
                                    <p className="w-100">{item.name}</p>
                                </div>
                            </div>
                        })}
                    </div>
                </>}
                <div className="button-wrapper">
                    <button type="button" className={button_class ? button_class : "btn btn-sm btn-success height32"} onClick={() => setShow(true)}>{button_title}</button>
                </div>
            </div>

            <Modal show={show} onHide={() => setShow(false)} animation={true} size="xl">

                <Modal.Header closeButton>
                    <Modal.Title>Quản lý file</Modal.Title>
                </Modal.Header>
                <Modal.Body className="body-image-box">
                    <div className="w-100 image-box-files">
                        {show &&
                            <div className="w-100 image-box-wrapper" >
                                <div className="w-100 header-image-wrapper d-flex justify-content-between">
                                    <div className="col-12 col-sm-9 search-image">
                                        <div className="row">
                                            <div className="col-12 col-sm-5">
                                                <div className="upload-group d-flex">
                                                    <div className="input-group mr-2" onClick={() => handleUpload()}>
                                                        <input type="file" ref={fileUpload} onChange={uploadProfilePic} className="d-none" accept={onlytype ? onlytype+'/*' : '*'}/>
                                                        <button className="btn btn-sm btn-info">Chọn file</button>
                                                        <input type="text" className="form-control height32" placeholder="upload file..." readOnly defaultValue={nameFile} />
                                                    </div>
                                                    {uploading && <Spinner />}
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="upload-group d-flex">
                                                    <div className="input-group mr-2" >
                                                        <input type="text" className="form-control height32" placeholder="Nhập tên thư mục..." onChange={(e) => setFolderTitle(e.target.value)} defaultValue={folderTitle} />
                                                        <button className="btn btn-sm btn-success" onClick={() => addFolder()}>Thêm thư mục</button>
                                                    </div>
                                                    {addingFolder && <Spinner />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-3 search-image">
                                        <input type="text" className="form-control height32" placeholder="Tìm kiếm..." defaultValue={''} />
                                    </div>
                                </div>
                                <div className="w-100 list-image">
                                    <div className="breadcrumb">
                                        {breadcrumb.map((item, i) => {
                                            return (
                                                <button type="button" className="item" key={i} onClick={() => open_folder(item.id, item.title, i)}>{item.title} /</button>
                                            )
                                        })}
                                    </div>
                                    {listImage && listImage.length > 0 ? <>
                                        <ul className="row list-image">
                                            {listImage.map((item, i) => {
                                                return <li className={checked.includes(item.url + '') ? "col-12 col-sm-2 item-image active" : "col-12 col-sm-2 item-image"} key={i}>
                                                    <div className="content-img-box">
                                                        <div className="content-wrapper" onClick={(e) => { item.type === 2 ? open_folder(item.id, item.name, null) : choisce_image(item.url) }}>
                                                            {item.type === 1 ?
                                                                <>
                                                                    <span className="itemcheck fas fa-check-circle"></span>
                                                                    <div className="img-wrapper">
                                                                        {(item.file_type === "jpg" || item.file_type === "png" || item.file_type === "jpeg" || item.file_type === "JPEG" || item.file_type === "PNG" || item.file_type === "JPG") &&
                                                                            <img src={item.url} alt={item.title} />
                                                                        }

                                                                        {(item.file_type === "pdf" || item.file_type === "PDF") &&
                                                                            <img src={pdficon} alt={item.title} />
                                                                        }
                                                                        {(item.file_type === "doc" || item.file_type === "docx") &&
                                                                            <img src={docicon} alt={item.title} />
                                                                        }
                                                                        {(item.file_type === "xlsx" || item.file_type === "xls" || item.file_type === "csv" ) &&
                                                                            <img src={excelricon} alt={item.title} />
                                                                        }
                                                                    </div>
                                                                </>
                                                                :
                                                                <div className="img-wrapper"><img src={foldericon} alt={item.title} /></div>
                                                            }
                                                            <p className="filename">{item.name}</p>
                                                            <p className="date">{item.datecreate && <Moment format="HH:mm DD/MM/YYYY">{new Date(item.datecreate * 1000)}</Moment>}</p>
                                                        </div>

                                                        <button className="w-100 btn btn-sm btn-delete btn-danger" onClick={() => actionDelete(item.id)}>Xóa</button>
                                                    </div>
                                                </li>
                                            })}
                                        </ul>
                                        {imgPage && imgPage > 1 &&
                                            <div className="w-100 pagination-group">
                                                <Pagination
                                                    activePage={imgPage}
                                                    itemsCountPerPage={10}
                                                    totalItemsCount={totalImgRecord}
                                                    pageRangeDisplayed={5}
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    prevPageText="<"
                                                    nextPageText=">"
                                                    onChange={setImgPage}
                                                />
                                            </div>
                                        }
                                    </>
                                        : <h3 className="col-12">Thư mục trống</h3>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-sm btn-info text-white" onClick={() => actionSellect()}>Chọn file</button>
                    <button type="button" className="btn btn-sm btn-secondary" onClick={() => setShow(false)}>Đóng</button>
                </Modal.Footer>

            </Modal>
        </div >);
}
//https://github.com/elrumordelaluz/reactour