import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import ViewStep from "./ViewStep";
import StudentByClass from "./StudentByClass";
import Spinner from "../../component/Spinner";
import { CONFIG_STEP_TEST_1 } from "../../configs";
import UserDropDownClass from "../../component/UserDropDownClass";
import AlertErrorForm from "../../component/AlertErrorForm";
import { Accordion } from "react-bootstrap";
import parse from 'html-react-parser';

export default function BaiKiemTraStep3(props) {
  //const { id } = props;
  const { detail, nextStep, previewStep } = props;
  const [quesPoint, setQuesPoint] = useState(0)
  const [countQues, setCountQues] = useState(0)

  const formik = useFormik({
    initialValues: detail,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
    }),
    onSubmit: (values, { setSubmitting }) => {
      values.number = countQues
      values.listclass.map((item, index) => {
        if (values.hasOwnProperty('class_' + item.value)) {
          values.listclass[index] = values['class_' + item.value]
        }
        return item
      })
      setSubmitting(false)
      nextStep(values)
    },
  });
  const showAnwser = (answers, correct) => {
    return (<div>
      {answers && Array.isArray(answers) && answers.length > 0 ? <>
        {answers.map((answ, index) => {
          if (answ) return <div key={index} className={(correct && correct.length > 0 && correct.includes(index)) || correct === index ? "text-success" : ''}>{parse(answ + '')}
            {(correct && answers && correct.length === answers.length) && <span className="text-success"> ({parse(correct[index] + '')})</span>}
          </div>
          else return null;
        })}
      </> : <>
        {correct && Array.isArray(correct) && correct.length > 0 ? <>
          {correct.map((answ, index) => {
            if (answ) return <div key={index} className="text-success">{parse(answ + '')}</div>
            else return null;
          })}</> : <>
        </>}
      </>}
    </div>)
  }
  const DiemSo = (tree, jndex, e) => {
    let _questions = Object.assign({}, formik.values.questions);
    let _ques = Object.assign({}, _questions[tree.id].list[jndex]);
    _ques.point = e.target.value;
    _questions[tree.id].list[jndex] = _ques;
    formik.setFieldValue('questions', _questions);
  }

  useEffect(() => {
    if (formik.values.questions) {
      const qus = formik.values.questions;
      const totalPoint = formik.values.total_point
      if (typeof (qus) === 'object') {
        let count = 0
        Object.values(qus).map((item, index) => {
          count = count + item.list.length;
          return count
        })

        if (count > 0) {
          setCountQues(count)
          setQuesPoint(parseFloat((totalPoint / count).toFixed(2)))
        }
      }
    }
  }, [formik.values.questions, formik.values.total_point])

  return (
    <div className="row">
      <div className="col-sm-12 col-md-6 offset-md-3">
        <ViewStep step={2} arrStep={CONFIG_STEP_TEST_1} />
        <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-12 form-group">
              <div className="w-100 mt-3 mb-3">
                <div>Thang điểm</div>
                <input type="number" name="total_point" className="form-control" placeholder="Nhập thang điểm"  {...formik.getFieldProps("total_point")} />
                <div className="mt-2 mb-3">
                  Tổng số câu hỏi: {countQues}
                </div>
              </div>

              {Object.values(formik.values.questions).map((item, index) => {
                return (<div key={index} className="border rounded mb-3 overflow-hidden">
                  <div className="bg-info p-2 text-light"><b>Cây kiến thức: {item.title}</b></div>
                  <div className="p-2">
                    <div><p>Số câu hỏi đã chọn:<b>{item.list.length}</b></p></div>
                    <div className="mt-2">
                      {item.list.map((quest, jndex) => {
                        return (<div key={jndex}>
                          <div className="border rounded mb-3 mr-2 font14 overflow-hidden">
                            <div className="p-2 bg-info text-light">
                              <div><b>{quest.typetitle}</b></div>
                              <div><b>Tạo bởi : {quest.fullname} ({quest.email})</b></div>
                            </div>
                            <div className="p-2">
                              <div>
                                {parse(quest.title)}
                              </div>
                              <div className="border-bottom my-2">
                                <div>
                                  {showAnwser(quest.answers)}
                                </div>
                                <div className="mt-1 d-flex justify-content-between align-items-center border-top pt-2">
                                  <b>ID: {quest.id}</b>
                                </div>
                                <div>
                                <input type="text" value={quest.point || quest.point == 0 ? quest.point : quesPoint}  name="Điểm" className="form-control" placeholder="Nhập điểm" onChange={async (e) => { DiemSo(item, jndex, e) }} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        );
                      })}
                    </div>
                  </div>
                </div>)
              })}

              <label>Chọn lớp <span className="text-danger">*</span> </label>
              <UserDropDownClass apiname={"class?key=&orderby=title-ASC&page=1&limit=500&grade_id=" + (formik.values.grade_id)} placeholder="Tất cả lớp" detail={detail} colvalue={'class.id'} formik={formik} colname={'class.title'} name='listclass'
                onChange={async (e) => { await formik.setFieldValue('listclass', e); }}
              ></UserDropDownClass>
              <AlertErrorForm formik={formik} name="listclass"></AlertErrorForm>
            </div>
            <div className="col-12 form-group">
              <Accordion defaultActiveKey={0}>
                {formik.values.listclass.map((item, index) => {
                  return <Accordion.Item eventKey={index} className="card mt-3" key={index}>
                    <Accordion.Header as="div" bsPrefix="accordion-header card-header" onClick={() => { }}>
                      <div className="row d-flex justify-content-between">
                        <div className="col-10">
                          Lớp {item.label}
                        </div>
                        <div className="pr-3">
                          <span className="fas fa-angle-down"></span>
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body bsPrefix="list-studen-card accordion-body card-body">
                      <StudentByClass myclass={item} onUpdate={(e) => {
                        formik.setFieldValue('class_' + e.value, e);
                      }} />
                    </Accordion.Body>
                  </Accordion.Item>
                })}
              </Accordion>
            </div>

            <div className="col-12 form-group mg-t-8 text-center">
              <button type="button" onClick={previewStep} className="btn-fill-lg bg-blue-dark btn-hover-yellow" >Quay lại</button>
              <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" > {formik.isSubmitting ? <Spinner /> : <>Kết thúc</>}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
