import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MyEditor2 from "./../../../component/MyEditor2";
import { saveAnswers } from "../../../redux/Actions";

export default function QEssay(props) {
    const { item, currentAnswer } = props;
    const { Answers } = useSelector(state => state.save);
    const dispatch = useDispatch();

    const onBlur = (data) => {
        let newListAnswers = { ...Answers }
        newListAnswers[item.id] = { question: item, answers: data, percent: 0 };
        dispatch(saveAnswers(newListAnswers))
    }


    return (<div className="col-12 mt-3">
        <MyEditor2 height={150} key={Math.random()}
            initialValue={currentAnswer || ''}
            onValue={(value) => onBlur(value)}
        />
    </div>);
}
