import { useFormik } from "formik";
import * as Yup from "yup";
import React from "react";
import { useSelector } from "react-redux";
import Axios from "axios";
import { CONFIG_STEP_TEST_1 } from "../configs";
import ViewStep from "../examination/tests/ViewStep";
import { URL_LIST_ALL } from "../configs/Api";
import UserDropDown from "../component/UserDropDown";
import AlertErrorForm from "../component/AlertErrorForm";
import UserDropDownClass from "../component/UserDropDownClass";
import Spinner from "../component/Spinner";

export default function FormBookStep1(props) {
  const { user } = useSelector((state) => state.auth);
  const { nextStep, detail } = props
  const defaultdetail = { type:4,code:'',author_id: user.data.id, title: '', status: 1, number: 0, total_point: 10, grade_id: '', subject_id: '', listclass: '', questions: {} }

  const formik = useFormik({
    initialValues: detail ? detail : defaultdetail,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      title: Yup.string().trim().nullable().required('Nhập tên phiếu bài tập').max(150, "Độ dài không quá 150 ký tự"),
      code: Yup.string().trim().nullable().required('Vui lòng nhập mã bài tập').max(5, "Độ dài không quá 5 ký tự"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false)
      nextStep(values)
    },
  });

  const setSubject = (value) => {
    Axios.get(URL_LIST_ALL + "subjects?grade_id=" + value, user.config)
      .then(async (res) => {
        if (res.data.status === "success") {
          let data_subject = res.data.data
          if (data_subject) {
            formik.setFieldValue("subject_id", data_subject[0].id)
          }
        } else console.log(res.data.message);
      }).catch((err) => { console.log(err) });
  }
  return (
    <div className="row">
      <div className="col-sm-12 col-md-6 offset-md-3">
        <ViewStep step={0} arrStep={CONFIG_STEP_TEST_1} />
        <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-12 form-group">
              <label>Tiêu đề cho bài tập <span className="text-danger">*</span></label>
              <input type="text" className="form-control h-31" placeholder="Tiêu đề cho bài tập" name="title"
                {...formik.getFieldProps("title")}
              ></input>
              <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
            </div>
            <div className="col-12 form-group">
              <label>Mã bài tập <span className="text-danger">*</span></label>
              <input type="text" className="form-control h-31" placeholder="Tiêu đề cho bài tập" name="code" {...formik.getFieldProps("code")}></input>
              <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
            </div>
            <div className="col-12 form-group">
              <label>Chọn khóa học <span className="text-danger">*</span></label>
              <UserDropDown apiname={"khoahoc?orderby=title-ASC&"} placeholder="Chọn khóa học" colvalue={"khoahoc.id"} colname={"khoahoc.title"} formik={formik} name="grade_id"
                onChange={async (e) => { await formik.setFieldValue("grade_id", e); setSubject(e) }}
              ></UserDropDown>
              <AlertErrorForm formik={formik} name="grade_id"></AlertErrorForm>
            </div>
            <input type="hidden" placeholder="" className="form-control height32" {...formik.getFieldProps("subject_id")} autoComplete="off" />

            <div className="col-12 form-group mg-t-8 text-center">
              <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" > {formik.isSubmitting ? <Spinner /> : <>Tiếp tục</>}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
