import React, { useState } from 'react';
import QRCode from 'qrcode.react';

function QRCodeGenerator() {
    const [qrData, setQRData] = useState('');

    const handleChange = (event) => {
        setQRData(event.target.value);
    };

    return (
        <>
            <div className="col-xl-4 col-lg-6 col-12 form-group">
                <label>Nội dung tạo <span className="text-danger">*</span></label>
                <input type="text" placeholder="Nội dung..." className="form-control height32" value={qrData} onChange={handleChange} />
            </div>
            {qrData && <QRCode value={qrData} size={500}/>}
        </>
    );
}

export default QRCodeGenerator;