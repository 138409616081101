import React, { useState } from "react";
import ReactMomentCountDown from "react-moment-countdown";

export default function TimeOut(props) {
  const { formatMask, now, onTimeOut } = props;
  const [timeOut, setTimeOut] = useState(false);
  
  const onCountdownEnd = () => {
    onTimeOut(true)
    setTimeOut(true);
  };
  
  const dateInFuture = now;
  return (
    <h1 className={"text-danger text-center"}>
      {timeOut ? (
        "Hết giờ"
      ) : (
        <ReactMomentCountDown
          toDate={dateInFuture}
          targetFormatMask={formatMask}
          onCountdownEnd={onCountdownEnd}
        />
      )}
    </h1>
  );
}
