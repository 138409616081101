import React, { useState } from "react";
import ReactHtmlParser from 'react-html-parser'

export default function QEssay(props) {
    const {currentAnswer} = props;
    const [choice] = useState(currentAnswer || '')
    
    return (<div className="col-12 mt-3">
        {
            !choice ? <textarea className="form-control form-control-sm border" readOnly value={choice||''} style={{height:70}} placeholder="" ></textarea>
        :
            <div className="w-100 mb-3">
               { ReactHtmlParser(choice) }
            </div>
        }
    </div>);
}
