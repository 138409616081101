import Axios from "axios";
import React, { useEffect, useState } from "react";
import { URL_LIST_ALL } from "../configs/Api";
import moment from "moment";
import { useSelector } from "react-redux";
import SelectWeek from "./SelectWeek";
import { useLocation } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


export default function FilterGradeClassWeekSubject(props) {
    let query = useQuery();
    const { setClassChoice, classChoice, setTimeTableChoice, viewtype, idgroup } = props;
    const { user } = useSelector((state) => state.auth);
    const [gradeChoice, setGradeChoice] = useState(query.get('grade_id'));
    const [weekChoice, setWeekChoice] = useState(query.get('week'));
    const [dayChoice, setDayChoice] = useState(query.get('date'));
    const [idChoice, setIdChoice] = useState(parseInt(query.get('idchoice')));
    const [listGardes, setListGardes] = useState([]);
    const [listClass, setListClass] = useState([]);
    const [listTimeTable, setListTimeTable] = useState([]);
    //Lấy danh sách khối
    useEffect(() => {
        let mounted = true;
        if (!idgroup) {
            Axios.get(URL_LIST_ALL + "khoahoc?page=1&limit=500&orderby=title-ASC", user.config).then((res) => {
                if (res.data && res.data.status === "success") {
                    if (mounted) setListGardes(res.data.data);
                    if (res.data.data && res.data.data.length > 0)
                        if (mounted && !gradeChoice) setGradeChoice(res.data.data[res.data.data.length - 1].id);
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
        }
        return () => (mounted = false);
    }, [ user]);// eslint-disable-line
    //Lấy danh sách lớp
    useEffect(() => {
        let mounted = true;
        if (gradeChoice || idgroup > 0) {
            let link = URL_LIST_ALL + "class?page=1&limit=500&orderby=title-ASC&viewtype=" + viewtype
            if (gradeChoice) link += "&grade_id=" + gradeChoice;
            else if (idgroup > 0) link += "&group_id=" + idgroup;
            Axios.get(link, user.config).then((res) => {
                if (res.data && res.data.status === "success") {
                    if (mounted) {
                        let objData = {}
                        res.data.data.map((item) => {
                            objData[item.id] = item
                            return item;
                        })
                        setListClass(Object.values(objData));
                    }
                    if (res.data.data && res.data.data.length > 0 && !classChoice) {
                        if (mounted) setClassChoice(res.data.data[res.data.data.length - 1].id);
                    }
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
        }
        return () => (mounted = false);
    }, [gradeChoice, user, idgroup]);// eslint-disable-line
    //Lấy danh sách thời khoá biểu
    useEffect(() => {
        let mounted = true;
        if (classChoice && weekChoice > 0) {
            Axios.get(URL_LIST_ALL + "timetable?page=1&limit=500&class_id=" + classChoice + "&week=" + weekChoice + '&orderby=date-asc', user.config).then((res) => {
                if (res.data && res.data.status === "success") {
                    if (mounted) setListTimeTable(res.data.data);
                    let dayDefault = null;
                    res.data.data.forEach((item) => {
                        let now = moment().format('YYYY-MM-DD');
                        if (dayChoice) now = dayChoice
                        if (now === item.date) {
                            if (!idChoice || idChoice === item.id) {
                                dayDefault = item
                                return;
                            }
                        }
                    });
                    if (!dayDefault) dayDefault = res.data.data[0]
                    if (dayDefault) {
                        if (mounted) setTimeTableChoice(dayDefault)
                        if (mounted && !dayChoice) setDayChoice(dayDefault.date)
                        if (mounted && !idChoice) setIdChoice(dayDefault.id)
                    }
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
        } else {
            setListTimeTable([])
            setDayChoice(null)
            setIdChoice(null)
            setTimeTableChoice(null)
        }
        return () => (mounted = false);
    }, [classChoice, weekChoice, setTimeTableChoice, user]);// eslint-disable-line
    //Lay danh sach ngay trong tuan
    const createWeekDate = () => {
        let list = {};
        listTimeTable.forEach(item => {
            list[item.date] = true;
        })
        return list;
    }
    //Lọc danh sách tiết trong ngày
    const filterCalendar = (date) => {
        let list = [];
        listTimeTable.forEach(item => {
            if (item.date === date) {
                list[item.orderonday - 1] = item;
                return;
            }
        })
        return list;
    }

    //Sự kiện thay đổi ngày trong tuần
    const changeDay = (date) => {
        setDayChoice(date);
        listTimeTable.forEach(item => {
            if ((item.week + '') === (weekChoice + '') && (item.date + '') === (date + '')) {
                setTimeTableChoice(item)
                setIdChoice(item.id)
                return;
            }
        })
    }

    //Sự kiện thay đổi tiết học trong ngày
    const changeID = (id) => {
        setIdChoice(id);
        listTimeTable.forEach(item => {
            if ((item.id + '') === (id + '')) {
                setTimeTableChoice(item)
                return;
            }
        })
    }

    return (
        <form className="form-inline">
            {!idgroup && <>
                <label className="mr-2">Khối</label>
                <select className="form-control form-control-sm" value={gradeChoice ? gradeChoice : 0} onChange={(e) => {
                    setClassChoice(null);
                    setGradeChoice(e.target.value)
                }}>
                    <option value={0} disabled>Chọn khối</option>
                    {listGardes.map((item) => {
                        return <option key={item.id} value={item.id}>{item.title}</option>
                    })}
                </select>
            </>}
            <label className="mr-2  mt-2 mt-sm-0 ml-md-2">Lớp học</label>
            <select className="form-control form-control-sm" value={classChoice ? classChoice : 0} onChange={(e) => setClassChoice(e.target.value)}>
                <option value={0} disabled>Chọn lớp</option>
                {listClass.map((item, index) => {
                    return <option key={index} value={item.id}>{item.title}</option>
                })}
            </select>
            <label className="mr-2  mt-2 mt-sm-0  ml-md-2">Tuần thứ</label>
            <SelectWeek weekChoice={weekChoice} setWeekChoice={setWeekChoice}></SelectWeek>
            <label className="mr-2  mt-2 mt-sm-0  ml-md-2">Ngày</label>
            <select className="form-control form-control-sm text-capitalize" value={dayChoice ? dayChoice : 0} onChange={(e) => changeDay(e.target.value)}>
                <option value={0} disabled>Chọn ngày</option>
                {Object.keys(createWeekDate()).map((date, index) => {
                    return <option key={index} value={date} >{moment(date).format('dddd') + ' - ' + moment(date).format('DD-MM-YYYY')}</option>
                })}
            </select>
            <label className="mr-2 mt-2 mt-sm-0  ml-md-2">Tiết học</label>
            <select className="form-control form-control-sm " value={idChoice ? idChoice : 0} onChange={(e) => changeID(e.target.value)}>
                <option value={0} disabled>Chọn tiết học</option>
                {filterCalendar(dayChoice).map((item, index) => {
                    return <option key={index} value={item.id} >{item.orderonday} - {item.title}</option>
                })}
            </select>
        </form>
    );
}
