
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { URL_LIST_ALL } from "../configs/Api";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";

export default function SuggestQuestion(props) {
    const { question_id } = props
    const { user } = useSelector((state) => state.auth);
    const [showSugget, setShowSugget] = useState(false);
    const [listSuggest, setListSugget] = useState([]);
    const [suggetStep, setSuggetStep] = useState(0);

    useEffect(() => {
        Axios.get(URL_LIST_ALL + "at_suggest_question?orderby=id-asc&question_id=" + question_id, user.config).then((res) => {
            if (res.data && res.data.status === "success") {
                setListSugget(res.data.data)
            } else {

            }
        }).catch((err) => console.log(err));
    }, [question_id])

    return (
        <div className="list_suggest">
            {listSuggest && listSuggest.length > 0 ?
                <button type="button" className="btn btn-sm btn-warning btn-sugget" onClick={() => setShowSugget(true)}>Gợi ý</button>
                : ''
            }

            <Modal show={showSugget} onHide={() => setShowSugget(false)} animation={true} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Gợi ý: {suggetStep + 1}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {listSuggest && listSuggest[suggetStep] && listSuggest[suggetStep].suggest}
                </Modal.Body>
                <Modal.Footer>
                    <div className="w-100 d-flex justify-content-between">
                        <div>
                            {suggetStep > 0 && <button className="btn btn-sm btn-info" onClick={()=>setSuggetStep(suggetStep-1)}><span className="fas fa-angle-left"></span></button>}
                        </div>
                        <div>
                            {suggetStep < (listSuggest.length - 1) && <button className="btn btn-sm btn-info" onClick={()=>setSuggetStep(suggetStep+1)}><span className="fas fa-angle-right"></span></button>}
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}