import React, { useEffect, useState } from 'react';
import AkkhorLayout from '../layout/Akkhor';
import UserDropDown from '../component/UserDropDown';
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import Axios from "axios";
import { URL_LIST_ALL } from '../configs/Api';
import { useSelector } from 'react-redux';

export default function DiemDanhTietHoc(props) {
    const { user } = useSelector((state) => state.auth);
    const [class_id, setClass_id] = useState('all');
    const [classInfo, setClassInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dateCheck, setDateCheck] = useState(moment(new Date()).format('DD-MM-YY'))
    const [dateSelect, setDateSelect] = useState({ datecheck: new Date() })
    const [listOnday, setListOnday] = useState([])

    const handleDate = (name, value) => {
        setDateCheck(moment(value).format('DD-MM-YYYY'))
        let newDate = Object.assign({}, dateSelect)
        newDate[name] = value
        setDateSelect(newDate)
    };

    useEffect(() => {
        if (class_id && dateCheck && class_id !== 'all') {
            setLoading(true)
            Axios.get(URL_LIST_ALL + "check_student_onday?class_id=" + class_id + '&datecheck=' + dateCheck, user.config).then(async (res) => {
                if (res.data.status === "success") {
                    setListOnday(res.data.data)
                    setClassInfo(res.data.class_info)

                } else console.log(res.data.message);
                setLoading(false)
            }).catch((err) => {
                console.log(err)
                setLoading(false)
            });
        }
    }, [class_id, dateCheck])

    const checkOnday = (id, type, value) => {
        let data = {}
        data[type] = value
        data.author_id = user.data.id
        updateList(id, type, value)
        Axios.put(URL_LIST_ALL + 'students_onday/' + id, data, user.config).then(async (res) => {
        }).catch((err) => {
            console.log(err)
        });
    }

    const updateList = (id, type, value) => {
        let cloneList = [...listOnday]

        cloneList.forEach((item, i) => {
            if (item.id === id) {
                cloneList[i][type] = value
            }
        })
        setListOnday(cloneList)
    }

    return (
        <AkkhorLayout idrole="attends">
            <h4 className="mt-2">Điểm danh tiết học</h4>
            <div className="card pb-0 height-auto mb-3 mt-5">
                <div className="card-body">
                    <div className='row'>
                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                            <label>Chọn lớp</label>
                            <UserDropDown apiname={"class?key=&orderby=title-ASC&page=1&limit=50"} placeholder="Chọn lớp học" defaultValue={class_id} colvalue={"class.id"} colname={"class.title"} name="class_id"
                                onChange={async (e) => { setClass_id(e); }} all={true}
                            ></UserDropDown>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                            <label>Ngày điểm danh</label>
                            <DateTimePicker onChange={(value) => { handleDate('datecheck', value) }} value={dateSelect.datecheck ? new Date(dateSelect.datecheck) : null} maxDate={new Date()} format="dd-MM-yy" className="form-control height32 air-datepicker"></DateTimePicker>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-12 form-group"></div>
                        <div className="col-xl-3 col-lg-3 col-12 form-group d-flex justify-content-end">
                            <div><a className='btn btn-sm btn-info' href='/xuat-diem-danh'>Xuất dữ liệu</a></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card pb-0 height-auto my-1">
                {loading ?
                    <div className="outer-loader">
                        <div className="loader"></div>
                    </div> :
                    <div className="card-body">
                        {classInfo && <h4>Điểm danh lớp {classInfo.title}</h4>}
                        <div className='w-100'>
                            <table className='table table-triple table-bordered mt-2'>
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Mã học sinh</th>
                                        <th scope="col">Họ và tên</th>
                                        <th scope="col" className='text-center'>Đủ</th>
                                        <th scope="col" className='text-center'>Vắng</th>
                                        <th scope="col" className='text-center'>Có phép</th>
                                        <th scope="col">Ghi chú</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listOnday && listOnday.map((item, i) => {
                                        return <tr className='w-100 item-onday' key={i}>
                                            <td>{i + 1}</td>
                                            <td>{item.code}</td>
                                            <td>{item.fullname}</td>
                                            <td>
                                                <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                                                    <button className='btn-radio' type='button' onClick={() => checkOnday(item.id, 'status', 1)}>
                                                        <i className={item.status && item.status === 1 ? 'fas fa-check-circle text-success' : 'far fa-circle '}></i>
                                                    </button>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                                                    <button className='btn-radio' type='button' onClick={() => checkOnday(item.id, 'status', 2)}>
                                                        <i className={item.status && item.status === 2 ? 'fas fa-check-circle text-success' : 'far fa-circle '}></i>
                                                    </button>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                                                    <button className='btn-radio' type='button' onClick={() => checkOnday(item.id, 'status', 3)}>
                                                        <i className={item.status && item.status === 3 ? 'fas fa-check-circle text-success' : 'far fa-circle '}></i>
                                                    </button>
                                                </div>
                                            </td>
                                            <td><textarea className='form-control' placeholder='Ghi chú...' onChange={(e) => { checkOnday(item.id, 'notes', e.target.value) }} value={item.notes ? item.notes : ''}></textarea></td>
                                        </tr>
                                    })}
                                </tbody>

                            </table>

                        </div>
                    </div>

                }

            </div>
        </AkkhorLayout>
    );
}
