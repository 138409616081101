import Axios from "axios";
import React, { useEffect, useState } from "react";
import AkkhorLayout from '../layout/Akkhor';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import moment from "moment";
import { URL_LIST_ALL } from "../configs/Api";
import SingleChoiceQuestion from "../examination/questions/SingleChoiceQuestion";
import parse from 'html-react-parser'
import GenderCode from "../component/GenderCode";
import FeedBackComment from "../component/FeedBackComment";
import { useLocation, useParams } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function ClientBooksExDetail(props) {
    let query = useQuery();
    const { code } = useParams();
    const { user } = useSelector((state) => state.auth);
    const paramStudentId = parseInt(query.get("student_id"));
    const questionID = parseInt(query.get("question_id"));

    const [detail, setDetail] = useState(null);
    const [dataExam, setDataExam] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [listQuestion, setListQuestion] = useState([]);
    const [indexQuestion, setIndexQuestion] = useState(0);
    const [now] = useState(moment(new Date()));
    const [onAnswer, setOnAnswer] = useState(null);
    const [checkChoice, setCheckChoice] = useState(false);
    const [textStatus, setTextStatus] = useState('');
    const [finish, setFinish] = useState(false);
    const { Answers } = useSelector(state => state.save);
    const [myPoint, setMyPoint] = useState(0)
    const [examStudent, setExamStudent] = useState(null)

    useEffect(() => {
        if (code && user) {
            let mounted = true;
            let student_id = paramStudentId ? paramStudentId : user.data.id
            Axios.get(URL_LIST_ALL + "at_exam_students_answers?limit=1000&orderby=id-asc&book_code=" + code + "&student_id=" + student_id, user.config).then(async (res) => {
                if (res.data.status === "success" && mounted) {
                    let data_rs = res.data
                    setDataExam(data_rs)
                    setListQuestion(data_rs.data);
                    data_rs.test_info.total = data_rs.total
                    setDetail(data_rs.test_info)
                    if (data_rs.exam_student_test) setExamStudent(data_rs.exam_student_test)
                    console.log(data_rs.exam_student_test);
                    if (data_rs.exam_student_test && data_rs.exam_student_test.status !== 1) {
                        let check = 1
                        data_rs.data.forEach((item, i) => {
                            console.log(item);
                            if (item.answer_status === 2 && check === 1) {
                                setIndexQuestion(i);
                                check = 0
                            }
                        });
                        if (check === 1 && (data_rs.exam_student_test.status == 0 || data_rs.exam_student_test.status == 2)) {
                            setFinish(true)
                            onFinish()
                        }
                    } else {
                        setIndexQuestion(data_rs.total - 1);
                        if (data_rs.exam_student_test.point) setMyPoint(data_rs.exam_student_test.point)
                        setFinish(true)
                    }
                    if (questionID) {
                        data_rs.data.forEach((item, i) => {
                            if (item.id == questionID) {
                                setIndexQuestion(i);
                            }
                        });
                    }
                }
                else console.log(res.data.message);
            }).catch((err) => console.log(err));

            return () => (mounted = false);
        }
    }, [user, code, now, refresh]);// eslint-disable-line

    const checkQuestion = (item_answer, answers) => {
        let s_answers = answers ? answers : (Answers && Answers[item_answer.id])
        if (s_answers) {
            let ans_true = true
            let student_answers = s_answers.answers
            let question_answers = s_answers.question.corrects

            if (!Array.isArray(question_answers)) {
                question_answers = JSON.parse(question_answers)
            }
            let snschoice = []
            let array_alpha = ['A', 'B', 'C', 'D', 'E', 'F', 'G']
            let text_ans = ''
            if (s_answers.question.code === "QFillTo") {
                question_answers.forEach((item, i) => {
                    if (typeof item == 'string' && item.includes("||")) {
                        const arr = item.split('||').map(string => string.toString().trim())
                        if (arr.includes(student_answers[i].trim())) {
                            ans_true = true
                            snschoice.push(1)
                        } else {
                            ans_true = false
                            snschoice.push(0)
                        }
                    } else {
                        if (item == student_answers[i]) {/* eslint eqeqeq: 0 */
                            ans_true = true
                            snschoice.push(1)
                        } else if (item == student_answers[i].trim()) {/* eslint eqeqeq: 0 */
                            ans_true = true
                            snschoice.push(1)
                        } else if (typeof item == 'string' && item.trim() == student_answers[i].trim()) {/* eslint eqeqeq: 0 */
                            ans_true = true
                            snschoice.push(1)
                        } else {
                            ans_true = false
                            snschoice.push(0)
                        }
                    }
                });

                text_ans = question_answers.join("; ")
                if (typeof text_ans == 'string' && text_ans.includes("||")) {
                    text_ans = text_ans.replace('||', ' hoặc ')
                }
                text_ans = question_answers.map((itQ, i) => {
                    return (<div className="col-6 d-flex mt-1 d-flex align-items-center" key={i}>
                        <span className="text-number d-inline-block mr-2">{i + 1}</span> <span className="w-100 p-1 border rounded d-inline-block border-secondary bg-white">{typeof itQ == 'string' && itQ.includes("||") ? itQ.replace('||', ' hoặc ') : itQ}</span>
                    </div>)
                })
            }
            if (s_answers.question.code === "QChooseOne") {
                if (student_answers === question_answers) {
                    ans_true = true
                    snschoice.push(1)
                } else {
                    ans_true = false
                    snschoice.push(0)
                }
                let answ = Array.isArray(s_answers.question.answers) ? s_answers.question.answers : JSON.parse(s_answers.question.answers)
                text_ans = <div>{array_alpha[question_answers]}. <button type="button" className="btn btn-sm btn-secondary">{answ[question_answers]}</button></div>
            }
            if (s_answers.question.code === "QChooseMulti") {
                ans_true = true
                Object.keys(student_answers).forEach((item, i) => {
                    if (question_answers[i] != item) { /* eslint eqeqeq: 0 */
                        ans_true = false
                    }
                });
                snschoice.push(ans_true ? 1 : 0)
            }

            if (s_answers.question.code === "QDropDrag") {
                ans_true = true
                student_answers.forEach((itA, idx) => {
                    if (itA !== question_answers[idx]) {
                        ans_true = false
                    }
                });
                snschoice.push(ans_true ? 1 : 0)
            }
            let point = 0

            if (ans_true) point = 1
            if (point === 1) {
                setCheckChoice(true)
                setTextStatus(<div className="col-12 rounded pt-1 pb-1 bg-success text-white">Chính xác</div>)
            } else {
                if (s_answers.question.code !== "QChooseOne" && s_answers.question.code !== "QFillTo") {
                    text_ans = text_ans.includes("<class") && text_ans.includes(">") ? text_ans : parse(text_ans);
                }

                if (s_answers.question.code === "QDropDrag") {
                    text_ans = question_answers.map((itQ, i) => {
                        return (<div className="col-auto pl-0 pr-0 mt-1 d-flex align-items-center" key={i}>
                            <div className="py-1 px-2 cursor-pointer rounded border-grey bg-white">
                                {itQ.includes("<class") && itQ.includes(">") ? itQ : parse(itQ)}
                            </div>
                            {i + 1 < question_answers.length && <i className="fas fa-arrow-right" style={{ fontSize: 11 }}></i>}
                        </div>)
                    })
                }
                if (s_answers.question.code === "QChooseMulti") {
                    text_ans = question_answers.map((itQ, i) => {
                        let answ = Array.isArray(s_answers.question.answers) ? s_answers.question.answers : JSON.parse(s_answers.question.answers)
                        return (<div className="col-6 mt-1" key={i}>{array_alpha[itQ]}. <button type="button" className="btn btn-sm btn-secondary">{answ[itQ]}</button></div>)
                    })
                }
                setCheckChoice(true)
                setTextStatus(<div className="col-12 rounded pt-1 pb-1" style={{ backgroundColor: "rgba(255,223,224)" }}>
                    <h4 className="w-100 text-center text-danger">Sai rồi</h4>
                    <div className="row">
                        <div className="col-12 col-sm-2"><strong>Đáp án đúng là:</strong></div>
                        <div className="col-12 col-sm-10" style={{ whiteSpace: "pre-wrap" }}>
                            {s_answers.question.code !== "QDropDrag" && s_answers.question.code !== "QFillTo" ? <span>{text_ans}</span> : <div className="row">{text_ans}</div>}
                        </div>
                    </div>
                </div>)
            }
            if (!answers) {
                saveStudentAnswer(item_answer.id, item_answer.id_student_answer, point)
                setOnAnswer(snschoice);
            }
        }
    }

    const saveStudentAnswer = (qid, id_student_answer, point, type = null) => {

        let datasave = {}
        if (type) {
            datasave = {
                student_id: user.data.id,
                test_id: dataExam.exam_student_test.test_id,
                question_id: qid,
                point: point,
                corrects: null,
                status: 1,
            }
        } else {
            let student_answers = Answers && Answers[qid] && Answers[qid].answers ? Answers[qid].answers : null
            datasave = {
                student_id: user.data.id,
                test_id: dataExam.exam_student_test.test_id,
                question_id: qid,
                point: point,
                corrects: JSON.stringify(student_answers),
                status: 1,
            }
        }
        Axios.put(URL_LIST_ALL + "at_exam_students_answers/" + id_student_answer, datasave, user.config).then(async (res) => {
        }).catch((err) => { console.log(err); });
    }

    useEffect(() => {
        if (listQuestion && listQuestion[indexQuestion]) {
            let currenQuestion = listQuestion[indexQuestion]
            if (currenQuestion.students_answers) {
                checkQuestion(indexQuestion, { answers: currenQuestion.students_answers, question: currenQuestion })
            }
        }
    }, [listQuestion])

    const onFinish = () => {
        if (detail) {
         
            Axios.post(URL_LIST_ALL + "on_finish_test", { test_id: detail.id }, user.config).then(async (res) => {
                if (res.data.status === 'success') {
                    setRefresh(!refresh)
                }
            }).catch((err) => { console.log(err); });
        }

    }

    const onNextQuestion = () => {
        let clone_list = [...listQuestion]
        let check = 1
        let clone_ques = { ...clone_list[indexQuestion] }
        clone_ques.answer_status = 1
        clone_list[indexQuestion] = clone_ques

        setListQuestion(clone_list)
        clone_list.forEach((item, i) => {
            if (item.answer_status === 2 && check == 1 && i !== indexQuestion) {
                check = 0
                setIndexQuestion(i)
                setCheckChoice(false)
                setTextStatus("")
            }
        });
        if (check === 1) {
            setRefresh(!refresh)
        }
    }
    return (
        <AkkhorLayout idrole="examination" roleignone={user.data.typeaccount === 2 || user.data.typeaccount === 4}>
            {detail && listQuestion && (
                <div className="container-fluid p-0">
                    <div className="breadcrumbs-area py-1 py-md-3">
                        <ul>
                            <li>
                                <Link to="/">Trang chủ</Link>
                            </li>
                            <li>Bài tập</li>
                        </ul>
                    </div>
                    <div className="card height-auto card-question">
                        <div className="card-body">
                            <div className="w-100 minheight-500">
                                <div className="row">
                                    <div className="col-12">

                                        {listQuestion.length > 0 && listQuestion[indexQuestion] && (
                                            <div className="mb-2 border minheight-500 rounded position-relative overflow-hidden " id={"nuques-" + indexQuestion}>
                                                <h2 className="race-title text-center my-2">{detail.title}</h2>
                                                <div className="w-100 pl-3 pr-3 mt-3 position-relative">
                                                    <div className="w-100 outline">
                                                        <div className="bg-success line-head text-center" style={{ color: "#FFF", fontSize: 11, height: 16, width: (finish ? 100 : ((indexQuestion) / detail.total) * 100) + "%" }}>
                                                            {(finish ? 100 : (((indexQuestion) / detail.total) * 100).toFixed(0)) + "%"}
                                                        </div>
                                                    </div>
                                                    <span className="fas fa-running icon-running" style={{ left: (finish ? 100 : ((indexQuestion) / detail.total) * 100) + "%" }}></span>
                                                    <span className="fas fa-flag-checkered icon-running" style={{ right: 0 }}></span>
                                                </div>
                                                <div className="w-100 text-center  mb-3">
                                                    {indexQuestion + 1}/{detail && detail.total} Câu hỏi
                                                </div>
                                                {!finish ?
                                                    <>
                                                        <div className="h-400 question-choisce">
                                                            <div className="font-weight-bold p-2 bg-info text-light d-flex justify-content-between">
                                                                <div>Câu hỏi {indexQuestion + 1}</div>
                                                                {/* <div className="text-warning"><input type="checkbox" checked={(considers && considers[listQuestion[indexQuestion].id]) ? true : false} onChange={onChangeConsider} value={listQuestion[indexQuestion].id} /> Đang cân nhắc</div> */}
                                                            </div>
                                                            {listQuestion[indexQuestion].parent_title && <div className="p-2 border-bottom">
                                                                <GenderCode text_string={listQuestion[indexQuestion].parent_title} />
                                                            </div>}
                                                            <div className="p-3 title-question">
                                                                <GenderCode text_string={listQuestion[indexQuestion].title} />
                                                            </div>
                                                            <div>
                                                                <SingleChoiceQuestion
                                                                    key={indexQuestion}
                                                                    index={indexQuestion}
                                                                    item={listQuestion[indexQuestion]}
                                                                    test={''}
                                                                    onAnswer={onAnswer}
                                                                ></SingleChoiceQuestion>
                                                            </div>
                                                            <div className="w-100 d-block d-sm-none mt-2">
                                                                {textStatus}
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-between p-2 action-race">
                                                            <div> <button className="btn btn-warning" onClick={() => {
                                                                saveStudentAnswer(indexQuestion, listQuestion[indexQuestion].id_student_answer, 0, 1)
                                                                onNextQuestion()
                                                            }} >Bỏ qua</button></div>
                                                            <div className="w-100 d-none d-sm-block text-result pl-3 pr-3 ">
                                                                {textStatus}
                                                            </div>
                                                            <div>
                                                                {checkChoice || (listQuestion[indexQuestion].students_answers) ?
                                                                    <>
                                                                        {indexQuestion < detail.total - 1 ?
                                                                            <button className="btn btn-success" onClick={() => {
                                                                                if (indexQuestion < detail.total - 1) {
                                                                                    onNextQuestion()
                                                                                }
                                                                            }} >Tiếp tục</button>
                                                                            :
                                                                            <button className="btn btn-success" onClick={() => { onFinish() }} >Kết thúc</button>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <button className="btn btn-info" onClick={() => {
                                                                        checkQuestion(listQuestion[indexQuestion], null)
                                                                    }}>Kiểm tra</button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <h3 className="w-100 text-center mt-5">Chúc mừng bạn đã hoàn thành {detail.title}</h3>
                                                        <h4 className="w-100 text-center mt-2">Số điểm của bạn đạt được hiện tại là: {myPoint}/{detail.total}</h4>
                                                        <div className="w-100 text-center mt-2">
                                                            <a className="text-danger" href={"/books-exercise/" + dataExam.exam_student_test.test_id}><span className="fas fa-flag-checkered"></span> <strong>Xem kết quả</strong> <i className="fas fa-angle-double-right"></i></a>
                                                        </div>
                                                    </>
                                                }

                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {listQuestion && detail ?
                        <div className="card height-auto card-question mt-2">
                            <div className="card-body">
                                <FeedBackComment question_id={listQuestion[indexQuestion].id} test_id={detail.id} exam_student={examStudent} />
                            </div>
                        </div>
                        : <></>
                    }
                </div>
            )}
        </AkkhorLayout>
    );
}

