import React, { useState, useEffect } from "react";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/Api";
import { useSelector } from "react-redux";
import Loading from "./Loading";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { checkRole } from "../model";
import { Table } from "react-bootstrap";
import Moment from "react-moment";
import FastLogin from "./FastLogin";
import PartsOfMember from "./PartsOfMember";
import ResetPass from "./ResetPass";
import Pagination from "react-js-pagination";

function CustomList(props) {
    const parsed = window.location.search.replace('?', '');
    let { children,listCheck, idrole, name, linkaction, linkactionadd, defaultLimit, upload, params, columns, onlyList, noAction, moresearch, refresh, TopBar, changePos } = props;
    const { user } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const limit = defaultLimit ? defaultLimit : 10;
    const [totalRecord, setTotalRecord] = useState(defaultLimit ? defaultLimit : 10);
    const [change, setChange] = useState(false);
    const allowAdd = checkRole(user, idrole, 'add');
    const allowEdit = checkRole(user, idrole, 'edit');
    const allowDelete = checkRole(user, idrole, 'delete');
    const allowUpload = checkRole(user, idrole, 'upload');
    const allowView = checkRole(user, idrole, 'view');
    const allowAccept = checkRole(user, idrole, 'approve');
    const allowExport = checkRole(user, idrole, 'export');
    const baihoc_manager = checkRole(user, idrole, 'baihoc_manager');
    const manhinh_manager = checkRole(user, idrole, 'manhinh_manager');

    useEffect(
        function () {
            let mounted = true;
            if (mounted) {
                setRows([]);
                setLoading(true)
            }
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token,
                    'Content-Type': 'application/json; charset=UTF-8',
                    'search': JSON.stringify(moresearch)
                }
            }
            Axios.get(URL_LIST_ALL + name + "?&page=" + page + "&limit=" + limit + (params ? '&' + params : '') + (parsed ? '&' + parsed : ''), config)
                .then((res) => {
                    if (mounted) setLoading(false)
                    if (res.data.status === "success") {
                        if (mounted) {
                            setRows(res.data.data);
                            setTotalRecord(res.data.total);
                        }
                    } else console.log(res.data.message);
                })
                .catch((err) => {
                    if (mounted) setLoading(false)
                });

            return () => (mounted = false);

        },
        [name, change, user, page, limit, params, parsed, moresearch, refresh]
    );

    const actionDelete = (id) => {
        confirmAlert({
            title: "Thông báo",
            message: "Bạn có chắc muốn xoá dữ liệu",
            buttons: [
                {
                    label: "Có",
                    onClick: () => {
                        Axios.delete(URL_LIST_ALL + name + '/' + id, user.config).then(res => {

                            if (res.data.status === "success") {
                                setChange(!change);
                            } else alert(res.data.message);
                        }).catch(err => console.log(err));
                    },
                },
                { label: "Không" },
            ],
        });
    }
    const actionDeleteAll = () => {
        confirmAlert({
            title: "Thông báo",
            message: "Bạn có chắc muốn xoá dữ liệu",
            buttons: [
                {label: "Có",
                    onClick: () => {
                        if(listCheck){
                            listCheck.forEach(item => {
                                Axios.delete(URL_LIST_ALL + name + '/' + item, user.config).then(res => {
                                    if (res.data.status === "success") {
                                    } else alert(res.data.message);
                                }).catch(err => console.log(err));
                            });
                            setChange(!change);
                        }
                    },
                },
                { label: "Không" },
            ],
        });
    }

    const changePosition = (id, type) => {
        var data = { name: name, type: type }
        Axios.put(URL_LIST_ALL + 'change_position/' + id, data, user.config).then(res => {
            if (res.data.status === "success") {
                setChange(!change);
            } else alert(res.data.message);
        }).catch(err => console.log(err));
    }

    return (
        <>
            {loading && (<Loading />)}
            {!onlyList && <div className="d-flex justify-content-end mb-3">
                {TopBar && <>{TopBar}</>}
                {listCheck && listCheck.length > 0 &&
                    <button className="btn btn-sm btn-danger m-2" onClick={()=>actionDeleteAll()}>Xóa mục đã chọn</button>
                }
                {allowExport &&
                    <Link
                        to={"/export-" + (linkaction ? linkaction : name)}
                        className="btn btn-info m-2"
                        style={{ fontSize: 13, fontWeight: '600', color: "#FFF" }}>
                        <i className="fas fa-plus-"></i> Xuất file
                    </Link>}
                {!noAction && allowAdd &&
                    <Link
                        to={"/add-" + (linkactionadd ? linkactionadd : name)}
                        className="btn btn-success m-2"
                        style={{ fontSize: 13, fontWeight: '600' }}>
                        <i className="fas fa-plus-"></i> Thêm mới
                    </Link>}
                {!upload && allowUpload &&
                    <Link
                        to={"/upload-" + (linkaction ? linkaction : name)}
                        className="btn btn-info m-2"
                        style={{ fontSize: 13, fontWeight: '600', color: "#FFF" }}>
                        <i className="fas fa-plus-"></i> Upload
                    </Link>}
                
            </div>}

            {columns && columns.length > 0 &&
                <>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                {columns.map((item, i) => {
                                    return <th key={i}>{item.label}</th>
                                })}
                                {!noAction && (allowDelete || allowEdit || allowView || allowAccept) && <td>Thao tác</td>}
                                {manhinh_manager && name == 'khoahoc_baihoc' &&
                                    <th>Quản lý màn hình</th>
                                }
                            </tr>

                        </thead>
                        <tbody>
                            {rows && rows.length > 0 && rows.map((itemTr, iTr) => {
                                // let idrow = itemTr.id;
                                return (
                                    <tr key={iTr}>
                                        {columns.map((itemTd, iTd) => {
                                            let result = itemTr[itemTd.name]
                                            if (itemTd.name === 'datecreate' || itemTd.name === 'datemodified' || itemTd.name === 'datesend') {
                                                if (itemTr[itemTd.name]) {
                                                    let time = new Date(itemTr[itemTd.name] * 1000);
                                                    result = <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>
                                                }
                                            }
                                            if (itemTd.type && itemTd.type === 'fastlogin') {
                                                result = <FastLogin id={itemTr[itemTd.name]} />;
                                            }
                                            if (itemTd.name === 'partofmember') {
                                                result = <PartsOfMember id={itemTr[itemTd.name]} />;
                                            }
                                            if (itemTd.type && itemTd.type === 'resetpass') {
                                                result = <ResetPass id={itemTr[itemTd.name]} />;
                                            }
                                            if (itemTd.name === 'status') {
                                                result = itemTr[itemTd.name] === 1 ? "Hoạt động" : "Ngưng hoạt động";
                                            }
                                            if (itemTd.name === 'datestart' || itemTd.name === 'date_start' || itemTd.name === 'dateend' || itemTd.name === 'date_end' || itemTd.name === 'date_check' || itemTd.name === 'datecheck') {
                                                if (itemTr[itemTd.name]) {
                                                    let time = new Date(itemTr[itemTd.name]);
                                                    result = <Moment format="DD/MM/YYYY">{time}</Moment>
                                                }
                                            }
                                            if (itemTd.options && itemTd.options.customBodyRender) {
                                                let rs = itemTd.options.customBodyRender
                                                return <td key={iTd}>{rs(itemTr[itemTd.name], iTd)}</td>
                                            } else {
                                                return <td key={iTd}>{result}</td>
                                            }

                                        })}

                                        {manhinh_manager && name == 'khoahoc_baihoc' &&
                                            <td>
                                                <Link className="btn btn-sm btn-success" to={"/man-hinh?baihocid=" + itemTr.id}>Quản lý các màn hình</Link>
                                            </td>
                                        }
                                        {!noAction && (allowEdit || allowDelete || allowView || allowAccept) &&
                                            <td>
                                                {allowEdit && <Link to={"/edit-" + (linkaction ? linkaction : name) + "/" + itemTr.id} className="btn btn-sm btn-warning m-1" data-toggle="tooltip" data-placement="bottom" title="Sửa"><i className="fas fa-pencil-alt"></i></Link>}
                                                {allowDelete && <button className="btn btn-sm btn-danger m-1" onClick={() => actionDelete(itemTr.id)} data-toggle="tooltip" data-placement="bottom" title="Xóa"><i className="fas fa-trash-alt"></i></button>}
                                                {allowView && name !== 'users_onday' && <Link to={"/view-" + (linkaction ? linkaction : name) + "/" + itemTr.id} className="btn btn-sm btn-warning m-1" data-toggle="tooltip" data-placement="bottom" title="Xem"><i className="fas fa-eye"></i></Link>}
                                                {allowAccept && <Link to={"/approve-" + (linkaction ? linkaction : name) + "/" + itemTr.id} className="btn btn-sm btn-success m-1" data-toggle="tooltip" data-placement="bottom" title="Phê duyệt">Xem</Link>}
                                                {changePos && <div className="form-pos">
                                                    <button className="btn btn-sm btn-outline-info" type="button" title="Di chuyển lên" onClick={() => changePosition(itemTr.id, 'up')}><span className="fas fa-angle-up"></span></button>
                                                    <button className="btn btn-sm btn-outline-info" type="button" title="Di chuyển xuống" onClick={() => changePosition(itemTr.id, 'down')}><span className="fas fa-angle-down"></span></button>
                                                </div>}
                                            </td>
                                        }

                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </Table>
                    <Pagination
                        activePage={page}
                        itemsCountPerPage={limit}
                        totalItemsCount={totalRecord}
                        pageRangeDisplayed={5}
                        itemClass="page-item"
                        linkClass="page-link"
                        prevPageText="<"
                        nextPageText=">"
                        onChange={setPage}
                    />
                </>
            }
        </>
    );
}
export default React.memo(CustomList)
//https://github.com/gregnb/mui-datatables