import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveAnswers } from "../../../redux/Actions";

export default function QTrueFlase(props) {
    const {item,currentAnswer} = props;
    const {Answers} = useSelector(state=>state.save);
    const dispatch = useDispatch();

    let answers = item.answers;
    const [choice, setChoice] = useState(currentAnswer)
    
    const chooseItem=(stt) => {
        setChoice(stt)
        let newListAnswers = {...Answers}
        newListAnswers[item.id] = {question:item, answers:stt, percent:(stt+'')===(answers.correct+'')?100:0};
        dispatch(saveAnswers(newListAnswers))
    }
    return (<div className="col-12 form-group mt-3">
    <button className={"btn btn-sm "+(choice===false?' btn-info':' btn-secondary')} onClick={()=>chooseItem(false)} > Sai </button>
    <button className={"btn btn-sm ml-3 "+(choice===true?' btn-info':' btn-secondary')} onClick={()=>chooseItem(true)}> Đúng </button>
</div>);
}
