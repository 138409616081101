import UserDropDown from "../../component/UserDropDown";
import AkkhorLayout from "../../layout/Akkhor";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import React, { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import parse from 'html-react-parser'
import PreviewQuestion from "../../component/PreviewQuestion";
import { URL_LIST_ALL } from "../../configs/Api";
import Axios from "axios";
import CustomList from "../../component/CustomList";

export default function ListQuestion(props) {
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const { user } = useSelector((state) => state.auth);
  const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
  const localgrade_id = user && user.data && user.data.class && user.data.class.grade_id > 0 ? user.data.class.grade_id : 0;
  const [params, setParam] = useState('join=1');
  const [grade_id, setGrade_id] = useState(localgrade_id > 0 ? localgrade_id : 'all');
  const [subject_id, setSubject_id] = useState('all');
  const [level_id, setLevel_id] = useState('all');
  const [scope_id, setScope_id] = useState('all');
  const [type_id, setType_id] = useState('all');
  const [tagID, setTagID] = useState('all');
  const [listSubjects, setListSubject] = useState([]);
  const [treeId, setTreeId] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [title, setTitle] = useState('');
  const [listCheck, setListCheck] = useState([]);

  const handleCallback = (start, end, label) => {
    setStartDate(start)
    setEndDate(end)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = 'join=1'
    if (title !== '') _param += "&title=" + title
    if (grade_id > 0) _param += "&grade_id=" + grade_id
    if (subject_id > 0) _param += "&subject_id=" + subject_id
    if (level_id > 0) _param += "&level_id=" + level_id
    if (scope_id > 0) _param += "&scope_id=" + scope_id
    if (type_id > 0) _param += "&type_id=" + type_id
    if (treeId > 0) _param += "&tree_id=" + treeId
    if (tagID !== 0) _param += "&tag_id=" + tagID
    if (startDate > 0 && endDate > 0) _param += "&daterange=" + startDate.format('YYYY-MM-DD') + "_" + endDate.format('YYYY-MM-DD')
    setParam(_param);
  };

  const columns = [
    {
      name: "id", label: "Chọn", options: {
        filter: false, sort: true,
        customBodyRender: (value) => {
          const time = new Date(value * 1000);
          return <div className="w-100 d-flex justify-content-center">
            <input type="checkbox" defaultValue={value} checked={listCheck.includes(value) ? true : false} onChange={() => {
              let clone_check = [...listCheck]
              if (clone_check.includes(value)) {
                var index = clone_check.indexOf(value)
                if (index !== -1) {
                  clone_check.splice(index, 1);
                }
              } else {
                clone_check.push(value)
              }
              setListCheck(clone_check)
            }} />
          </div>;
        },
      }
    },
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    {
      name: "title", label: "Câu hỏi", options: {
        filter: false, sort: true,
        customBodyRender: (value) => parse(value),
      },
    },

    { name: "typetitle", label: "Dạng câu hỏi", options: { filter: false, sort: true } },
    { name: "fullname", label: "Người tạo", options: { filter: false, sort: true } },
    {
      name: "datecreate", label: "Ngày đăng", options: {
        filter: false, sort: true,
        customBodyRender: (value) => {
          const time = new Date(value * 1000);
          return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
        },
      },
    },
    {
      name: "id", label: "Xem trước", options: {
        filter: true, sort: true,
        customBodyRender: (value, index) => {
          return <PreviewQuestion index={index} id={value} refresh={() => setRefresh(!refresh)} />
        }
      }
    }
  ];

  useEffect(() => {
    if (grade_id) {
      Axios.get(URL_LIST_ALL + "subjects?key=&istree=1&orderby=title-ASC&page=1&limit=50&school_id=" + school_id + "&grade_id=" + grade_id, user.config).then((res) => {
        let localList = []
        localList = localList.concat(res.data.data)
        setListSubject(localList);

      }).catch((err) => { console.log(err) });
    }
  }, [grade_id, user])


  return (
    <AkkhorLayout idrole="exam_question" roleignone={user.data.typeaccount === 2}>
      <div className="card pb-0 height-auto mb-3 mt-5">
        <div className="card-body">
          <div className="d-flex pb-2">
            <div className="mr-auto">
              <h3>Tìm kiếm câu hỏi</h3>
            </div>
            <div className="p-2">
              <Link to={"/add-at_exam_questions"}>
                <button className="btn btn-sm btn-success">Thêm mới</button>
              </Link>
            </div>
            <div className="p-2">
              <Link to={"/upload-question"}>
                <button className="btn btn-sm btn-success">Upload câu hỏi</button>
              </Link>
            </div>
          </div>
          <form className="new-added-form" method="get" >
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-12 form-group">
                <label>Tiêu đề</label>
                <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Tìm tiêu đề" className="form-control height32" />
              </div>
              <div className="col-xl-3 col-lg-3 col-12 form-group">
                <label>Khóa học</label>
                <UserDropDown apiname={"khoahoc?limit=10000&orderby=title-ASC"} placeholder="Chọn khối" colvalue={"khoahoc.id"} colname={"khoahoc.title"} defaultValue={grade_id} name="grade_id"
                  onChange={async (e) => { setGrade_id(e); setSubject_id('all') }} all={true}
                ></UserDropDown>
              </div>
              <div className="col-xl-3 col-lg-3 col-12 form-group">
                <label>Cây kiến thức</label>
                <UserDropDown apiname={"exam_tree?limit=10000&orderby=title-ASC"+ (grade_id && "&grade_id="+ grade_id)} placeholder="Chọn cây kiến thức" colvalue={"exam_tree.id"} colname={"exam_tree.title"} defaultValue={treeId} name="tree_id"
                  onChange={async (e) => { setTreeId(e)}} all={true}
                ></UserDropDown>
              </div>
              <div className="col-lg-3 col-12 form-group">
                <label> Mức độ nhận biết </label>
                <UserDropDown apiname={"exam_param?page=1&orderby=title-ASC&limit=500&idtype=4&school_id=" + school_id} placeholder="Chọn mức độ" colvalue={"exam_param.id"} defaultValue={level_id} colname={"exam_param.title"} name='level_id'
                  onChange={async (e) => { setLevel_id(e) }} all={true}></UserDropDown>
              </div>
              <div className="col-lg-3 col-12 form-group">
                <label> Phạm vi sử dụng</label>
                <UserDropDown apiname={"exam_param?page=1&orderby=title-ASC&limit=500&idtype=5&school_id=" + school_id} placeholder="Chọn phạm vi" colvalue={"exam_param.id"} defaultValue={scope_id} colname={"exam_param.title"} name="scope_id"
                  onChange={setScope_id} all={true}></UserDropDown>
              </div>
              <div className="col-lg-3 col-12 form-group">
                <label> Dạng câu hỏi</label>
                <UserDropDown apiname={"exam_param?page=1&orderby=title-ASC&idtype=2"} placeholder="Chọn phạm vi" colvalue={"exam_param.id"} defaultValue={scope_id} colname={"exam_param.title"} name="type_id"
                  onChange={setType_id} all={true}></UserDropDown>
              </div>
              <div className="col-lg-3 col-12 form-group">
                <label>Thẻ câu hỏi</label>
                <UserDropDown apiname={"at_tags_questions?page=1&orderby=title-ASC&status=1"} placeholder="Chọn phạm vi" colvalue={"at_tags_questions.alias"} defaultValue={scope_id} colname={"at_tags_questions.title"} name="tagID"
                  onChange={setTagID} all={true}></UserDropDown>
              </div>
              <div className="col-lg-6 col-12 form-group">
                <label> Thời gian khởi tạo</label>
                <DateRangePicker initialSettings={{ startDate: startDate, endDate: endDate, maxDate: "", }}
                  onCallback={handleCallback}
                >
                  <div className='d-flex align-items-center'>
                    <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Từ ngày</span>
                    <input
                      className="form-control h-31 form-control-sm"
                      placeholder='Từ ngày'
                      readOnly={true}
                      onChange={(e) => setStartDate(e)}
                      value={startDate > 0 ? startDate.format('YYYY-MM-DD') : ""}
                    />
                    <span className='fas fa-arrow-right mx-4' />
                    <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Đến ngày</span>
                    <input
                      className="form-control h-31 form-control-sm"
                      placeholder='Đến ngày'
                      readOnly={true}
                      onChange={(e) => setEndDate(e)}
                      value={endDate > 0 ? endDate.format('YYYY-MM-DD') : ""}
                    />
                  </div>
                </DateRangePicker>
              </div>
              <div className="col-12 ">
                <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <CustomList name="at_exam_questions" listCheck={listCheck} idrole="exam_question" params={params} title="Ngân hàng câu hỏi" defaultLimit={50} refresh={refresh} columns={columns}>
      </CustomList>
    </AkkhorLayout>
  );
}
