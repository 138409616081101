import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import UserDropDown from "../../component/UserDropDown";
import UserDropDownClass from "../../component/UserDropDownClass";
import AlertErrorForm from "../../component/AlertErrorForm";
import Spinner from "../../component/Spinner";
import DateRangePicker from "react-bootstrap-daterangepicker";
import ViewStep from "./ViewStep";
import { CONFIG_STEP_TEST_1 } from "../../configs";
import moment from "moment";
import { URL_LIST_ALL } from "../../configs/Api";
import Axios from "axios";

export default function PhieuBaiTapStep1(props) {
  const { user } = useSelector((state) => state.auth);
  const { nextStep, detail } = props
  const [dateBegin, setDateBegin] = useState(undefined);
  const [dateEnd, setDateEnd] = useState(undefined);
  const formatDate = 'DD-MM-YYYY'
  const defaultdetail = { author_id: user.data.id, title: '', status: 1, number: 0, random_question: 0, total_point: 10, grade_id: '', subject_id: '', listclass: '', questions: {}, datebegin: '', dateend: '' }

  const formik = useFormik({
    initialValues: detail ? detail : defaultdetail,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      title: Yup.string().trim().nullable().required('Nhập tên phiếu bài tập').max(150, "Độ dài không quá 150 ký tự"),
      grade_id: Yup.string().trim().nullable().required('Vui lòng chọn khối'),
      datebegin: Yup.string().trim().nullable().required('Nhập thời gian bắt đầu'),
      dateend: Yup.string().trim().nullable().required('Nhập thời gian kết thúc')
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false)
      nextStep(values)
    },
  });

  const handleCallback = (start, end, label) => {
    formik.setFieldValue("datebegin", Math.floor(start / 1000.0))
    formik.setFieldValue("dateend", Math.floor(end / 1000.0))
    setDateBegin(moment(new Date(start)).format(formatDate))
    setDateEnd(moment(new Date(end)).format(formatDate))
  }

  const setSubject = (value) => {
    Axios.get(URL_LIST_ALL + "subjects?grade_id=" + value, user.config)
      .then(async (res) => {
        if (res.data.status === "success") {
          let data_subject = res.data.data
          if (data_subject) {
            formik.setFieldValue("subject_id", data_subject[0].id)
          }
        } else console.log(res.data.message);
      }).catch((err) => { console.log(err) });
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-md-6 offset-md-3">
        <ViewStep step={0} arrStep={CONFIG_STEP_TEST_1} />
        <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-12 form-group">
              <label>Tên phiếu bài tập <span className="text-danger">*</span></label>
              <input type="text" className="form-control h-31" placeholder="Nhập tên phiếu bài tập" name="title"
                {...formik.getFieldProps("title")}
              ></input>
              <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
            </div>
            <div className="col-12 form-group">
              <label>Chọn khóa học <span className="text-danger">*</span></label>
              <UserDropDown apiname={"khoahoc?orderby=title-ASC&"} placeholder="Chọn khóa học" colvalue={"khoahoc.id"} colname={"khoahoc.title"} formik={formik} name="grade_id"
                onChange={async (e) => { await formik.setFieldValue("grade_id", e); setSubject(e) }}
              ></UserDropDown>
              <AlertErrorForm formik={formik} name="grade_id"></AlertErrorForm>
            </div>
            <input type="hidden" placeholder="" className="form-control height32" {...formik.getFieldProps("subject_id")} autoComplete="off" />

            <div className="col-12 form-group">
              <label> Thời gian mở <span className="text-danger">*</span></label>
              <DateRangePicker initialSettings={{ startDate: dateBegin, endDate: dateEnd, minDate: new Date(), timePicker: false }}
                onCallback={handleCallback}
              >
                <div className='d-flex align-items-center'>
                  <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Từ ngày</span>
                  <input
                    className="form-control h-31 form-control-sm"
                    placeholder='Từ ngày'
                    readOnly={true}
                    onChange={(e) => setDateBegin(e)}
                    value={dateBegin}
                  />
                  <span className='fas fa-arrow-right mx-4' />
                  <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Đến ngày</span>
                  <input
                    className="form-control h-31 form-control-sm"
                    placeholder='Đến ngày'
                    readOnly={true}
                    onChange={(e) => setDateEnd(e)}
                    value={dateEnd}
                  />
                </div>
              </DateRangePicker>
              <div className="row">
                <div className="col-6"><AlertErrorForm formik={formik} name="datebegin"></AlertErrorForm></div>
                <div className="col-6"><AlertErrorForm formik={formik} name="dateend"></AlertErrorForm></div>
              </div>
            </div>
            <div className="col-12 form-group">
              <label>Chọn lớp <span className="text-danger">*</span> </label>
              <UserDropDownClass apiname={"class?key=&page=1&orderby=title-ASC&limit=500&grade_id=" + (formik.values.grade_id)} placeholder="Tất cả lớp" detail={detail} colvalue={'class.id'} formik={formik} colname={'class.title'} name='listclass'
                onChange={async (e) => { await formik.setFieldValue('listclass', e); }}
              ></UserDropDownClass>
              <AlertErrorForm formik={formik} name="listclass"></AlertErrorForm>
            </div>
            <div className="col-lg-3 col-12 form-group">
              <label>Có trộn câu hỏi hay không </label>
              <select className="form-control height32" {...formik.getFieldProps("random_question")}>
                <option value="1" >Có</option>
                <option value="0" >Không</option>
              </select>
            </div>
            <div className="col-12 form-group mg-t-8 text-center">
              <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" > {formik.isSubmitting ? <Spinner /> : <>Tiếp tục</>}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
