import React from "react";
import { useSelector } from "react-redux";
import AkkhorLayout from "../layout/Akkhor";
import DashboardTeacher from "./DashboardTeacher";
import DashboardAdmin from "./DashboardAdmin";
import DashboardStudent from "./DashboardStudent";
import DashboardStudentBook from "./DashboardStudentBook";
import { useParams } from "react-router-dom";

export default function HomePage() {
  const { user } = useSelector((state) => state.auth);
  const { id } = useParams();
  return (
    <AkkhorLayout idrole="dashboard" roleignone={user.data.typeaccount===2 || user.data.typeaccount===4}>
        {user.data.typeaccount===0&&<DashboardAdmin />}
        {user.data.typeaccount===1&&<DashboardTeacher id={id}/>}
        {user.data.typeaccount===2&&<DashboardStudent id={id}/>}
        {user.data.typeaccount===4&&<DashboardStudentBook />}
    </AkkhorLayout>
  );
}
