import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/Akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/Api";
import { LIST_NATION, TYPE_ACCOUNT } from "../configs";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import UserDropDown from "../component/UserDropDown";


export default function FormUsersPage() {
    const { user } = useSelector((state) => state.auth);
    const { id } = useParams();
    const [autoRefresh, setAutoRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [dateSelect, setDateSelect] = useState({
        birthday: null
    });
    const defaultdetail = {
        typeaccount: 0, author_id: user.data.id, username: '', password: '', repassword: '', fullname: '', lastname: '', shortname: '', gender: '', mobile: '', email: '', birthday: '', address: '', nation: '', status: 1, role_id: ''
    }
    const handleDate = (name, value) => {
        formik.setFieldValue(name, moment(value).format('YYYY-MM-DD'))
        formik.setFieldTouched(name)
        let newDate = Object.assign({}, dateSelect)
        newDate[name] = value
        setDateSelect(newDate)
    };
    useEffect(
        function () {
            if (id > 0) {
                let mounted = true;
                Axios.get(URL_LIST_ALL + "users/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            
                            if (mounted) {
                                setDetail(res.data.data);
                                setDateSelect({birthday:res.data.data.birthday})
                            }
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [user, id, autoRefresh]
    );
    let validForm = {
        username: Yup.string().trim().nullable().required('Nhập tên đăng nhập').min(6, 'Tên đăng nhập dài tối thiểu 6 ký tự').max(40, 'Tên đăng nhập dài tối đa 40 ký tự').trim().matches(/^[@.a-z0-9]+$/, 'Tên đăng nhập không đúng định dạng'),
        password: Yup.string().trim().nullable().required('Nhập mật khẩu').min(6, "Độ dài mật khẩu từ 6 ký tự trở lên").max(150, "Độ dài không quá 150 ký tự"),
        repassword: Yup.string().trim().nullable().required('Nhập xác nhận mật khẩu').oneOf([Yup.ref('password'), null], 'Xác nhận mật khẩu không chính xác'),
        fullname: Yup.string().trim().nullable().required('Nhập họ và tên ').max(150, "Độ dài không quá 150 ký tự"),
        lastname: Yup.string().trim().nullable().required('Nhập tên').max(50, "Độ dài không quá 50 ký tự"),
        shortname: Yup.string().trim().nullable().required('Nhập tên viết tắt'),
        gender: Yup.string().trim().nullable().required('Chọn giới tính'),
        email: Yup.string().trim().nullable().required('Nhập địa chỉ email'),
        role_id: Yup.string().trim().nullable().when("typeaccount", (type) => {
            if (type < 2) return Yup.string().required("Chọn quyền hạn tài khoản")
        })
    }
    if (id > 0) {
        delete validForm.username
        delete validForm.password
        delete validForm.repassword
    }
    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape(validForm),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'users/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setAutoRefresh(!autoRefresh)
                    if (res.data.status === "success") {
                        setAutoRefresh(!autoRefresh)
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật tài khoản thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                Axios.post(URL_LIST_ALL + 'users', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setAutoRefresh(!autoRefresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới tài khoản thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    return (
        <AkkhorLayout idrole="list-users">
            <div className="card height-auto mt-5">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>{!id ? 'Thêm mới' : 'Sửa'} tài khoản</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            {!id && <>
                                <div className="col-xl-3 col-lg-6 col-12 form-group">
                                    <label>Tên đăng nhập <span className="text-danger">*</span></label>
                                    <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("username")} autoComplete="off" />
                                    <AlertErrorForm formik={formik} name="username"></AlertErrorForm>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-12 form-group">
                                    <label>Mật khẩu <span className="text-danger">*</span></label>
                                    <input type="password" placeholder="" className="form-control height32" {...formik.getFieldProps("password")} autoComplete="off" />
                                    <AlertErrorForm formik={formik} name="password"></AlertErrorForm>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-12 form-group">
                                    <label>Xác nhận mật khẩu<span className="text-danger">*</span></label>
                                    <input type="password" placeholder="" className="form-control height32" {...formik.getFieldProps("repassword")} autoComplete="off" />
                                    <AlertErrorForm formik={formik} name="repassword"></AlertErrorForm>
                                </div>
                            </>}
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Họ và tên <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("fullname")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="fullname"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Tên  <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32"  {...formik.getFieldProps("lastname")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="lastname"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Tên viết tắt  <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32"  {...formik.getFieldProps("shortname")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="shortname"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Giới tính <span className="text-danger">*</span></label>
                                <select className="form-control height32" {...formik.getFieldProps("gender")}>
                                    <option value="" disabled>Hãy chọn giới tính</option>
                                    <option value="1">Nam</option>
                                    <option value="2">Nữ</option>
                                    <option value="3">Khác</option>
                                </select>
                                <AlertErrorForm formik={formik} name="gender"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Ngày sinh</label>
                                <DateTimePicker onChange={(value) => { handleDate('birthday', value) }} value={dateSelect.birthday ? new Date(dateSelect.birthday) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                                <AlertErrorForm formik={formik} name="birthday"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Điện thoại</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("mobile")} />
                                <AlertErrorForm formik={formik} name="mobile"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Email <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("email")} />
                                <AlertErrorForm formik={formik} name="email"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Địa chỉ</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("address")} />
                            </div>

                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Dân tộc</label>
                                <select className="form-control height32" {...formik.getFieldProps("nation")}>
                                    <option value="" >Chọn dân tộc</option>
                                    {LIST_NATION.map((nation, index) => <option value={nation} key={index.toString()}>{nation}</option>)}
                                </select>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Loại tài khoản</label>
                                <select className="form-control height32" {...formik.getFieldProps("typeaccount")}>
                                    {TYPE_ACCOUNT.map((item, index) => <option key={index} value={index}>{item}</option>)}
                                </select>
                                <AlertErrorForm formik={formik} name="typeaccount"></AlertErrorForm>
                            </div>

                            {formik.values.typeaccount < 2 && <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Quyền  <span className="text-danger">*</span></label>
                                <UserDropDown apiname={"roles"} placeholder="Chọn quyền" colvalue={'roles.id'} colname={'roles.title'} formik={formik} name='role_id'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('role_id', e)
                                    }}></UserDropDown>
                                <AlertErrorForm formik={formik} name="role_id"></AlertErrorForm>
                            </div>}

                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu tài khoản</>}</button>
                                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
