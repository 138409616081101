import React from 'react';
import parse from 'html-react-parser';
import CodeMirror from '@uiw/react-codemirror';
import { langs } from '@uiw/codemirror-extensions-langs';
import { githubLightInit } from '@uiw/codemirror-theme-github';

const GenderCode = ({ text_string, hideNumber }) => {
  const selectLang = (type) => {
    switch (type) {
      case 'javascript':
        return langs.jsx();
      case 'html':
        return langs.html();
      case 'python':
        return langs.python();
      case 'php':
        return langs.php();
      case 'css':
        return langs.css();
      case 'java':
        return langs.java();
      case 'sql':
        return langs.sql();
      case 'kotlin':
        return langs.kotlin();
      case 'c':
        return langs.c();
      default:
        return langs.html();
    }
  };

  const renderWithCodeBlocks = (text) => {
    const codeRegex = /<code data-type="(\w+)">([\s\S]*?)<\/code>/g;
    const parts = text.split(codeRegex);
    const elements = [];

    parts.forEach((part, index) => {
      if (index % 3 === 0) {
        // Thay thế <class int> bằng &lt;class int&gt; để tránh phân tích sai
        const sanitizedPart = part.replace(/</g, '&lt;').replace(/>/g, '&gt;');
        elements.push(<span key={`text-${index}`}>{parse(sanitizedPart)}</span>);
      } else if (index % 3 === 1) {
        const lang = part;
        const codeContent = parts[index + 1].trim();
        elements.push(
          <CodeMirror
            key={`code-${index}`}
            height="auto"
            className={hideNumber ? 'hidenumber' : 'shownumber'}
            theme={githubLightInit({
              settings: {
                caret: '#c6c6c6',
                fontFamily: 'monospace',
              },
            })}
            readOnly={true}
            extensions={[selectLang(lang)]}
            value={codeContent}
            onChange={() => {}}
          />
        );
      }
    });

    return elements;
  };

  return (
    <div>
      {text_string.includes('<code') ? (
        renderWithCodeBlocks(text_string)
      ) : (
        <pre className='mb-0'>{parse(text_string + '')}</pre>
      )}
    </div>
  );
};

export default GenderCode;
