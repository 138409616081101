import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveAnswers } from "../../../redux/Actions";

export default function QTrueFlase(props) {
    const {item,currentAnswer,checkQuestions,objAnswers} = props;
    const {Answers} = useSelector(state=>state.save);
    const dispatch = useDispatch();
    const answers = item.answers;
    const answersCorrects = item.corrects;
    const [choice, setChoice] = useState(currentAnswer)
    useEffect(()=>{
        
      
    },[checkQuestions])
    const chooseItem=(stt) => {
        setChoice(stt)
        let newListAnswers = {...Answers}
        newListAnswers[item.id] = {question:item, answers:stt, percent:(stt+'')===(answers + '')?100:0,point:stt == item.corrects?1:0};
        dispatch(saveAnswers(newListAnswers))
        
    }
    return (<div className="col-12 form-group mt-3">
    {objAnswers[item.id]?
        <>
            {
                answersCorrects == choice ?
                    <>
                        <button className={"btn btn-sm "+(answersCorrects===false?' btn-success':' btn-secondary')}  > Sai </button>
                        <button className={"btn btn-sm ml-3 "+(answersCorrects===true?' btn-success':' btn-secondary')} > Đúng </button>
                    </>

                :
                <>
                    <button className={"btn btn-sm "+(choice===false?' btn-danger':' btn-secondary')}  > Sai </button>
                    <button className={"btn btn-sm ml-3 "+(choice===true?' btn-danger':' btn-secondary')} > Đúng </button>
                </>
            }
            
        </>
    :
        <>
            <button className={"btn btn-sm "+(choice===false?' btn-info':' btn-secondary')} onClick={()=>chooseItem(false)} > Sai </button>
            <button className={"btn btn-sm ml-3 "+(choice===true?' btn-info':' btn-secondary')} onClick={()=>chooseItem(true)}> Đúng </button>
        </>
    
    }
    
</div>);
}
