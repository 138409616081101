import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { URL_LIST_ALL } from "../../configs/Api";
import AkkhorLayout from '../../layout/Akkhor';
import Moment from "react-moment";
import { CSVLink } from "react-csv";
import moment from "moment";
import FilterWeek from "../../component/FilterWeek";
//Báo cáo nề nếp học tập cho tất cả các lớp
export default function DiligenPage() {
  const { user } = useSelector((state) => state.auth);
  const [classChoice] = useState(null);
  const [weekChoice, setWeekChoice] = useState(0);
  const [dayChoice, setDayChoice] = useState('all');
  const [timeTable, setTimeTable] = useState([]);
  const [student, setStudent] = useState(null);//Danh sách học sinh vắng mặt
  //Lấy danh sách học sinh
  useEffect(() => {
    let mounted = true;
    if (classChoice && weekChoice) {
        setTimeTable([])
        //Lấy thời koas biểu
        let urlTimeTable=URL_LIST_ALL + "timetable?page=1&limit=500&class_id=" + classChoice+"&week="+weekChoice+'&orderby=date-asc_orderonday-asc';
        if(dayChoice!=='all') urlTimeTable+="&date="+dayChoice;
        Axios.get(urlTimeTable, user.config)
        .then((res) => {
          if (res.data && res.data.status === "success") {
            if (mounted) setTimeTable(res.data.data);
          } else console.log(res.data.message);
        })
        .catch((err) => console.log(err));

        //Lấy danh sách học sinh vắng mặt trong các tiết học
        setStudent([])
        let urlOnday=URL_LIST_ALL + "students_onday?page=1&limit=500&typecheck=1&class_id=" + classChoice+"&week="+weekChoice+'&orderby=date-asc_orderonday-asc'
        if(dayChoice!=='all') urlOnday+="&date="+dayChoice;
        Axios.get(urlOnday, user.config)
        .then((res) => {
          if (res.data && res.data.status === "success") {
            if (mounted){
              const list=res.data.data;
              let newobj={}
              list.map((item)=>{
                if(item.status>2)
                {
                  if(newobj[item.orderonday])newobj[item.orderonday] += ', '+item.fullname
                  else newobj[item.orderonday] = ''+item.fullname
                }
                return item
              })
              setStudent(newobj);
            } 
          } else console.log(res.data.message);
        })
        .catch((err) => console.log(err));
    }
    return () => (mounted = false);
  }, [classChoice,weekChoice,dayChoice,user]);


  const filterDataExport=() => {
    let array=[
      {
        '1':'Tiết học',
        '2':'Môn học',
        '3':'Nội dung',
        '4':'Học sinh vắng',
        '5':'Nhận xét của giáo viên',
        '6':'',
        '7':'Xếp loại tiết học',
        '8':'',
        '9':'',
        '10':'',
        '11':'',
        '12':'',
        '13':'Giáo viên',
      },{
        '1':'1',
        '2':'2',
        '3':'3',
        '4':'4',
        '5':'Ưu điểm',
        '6':'Tồn tại',
        '7':'HT (4)',
        '8':'KL (3)',
        '9':'VS (2)',
        '10':'CC (1)',
        '11':'Cộng (10)',
        '12':'Xếp loại',
        '13':'7'
      }
      
    ];

    timeTable.map((item, i) => {
      if(item.orderonday===1)
      {
        array.push({
          '1':moment(item.date).format('dddd'),
          '2':moment(item.date).format('DD/MM/YYYY'),
          '3':'',
          '4':'',
          '5':'',
          '6':'',
          '7':'',
          '8':'',
          '9':'',
          '10':'',
          '11':'',
          '12':'',
          '13':'',
        })
      }
      array.push({
        '1':item.orderonday,
        '2':item.title,
        '3':item.name,
        '4':student[item.orderonday],
        '5':item.comment_advantages,
        '6':item.comment_defect,
        '7':item.point_learn,
        '8':item.point_discipline,
        '9':item.point_hygiene,
        '10':item.point_diligent,
        '11':item.point_total,
        '13':item.fullname
      }); 
      return item;
    })
    return array
  }

  return (
    <AkkhorLayout idrole="report.report-diary">
      <h4 className="my-2">Báo cáo nề nếp học tập</h4>
      <div className="d-flex justify-content-between" >
      <FilterWeek 
      weekChoice={weekChoice}
      setWeekChoice={setWeekChoice}
      dayChoice={dayChoice}
      setDayChoice={setDayChoice}
      viewtype={'all'}
      allday={true}
      typeclass={1}
      />
      <CSVLink
            data={filterDataExport()}
            filename={ "Nhat-Ky-Lop-Hoc-Tuan-"+weekChoice+"-Nam-"+moment().format('YYYY')+".csv"}
            className="btn"
            target="_blank"
          >
            Tải xuống <span className="fas fa-download text-info"></span>
          </CSVLink>
      </div>
      <div className="mt-3">
      <table className="table table-bordered">
            <thead className="align-items-center bg-secondary text-white text-center">
            <tr>
                <td rowSpan="3">Lớp</td>
                <td rowSpan="3">Sĩ số lớp</td>
                <td rowSpan="3">Học sinh vắng mặt</td>
                <td rowSpan="3">Môn học</td>
                <td colSpan="8">Kiểm tra vở ghi chép</td>
                <td colSpan="6">Kiểm tra bài tập</td>
            </tr>
            <tr>
                <td colSpan="2">Ghi chép đủ nhưng cẩu thả</td>
                <td colSpan="2">Chi chép thiếu</td>
                <td colSpan="2">Không ghi chép</td>
                <td colSpan="2">Vi phạm</td>
                <td colSpan="2">Chưa hoàn thành</td>
                <td colSpan="2">Không làm</td>
                <td colSpan="2">Vi phạm</td>
            </tr>
            <tr>
                <td>Số HS</td>
                <td>Tên HS</td>
                <td>Số HS</td>
                <td>Tên HS</td>
                <td>Số HS</td>
                <td>Tên HS</td>
                <td>Số HS</td>
                <td>Tên HS</td>
                <td>Số HS</td>
                <td>Tên HS</td>
                <td>Số HS</td>
                <td>Tên HS</td>
                <td>Số HS</td>
                <td>Tên HS</td>
            </tr>
        </thead>
            <tbody>
            {timeTable.map((item, i) => (
              <>
              {item.orderonday===1&&<tr>
                <td colSpan="13" className="bg-warning text-capitalize"><Moment locale="vi" format="dddd">{item.date}</Moment> <Moment format="DD/MM/YYYY">{item.date}</Moment></td>
              </tr>}
              <tr>
                  <td>6A0</td>
                  <td>33/35</td>
                  <td>Nguyễn Văn A, Nguyễn Văn B</td>
                  <td>Toán TA</td>
                  <td>1</td>
                  <td>Nguyễn Giang Nam</td>
                  <td>2</td>
                  <td>Nguyễn Văn A, Nguyễn Văn B</td>
                  <td>1</td>
                  <td>Nguyễn Giang Nam</td>
                  <td>2</td>
                  <td>Nguyễn Văn A, Nguyễn Văn B</td>
                  <td>1</td>
                  <td>Nguyễn Giang Nam</td>
                  <td>2</td>
                  <td>Nguyễn Văn A, Nguyễn Văn B</td>
                  <td>1</td>
                  <td>Nguyễn Giang Nam</td>
              </tr>
              </>
            ))}
            </tbody>
        </table>
      </div>
    </AkkhorLayout>
  );
}